import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Query } from 'react-apollo';
import qs from 'qs';
import gql from 'graphql-tag';
import LeaguesList from './LeaguesList';
import LinearProgress from '@material-ui/core/LinearProgress';
import { SectionHeader } from '../../components/chrome';
import { getLeaguesQuery } from './queries/getLeaguesQuery';

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	}
});

class LeaguesContainer extends Component {
	render() {
		const { classes, title, match, history, location } = this.props;
		const params = qs.parse(location.search.slice(1), { strictNullHandling: true });

		let newOrder;
		if (params.order) {
			newOrder = [];
			const orderSplit = params.order.split(',');
			newOrder.push([orderSplit[0], orderSplit[1]]);
		}

		let offset = parseFloat(params.offset) || 0;
		let limit = parseFloat(params.limit) || 10;

		return (
			<SectionHeader
				title="Leagues"
				rightButton={
					<Button variant="outlined" color="secondary" onClick={() => history.push('/leagues/new')}>
						Create League
					</Button>
				}
			>
				<Query
					query={getLeaguesQuery}
					variables={{
						where: null,
						offset: offset || 0,
						limit: limit || 10,
						order: newOrder || [['name', 'ASC']]
					}}
					fetchPolicy="cache-and-network"
					notifyOnNetworkStatusChange={true}
				>
					{({ loading, error, data, subscribeToMore, fetchMore }) => {
						if (loading) return <LinearProgress mode="indeterminate" color="primary" />;
						if (error) return <div style={styles.container}>Error: {error.message}</div>;

						return (
							<div style={{ marginTop: 24, marginBottom: 24 }}>
								<Grid container justify="center" spacing={24}>
									<Grid item xs={12} md={11} lg={10}>
										<LeaguesList
											data={data}
											subscribeToMore={subscribeToMore}
											match={match}
											history={history}
											title={title}
											limit={limit}
											offset={offset}
											loading={loading}
											error={error}
											onLoadMore={() =>
												fetchMore({
													variables: {
														offset: data.leagues.length
														// offset: offset + limit
													},
													updateQuery: (prev, { fetchMoreResult }) => {
														if (!fetchMoreResult) return prev;
														return Object.assign({}, prev, {
															divisions: [...prev.leagues, ...fetchMoreResult.leagues]
														});
													}
												})}
										/>
									</Grid>
								</Grid>
							</div>
						);
					}}
				</Query>
			</SectionHeader>
		);
	}
}

LeaguesContainer.contextTypes = {
	authUser: PropTypes.object,
	getUserProfile: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	changeLoginState: PropTypes.func,
	signIn: PropTypes.func,
	signOut: PropTypes.func
};

LeaguesContainer.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LeaguesContainer);
