import gql from 'graphql-tag';

export const updateTeamMutation = gql`
	mutation UpdateTeam($input: TeamInput) {
		updateTeam(input: $input) {
			id
			name
			slug
			isActive
			captain {
				id
				name
				slug
				username
				email
			}
			coCaptain {
				id
				name
				slug
				username
				email
			}
			league {
				id
				name
				slug
				type
			}
			location {
				id
				name
				slug
			}
			divisions {
				id
				name
				slug
			}
			players {
				id
				name
				username
			}
			# description
			# type
			# isActive
		}
	}
`;
