import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';

const prodConfig = {
	apiKey: 'AIzaSyDKAo0xnVtK_ALUhJ80q-eTsYYnSXnnTBY',
	authDomain: 'dpl-web-prod.firebaseapp.com',
	databaseURL: 'https://dpl-web-prod.firebaseio.com',
	projectId: 'dpl-web-prod',
	storageBucket: 'dpl-web-prod.appspot.com',
	messagingSenderId: '957895322256'
};

const devConfig = {
	apiKey: 'AIzaSyDKAo0xnVtK_ALUhJ80q-eTsYYnSXnnTBY',
	authDomain: 'dpl-web-prod.firebaseapp.com',
	databaseURL: 'https://dpl-web-prod.firebaseio.com',
	projectId: 'dpl-web-prod',
	storageBucket: 'dpl-web-prod.appspot.com',
	messagingSenderId: '957895322256'
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
let db = {};
let auth = {};
let firestore = {};

try {
	if (!firebase.apps.length) {
		firebase.initializeApp(config);
	}

	db = firebase.database();
	// const ref = firebase.database().ref();
	auth = firebase.auth();
	firestore = firebase.firestore();
	firestore.settings({ timestampsInSnapshots: true });
} catch (e) {
	console.error(e.message);
}

export { db, auth, firestore };
