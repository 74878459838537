import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Switch from '@material-ui/core/Switch';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({
	textField: {
		width: 400
	}
});

const InputFeedback = ({ children }) => <div className="text-danger">{children}</div>;

const Label = ({ error, children, ...props }) => {
	return <label {...props}>{children}</label>;
};

class SwitchInput extends Component {
	constructor(props) {
		super(props);
		const { field: { name, value } } = props;
		this.state = {
			[name]: value
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		console.log(event.target);
		this.setState({ [event.target.name]: event.target.checked });
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.field !== this.props.field) {
			this.setState({ [nextProps.field.name]: nextProps.field.value });
		}
	}

	render() {
		const {
			field: { name, value, ...field }, // { name, value, onChange, onBlur }
			form: { touched, errors, ...form }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
			className,
			label,
			classes,
			style,
			...props
		} = this.props;

		const error = errors[name];
		const touch = touched[name];
		// const classes = classnames(
		// 	'form-group',
		// 	{
		// 		'animated shake error': !!error
		// 	},
		// 	className
		// );
		return (
			<div style={style}>
				<FormControl className={classes.formControl}>
					<FormControlLabel
						control={
							<Switch
								{...field}
								checked={this.state[name]}
								onChange={event => {
									// form.setFieldValue(name, event.target.checked);
									this.setState({ [name]: event.target.checked });
									field.onChange(event);
								}}
								id={name}
								// input={<Input name={name} id={`${name}-helper`} />}
								value={value.toString()}
								color="primary"
								{...props}
							/>
						}
						label={this.state[name] === true ? 'Active' : 'Not Active'}
					/>

					{touch && error && <InputFeedback>{error}</InputFeedback>}
				</FormControl>
			</div>
		);
	}
}

SwitchInput.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SwitchInput);
