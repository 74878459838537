import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import Card from '@material-ui/core/Card';
import moment from 'moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import AttachMoney from '@material-ui/icons/AttachMoney';
import { ZeroState, Card, TabContainer } from '../../../components/chrome';
import eightBall from '../../../assets/images/ball/eight-ball.png';
import nineBall from '../../../assets/images/ball/nine-ball.png';
import tenBall from '../../../assets/images/ball/ten-ball.png';
import oneBall from '../../../assets/images/ball/one-ball.png';
import * as routes from '../../../config/routes';
import TournamentCardTabs from '../TournamentCardTabs';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import { isoCountries, getGameType, displayEnumType } from '../../../utils';
import currentUser from '../../../helpers/currentUser';

const styles = theme => ({
	listItem: {
		// padding: 0
	},
	cardNarrow: {
		margin: '24px 0px',
		padding: '5px 10px'
	},
	avatar: {
		backgroundColor: '#44475b',
		color: '#fff'
	}
});

class ChallengesCard extends Component {
	render() {
		const { classes, challenges, currentUser, history } = this.props;

		return (
			<Card nopadding>
				<h3
					style={{
						marginLeft: 24,
						marginRight: 10,
						marginTop: 10,
						marginBottom: 0,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<div>Challenges</div>
					<div>
						<IconButton onClick={() => history.push('/challenges/new')}>
							<AddCircleOutline />
						</IconButton>
					</div>
				</h3>
				<List defaultValue={0} style={{ flex: 1, padding: 0 }}>
					<React.Fragment>
						{(!challenges || challenges.length === 0) && (
								<ZeroState title="You have no pending challenges" style={{ margin: 15 }} />
							)}
						{challenges &&
							challenges.length > 0 &&
							challenges.map((challenge, index) => {
								return (
									<React.Fragment key={index}>
										<Link to={`/challenges/${challenge.id}`}>
											<ListItem className={classes.listItem} button>
												<Avatar
													src={
														challenge.challenger.id === currentUser.id
															? challenge.opponent && challenge.opponent.avatarImg
															: challenge.challenger && challenge.challenger.avatarImg
													}
													className={classes.avatar}
												>
													{challenge.challenger.id === currentUser.id
														? challenge.opponent && challenge.opponent.name.slice(0, 1)
														: challenge.challenger && challenge.challenger.name.slice(0, 1)}
												</Avatar>
												<ListItemText
													primary={
														challenge.challenger.id === currentUser.id
															? `To: ${challenge.opponent && challenge.opponent.name}`
															: `From: ${challenge.challenger &&
																	challenge.challenger.name}`
													}
													secondary={
														<React.Fragment>
															<span style={{ display: 'block' }}>{`${displayEnumType(
																challenge.matchFormat
															)} ${displayEnumType(challenge.gameType)}`}</span>
															<span style={{ display: 'block' }}>
																{moment(challenge.startTime).format('lll')}
															</span>
															<span style={{ display: 'block' }}>
																{challenge.accepted ? 'Accepted' : 'Awaiting approval'}
															</span>
														</React.Fragment>
													}
												/>
											</ListItem>
										</Link>
									</React.Fragment>
								);
							})}
					</React.Fragment>
				</List>
				{challenges &&
					challenges.length > 0 && (
						<Link to={`${routes.CHALLENGES}`}>
							<Button primary="true" style={{ margin: 10 }}>
								View all challenges
							</Button>
						</Link>
					)}
			</Card>
		);
	}
}

ChallengesCard.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithRouter = withRouter(ChallengesCard);
const WithStyles = withStyles(styles)(WithRouter);
export default currentUser(WithStyles);
