import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import auth from '../../auth/gql';

const styles = theme => ({
	layout: {
		width: 'auto',
		display: 'block', // Fix IE11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	paper: {
		textAlign: 'center',
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`
	}
});

class ConfirmEmailPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			error: null,
			emailConfirmed: false,
			invalidToken: false
		};
	}

	componentDidMount() {
		this.handleConfirmEmail();
	}

	handleConfirmEmail = () => {
		const { match } = this.props;
		const email = match.params.email;
		const token = match.params.token;
		if (email && token) {
			auth.confirmEmail(email, token)
				.then(result => {
					if (result && result.data && result.data.confirmEmail && result.data.confirmEmail.token) {
						this.setState({
							emailConfirmed: true
						});
					}
				})
				.catch(error => {
					// console.log(JSON.stringify(error, null, 4));
					if (error && error.message.indexOf('emailConfirmToken is invalid.') >= -1) {
						this.setState({ emailConfirmed: true, invalidToken: true });
					} else {
						this.setState({ error: error.message, emailConfirmed: false });
						this.props.alert.error(`${error.message}`);
					}
				});
		} else {
			throw new Error('No email and token found');
		}
	};

	render() {
		const { classes } = this.props;

		return (
			<div className="page-container">
				<main className={classes.layout}>
					<Paper className={classes.paper}>
						{this.state.emailConfirmed === true && this.state.invalidToken === false ? (
							<div>
								Thank you! Your email has been confirmed.
								<br />
								<Link to="/">Click here to go to your Dashboard.</Link>
							</div>
						) : this.state.emailConfirmed === true && this.state.invalidToken === true ? (
							<div>
								Your email address has already been confirmed.
								<br />
								<Link to="/">Click here to go to your Dashboard.</Link>
							</div>
						) : this.state.error !== null ? (
							<div>
								Your email address could not be confirmed.
								<br />
								<a
									href="#"
									onClick={e => {
										e.preventDefault();
										this.handleConfirmEmail();
									}}
									className="yellow"
								>
									Click here to retry
								</a>
							</div>
						) : null}
					</Paper>
				</main>
			</div>
		);
	}
}

ConfirmEmailPage.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles)(ConfirmEmailPage);

const WithAlert = withAlert(WithStyles);

export default withRouter(WithAlert);
