import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Query } from 'react-apollo';
// import createHistory from 'history/createBrowserHistory';
// import { Card, CardActions, CardHeader, CardText } from "material-ui/Card";
// import RaisedButton from "material-ui/RaisedButton";
import LinearProgress from '@material-ui/core/LinearProgress';
import TournamentsTabBar2 from './TournamentsTabBar2';
import Overview from './tabs/overview';
import Brackets from './tabs/brackets';
import Standings from './tabs/standings';
import Scores from './tabs/scores';
import Stream from './tabs/stream';
import Stats from './tabs/stats';
import Players from './tabs/players';
import { SectionHeader, Card, SectionContent } from '../../components/chrome';
import { getTournamentQuery } from './queries/getTournamentQuery';

// const history = createHistory();

const styles = theme => ({});

class TournamentDetail extends Component {
	render() {
		const { classes, match, theme, ...rest } = this.props;

		return (
			<Query query={getTournamentQuery} variables={{ slug: this.props.match.params.slug }}>
				{({ loading, error, data }) => {
					if (loading) return <LinearProgress mode="indeterminate" color="primary" />;
					if (error) return <div style={styles.container}>Error: {error.message}</div>;

					return (
						<SectionHeader
							title={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<span>{data.tournament.name}</span>
									<span
										className="live-badge"
										style={{ marginLeft: 15, fontSize: '0.5em', padding: '2px 10px' }}
									>
										LIVE
									</span>
								</div>
							}
							backButton={true}
							backPath="/tournaments"
							tabs={<TournamentsTabBar2 match={match} {...rest} />}
						>
							<Route
								exact
								path={`${match.url}/`}
								render={() => <Overview match={match} tournament={data.tournament} {...rest} />}
							/>
							<Route
								exact
								path={`${match.url}/overview`}
								render={() => <Overview match={match} tournament={data.tournament} {...rest} />}
							/>
							<Route
								exact
								path={`${match.url}/brackets`}
								render={() => <Brackets match={match} tournament={data.tournament} {...rest} />}
							/>
							<Route
								exact
								path={`${match.url}/standings`}
								render={() => <Standings match={match} tournament={data.tournament} {...rest} />}
							/>
							<Route
								exact
								path={`${match.url}/scores`}
								render={() => <Scores match={match} tournament={data.tournament} {...rest} />}
							/>
							<Route
								exact
								path={`${match.url}/stream`}
								render={() => <Stream match={match} tournament={data.tournament} {...rest} />}
							/>
							<Route
								exact
								path={`${match.url}/stats`}
								render={() => <Stats match={match} tournament={data.tournament} {...rest} />}
							/>
							<Route
								exact
								path={`${match.url}/players`}
								render={() => <Players match={match} tournament={data.tournament} {...rest} />}
							/>
						</SectionHeader>
					);
				}}
			</Query>
		);
	}
}

// const TournamentDetailWithData = graphql(TournamentDetailQuery, {
// 	options: props => ({
// 		variables: { slug: props.match.params.slug }
// 	})
// })(TournamentDetail);

TournamentDetail.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TournamentDetail);
