import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Button from "material-ui/Button";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import blue from '@material-ui/core/colors/blue';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
// import currentUser from '../../helpers/currentUser';
import IconButton from '@material-ui/core/IconButton';
// import Button from "material-ui/Button";
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { auth } from '../../firebase';
import userImage from '../../assets/images/iwooten.jpg';
// import AvatarImage from "../assets/img/avatar-rocketman.png";
import * as routes from '../../config/routes';

const styles = {
	blueAvatar: {
		height: 34,
		width: 34,
		marginRight: 10,
		color: '#fff',
		backgroundColor: blue['A700']
	},

	avatar: {
		width: 34,
		height: 34,
		backgroundColor: '#44475b',
		color: '#fff'
	},

	row: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center'
	}
};
class AvatarMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null
		};
	}

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	signOut = () => {
		const { history } = this.props;
		localStorage.removeItem('DPLAuthUser');
		localStorage.removeItem('DPLToken');
		auth.signOut()
			.then(result => {
				history.push(routes.SIGN_IN);
			})
			.catch(error => {
				console.log(error);
			});
	};

	render() {
		const { anchorEl } = this.state;
		const { history, classes } = this.props;
		const { authUser } = this.context;

		const username = authUser.username;
		const fullName = authUser.fullName;
		const firstName = fullName && fullName.split(' ')[0];
		const lastName = fullName && fullName.split(' ')[1];
		const email = authUser.email;

		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					alignItems: 'center',
					fontSize: 14
				}}
			>
				<IconButton
					onClick={this.handleClick}
					color="inherit"
					aria-owns={anchorEl ? 'simple-menu' : null}
					aria-haspopup="true"
				>
					<Avatar color="white" size="small" style={styles.avatar} src={authUser && authUser.avatarImg}>
						{authUser && authUser.name && authUser.name.slice(0, 1)}
					</Avatar>
				</IconButton>

				<Menu id="avatar-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
					<MenuItem onClick={() => history.push(routes.PASSWORD_CHANGE)}>Change Password</MenuItem>
					<MenuItem onClick={() => history.push(routes.PROFILE)}>Edit Profile</MenuItem>
					<MenuItem onClick={() => history.push(routes.ACCOUNT)}>Account Settings</MenuItem>
					<MenuItem onClick={this.signOut}>Sign Out</MenuItem>
				</Menu>
			</div>
		);
	}
}

AvatarMenu.contextTypes = {
	authUser: PropTypes.object,
	getUserProfile: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	changeLoginState: PropTypes.func,
	signIn: PropTypes.func,
	signOut: PropTypes.func
};

const WithStyles = withStyles(styles)(AvatarMenu);
export default withRouter(WithStyles);
