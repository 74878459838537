import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
// import Card from '@material-ui/core/Card';
import moment from 'moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import AttachMoney from '@material-ui/icons/AttachMoney';
import { ZeroState, Card, TabContainer } from '../../../components/chrome';
import eightBall from '../../../assets/images/ball/eight-ball.png';
import nineBall from '../../../assets/images/ball/nine-ball.png';
import tenBall from '../../../assets/images/ball/ten-ball.png';
import oneBall from '../../../assets/images/ball/one-ball.png';
import * as routes from '../../../config/routes';
import TournamentCardTabs from '../TournamentCardTabs';
import CheckIcon from '@material-ui/icons/Check';

const styles = theme => ({
	listItem: {
		// padding: 0
	},
	cardNarrow: {
		margin: '24px 0px',
		padding: '5px 10px'
	}
});

class TournamentScheduleCard extends Component {
	render() {
		const { classes, tournaments } = this.props;

		return (
			<Card nopadding>
				<h3 style={{ marginLeft: 24, marginTop: 20 }}>Tournament schedule</h3>
				<TournamentCardTabs titles={['This month', 'Upcoming']}>
					<TabContainer>
						<List
							defaultValue={0}
							style={{
								marginTop: 10,
								marginBottom: 10,
								padding: 0
							}}
						>
							{tournaments &&
								tournaments.length === 0 && (
									<ZeroState title="No tournaments scheduled for this month" style={{ marginTop: 15, marginLeft: 15, marginRight: 15, textAlign: 'center' }} />
								)}
							{tournaments &&
								tournaments.length > 0 &&
								tournaments.map((tournament, index) => {
									return (
										<React.Fragment key={index}>
											<ListItem className={classes.listItem} button>
												<Avatar src={nineBall} className={classes.avatar} />
												<ListItemText
													primary="2019 DPL National 9-Ball Championships"
													secondary="Oct 15-20, Las Vegas, NV"
												/>
												<ListItemSecondaryAction style={{ marginRight: 24 }}>
													<Button size="small" variant="outlined" color="secondary">
														<CheckIcon className={classes.leftIcon} />
														Going
													</Button>
												</ListItemSecondaryAction>
											</ListItem>
											<ListItem className={classes.listItem} button>
												<Avatar src={eightBall} className={classes.avatar} />
												<ListItemText
													primary="2019 DPL National 8-Ball Championships"
													secondary="Oct 15-20, Las Vegas, NV"
												/>
												<ListItemSecondaryAction style={{ marginRight: 24 }}>
													<Button size="small" variant="outlined" color="primary">
														RSVP
													</Button>
												</ListItemSecondaryAction>
											</ListItem>
											<ListItem className={classes.listItem} button>
												<Avatar src={tenBall} className={classes.avatar} />
												<ListItemText
													primary="2019 DPL National 10-Ball Championships"
													secondary="Oct 15-20, Las Vegas, NV"
												/>
												<ListItemSecondaryAction style={{ marginRight: 24 }}>
													<Button size="small" variant="outlined" color="primary">
														RSVP
													</Button>
												</ListItemSecondaryAction>
											</ListItem>
										</React.Fragment>
									);
								})}
						</List>
						
            <Link to={`${routes.TOURNAMENTS}`}>
              <Button primary="true" style={{ margin: 10 }}>
                View all tournaments
              </Button>
            </Link>
							
					</TabContainer>

					<TabContainer>All upcoming matches</TabContainer>
				</TournamentCardTabs>
			</Card>
		);
	}
}

TournamentScheduleCard.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TournamentScheduleCard);
