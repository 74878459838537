import gql from 'graphql-tag';

export const getDivisionQuery = gql`
	query GetDivision($id: ID, $slug: String) {
		division(id: $id, slug: $slug) {
			id
			name
			slug
			description
			type
			season
			week
			sessionId
			isActive
			isPublic
			isPrivate
			startDate
			endDate
			league {
				id
				name
				slug
				type
			}
			locations {
				id
				name
				slug
				address
				city
				state
				zip
				country
				lat
				lon
			}
			operator {
				id
				name
				slug
				username
				avatarImg
			}
			teams {
				id
				name
				slug
			}
			players {
				id
				name
				username
				avatarImg
				slug
			}
			createdAt
			# raw
		}
	}
`;
