import gql from 'graphql-tag';

import { locationFragment } from '../../../api/fragments/location';

export const getMatchQuery = gql`
	query GetMatch($id: ID) {
		match(id: $id) {
			id
			type
			gameType
			format
			location {
				...locationInfo
			}
			division {
				id
				name
				slug
				type
			}
			tournament {
				id
				name
				slug
			}
			players {
				id
				name
				slug
				username
				avatarImg
			}
			teams {
				id
				name
			}
			games {
				id
				createdAt
			}
			status
			verified
			startTime
			createdAt
		}
	}
	${locationFragment}
`;
