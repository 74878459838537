import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
// import Card from '@material-ui/core/Card';
import moment from 'moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import AttachMoney from '@material-ui/icons/AttachMoney';
import { ZeroState, Card, TabContainer } from '../../../components/chrome';
import eightBall from '../../../assets/images/ball/eight-ball.png';
import nineBall from '../../../assets/images/ball/nine-ball.png';
import tenBall from '../../../assets/images/ball/ten-ball.png';
import oneBall from '../../../assets/images/ball/one-ball.png';
import * as routes from '../../../config/routes';
import TournamentCardTabs from '../TournamentCardTabs';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';

const styles = theme => ({
	listItem: {
		// padding: 0
	},
	cardNarrow: {
		margin: '24px 0px',
		padding: '5px 10px'
	}
});

class TeamsCard extends Component {
	render() {
		const { classes, teams } = this.props;

		return (
			<Card nopadding style={{ height: 'auto' }}>
				<h3
					style={{
						marginLeft: 24,
						marginRight: 10,
						marginTop: 10,
						marginBottom: 0,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<div>Teams</div>
					<div>
						<IconButton>
							<AddCircleOutline />
						</IconButton>
					</div>
				</h3>
				<List defaultValue={0} style={{ flex: 1, padding: 0 }}>
					{teams &&
						teams.length === 0 && (
							<ZeroState
								title="You are not part of any teams"
								style={{ marginTop: 0, marginLeft: 15, marginRight: 15, textAlign: 'center' }}
							/>
						)}
					{teams &&
						teams.length > 0 &&
						teams.map((team, index) => {
							return (
								<React.Fragment key={index}>
									<Link to={`/teams/${team.slug}`}>
										<ListItem className={classes.listItem} button>
											<Avatar src={eightBall} className={classes.avatar} />
											<ListItemText
												primary={team.name}
												secondary={`${team.players.length} team members`}
											/>
										</ListItem>
									</Link>
								</React.Fragment>
							);
						})}
				</List>

				<Link to={`${routes.TEAMS}`}>
					<Button primary="true" style={{ margin: 10 }}>
						View all teams
					</Button>
				</Link>
			</Card>
		);
	}
}

TeamsCard.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TeamsCard);
