import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import tempImg from '../../assets/images/abstract.jpg';
import eightBall from '../../assets/images/ball/eight-ball.png';
import nineBall from '../../assets/images/ball/nine-ball.png';
import tenBall from '../../assets/images/ball/ten-ball.png';
import moment from 'moment';
import _ from 'lodash';
// import tournament from '../../../../dpl-api/connectors/mysql/models/tournaments';

const styles = theme => ({
	card: {
		cursor: 'pointer',
		color: 'rgb(117, 117, 117)',
		// border: 0,
		border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
		'&:hover': {
			border: '1px solid rgba(0,0,0,0)',
			boxShadow:
				'0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)'
		},
		flexGrow: 1,
		display: 'flex',
		height: '100%',
		width: '100%',
		flexDirection: 'column'
	},
	media: {
		// ⚠️ object-fit is not supported by IE11.
		objectFit: 'cover'
	},
	avatar: {
		width: 30,
		height: 30
		// backgroundColor: 'red'
	},
	avatarYellow: {
		width: 30,
		height: 30,
		backgroundColor: '#44475b',
		display: 'flex',
		alignItems: 'center',
		fontWeight: 400
	},
	cardHeaderGrey: {
		backgroundColor: '#fafafa'
	},
	cardHeaderYellow: {
		backgroundColor: '#fff9e2'
	},
	cardHeaderBlue: {
		backgroundColor: '#e6f3ff'
	}
});

class TournamentCard extends Component {
	getGameType(tournament) {
		return tournament.gameType === 'EIGHT_BALL'
			? eightBall
			: tournament.gameType === 'NINE_BALL' ? nineBall : tournament.gameType === 'TEN_BALL' ? tenBall : null;
	}

	displayEnumType(type) {
		const typeSplit = type.split('_');
		const prefix = _.capitalize(typeSplit[0]);
		const suffix = _.capitalize(typeSplit[1]);
		return `${prefix} ${suffix}`;
	}

	render() {
		const { classes, tournament, history } = this.props;
		// console.log(tournament);
		return (
			<Card
				className={classes.card}
				onClick={() => history.push(`${this.props.match.url}/${tournament.slug}`)}
				elevation={0}
			>
				<CardHeader
					avatar={
						this.getGameType(tournament) !== null ? (
							<Avatar
								size="small"
								aria-label="Type"
								className={classes.avatar}
								src={this.getGameType(tournament)}
							/>
						) : (
							<Avatar size="small" className={classes.avatarYellow}>
								D
							</Avatar>
						)
					}
					title={<h3>{tournament.name}</h3>}
					// subheader={moment(tournament.startTime).format('ll')}
					className={classes.cardHeaderGrey}
					// className={
					// 	tournament.gameType === 'EIGHT_BALL'
					// 		? classes.cardHeaderGrey
					// 		: tournament.gameType === 'NINE_BALL'
					// 			? classes.cardHeaderYellow
					// 			: tournament.gameType === 'TEN_BALL' ? classes.cardHeaderBlue : null
					// }
				/>

				{/*<CardMedia
					component="img"
					className={classes.media}
					height="140"
					image={tempImg}
					title="Contemplative Reptile"
				/>*/}
				<CardContent>
					{/*<div style={{ display: 'flex', alignItems: 'center' }}>
						<h3>{tournament.name}</h3>
				</div>*/}

					<Grid container spacing={8}>
						<Grid item xs={12} sm={6} md={4}>
							<Typography variant="caption" className="caption" gutterBottom>
								Location
							</Typography>
							<Typography variant="subheading" gutterBottom>
								{tournament.location.name}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<Typography variant="caption" className="caption" gutterBottom>
								City
							</Typography>
							<Typography variant="subheading" gutterBottom>
								{tournament.location.city}, {tournament.location.state}
							</Typography>
						</Grid>
						{/*<Grid item xs={12} sm={6} md={4}>
							<Typography variant="caption" gutterBottom>
								Game
							</Typography>
							<Typography variant="subheading" gutterBottom>
								{this.displayEnumType(tournament.gameType)}
							</Typography>
			</Grid>*/}
						{/*<Grid item xs={12} sm={6} md={4}>
							<Typography variant="caption" gutterBottom>
								Race to
							</Typography>
							<Typography variant="subheading" gutterBottom>
								{tournament.winnersRaceTo}
							</Typography>
		</Grid>*/}
						<Grid item xs={12} sm={6} md={4}>
							<Typography variant="caption" className="caption" gutterBottom>
								Start Time
							</Typography>
							<Typography variant="subheading" gutterBottom>
								{moment(tournament.startTime).format('lll')}
							</Typography>
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<Typography variant="caption" className="caption" gutterBottom>
								Participants
							</Typography>
							<Typography variant="subheading" gutterBottom>
								{tournament.totalPlayers}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<Typography variant="caption" className="caption" gutterBottom>
								Status
							</Typography>
							<Typography variant="subheading" gutterBottom>
								{this.displayEnumType(tournament.status)}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<Typography variant="caption" className="caption" gutterBottom>
								Progress
							</Typography>
							<Typography variant="subheading" gutterBottom>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
										width: '100%'
									}}
								>
									<span
										style={{
											width: '100%',
											textAlign: 'left',
											backgroundColor: '#ececec',
											height: 20
										}}
									>
										<span
											style={{
												display: 'inline-block',
												backgroundColor: '#5fa6ff',
												width: `${(tournament.progress || 0) / 100 * 100}%`,
												height: '100%',
												position: 'relative',
												transition: 'background-color .3s ease',
												cursor: 'pointer'
											}}
										/>
									</span>
									<span style={{ width: 80, marginLeft: 10 }}>{tournament.progress || 0}%</span>
								</div>
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions>
					<Button color="primary">View Details</Button>
					{/*<Button color="primary">+ Join</Button>*/}
				</CardActions>
			</Card>
		);
	}
}

TournamentCard.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TournamentCard);
