import React from 'react';
import BreadcrumbBar from './BreadcrumbBar';
import '../../assets/css/app.css';

// const styles = {
//   appSubHeader: {
//     position: "fixed",
//     top: 64
//   }
// };

const SubHeader = props => (
	<div className="sub-header">
		<BreadcrumbBar {...props} />
	</div>
);

export default SubHeader;
