import gql from 'graphql-tag';

export const getMatchesQuery = gql`
	query Matches($where: JSON, $offset: Int, $limit: Int, $order: [[String]]) {
		matches(where: $where, offset: $offset, limit: $limit, order: $order) {
			id
			type
			gameType
			format
			division {
				id
				name
			}
			tournament {
				id
				name
			}
			players {
				id
				name
			}
			teams {
				id
				name
			}
			games {
				id
				createdAt
			}
			status
			verified
			startTime
			createdAt
		}
	}
`;
