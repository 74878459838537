import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import DivisionToolbar from './DivisionToolbar';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Avatar from '@material-ui/core/Avatar';
import _ from 'lodash';
import ConfirmDialog from '../../components/ConfirmDialog';
import { removeDivisionMutation } from './mutations/removeDivisionMutation';
import { graphql, compose } from 'react-apollo';
import { isoCountries, getGameType, displayEnumType } from '../../utils';
import LocationOn from '@material-ui/icons/LocationOn';
import { getDivisionsQuery } from './queries/getDivisionsQuery';

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	},
	card: {
		// padding: 10,
		// margin: 24,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		border: '1px solid #EAEDF3',
		boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.04)',
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},
	cell: {
		padding: '4px 30px 4px 20px',
		fontSize: 13
		// fontSize: 14
	},
	avatar: {
		width: 30,
		height: 30
	}
});
class DivisionsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dialogOpen: false,
			selectedDivision: null,
			order: 'asc',
			orderBy: 'name',
			selected: [],
			page: 0,
			rowsPerPage: 10,
			limit: this.props.limit || 10,
			offset: this.props.offset || 0
		};
		this.handleCloseDialog = this.handleCloseDialog.bind(this);
		this.handleConfirmDialog = this.handleConfirmDialog.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.offset !== this.props.offset || nextProps.limit !== this.props.limit) {
			this.setState({
				offset: nextProps.offset,
				limit: nextProps.limit
			});
		}
	}

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}

		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
		this.props.onLoadMore();
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
		this.props.onLoadMore();
	};

	handleCloseDialog() {
		this.setState({
			dialogOpen: false,
			selectedDivision: null
		});
	}

	handleConfirmDialog(division) {
		this.setState({
			selectedDivision: division,
			dialogOpen: true
		});
	}

	handleChangePage(event, page) {
		// this.setState({ page });
	}

	handleChangeRowsPerPage(event) {
		// this.setState({ rowsPerPage: event.target.value });
	}

	render() {
		const {
			classes,
			match,
			history,
			data,
			loading,
			error,
			subscribeToMore,
			count,
			onLoadMore,
			offset,
			limit
		} = this.props;

		const { order, orderBy, selected, rowsPerPage, page } = this.state;
		let emptyRows;

		if (!loading && data.divisions) {
			emptyRows = rowsPerPage - Math.min(rowsPerPage, data.divisions.length - page * rowsPerPage);
		}

		return (
			<React.Fragment>
				<Paper elevation={0} className={classes.card}>
					<div className={classes.container}>
						{loading && (
							<div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15 }}>
								Loading...
							</div>
						)}
						{error && <div style={styles.container}>Error: {error.message}</div>}

						{!loading &&
							data.divisions && (
								<React.Fragment>
									{/*<DivisionToolbar data={data} />*/}
									<Table className={classes.table}>
										<TableHead>
											<TableRow>
												{/*<TableCell className={classes.cell}>#</TableCell>*/}
												<TableCell className={classes.cell}>Name</TableCell>
												<TableCell className={classes.cell}>Description</TableCell>
												<TableCell className={classes.cell}>Type</TableCell>
												{/*<TableCell className={classes.cell}>Season</TableCell>
    <TableCell className={classes.cell}>Week</TableCell>*/}
												<TableCell className={classes.cell}>Location</TableCell>
												<TableCell className={classes.cell}>Operator</TableCell>
												<TableCell className={classes.cell}>Actions</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{data.divisions &&
												data.divisions.map((division, index) => {
													return (
														<TableRow
															key={division.id}
															hover
															onClick={() => history.push(`/divisions/${division.slug}`)}
															style={{ cursor: 'pointer' }}
														>
															{/*<TableCell className={classes.cell}>{index + 1}</TableCell>*/}
															<TableCell className={classes.cell}>
																<div style={{ display: 'flex', alignItems: 'center' }}>
																	<Avatar
																		size="small"
																		src={getGameType(division.type)}
																		className={classes.avatar}
																		style={{ marginRight: 15 }}
																	/>
																	<span>{division.name}</span>
																</div>
															</TableCell>
															<TableCell className={classes.cell}>
																{_.truncate(division.description, { length: 50 })}
															</TableCell>
															<TableCell className={classes.cell}>
																{(division.type && displayEnumType(division.type)) ||
																	'N/A'}
															</TableCell>
															{/*<TableCell className={classes.cell}>{division.season}</TableCell>
                <TableCell className={classes.cell}>{division.week}</TableCell>*/}
															<TableCell className={classes.cell}>
																<div style={{ display: 'flex', alignItems: 'center' }}>
																	<LocationOn style={{ height: 18, marginRight: 10 }} />{' '}
																	{(division.locations &&
																		division.locations.map(location => {
																			return location.name;
																		})) ||
																		'N/A'}
																</div>
															</TableCell>
															<TableCell className={classes.cell}>
																{(division.operator && division.operator.name) || 'N/A'}
															</TableCell>
															<TableCell className={classes.cell}>
																<div style={{ display: 'flex', alignItems: 'center' }}>
																	<Button
																		color="primary"
																		size="small"
																		onClick={e => {
																			e.preventDefault();
																			e.stopPropagation();
																			history.push(
																				`/divisions/${division.slug}/edit`
																			);
																		}}
																	>
																		Edit
																	</Button>
																	<Button
																		color="primary"
																		size="small"
																		onClick={e => {
																			e.preventDefault();
																			e.stopPropagation();
																			this.handleConfirmDialog(division);
																		}}
																		style={{ color: 'rgb(225, 0, 80)' }}
																	>
																		Remove
																	</Button>
																</div>
															</TableCell>
														</TableRow>
													);
												})}
										</TableBody>
									</Table>
									<TablePagination
										component="div"
										count={data.divisions.length}
										rowsPerPage={50}
										rowsPerPageOptions={[50, 100, 200]}
										page={0}
										backIconButtonProps={{
											'aria-label': 'Previous Page'
										}}
										nextIconButtonProps={{
											'aria-label': 'Next Page'
										}}
										onChangePage={this.handleChangePage}
										onChangeRowsPerPage={this.handleChangeRowsPerPage}
									/>
									{this.state.dialogOpen === true && (
										<ConfirmDialog
											title="Confirm Division Removal"
											open={this.state.dialogOpen}
											onClose={this.handleCloseDialog}
											onConfirm={() => {
												this.props
													.removeDivision({
														mutation: removeDivisionMutation,
														variables: { id: this.state.selectedDivision.id },
														// refetchQueries: ['GetDivisions'],
														refetchQueries: [
															{
																query: getDivisionsQuery
															}
														]
														// awaitRefetchQueries: true
													})
													.then(() => {
														this.setState({ dialogOpen: false });
													})
													.catch(error => {
														console.log(error);
														this.setState({ dialogOpen: false });
													});
											}}
										>
											⚠️ Are you sure you want to delete <b>{this.state.selectedDivision.name}</b>?
										</ConfirmDialog>
									)}
								</React.Fragment>
							)}
					</div>
				</Paper>
			</React.Fragment>
		);
	}
}

DivisionsList.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithGraphQL = graphql(removeDivisionMutation, {
	options: ({ id }) => ({ variables: { id } }),
	name: 'removeDivision'
})(DivisionsList);

export default withStyles(styles)(WithGraphQL);
