import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CreateForm from './CreateForm';
import DivisionCreateEditForm from './DivisionCreateEditForm';
import DivisionCreateForm from './DivisionCreateForm';
import { SectionHeader } from '../../components/chrome';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	card: {
		padding: 20,
		fontWeight: 400,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},
	cardNopadding: {
		padding: 0,
		fontWeight: 400,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},
	toolbarTitle: {
		marginBottom: 15
	}
});

class DivisionCreate extends Component {
	render() {
		const { classes } = this.props;

		return (
			<SectionHeader title="Create Division" backButton>
				<div style={{ marginTop: 24, marginBottom: 24 }}>
					<Grid container justify="center" spacing={24}>
						<Grid item xs={12} md={11} lg={10}>
							<Grid container justify="center" spacing={24}>
								<Grid item xs={12}>
									<Card className={classes.card} elevation={0}>
										<Typography variant="title" color="inherit" className={classes.toolbarTitle}>
											<span>Division Details</span>
										</Typography>
										<DivisionCreateForm />
									</Card>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</SectionHeader>
		);
	}
}

DivisionCreate.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DivisionCreate);
