import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Autosuggest from './Autosuggest';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Formsy from 'formsy-react';
import TextInput from '../../components/forms/TextInputFormsy';
import SelectField from '../../components/forms/SelectFormsy';
import SwitchField from '../../components/forms/SwitchFormsy';
import MultiSelectField from '../../components/forms/MultiSelectFormsy';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { getTeamQuery } from './queries/getTeamQuery';
import { updateTeamMutation } from './mutations/updateTeamMutation';
import { createTeamMutation } from './mutations/createTeamMutation';
import eightBall from '../../assets/images/ball/eight-ball.png';
import nineBall from '../../assets/images/ball/nine-ball.png';
import tenBall from '../../assets/images/ball/ten-ball.png';
import queryString from 'query-string';

const styles = theme => ({
	error: {
		color: '#f44336'
	},
	content: {
		// padding: '10px 24px 20px 24px'
		// maxWidth: 600
	},
	input: {
		marginTop: 5
	},
	submit: {
		marginTop: 10
	},
	selectEmpty: {
		marginTop: theme.spacing.unit * 2
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	}
});

export const getLeaguesQuery = gql`
	query GetLeagues {
		leagues {
			id
			name
			slug
			type
		}
	}
`;

export const getDivisionsQuery = gql`
	query GetDivisions {
		divisions {
			id
			name
			slug
			type
		}
	}
`;

export const getLocationsQuery = gql`
	query GetLocations {
		locations {
			id
			name
			slug
			# address
			# city
			# state
			# zip
			# country
			# lat
			# lon
			# createdAt
		}
	}
`;

export const getPlayersQuery = gql`
	query GetPlayers {
		users {
			id
			name
			username
			avatarImg
			# address
			# city
			# state
			# zip
			# country
			# lat
			# lon
			# createdAt
		}
	}
`;

class TeamCreateForm extends React.Component {
	constructor(props) {
		super(props);
		this.disableButton = this.disableButton.bind(this);
		this.enableButton = this.enableButton.bind(this);
		this.state = { canSubmit: false, submitting: false };
		this.submit = this.submit.bind(this);
	}

	getGameType = type => {
		return type.indexOf('EIGHT_BALL') !== -1
			? eightBall
			: type.indexOf('NINE_BALL') !== -1
			? nineBall
			: type.indexOf('TEN_BALL') !== -1
			? tenBall
			: null;
	};

	getLeagues = () => {
		const {
			getLeagues: { leagues, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = leagues.map(league => {
			return {
				label: league.name,
				value: league.id
			};
		});
		return formatted;
	};

	getDivisions = () => {
		const {
			getDivisions: { divisions, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = divisions.map(division => {
			return {
				label: division.name,
				value: division.id,
				avatar: this.getGameType(division.type)
			};
		});
		return formatted;
	};

	getLocations = () => {
		const {
			getLocations: { locations, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = locations.map(location => {
			return {
				label: location.name,
				value: location.id
			};
		});
		return formatted;
	};

	getPlayers = () => {
		const {
			getPlayers: { users, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = users.map(player => {
			return {
				label: player.name,
				value: player.id,
				avatar: player.avatarImg
			};
		});
		return formatted;
	};

	disableButton() {
		this.setState({ canSubmit: false });
	}

	enableButton() {
		this.setState({ canSubmit: true });
	}

	submit(model) {
		console.log(model);
		// fetch('http://example.com/', {
		// 	method: 'post',
		// 	body: JSON.stringify(model)
		// });
		this.setState({
			submitting: true
		});
		if (!this.props.slug) {
			this.props
				.createTeam({
					mutation: createTeamMutation,
					variables: {
						input: {
							name: model.name,
							leagueId: model.league,
							locationId: model.location,
							userIds: model.players,
							captainId: model.captain,
							coCaptainId: model.coCaptain,
							divisionIds: model.divisions,
							isActive: Boolean(model.isActive)
						}
					},
					// refetchQueries: ['GetTeam']
					refetchQueries: [{ query: getTeamQuery }]
				})
				.then(({ data }) => {
					// console.log(data);
					this.props.history.push(`/teams/${data.createTeam.slug}`);
				})
				.catch(error => {
					console.log(error);
					this.setState({ submitting: false });
				});
		} else {
			const id = this.props.getTeam.team.id;
			this.props
				.updateTeam({
					mutation: updateTeamMutation,
					variables: {
						input: {
							id: id,
							name: model.name,
							leagueId: model.league,
							locationId: model.location,
							userIds: model.players,
							captainId: model.captain,
							coCaptainId: model.coCaptain,
							divisionIds: model.divisions,
							isActive: Boolean(model.isActive)
						}
					},
					// refetchQueries: ['GetTeam'],
					refetchQueries: [{ query: getTeamQuery, variables: { id: id } }]
					// update: (store, { data: { updateTeam } }) => {
					// 	this.props.updateStoreAfterUpdate(store, updateTeam, values.id);
					// }
					// update: (cache, { data: { updateTeam } }) => {
					// 	// const { team } = cache.readQuery({ query: getTeamQuery });
					// 	// console.log(team);
					// 	cache.writeQuery({
					// 		query: getTeamQuery,
					// 		data: { team: updateTeam }
					// 	});
					// }

					// optimisticResponse: {
					// 	__typename: 'Mutation',
					// 	updateTeam: {
					// 		id: values.id,
					// 		__typename: 'Team',
					// 		name: values.name,
					// 		slug: values.slug,
					// 		isActive: values.isActive,
					// 		location: values.location,
					// 		players: values.players
					// 	}
					// }
				})
				.then(({ data }) => {
					console.log(data);

					this.props.history.push(`/teams/${data.updateTeam.slug}`);
				})
				.catch(error => {
					console.log(error);
					this.setState({ submitting: false });
				});
		}
	}

	render() {
		const {
			classes,
			getTeam: { team, loading, error },
			getLocations: { locations }
		} = this.props;
		const parsed = queryString.parse(this.props.location.search);
		const leagueId = parsed.leagueId;

		return (
			<React.Fragment>
				{loading === true ? (
					<div>Loading...</div>
				) : (
					<Formsy onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
						<TextInput
							name="name"
							label="Name"
							value={team && team.name}
							validations="isExisty"
							validationError="Name is required"
							required
							autoFocus
						/>

						<SelectField
							name="league"
							label="League"
							value={(team && team.league.id) || leagueId}
							validations="isExisty"
							validationError="League is required"
							options={this.getLeagues()}
							required
						/>

						<SelectField
							name="location"
							label="Location"
							value={team && team.location.id}
							validations="isExisty"
							validationError="Location is required"
							options={this.getLocations()}
							required
						/>

						<MultiSelectField
							name="divisions"
							label="Divisions"
							value={
								team &&
								team.divisions.map(division => {
									return division.id;
								})
							}
							options={this.getDivisions()}
							required
						/>

						<MultiSelectField
							name="players"
							label="Players"
							value={
								team &&
								team.players.map(player => {
									return player.id;
								})
							}
							options={this.getPlayers()}
						/>

						<SelectField
							name="captain"
							label="Captain"
							value={team && team.captain && team.captain.id}
							options={this.getPlayers()}
						/>

						<SelectField
							name="coCaptain"
							label="Co-captain"
							value={team && team.coCaptain && team.coCaptain.id}
							options={this.getPlayers()}
						/>

						<SwitchField
							// type="checkbox"
							// checked={team.isActive}
							id="isActive"
							name="isActive"
							label="Active"
							value={team && team.isActive === true ? true : false}
						/>

						<br />

						<Button
							type="submit"
							// fullWidth
							variant="contained"
							color="primary"
							disabled={!this.state.canSubmit}
							// className={classes.submit}
						>
							Submit
						</Button>
					</Formsy>
				)}
			</React.Fragment>
		);
	}
}

const WithGraphQL = compose(
	graphql(getTeamQuery, {
		options: ({ slug }) => ({
			variables: { slug },
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'no-cache'
			// refetchQueries: [
			// 	{
			// 		query: getTeamQuery,
			// 		variables: { variables: { slug } }
			// 	}
			// ]
		}),
		name: 'getTeam'
	}),
	graphql(getLeaguesQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getLeagues'
	}),
	graphql(getDivisionsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getDivisions'
	}),
	graphql(getLocationsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getLocations'
	}),
	graphql(getPlayersQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getPlayers'
	}),
	graphql(createTeamMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'createTeam'
	}),
	graphql(updateTeamMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'updateTeam'
	})
)(TeamCreateForm);

const WithRouter = withRouter(WithGraphQL);

export default withStyles(styles)(WithRouter);
