import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import _ from 'lodash';
// import * as tournamentData from "./tournament_data.json";
import gql from 'graphql-tag';
import Avatar from '@material-ui/core/Avatar';
import eightBall from '../../assets/images/ball/eight-ball.png';
import nineBall from '../../assets/images/ball/nine-ball.png';
import tenBall from '../../assets/images/ball/ten-ball.png';

const TOURNAMENT_ADDED = gql`
	subscription {
		tournamentAdded {
			id
			name
			description
			slug
			location {
				id
				name
				city
				state
			}
			type
			description
			gameType
			winnersRaceTo
			losersRaceTo
			totalPlayers
			startTime
			director {
				id
				name
			}
			players {
				id
				name
			}
			status
			progress
			# raw
		}
	}
`;

const TOURNAMENT_UPDATED = gql`
	subscription {
		tournamentUpdated {
			id
			name
			description
			slug
			location {
				id
				name
				city
				state
			}
			type
			description
			gameType
			winnersRaceTo
			losersRaceTo
			totalPlayers
			startTime
			director {
				id
				name
			}
			players {
				id
				name
			}
			status
			progress
			# raw
		}
	}
`;

const TOURNAMENT_REMOVED = gql`
	subscription {
		tournamentDeleted {
			id
		}
	}
`;

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function stableSort(array, cmp) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const styles = theme => ({
	cell: {
		padding: '4px 30px 4px 20px',
		fontSize: 13
	},
	avatar: {
		width: 30,
		height: 30,
		marginRight: 15
	},
	avatarYellow: {
		width: 30,
		height: 30,
		marginRight: 15,
		backgroundColor: '#44475b',
		display: 'flex',
		alignItems: 'center',
		fontWeight: 400,
		fontSize: 14
	}
});
class TournamentsList extends Component {
	componentDidMount() {
		this.props.subscribeToMore({
			document: TOURNAMENT_ADDED,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;
				// const newTournament = subscriptionData.data.tournamentAdded;

				return Object.assign({}, prev, {
					tournaments: [...prev.tournaments, subscriptionData.data.tournamentAdded]
				});

				// return {
				// 	tournaments: [...prev.tournaments, subscriptionData.data.tournamentAdded]
				// };
			}
		});

		this.props.subscribeToMore({
			document: TOURNAMENT_UPDATED,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;

				const updatedList = prev.tournaments.map(r => {
					if (r !== null && r.id === subscriptionData.data.tournamentUpdated.id) {
						return subscriptionData.data.tournamentUpdated;
					} else {
						return r;
					}
				});

				return {
					tournaments: [...updatedList]
				};
			}
		});

		this.props.subscribeToMore({
			document: TOURNAMENT_REMOVED,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;
				// let removed = subscriptionData.data.tournamentRemoved;
				// let original = prev.tournaments;
				// let toDelete = original.find(tournament => {
				// 	if (tournament) {
				// 		return tournament.id === removed.id;
				// 	}
				// });
				// let updatedList = original.splice(toDelete, 1);
				// return updatedList;

				return {
					tournaments: [
						...prev.tournaments.filter(r => {
							if (r !== null) {
								return r.id !== subscriptionData.data.tournamentRemoved.id;
							} else {
								return false;
							}
						})
					]
				};
			}
		});
	}

	getGameType(tournament) {
		return tournament.gameType === 'EIGHT_BALL'
			? eightBall
			: tournament.gameType === 'NINE_BALL' ? nineBall : tournament.gameType === 'TEN_BALL' ? tenBall : null;
	}

	displayEnumType(type) {
		const typeSplit = type.split('_');
		const prefix = _.capitalize(typeSplit[0]);
		const suffix = _.capitalize(typeSplit[1]);
		return `${prefix} ${suffix}`;
	}

	render() {
		const { classes, match, history, tournaments, order, orderBy, page, rowsPerPage } = this.props;

		return (
			<React.Fragment>
				{stableSort(tournaments, getSorting(order, orderBy))
					.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					.map((tournament, index) => {
						if (tournament) {
							return (
								<TableRow
									key={tournament.id}
									hover
									onClick={() => history.push(`${this.props.match.url}/${tournament.slug}`)}
									style={{ cursor: 'pointer', transition: 'all 150ms ease' }}
									tabIndex={-1}
								>
									{/*<TableCell className={classes.cell}>{index + 1}</TableCell>*/}
									<TableCell className={classes.cell}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											{this.getGameType(tournament) !== null ? (
												<Avatar
													aria-label="Recipe"
													className={classes.avatar}
													src={this.getGameType(tournament)}
												/>
											) : (
												<Avatar size="small" className={classes.avatarYellow}>
													D
												</Avatar>
											)}
											<span>{tournament.name}</span>
											{/*<Link to={`${this.props.match.url}/${tournament.slug}`}>{tournament.name}</Link>*/}
										</div>
									</TableCell>
									<TableCell className={classes.cell}>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
												width: '100%'
											}}
										>
											<span
												style={{
													width: '100%',
													textAlign: 'left',
													backgroundColor: '#ececec',
													height: 20
												}}
											>
												<span
													style={{
														display: 'inline-block',
														// backgroundColor: '#bac2cc',
														backgroundColor: '#5fa6ff',
														width: `${(tournament.progress || 0) / 100 * 100}%`,
														height: '100%',
														position: 'relative',
														transition: 'background-color .3s ease',
														cursor: 'pointer'
													}}
												/>
											</span>
											<span style={{ width: 80, marginLeft: 10 }}>
												{tournament.progress || 0}%
											</span>
										</div>
									</TableCell>
									<TableCell className={classes.cell}>
										{tournament.location.city}, {tournament.location.state}
									</TableCell>
									<TableCell className={classes.cell}>
										{this.displayEnumType(tournament.gameType)}
									</TableCell>

									<TableCell className={classes.cell}>
										{this.displayEnumType(tournament.status)}
									</TableCell>
									<TableCell className={classes.cell} numeric>
										{tournament.totalPlayers}
									</TableCell>
									<TableCell className={classes.cell}>
										{moment(tournament.startTime).format('ll')}
									</TableCell>
								</TableRow>
							);
						}
					})}
			</React.Fragment>
		);
	}
}

TournamentsList.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles, { withTheme: true })(TournamentsList);
export default withRouter(WithStyles);
