import React from 'react';
import Bracket from '../../../components/Bracket2';
// import ContainerDimensions from 'react-container-dimensions';

const Brackets = ({ match, tournament }) => (
	<Bracket />
	// <div>
	//   <h5>Brackets</h5>
	//   <pre>{JSON.stringify(match, null, 2)}</pre>
	//   <pre>{JSON.stringify(tournament, null, 2)}</pre>
	// </div>
);

export default Brackets;
