import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link, Redirect, Switch, withRouter } from 'react-router-dom';
// import blueGrey from '@material-ui/core/colors/blueGrey';
// import darkBaseTheme from "material-ui/styles/baseThemes/darkBaseTheme";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Amplitude from 'react-amplitude';
import DefaultLayout from './layouts/DefaultLayout';
import HomeLayout from './layouts/HomeLayout';
import SubLayout from './layouts/SubLayout';
import SubLayoutClean from './layouts/SubLayoutClean';
// import { Card } from "material-ui/Card";
import TournamentDetail from './sections/tournaments/TournamentDetail';
import TournamentCreate from './sections/tournaments/TournamentCreate';
import TournamentContainer from './sections/tournaments/TournamentContainer';
import MatchesContainer from './sections/matches/MatchesContainer';
import MatchDetail from './sections/matches/MatchDetail';
import MatchCreate from './sections/matches/MatchCreate';
import MatchEdit from './sections/matches/MatchEdit';
import HomeContainer from './sections/home/HomeContainer';
import StatsContainer from './sections/stats/StatsContainer';
import TeamsContainer from './sections/teams/TeamsContainer';
import TeamDetail from './sections/teams/TeamDetail';
import TeamCreate from './sections/teams/TeamCreate';
import TeamEdit from './sections/teams/TeamEdit';
import PlayersContainer from './sections/players/PlayersContainer';
import PlayerDetail from './sections/players/PlayerDetail';
import PlayerCreate from './sections/players/PlayerCreate';
import PlayerEdit from './sections/players/PlayerEdit';
import ChallengesContainer from './sections/challenges/ChallengesContainer';
import ChallengeCreate from './sections/challenges/ChallengeCreate';
import ChallengeDetail from './sections/challenges/ChallengeDetail';
import ChallengeEdit from './sections/challenges/ChallengeEdit';
import ConversationsContainer from './sections/conversations/ConversationsContainer';
import LocationsContainer from './sections/locations/LocationsContainer';
import LocationDetail from './sections/locations/LocationDetail';
import LocationCreate from './sections/locations/LocationCreate';
import LocationEdit from './sections/locations/LocationEdit';
import LeaguesContainer from './sections/leagues/LeaguesContainer';
import LeagueDetail from './sections/leagues/LeagueDetail';
import LeagueCreate from './sections/leagues/LeagueCreate';
import LeagueEdit from './sections/leagues/LeagueEdit';
import DivisionsContainer from './sections/divisions/DivisionsContainer';
import DivisionDetail from './sections/divisions/DivisionDetail';
import DivisionCreate from './sections/divisions/DivisionCreate';
import DivisionEdit from './sections/divisions/DivisionEdit';
import SignUpPage from './sections/auth/signup';
import SignInPage from './sections/auth/signin';
import PasswordForgetPage from './sections/auth/PasswordForget';
import PasswordChangePage from './sections/auth/PasswordChange';
import ConfirmEmailPage from './sections/auth/confirmEmail';
import TermsPage from './sections/legal/TermsPage';
import PrivacyPage from './sections/legal/PrivacyPage';
import ProfilePage from './sections/profile/ProfilePage';
import InvoiceCreate from './sections/invoices/InvoiceCreate';
import withAuthentication from './helpers/withAuthentication';
import * as routes from './config/routes';

import './assets/css/app.css';

// const styles = {
//   container: {
//     textAlign: "center",
//     paddingTop: 200
//   },
//   titleBar: {
//     paddingLeft: 22,
//     paddingRight: 22
//   },
//   heading: {
//     margin: 0,
//     textAlign: "left",
//     display: "inline-block"
//   },
//   headerBar: {
//     paddingTop: 36,
//     paddingBottom: 36
//   },
//   card: {
//     margin: 24
//   },
//   fabIcon: {
//     position: "fixed",
//     bottom: 30,
//     right: 30
//   }
// };

const theme = createMuiTheme({
	// overrides: {
	// 	MuiToggleButton: {
	// 		// Name of the component ⚛️ / style sheet
	// 		root: {
	// 			// Name of the rule
	// 			color: 'white' // Some CSS
	// 		}
	// 	}
	// },
	typography: {
		fontFamily: 'din_lightregular, Roboto, sans-serif',
		fontWeight: 300,
		useNextVariants: true
	},
	palette: {
		primary: {
			main: '#292C39',
			light: '#FFCC00',
			dark: '#000000'
		},
		secondary: {
			main: '#FFCC00',
			light: '#fff',
			dark: '#f3c200',
			contrastText: '#000'
		},
		white: {
			main: '#fff',
			light: '#fff',
			dark: '#000',
			contrastText: '#000'
		},
		default: {
			// light: will be calculated from palette.primary.main,
			main: '#ffffff'
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contast with palette.primary.main
		},
		dark: {
			light: '#0066ff',
			main: '#0044ff',
			// dark: will be calculated from palette.secondary.main,
			contrastText: '#ffcc00'
		},
		light: {
			light: '#fff',
			main: '#292C39',
			dark: '#000000',
			contrastText: '#ffcc00'
		}
	},

	appBar: {
		height: 64
	},
	menuItem: {
		hoverColor: '#e5e5e5'
		// selectedTextColor: 'blue'
	}
	// overrides: {
	// 	MuiTab: {
	// 		label: {
	// 			fontWeight: 300
	// 		}
	// 	}
	// }
});

const PrivateRoute = ({ layout: Layout, component: Component, title, ...context }) => (
	<Route
		{...context}
		render={props => {
			// console.log(context);
			return context.checkAuthStatus() === true ? (
				<Layout component={Component} title={title} />
			) : (
				<Redirect
					to={{
						pathname: '/login',
						state: { from: props.location }
					}}
				/>
			);
		}}
	/>
);

const NoMatch = ({ location }) => (
	<div>
		<h3>
			No match for <code>{location.pathname}</code>
		</h3>
	</div>
);

class App extends Component {
	render() {
		return (
			<MuiThemeProvider theme={theme}>
				<Router
					history={this.props.history}
					location={this.props.location}
					onChange={location => {
						console.log(this.props.props);
						console.log(location);
						// Amplitude.event('Route changed', location);
						// amplitude.getInstance().logEvent(location.pathname);
					}}
				>
					<div className="App">
						<Switch>
							<PrivateRoute
								exact
								path="/"
								layout={HomeLayout}
								component={HomeContainer}
								title="Home"
								{...this.context}
							/>
							<SubLayoutClean
								exact
								path="/invoices/new"
								component={InvoiceCreate}
								title="Create Invoice"
							/>
							<SubLayoutClean exact path={routes.SIGN_UP} component={SignUpPage} title="Sign Up" />
							<SubLayoutClean exact path={routes.SIGN_IN} component={SignInPage} title="Sign In" />
							<SubLayoutClean
								exact
								path={routes.PASSWORD_FORGET}
								component={PasswordForgetPage}
								title="Password Forget"
							/>
							<SubLayoutClean
								exact
								path={routes.PASSWORD_CHANGE}
								component={PasswordChangePage}
								title="Password Change"
							/>
							<SubLayoutClean
								exact
								path="/confirm-email/:email/:token"
								component={ConfirmEmailPage}
								title="Confirm Email"
							/>
							<SubLayoutClean
								exact
								path={routes.USER_PROFILE}
								component={ProfilePage}
								title="User Profile"
							/>

							<PrivateRoute
								exact
								path="/leagues"
								layout={DefaultLayout}
								component={LeaguesContainer}
								title="Leagues"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/leagues/new"
								layout={DefaultLayout}
								component={LeagueCreate}
								title="Create League"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/leagues/:slug/edit"
								layout={DefaultLayout}
								component={LeagueEdit}
								title="Edit League"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/leagues/:slug"
								layout={DefaultLayout}
								component={LeagueDetail}
								title="League Detail"
								{...this.context}
							/>

							<PrivateRoute
								exact
								path="/divisions"
								layout={DefaultLayout}
								component={DivisionsContainer}
								title="Divisions"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/divisions/new"
								layout={DefaultLayout}
								component={DivisionCreate}
								title="Create Division"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/divisions/:slug/edit"
								layout={DefaultLayout}
								component={DivisionEdit}
								title="Edit Division"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/divisions/:slug"
								layout={DefaultLayout}
								component={DivisionDetail}
								title="Division Detail"
								{...this.context}
							/>

							<PrivateRoute
								exact
								path="/teams"
								layout={DefaultLayout}
								component={TeamsContainer}
								title="Teams"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/teams/new"
								layout={DefaultLayout}
								component={TeamCreate}
								title="Create Team"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/teams/:slug"
								layout={DefaultLayout}
								component={TeamDetail}
								title="Team Details"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/teams/:slug/edit"
								layout={DefaultLayout}
								component={TeamEdit}
								title="Edit Team"
								{...this.context}
							/>

							<PrivateRoute
								exact
								path="/challenges"
								layout={DefaultLayout}
								component={ChallengesContainer}
								title="Challenges"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/challenges/new"
								layout={DefaultLayout}
								component={ChallengeCreate}
								title="Create Challenge"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/challenges/:id"
								layout={DefaultLayout}
								component={ChallengeDetail}
								title="Challenge Details"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/challenges/:id/edit"
								layout={DefaultLayout}
								component={ChallengeEdit}
								title="Edit Challenge"
								{...this.context}
							/>

							<PrivateRoute
								exact
								path="/locations"
								layout={DefaultLayout}
								component={LocationsContainer}
								title="Locations"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/locations/new"
								layout={DefaultLayout}
								component={LocationCreate}
								title="Create Location"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/locations/:slug/edit"
								layout={DefaultLayout}
								component={LocationEdit}
								title="Edit Location"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/locations/:slug"
								layout={DefaultLayout}
								component={LocationDetail}
								title="Create Location"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/matches"
								layout={DefaultLayout}
								component={MatchesContainer}
								title="Matches"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/matches/new"
								layout={DefaultLayout}
								component={MatchCreate}
								title="Create Match"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/matches/:id"
								layout={DefaultLayout}
								component={MatchDetail}
								title="Match Detail"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/matches/:id/edit"
								layout={DefaultLayout}
								component={MatchEdit}
								title="Edit Match"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/messages"
								layout={DefaultLayout}
								component={ConversationsContainer}
								title="Messages"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/stats"
								layout={DefaultLayout}
								component={StatsContainer}
								title="Statistics"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/players"
								layout={DefaultLayout}
								component={PlayersContainer}
								title="Players"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/players/new"
								layout={DefaultLayout}
								component={PlayerCreate}
								title="Add Player"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/players/:slug"
								layout={DefaultLayout}
								component={PlayerDetail}
								title="Player Details"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/players/:slug/edit"
								layout={DefaultLayout}
								component={PlayerEdit}
								title="Edit Player"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/tournaments"
								layout={DefaultLayout}
								component={TournamentContainer}
								title="Tournaments"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/tournaments/new"
								layout={DefaultLayout}
								component={TournamentCreate}
								title="Create Tournament"
								{...this.context}
							/>
							<PrivateRoute
								path="/tournaments/:slug"
								layout={DefaultLayout}
								component={TournamentDetail}
								title="Tournament Details"
								{...this.context}
							/>

							<PrivateRoute
								exact
								path="/terms"
								layout={DefaultLayout}
								component={TermsPage}
								title="Terms of Use"
								{...this.context}
							/>
							<PrivateRoute
								exact
								path="/privacy"
								layout={DefaultLayout}
								component={PrivacyPage}
								title="Privacy Policy"
								{...this.context}
							/>
							<Route component={NoMatch} />
						</Switch>
					</div>
				</Router>
			</MuiThemeProvider>
		);
	}
}

App.contextTypes = {
	authUser: PropTypes.object,
	getUserProfile: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	checkAuthStatus: PropTypes.func,
	changeLoginState: PropTypes.func,
	signIn: PropTypes.func,
	signOut: PropTypes.func
};

export default withAuthentication(App);

// import React, { Component } from "react";
// import { graphql } from "react-apollo";
// import gql from "graphql-tag";
// import logo from "./logo.svg";
// import "./App.css";

// class App extends Component {
//   render() {
//     if (this.props.data.loading) {
//       return <div>Loading</div>;
//     }

//     return (
//       <ul>
//         {this.props.data.allPosts.map(({ id, title, views }) => (
//           <li key={id}>
//             {title} {views}
//           </li>
//         ))}
//       </ul>
//       // <div className="App">
//       //   <header className="App-header">
//       //     <img src={logo} className="App-logo" alt="logo" />
//       //     <h1 className="App-title">Welcome to React</h1>
//       //   </header>
//       //   <p className="App-intro">
//       //     To get started, edit <code>src/App.js</code> and save to reload.
//       //   </p>
//       // </div>
//     );
//   }
// }

// export default graphql(gql`
//   query PostsAppQuery {
//     allPosts {
//       id
//       title
//       views
//     }
//   }
// `)(App);

// // export default App;
