import React, { Component } from 'react';

const styles = {};

class MatchGroup extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { connectors } = this.props;

		return (
			<div className="match-group">
				{(connectors === 'left' || connectors === 'both') && (
						<div className="connector left">
							<div className="line" />
							<div className="merger" />
						</div>
					)}

				<div className="matches">{this.props.children}</div>
				{(connectors === 'right' || connectors === 'both') && (
						<div className="connector right">
							<div className="merger" />
							<div className="line" />
						</div>
					)}
			</div>
		);
	}
}

export default MatchGroup;
