import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import CountUp from 'react-countup';
import Divider from '@material-ui/core/Divider';
import { Subscription, Query } from 'react-apollo';
import Button from '@material-ui/core/Button';
import { Card } from '../../../components/chrome';
import gql from 'graphql-tag';
// import eventImage from '../../../assets/images/iwooten.jpg';
import * as routes from '../../../config/routes';
// import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple';

const GET_USER_QUERY = gql`
	query UserInfo {
		currentUser {
			id
			name
			username
			email
			avatarImg
			playerNumber
			online
			firstRun
			token
			divisions {
				id
				name
				slug
			}
		}
	}
`;

// const GET_USER_QUERY = gql`
// 	query UserInfo($userId: ID) {
// 		user(id: $userId) {
// 			id
// 			name
// 			username
// 			email
// 			avatarImg
// 			playerNumber
// 			online
// 			firstRun
// 			token
// 			divisions {
// 				id
// 				name
// 				slug
// 			}
// 		}
// 	}
// `;

// const USER_CONNECTED = gql`
// 	subscription onUserConnected($userId: ID) {
// 		userConnected(id: $userId) {
// 			id
// 			name
// 			username
// 			avatarImg
// 			online
// 		}
// 	}
// `;

// const USER_DISCONNECTED = gql`
// 	subscription onUserDisconnected($userId: ID) {
// 		userDisconnected(id: $userId) {
// 			id
// 			name
// 			username
// 			avatarImg
// 			online
// 		}
// 	}
// `;

const USER_ONLINE_STATUS_CHANGED = gql`
	subscription onUserStatusChanged($userId: ID) {
		userOnlineStatusChanged(id: $userId) {
			id
			name
			username
			email
			avatarImg
			playerNumber
			online
			firstRun
			token
			divisions {
				id
				name
				slug
			}
		}
	}
`;

const styles = theme => ({
	badgeOffline: {
		width: 12,
		height: 12,
		top: 4,
		right: 4,
		backgroundColor: '#ff0044',
		border: '1px solid #fff'
	},
	badgeOnline: {
		width: 12,
		height: 12,
		top: 4,
		right: 4,
		backgroundColor: '#39c700',
		border: '1px solid #fff'
	},
	avatarLarge: {
		width: 70,
		height: 70,
		backgroundColor: '#44475b',
		color: '#fff'
	}
});

function easeOutExpo(t, b, c, d) {
	return c * (-Math.pow(2, -10 * t / d) + 1) * 1024 / 1023 + b;
}

class ProfileStatus extends Component {
	componentDidMount() {
		this.props.subscribeToUser();
	}

	render() {
		const { classes, data, loading, error } = this.props;

		if (loading) return <div>Loading...</div>;
		if (error) return <div style={styles.container}>Error: {error.message}</div>;
		if (!loading && data && !data.currentUser) {
			return <div>User not found.</div>;
		}

		return (
			<React.Fragment>
				<React.Fragment>
					<Badge
						badgeContent=""
						color="secondary"
						classes={{
							badge:
								(!loading && data.currentUser.online === true) || data.currentUser.online === true
									? classes.badgeOnline
									: classes.badgeOffline
						}}
					>
						<Avatar src={data.currentUser && data.currentUser.avatarImg} className={classes.avatarLarge}>
							{data.currentUser && data.currentUser.name.slice(0, 1)}
						</Avatar>
					</Badge>
					<ul style={{ margin: '10px 10px 10px 10px' }}>
						<li>
							<h3 style={{ marginBottom: 0 }}>
								{data.currentUser.name}{' '}
								{data.currentUser.username ? `(${data.currentUser.username})` : null}
							</h3>
						</li>
						<li>
							<div
								style={
									(!loading && data.currentUser.online === true) || data.currentUser.online === true
										? { color: '#39c700' }
										: { color: '#ff0044' }
								}
							>
								{(!loading && data.currentUser.online === true) || data.currentUser.online === true
									? 'Online'
									: 'Offline'}
							</div>
						</li>
					</ul>
				</React.Fragment>
			</React.Fragment>
		);
	}
}

const ProfileStatusWithStyles = withStyles(styles)(ProfileStatus);

class UserProfileCard extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	// componentDidMount() {
	// 	subscribeToMore({
	// 		document: USER_ONLINE,
	// 		updateQuery: (prev, { subscriptionData }) => {
	// 			if (!subscriptionData.data) return prev;

	// 			return Object.assign({}, prev, {
	// 				userOnline: [...prev.userOnline, subscriptionData.data.userOnline]
	// 			});
	// 		}
	// 	});

	// 	subscribeToMore({
	// 		document: USER_OFFLINE,
	// 		updateQuery: (prev, { subscriptionData }) => {
	// 			if (!subscriptionData.data) return prev;

	// 			return Object.assign({}, prev, {
	// 				userOffline: [...prev.userOffline, subscriptionData.data.userOffline]
	// 			});
	// 		}
	// 	});
	// }

	render() {
		const { classes, user } = this.props;
		const { authUser } = this.context;

		// console.log(authUser);

		// const userConnected = ({ userId }) => (
		// 	<Subscription subscription={USER_CONNECTED} variables={{ userId }}>
		// 		{({ data, loading }) => {
		// 			!loading && this.displayOnlineStatus(data);
		// 			return null;
		// 			// return <h4>User connected: {!loading && JSON.stringify(data)}</h4>;
		// 		}}
		// 	</Subscription>
		// );

		// const userDisconnected = ({ userId }) => (
		// 	<Subscription subscription={USER_DISCONNECTED} variables={{ userId }}>
		// 		{({ data, loading }) => {
		// 			!loading && this.displayOnlineStatus(data);
		// 			return null;
		// 			// return <h4>User connected: {!loading && JSON.stringify(data)}</h4>;
		// 		}}
		// 	</Subscription>
		// );

		return (
			<Card
				nopadding
				style={{
					display: 'flex',
					justifyContent: 'center',
					flex: 1,
					textAlign: 'center',
					alignItems: 'center',
					flexDirection: 'column'
				}}
			>
				{authUser ? (
					<div style={{ marginTop: 30, width: '100%' }}>
						<Query query={GET_USER_QUERY} variables={{ userId: authUser.id }}>
							{({ subscribeToMore, ...result }) => {
								return (
									<ProfileStatusWithStyles
										{...result}
										subscribeToUser={() => {
											subscribeToMore({
												document: USER_ONLINE_STATUS_CHANGED,
												// variables: { userId: data.currentUser.id },
												updateQuery: (prev, { subscriptionData }) => {
													if (!subscriptionData.data) return prev;
													const userStatus = subscriptionData.data.userOnlineStatusChanged;
													return Object.assign({}, prev, {
														currentUser: userStatus
													});
													// return Object.assign({}, prev, {
													//   entry: {
													//     comments: [newFeedItem, ...prev.entry.comments]
													//   }
													// });
												}
											});
										}}
									/>
								);
							}}
						</Query>

						<Link to={`${routes.USER_PROFILE}`}>
							<Button size="small" variant="outlined" color="primary">
								Edit Profile
							</Button>
						</Link>

						<Divider style={{ marginTop: 20 }} />
						<Grid container spacing={24} style={{ marginTop: 5, marginBottom: 5 }}>
							<Grid item xs={6}>
								<div>Player rating</div>
								<h2 style={{ marginTop: 10, marginBottom: 10 }}>
									<CountUp end={1392} duration={1.2} easingFn={easeOutExpo} />
								</h2>
							</Grid>
							<Grid item xs={6}>
								<div>Total Credits</div>
								<h2 style={{ marginTop: 10, marginBottom: 10 }}>
									<CountUp
										end={35.0}
										// prefix="$"
										// decimals={2}
										duration={1.2}
									/>
								</h2>
							</Grid>
						</Grid>
						{/*<Link to={`${routes.MATCHES}`} style={{ margin: 10 }}>
                      <Button primary="true">View public profile</Button>
                      </Link>*/}
					</div>
				) : (
					<div>Loading...</div>
				)}
			</Card>
		);
	}
}

UserProfileCard.contextTypes = {
	authUser: PropTypes.object,
	getUserProfile: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	changeLoginState: PropTypes.func,
	signIn: PropTypes.func,
	signOut: PropTypes.func
};

UserProfileCard.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserProfileCard);
