import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import MomentUtils from '@date-io/moment';
// import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import { createStore, combineReducers, compose } from 'redux';
// import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { ApolloProvider } from 'react-apollo';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
// import Amplitude from 'react-amplitude';
import { Provider as AlertProvider } from 'react-alert';
// import AlertTemplate from './components/chrome/AlertTemplate';
import AlertTemplate from 'react-alert-template-oldschool-dark';
// import { ApolloLink } from "apollo-link";
import rootReducer from './reducers';
import client from './config/apolloClient';
import { Helmet } from 'react-helmet';
import App from './app';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import './assets/css/index.css';

// Amplitude.initialize("aa6068a22342f716b4ac436f999aa625");

// const logger = new ApolloLink((operation, forward) => {
//   console.log(operation.operationName);
//   return forward(operation).map(result => {
//     console.log(`received result from ${operation.operationName}`);
//     return result;
//   });
// });

const store = createStore(
	combineReducers({
		rootReducer
		// apollo: client.reducer()
	}),
	{}, // initial state
	compose(
		// applyMiddleware(logger()),
		// applyMiddleware(client.middleware()),
		// If you are using the devToolsExtension, you can add it here also
		typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
	)
);

// optional cofiguration for react-alert
const options = {
	position: 'bottom left',
	timeout: 5000,
	offset: '30px',
	transition: 'fade'
};

const rootEl = document.getElementById('root');
ReactDOM.render(
	<AlertProvider template={AlertTemplate} {...options}>
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<ApolloProvider store={store} client={client}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Digital Pool League (DPL)</title>
					<link rel="canonical" href="https://www.digitalpoolleague.com" />
					<meta name="description" content="Play what you want, when you want, where you want!" />
				</Helmet>
				<CssBaseline />
				<App />
			</ApolloProvider>
		</MuiPickersUtilsProvider>
	</AlertProvider>,
	rootEl
);
// registerServiceWorker();
unregister();
