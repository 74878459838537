import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const styles = {
	textColorPrimary: {
		color: '#fff'
	},
	textColorSecondary: {
		color: '#999',
		'&:hover': {
			color: '#fff',
			transition: 'all 300ms cubic-bezier(0.4, 0.0, 0.2, 1)'
		}
	}
};

class TournamentContainerTabs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: 0
		};

		this.handleActive = this.handleActive.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.isActive = this.isActive.bind(this);
	}

	handleActive(tab) {
		const route = tab.props['data-route'] || '/';
		// const match = tab.props['data-match'];
		let urlPath = window.location.href;
		let parsed = urlPath.split('/');
		let newUrl = `/${parsed[3]}/${parsed[4]}${route}`;
		const location = {
			pathname: newUrl
		};
		this.props.history.replace(location);
	}

	handleChange(event, value) {
		this.setState({ value });
	}

	isActive(tab) {
		const activeTab = this.state.activeTab;
		return tab === activeTab ? 'tab active' : 'tab';
	}

	render() {
		const { value } = this.state;
		const { classes } = this.props;

		return (
			<Tabs
				value={value}
				onChange={this.handleChange}
				indicatorColor="secondary"
				textColor="secondary"
				scrollable
				scrollButtons="off"
				// className={classes.tabContainer}
				fullWidth
			>
				<Tab
					label="In progress"
					classes={{
						label: classes.label,
						textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
						textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
					}}
					style={{ maxWidth: 200 }}
				/>
				<Tab
					label="Upcoming"
					classes={{
						label: classes.label,
						textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
						textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
					}}
					style={{ maxWidth: 200 }}
				/>
				<Tab
					label="My tournaments"
					classes={{
						label: classes.label,
						textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
						textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
					}}
					style={{ maxWidth: 200 }}
				/>
			</Tabs>
		);
	}
}

TournamentContainerTabs.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TournamentContainerTabs);
