import gql from 'graphql-tag';

export const leaveLeagueMutation = gql`
	mutation LeaveLeague($id: ID!) {
		leaveLeague(id: $id) {
			id
			__typename
		}
	}
`;
