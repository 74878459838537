import gql from 'graphql-tag';

export const locationFragment = gql`
	fragment locationInfo on Location {
		id
		name
		slug
		address
		city
		state
		zip
		country
	}
`;
