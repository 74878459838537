import gql from 'graphql-tag';

export const joinLeagueMutation = gql`
	mutation JoinLeague($id: ID) {
		joinLeague(id: $id) {
			id
			__typename
		}
	}
`;
