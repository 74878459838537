import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import { withAlert } from 'react-alert';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Schedule from '@material-ui/icons/Schedule';
import NavigationArrowBack from '@material-ui/icons/ArrowBack';
import SimpleGoogleMap from '../../components/SimpleGoogleMap';
import { StaticGoogleMap, Marker, Path } from 'react-static-google-map';
import LocationOn from '@material-ui/icons/LocationOn';
import eightBall from '../../assets/images/ball/eight-ball.png';
import nineBall from '../../assets/images/ball/nine-ball.png';
import tenBall from '../../assets/images/ball/ten-ball.png';
import OtherActionsButton from './OtherActionsButton';
import { SectionHeader, SectionContent, Card, ZeroState } from '../../components/chrome';
import ConfirmDialog from '../../components/ConfirmDialog';
import { getChallengeQuery } from './queries/getChallengeQuery';
import { removeChallengeMutation } from './mutations/removeChallengeMutation';
import { addCommentMutation } from './mutations/addCommentMutation';
import { acceptChallengeMutation } from './mutations/acceptChallengeMutation';
import { declineChallengeMutation } from './mutations/declineChallengeMutation';
import { graphql, compose } from 'react-apollo';
import { isoCountries, getGameType, displayEnumType } from '../../utils';
import _ from 'lodash';
import moment from 'moment';
import CommentForm from './CommentForm';
import ChallengeProgressStepper from './ChallengeProgressStepper';
import eightBallHeader from '../../assets/images/eight-ball-header-side.png';
import nineBallHeader from '../../assets/images/nine-ball-header-side.png';
import tenBallHeader from '../../assets/images/ten-ball-header-side.png';
import currentUser from '../../helpers/currentUser';

const styles = theme => ({
	toolbarTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	avatar: {
		backgroundColor: '#44475b',
		color: '#fff'
	},
	avatarLarge: {
		width: 60,
		height: 60,
		backgroundColor: '#44475b',
		color: '#fff'
	},
	backLink: {
		display: 'inline-block'
	},
	header: {
		[theme.breakpoints.up('sm')]: {
			backgroundImage: `url(${eightBallHeader})`
		},
		backgroundRepeat: 'no-repeat',
		backgroundSize: '20%',
		backgroundPosition: 'right'
	}
});

const instructions = {
	pending_review:
		'This challenge is pending review. You have 7 days to either Accept or Deny this request before it expires. Once you accept, the challenger will be notified and you will have 7 days to complete the match against your opponent at the desired time and location. You can comment on the challenge below, or message your opponent directly if you need to modify any details.',
	accepted:
		'Your match is now scheduled. The next step is to meet your opponent at the agreed upon start time and location and play your match within the next 10 days.',
	not_accepted: 'Not accepted instructions'
};

class ChallengeDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dialogOpen: false,
			declineDialogOpen: false
		};
		this.handleCloseDialog = this.handleCloseDialog.bind(this);
		this.handleConfirmDialog = this.handleConfirmDialog.bind(this);
		this.handleAddComment = this.handleAddComment.bind(this);
		this.acceptChallenge = this.acceptChallenge.bind(this);
		this.declineChallenge = this.declineChallenge.bind(this);
		this.handleDeclineConfirmDialog = this.handleDeclineConfirmDialog.bind(this);
		this.handleDeclineCloseDialog = this.handleDeclineCloseDialog.bind(this);
	}

	getGameType = type => {
		return type.indexOf('EIGHT_BALL') !== -1
			? eightBallHeader
			: type.indexOf('NINE_BALL') !== -1
			? nineBallHeader
			: type.indexOf('TEN_BALL') !== -1
			? tenBallHeader
			: null;
	};

	handleDeclineCloseDialog() {
		this.setState({
			declineDialogOpen: false
		});
	}

	handleDeclineConfirmDialog() {
		this.setState({
			declineDialogOpen: true
		});
	}

	handleCloseDialog() {
		this.setState({
			dialogOpen: false
		});
	}

	handleConfirmDialog() {
		this.setState({
			dialogOpen: true
		});
	}

	handleAddComment(comment, challenge) {
		return this.props.addComment({
			mutation: addCommentMutation,
			variables: {
				challengeId: challenge.id,
				input: {
					message: comment,
					authorId: challenge.challenger.id
				}
			},
			refetchQueries: [{ query: getChallengeQuery, variables: { id: challenge.id } }]
		});
	}

	isChallengeOwner(challenge) {
		const currentUser = this.props.currentUser;
		return challenge.challenger.id === currentUser.id;
	}

	acceptChallenge(challenge) {
		return this.props.acceptChallenge({
			mutation: acceptChallengeMutation,
			variables: {
				input: {
					id: challenge.id,
					challengerId: challenge.challenger.id,
					opponentId: challenge.opponent.id
				}
			},
			refetchQueries: [{ query: getChallengeQuery, variables: { id: challenge.id } }]
		});
	}

	declineChallenge(challenge) {
		return this.props.declineChallenge({
			mutation: declineChallengeMutation,
			variables: {
				input: {
					id: challenge.id,
					challengerId: challenge.challenger.id,
					opponentId: challenge.opponent.id
				}
			},
			refetchQueries: [{ query: getChallengeQuery, variables: { id: challenge.id } }]
		});
	}

	render() {
		const { classes, match, history } = this.props;

		return (
			<Query
				query={getChallengeQuery}
				fetchPolicy="no-cache"
				notifyOnNetworkStatusChange={true}
				variables={{ id: match.params.id }}
			>
				{({ loading, error, data, subscribeToMore }) => {
					if (loading) return <LinearProgress mode="indeterminate" color="primary" />;
					if (error) return <div style={styles.container}>Error: {error.message}</div>;
					if (data && !data.challenge) {
						return <div>Challenge not found.</div>;
					}

					console.log(data.challenge);

					return (
						<SectionHeader
							title="Challenge Overview"
							backButton={true}
							backPath="/challenges"
							rightButton={
								<Button
									variant="outlined"
									color="secondary"
									onClick={() => history.push('/challenges/new')}
								>
									Create Challenge
								</Button>
							}
						>
							<SectionContent>
								<Grid container justify="center" spacing={24}>
									{/*<Grid item xs={11} sm={12}>
										<Link to={'/challenges'} className={classes.backLink}>
											<Typography variant="subtitle1" gutterBottom>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<NavigationArrowBack style={{ width: 16, height: 16 }} />
													<span style={{ marginLeft: 5 }}>Back to Challenges list</span>
												</div>
											</Typography>
										</Link>
						</Grid>*/}

									<Grid item xs={12} sm={12} md={4}>
										<Grid container justify="center" spacing={24}>
											<Grid item xs={12}>
												<Card>
													<Grid container spacing={16}>
														<Grid item xs={5}>
															<Link to={`/players/${data.challenge.challenger.slug}`}>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		flexDirection: 'column'
																	}}
																>
																	<Avatar
																		size="large"
																		className={classes.avatarLarge}
																		src={data.challenge.challenger.avatarImg}
																	>
																		{data.challenge.challenger.name.slice(0, 1)}
																	</Avatar>

																	<div
																		style={{
																			marginTop: 10,
																			fontSize: 16,
																			textAlign: 'center'
																		}}
																	>
																		{data.challenge.challenger.name}
																	</div>

																	<i style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
																		Challenger
																	</i>
																</div>
															</Link>
														</Grid>
														<Grid
															item
															xs={2}
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center'
															}}
														>
															<div>VS.</div>
														</Grid>
														<Grid item xs={5}>
															<Link to={`/players/${data.challenge.opponent.slug}`}>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		flexDirection: 'column'
																	}}
																>
																	<Avatar
																		size="large"
																		className={classes.avatarLarge}
																		src={data.challenge.opponent.avatarImg}
																	>
																		{data.challenge.opponent.name.slice(0, 1)}
																	</Avatar>

																	<div
																		style={{
																			marginTop: 10,
																			fontSize: 16,
																			textAlign: 'center'
																		}}
																	>
																		{data.challenge.opponent.name}
																	</div>

																	<i style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
																		Opponent
																	</i>
																</div>
															</Link>
														</Grid>
													</Grid>
												</Card>
											</Grid>

											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20
														}}
													>
														Challenge progress
													</Typography>
													<Typography
														variant="subtitle1"
														gutterBottom
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20
														}}
													>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'flex-start',
																width: '100%'
															}}
														>
															<span
																style={{
																	width: '100%',
																	textAlign: 'left',
																	backgroundColor: '#ececec',
																	height: 20
																}}
															>
																<span
																	style={{
																		display: 'inline-block',
																		backgroundColor: '#ffcc00',
																		width: `${(25 / 100) * 100}%`,
																		height: '100%',
																		position: 'relative',
																		transition: 'background-color .3s ease',
																		cursor: 'pointer'
																	}}
																/>
															</span>
															<span style={{ width: 80, marginLeft: 10 }}>1/4</span>
														</div>
													</Typography>

													<ChallengeProgressStepper />
												</Card>
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={12} sm={12} md={8}>
										<Grid container justify="center" spacing={24}>
											<Grid item xs={12}>
												<Card nopadding>
													<div
														className={classes.header}
														style={{
															backgroundImage: `url(${this.getGameType(
																data.challenge.gameType
															)})`
														}}
													>
														<Typography
															variant="h6"
															color="inherit"
															className={classes.toolbarTitle}
															style={{
																paddingTop: 20,
																paddingLeft: 20,
																paddingRight: 20,
																paddingBottom: 20
															}}
														>
															<span>Challenge Details</span>
															{this.isChallengeOwner(data.challenge) === true && (
																<div style={{ display: 'flex', alignItems: 'center' }}>
																	<Button
																		variant="text"
																		color="primary"
																		size="small"
																		onClick={e => {
																			e.preventDefault();
																			history.push(
																				`/challenges/${data.challenge.id}/edit`
																			);
																		}}
																	>
																		Edit
																	</Button>
																	<Button
																		variant="text"
																		color="primary"
																		size="small"
																		onClick={e => {
																			e.preventDefault();
																			this.handleConfirmDialog(data.challenge);
																		}}
																		style={{
																			marginLeft: 10,
																			// borderColor: 'rgb(225, 0, 80)',
																			color: 'rgb(225, 0, 80)',
																			opacity:
																				data.challenge.status === 'ACCEPTED'
																					? 0.3
																					: 1.0
																		}}
																		disabled={
																			data.challenge.status === 'ACCEPTED'
																				? true
																				: false
																		}
																	>
																		Remove
																	</Button>
																</div>
															)}
														</Typography>

														<div
															style={{
																paddingTop: 0,
																paddingLeft: 20,
																paddingRight: 20,
																paddingBottom: 20
															}}
														>
															<Grid container spacing={16}>
																<Grid item xs={12} sm={4}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Game Type
																	</Typography>
																	<Typography variant="subtitle1" gutterBottom>
																		{displayEnumType(data.challenge.gameType) ||
																			'N/A'}
																	</Typography>
																</Grid>
																<Grid item xs={12} sm={4}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Race To
																	</Typography>
																	<Typography variant="subtitle1" gutterBottom>
																		{data.challenge.raceTo}
																	</Typography>
																</Grid>
																<Grid item xs={12} sm={4}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Match Format
																	</Typography>
																	<Typography variant="subtitle1" gutterBottom>
																		{displayEnumType(data.challenge.matchFormat) ||
																			'N/A'}
																	</Typography>
																</Grid>
																<Grid item xs={12} sm={4}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Status
																	</Typography>
																	<Typography
																		variant="subtitle1"
																		gutterBottom
																		style={
																			data.challenge.status === 'ACCEPTED'
																				? {
																						color: 'rgb(57, 199, 0)',
																						display: 'flex',
																						alignItems: 'center'
																				  }
																				: data.challenge.status ===
																				  'NOT_ACCEPTED'
																				? {
																						color: 'rgb(225, 0, 80)',
																						display: 'flex',
																						alignItems: 'center'
																				  }
																				: {
																						color: '#0091ff',
																						display: 'flex',
																						alignItems: 'center'
																				  }
																		}
																	>
																		{data.challenge.status === 'ACCEPTED' && (
																			<CheckCircle
																				style={{
																					width: 20,
																					height: 20,
																					marginRight: 5
																				}}
																			/>
																		)}
																		{data.challenge.status === 'PENDING_REVIEW' && (
																			<Schedule
																				style={{
																					width: 20,
																					height: 20,
																					marginRight: 5
																				}}
																			/>
																		)}
																		{displayEnumType(data.challenge.status) ||
																			'N/A'}
																	</Typography>
																</Grid>
																{/*<Grid item xs={12} sm={4}>
															<Typography
																variant="caption"
																className="caption"
																gutterBottom
															>
																Accepted
															</Typography>
															<Typography variant="subtitle1" gutterBottom>
																{Boolean(data.challenge.accepted).toString()}
															</Typography>
															</Grid>*/}
																<Grid item xs={12} sm={4}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Start Time
																	</Typography>
																	<Typography variant="subtitle1" gutterBottom>
																		{moment(data.challenge.startTime).format(
																			'llll'
																		) || 'N/A'}
																	</Typography>
																</Grid>
																<Grid item xs={12} sm={4}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Created
																	</Typography>
																	<Typography variant="subtitle1" gutterBottom>
																		{moment(data.challenge.createdAt).format(
																			'llll'
																		) || 'N/A'}
																	</Typography>
																</Grid>
																<Grid item xs={12} sm={12}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Notes
																	</Typography>
																	<Typography variant="subtitle1" gutterBottom>
																		{data.challenge.notes || 'N/A'}
																	</Typography>
																</Grid>
															</Grid>
														</div>

														<Divider />

														<Typography
															variant="h6"
															color="inherit"
															className={classes.toolbarTitle}
															style={{
																paddingTop: 20,
																paddingLeft: 20,
																paddingRight: 20
															}}
														>
															Next steps
														</Typography>

														<div
															style={{
																paddingTop: 20,
																paddingLeft: 20,
																paddingRight: 20,
																paddingBottom: 20
															}}
														>
															<Typography variant="subtitle1">
																{data.challenge.status === 'PENDING_REVIEW'
																	? instructions.pending_review
																	: data.challenge.status === 'ACCEPTED'
																	? instructions.accepted
																	: data.challenge.status === 'NOT_ACCEPTED'
																	? instructions.not_accepted
																	: 'N/A'}
															</Typography>
															<br />
															{this.context.authUser.id !==
																data.challenge.challenger.id &&
															data.challenge.status !== 'ACCEPTED' &&
															data.challenge.status !== 'NOT_ACCEPTED' ? (
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center'
																	}}
																>
																	<Button
																		variant="contained"
																		color="secondary"
																		size="large"
																		onClick={event =>
																			this.acceptChallenge(data.challenge)
																		}
																		disabled={
																			this.context.authUser.id ===
																				data.challenge.challenger.id ||
																			data.challenge.status === 'ACCEPTED' ||
																			data.challenge.status === 'NOT_ACCEPTED'
																				? true
																				: false
																		}
																	>
																		Accept
																	</Button>
																	<Button
																		variant="contained"
																		color="primary"
																		size="large"
																		style={{
																			marginLeft: 10
																			// borderColor: 'rgb(225, 0, 80)',
																			// color: 'rgb(225, 0, 80)'
																		}}
																		onClick={() =>
																			this.handleDeclineConfirmDialog(
																				data.challenge
																			)
																		}
																		// onClick={event => this.declineChallenge(data.challenge)}
																		disabled={
																			this.context.authUser.id ===
																				data.challenge.challenger.id ||
																			data.challenge.status === 'ACCEPTED' ||
																			data.challenge.status === 'NOT_ACCEPTED'
																				? true
																				: false
																		}
																	>
																		Decline
																	</Button>
																</div>
															) : null}

															{data.challenge.status === 'ACCEPTED' && (
																<React.Fragment>
																	<Button
																		variant="contained"
																		color="primary"
																		size="large"
																		style={{ marginRight: 10 }}
																		onClick={() => {
																			history.push(
																				`/matches/${
																					data.challenge.matches[0].id
																				}`
																			);
																		}}
																	>
																		View match details
																	</Button>
																	<OtherActionsButton />
																</React.Fragment>
															)}
														</div>
													</div>
												</Card>
											</Grid>

											<Grid item xs={12} sm={6}>
												<Card nopadding>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20
														}}
													>
														Location
													</Typography>
													<React.Fragment>
														{data.challenge.location ? (
															<List
																defaultValue={0}
																style={{
																	paddingLeft: 0,
																	paddingRight: 0,
																	paddingBottom: 10,
																	width: '100%'
																}}
															>
																<ListItem
																	className={classes.listItem}
																	onClick={() =>
																		history.push(
																			`/locations/${data.challenge.location.slug}`
																		)
																	}
																	button
																>
																	<Avatar size="small" className={classes.avatar}>
																		<LocationOn
																			style={{
																				height: 22
																			}}
																		/>
																	</Avatar>
																	<ListItemText
																		primary={data.challenge.location.name}
																		secondary={`${
																			data.challenge.location.address
																		}, ${data.challenge.location.city}, ${
																			data.challenge.location.state
																		} ${data.challenge.location.zip}`}
																	/>
																</ListItem>
															</List>
														) : (
															<ZeroState
																title="No locations yet"
																style={{ margin: 15 }}
															/>
														)}
													</React.Fragment>
												</Card>
											</Grid>

											<Grid item xs={12} sm={6}>
												<Card nopadding>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20
														}}
													>
														Division
													</Typography>
													{data.challenge.division ? (
														<List
															defaultValue={0}
															style={{
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10,
																width: '100%'
															}}
														>
															<ListItem
																className={classes.listItem}
																onClick={() =>
																	history.push(
																		`/divisions/${data.challenge.division.slug}`
																	)
																}
																button
															>
																<Avatar
																	size="small"
																	src={getGameType(data.challenge.division.type)}
																	className={classes.avatar}
																/>
																<ListItemText
																	primary={data.challenge.division.name}
																	secondary={displayEnumType(
																		data.challenge.division.type
																	)}
																/>
															</ListItem>
														</List>
													) : (
														<ZeroState
															title="This team does not have any divisions yet"
															style={{ margin: 15 }}
														>
															<Button
																variant="outlined"
																color="primary"
																size="small"
																onClick={e => {
																	e.preventDefault();
																	history.push(`/divisions/new`);
																}}
															>
																Add Division
															</Button>
														</ZeroState>
													)}
												</Card>
											</Grid>

											<Grid item xs={12}>
												<Card>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
													>
														More information
													</Typography>
													<div style={{ marginTop: 10 }}>
														<a href="#" style={{ color: '#0091ff' }}>
															How Challenges Work
														</a>
														<br />
														<a href="#" style={{ color: '#0091ff' }}>
															Frequently Asked Questions
														</a>
													</div>
												</Card>
											</Grid>

											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20,
															paddingBottom: 10
														}}
													>
														Comments ({data.challenge.commentCount || 0})
													</Typography>

													{data.challenge.comments && data.challenge.comments.length > 0 ? (
														<List
															defaultValue={0}
															style={{
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															{data.challenge.comments.map((comment, index) => {
																// console.log(comment);
																return (
																	<React.Fragment key={index}>
																		<ListItem className={classes.listItem}>
																			<Avatar
																				size="small"
																				className={classes.avatar}
																				src={
																					comment.author &&
																					comment.author.avatarImg
																				}
																			>
																				{comment.author &&
																					comment.author.name.slice(0, 1)}
																			</Avatar>

																			<ListItemText
																				primary={
																					comment.author &&
																					comment.author.name
																				}
																				secondary={comment.message}
																			/>
																		</ListItem>
																	</React.Fragment>
																);
															})}
														</List>
													) : null}

													<div
														style={{
															paddingBottom: 20,
															paddingLeft: 20,
															paddingRight: 20
														}}
													>
														<CommentForm
															addComment={this.handleAddComment}
															challenge={data.challenge}
														/>
													</div>
												</Card>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</SectionContent>
							<ConfirmDialog
								title="Confirm Challenge Removal"
								open={this.state.dialogOpen}
								onClose={this.handleCloseDialog}
								onConfirm={() => {
									this.props
										.removeChallenge({
											mutation: removeChallengeMutation,
											variables: { id: data.challenge.id },
											refetchQueries: ['GetChallenges']
										})
										.then(() => {
											this.setState({ dialogOpen: false });
											this.props.history.push(`/challenges`);
										})
										.catch(error => {
											console.log(error);
											this.setState({ dialogOpen: false });
										});
								}}
							>
								⚠️ Are you sure you want to delete this Challenge?
							</ConfirmDialog>

							<ConfirmDialog
								title="Confirm Decline Challenge"
								open={this.state.declineDialogOpen}
								onClose={this.handleDeclineCloseDialog}
								onConfirm={() => {
									this.declineChallenge(data.challenge)
										.then(() => {
											this.setState({ dialogOpen: false });
											this.props.history.push(`/challenges`);
										})
										.catch(error => {
											console.log(error);
											this.setState({ dialogOpen: false });
										});
								}}
							>
								⚠️ Are you sure you want to decline this Challenge?
							</ConfirmDialog>
						</SectionHeader>
					);
				}}
			</Query>
		);
	}
}

ChallengeDetail.contextTypes = {
	authUser: PropTypes.object,
	getUserProfile: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	changeLoginState: PropTypes.func,
	signIn: PropTypes.func,
	signOut: PropTypes.func
};

ChallengeDetail.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithGraphQL = compose(
	graphql(removeChallengeMutation, {
		options: ({ id }) => ({ variables: { id } }),
		name: 'removeChallenge'
	}),
	graphql(addCommentMutation, {
		options: ({ challengeId, input }) => ({ variables: { challengeId, input } }),
		name: 'addComment'
	}),
	graphql(acceptChallengeMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'acceptChallenge'
	}),
	graphql(declineChallengeMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'declineChallenge'
	})
)(ChallengeDetail);

const WithRouter = withRouter(WithGraphQL);
const WithStyles = withStyles(styles)(WithRouter);
const WithAlert = withAlert(WithStyles);
export default currentUser(WithAlert);
