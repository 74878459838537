import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
// import FontIcon from 'material-ui/FontIcon';
// import NavigationExpandMoreIcon from 'material-ui/svg-icons/navigation/expand-more';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
// import DropDownMenu from 'material-ui/DropDownMenu';
// import RaisedButton from "material-ui/RaisedButton";
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterList from '@material-ui/icons/FilterList';
// import ContentAdd from "material-ui/svg-icons/content/add";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const styles = {
	toolbar: {
		display: 'flex',
		paddingRight: 0,
		paddingLeft: 0,
		backgroundColor: 'transparent',
		// borderBottom: '1px solid rgb(224, 224, 224)',
		justifyContent: 'space-between'
	},
	toolbarTitle: {
		paddingLeft: 20,
		color: '#000',
		fontWeight: 400
	},
	buttonStyle: {
		float: 'right',
		backgroundColor: '#ffcc00'
	}
};

// <IconButton>
//   <ContentAdd />
// </IconButton>

class TournamentToolbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null
		};
		this.handleClick = this.handleClick.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	handleClick(event) {
		this.setState({ anchorEl: event.currentTarget });
	}

	handleClose() {
		this.setState({ anchorEl: null });
	}

	render() {
		// const {
		//   match,
		//   data: { loading, error, _allTournamentsMeta },
		//   ...rest
		// } = this.props;
		const { anchorEl } = this.state;
		const { classes, loading, error, data: { tournamentsConnection, tournaments } } = this.props;

		if (loading) {
			return (
				<Toolbar style={styles.toolbar}>
					<Typography variant="title" color="inherit" style={styles.toolbarTitle}>
						{this.props.text || 'Loading...'}
					</Typography>
				</Toolbar>
			);
		} else if (error) {
			return (
				<Toolbar style={styles.toolbar}>
					<Typography variant="title" color="inherit" style={styles.toolbarTitle}>
						{this.props.text || 'No results found'}
					</Typography>
				</Toolbar>
			);
		} else {
			return (
				<React.Fragment>
					<Toolbar style={styles.toolbar}>
						<Typography variant="title" color="inherit" style={styles.toolbarTitle}>
							{/*{`${tournamentsConnection.aggregate.count} Results`}*/}
							All Tournaments ({tournaments.length})
						</Typography>

						<div>
							<IconButton label="Create a tournament" primary="true" aria-label="add">
								<AddIcon />
							</IconButton>
							<IconButton>
								<FilterList />
							</IconButton>
							<IconButton
								aria-label="More"
								aria-owns={anchorEl ? 'simple-menu' : null}
								aria-haspopup="true"
								onClick={this.handleClick}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={this.handleClose}
							>
								<MenuItem>Refresh</MenuItem>
								<MenuItem>Send feedback</MenuItem>
								<MenuItem>Settings</MenuItem>
								<MenuItem>Help</MenuItem>
								<MenuItem>Sign out</MenuItem>
							</Menu>
						</div>
					</Toolbar>
				</React.Fragment>
			);
		}
	}
}

// const TournamentToolbarQuery = graphql(gql`
// 	query TournamentCountQuery {
// 		tournamentsConnection {
// 			aggregate {
// 				count
// 			}
// 		}
// 	}
// `)(TournamentToolbar);

TournamentToolbar.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TournamentToolbar);
