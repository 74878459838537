import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ZeroState, Card } from '../../components/chrome';
import classnames from 'classnames';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CommentIcon from '@material-ui/icons/Comment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CommentForm from './CommentForm';

const styles = theme => ({
	avatar: {
		backgroundColor: '#44475b',
		color: '#fff'
	},
	actions: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: '#fafafa'
	},
	comments: {
		backgroundColor: '#fafafa'
	},
	expand: {
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		}),
		marginLeft: 'auto',
		[theme.breakpoints.up('sm')]: {
			marginRight: -8
		}
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	},
	listItem: {
		paddingLeft: 0,
		paddingRight: 0,
		paddingTop: 5,
		paddingBottom: 5,
		display: 'flex',
		alignItems: 'flex-start'
	}
});

const comments = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

class Post extends Component {
	state = { expanded: false };

	handleExpandClick = () => {
		this.setState(state => ({ expanded: !state.expanded }));
	};

	render() {
		const { classes } = this.props;

		return (
			<Card nopadding>
				<CardHeader
					avatar={
						<Avatar aria-label="Recipe" className={classes.avatar}>
							R
						</Avatar>
					}
					action={
						<IconButton>
							<MoreVertIcon />
						</IconButton>
					}
					title="Shrimp and Chorizo Paella"
					subheader="September 14, 2016"
				/>
				<CardContent style={{ marginBottom: 10 }}>
					<Typography component="p" style={{ maxWidth: 560 }}>
						This impressive paella is a perfect party dish and a fun meal to cook together with your guests.
						Add 1 cup of frozen peas along with the mussels, if you like.
					</Typography>
				</CardContent>
				<Divider />
				<CardActions className={classes.actions}>
					<Button
						onClick={this.handleExpandClick}
						aria-expanded={this.state.expanded}
						aria-label="Show comments"
						style={{ display: 'flex', alignItems: 'center' }}
					>
						{this.state.expanded === true ? 'Hide comments' : 'Show comments'}
					</Button>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<CommentIcon />
						<span style={{ marginLeft: 5 }}>10</span>
					</div>
				</CardActions>
				<Collapse in={this.state.expanded} timeout="auto" unmountOnExit className={classes.comments}>
					<CardContent>
						<List
							defaultValue={0}
							style={{
								paddingTop: 0,
								paddingLeft: 0,
								paddingRight: 0,
								paddingBottom: 15
							}}
						>
							{comments.map((comment, index) => {
								return (
									<ListItem key={index} className={classes.listItem}>
										<Avatar size="small" className={classes.avatar}>
											T
										</Avatar>

										<ListItemText
											primary={'John Doe'}
											secondary={
												'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vehicula malesuada pharetra. Ut nec arcu pharetra, hendrerit dui non, porta dolor. Praesent aliquam purus a posuere dictum.'
											}
											style={{ maxWidth: 500 }}
										/>
										<ListItemSecondaryAction>2 days ago</ListItemSecondaryAction>
									</ListItem>
								);
							})}
						</List>
						<CommentForm />
					</CardContent>
				</Collapse>
			</Card>
		);
	}
}

Post.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Post);
