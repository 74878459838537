import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withFormsy } from 'formsy-react';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
	textField: {
		width: '100%',
		maxWidth: 400,
		backgroundColor: '#fafafa'
	},
	textFieldError: {
		width: '100%',
		maxWidth: 400,
		backgroundColor: '#fff5f5'
	}
});

const InputFeedback = ({ children }) => <div className="text-danger">{children}</div>;

class TextInput extends Component {
	constructor(props) {
		super(props);
		this.changeValue = this.changeValue.bind(this);
	}

	changeValue(event) {
		// setValue() will set the value of the component, which in
		// turn will validate it and the rest of the form
		// Important: Don't skip this step. This pattern is required
		// for Formsy to work.
		this.props.setValue(event.currentTarget.value);
	}

	render() {
		const {
			classes,
			value,
			multiline,
			rows,
			name,
			label,
			getValue,
			getErrorMessage,
			autoFocus,
			required,
			style,
			variant
		} = this.props;
		// An error message is returned only if the component is invalid
		const errorMessage = getErrorMessage();
		// const selectedValue = value && value.hasOwnProperty('id') ? value.id : value;

		return (
			<FormControl margin="dense" required fullWidth>
				<InputLabel htmlFor={`${name}-helper`}>{name}</InputLabel>
				<TextField
					error={Boolean(errorMessage)}
					id={name}
					name={name}
					label={label}
					value={getValue() || ''}
					onChange={this.changeValue}
					type="text"
					// defaultValue="Default Value"
					className={errorMessage ? classes.textFieldError : classes.textField}
					// margin="dense"
					autoComplete={name}
					autoFocus={autoFocus ? true : false}
					required={required ? true : false}
					multiline={multiline ? true : false}
					rows={rows ? rows : 0}
					style={style}
					variant={variant ? variant : 'standard'}
				/>

				<InputFeedback>{errorMessage}</InputFeedback>
			</FormControl>
		);
	}
}

TextInput.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles)(TextInput);
export default withFormsy(WithStyles);
