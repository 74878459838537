import gql from 'graphql-tag';

export const getLocationsQuery = gql`
	query GetLocations($offset: Int, $limit: Int, $order: [[String]]) {
		locations(offset: $offset, limit: $limit, order: $order) {
			id
			name
			slug
			type
			address
			city
			state
			zip
			country
			lat
			lon
			phone
			email
			website
			operatingHours
			isPublic
			isPrivate
			owner {
				id
				name
				slug
				username
				email
			}
			leagues {
				id
				name
				slug
				type
			}
			teams {
				id
				name
				slug
				divisions {
					id
					name
				}
			}
			tournaments {
				id
				name
				slug
			}
			createdAt
			# raw
		}
	}
`;
