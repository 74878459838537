import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	}
});

class TournamentCardTabs extends React.Component {
	state = {
		value: 0
	};

	handleChange = (event, value) => {
		this.setState({ value });
	};

	render() {
		const { classes } = this.props;
		const { value } = this.state;

		return (
			<div className={classes.root}>
				<Tabs
					value={value}
					onChange={this.handleChange}
					indicatorColor="secondary"
					textColor="primary"
					// fullWidth
					style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
				>
					{this.props.titles.map((title, index) => {
						return <Tab key={index} label={title} />;
					})}
				</Tabs>
				{this.props.children.map((container, index) => {
					if (value === index) {
						return <React.Fragment key={index}>{container}</React.Fragment>;
					}
				})}
			</div>
		);
	}
}

TournamentCardTabs.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TournamentCardTabs);
