import React, { Component } from 'react';

const styles = {
	// round: {
	// 	flex: 1,
	// 	minWidth: 300
	// }
};

class Round extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <div className={this.props.className}>{this.props.children}</div>;
	}
}

export default Round;
