import gql from 'graphql-tag';

export const addCommentMutation = gql`
	mutation AddComment($challengeId: ID!, $input: CommentInput) {
		addChallengeComment(challengeId: $challengeId, input: $input) {
			id
			message
			author {
				id
				name
				username
				avatarImg
			}
		}
	}
`;
