import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { withFormsy } from 'formsy-react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({
	formControl: {
		marginTop: 8,
		marginBottom: 4,
		width: '100%',
		maxWidth: 400,
		backgroundColor: '#fafafa'
	}
});

const InputFeedback = ({ children }) => <div className="text-danger">{children}</div>;

class SwitchField extends Component {
	constructor(props) {
		super(props);
		this.changeValue = this.changeValue.bind(this);
	}

	changeValue(event) {
		// setValue() will set the value of the component, which in
		// turn will validate it and the rest of the form
		// Important: Don't skip this step. This pattern is required
		// for Formsy to work.
		this.props.setValue(event.currentTarget.checked);
	}
	render() {
		const { classes, value, text, name, label, getValue, getErrorMessage, autoFocus, required, style } = this.props;
		// An error message is returned only if the component is invalid
		const errorMessage = getErrorMessage();

		return (
			<div style={style}>
				<FormControl className={classes.formControl}>
					<FormControlLabel
						control={
							<Switch
								id={name}
								name={name}
								label={label}
								checked={getValue() || false}
								onChange={this.changeValue}
								value={name}
								color="primary"
								autoComplete={name}
								autoFocus={autoFocus ? true : false}
								required={required ? true : false}
							/>
						}
						label={getValue() === true ? (text && text[0]) || label : (text && text[1]) || `Not ${label}`}
					/>

					<InputFeedback>{errorMessage}</InputFeedback>
				</FormControl>
			</div>
		);
	}
}

SwitchField.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles)(SwitchField);
export default withFormsy(WithStyles);
