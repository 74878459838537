import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';

class SimpleMap extends Component {
	static defaultProps = {
		center: {
			lat: 59.95,
			lng: 30.33
		},
		zoom: 11
	};

	constructor(props) {
		super(props);
		this.state = {
			center: {
				lat: props.center.lat,
				lng: props.center.lng
			}
		};
	}

	componentDidMount() {
		this.setState({
			center: {
				lat: parseFloat(this.props.lat),
				lng: parseFloat(this.props.lon)
			}
		});
	}

	componentWillReceiveProps(nextProps) {
		console.log(nextProps);
		if (nextProps.lat !== this.props.lat || nextProps.lon !== this.props.lon) {
			this.setState({
				center: {
					lat: parseFloat(nextProps.lat),
					lng: parseFloat(nextProps.lon)
				}
			});
		}
	}

	render() {
		const { width } = this.props;
		const { center } = this.state;

		return (
			// Important! Always set the container height explicitly
			<div style={{ height: 300, width: '100%' }}>
				<GoogleMapReact
					bootstrapURLKeys={{ key: 'AIzaSyDKAo0xnVtK_ALUhJ80q-eTsYYnSXnnTBY' }}
					defaultCenter={center}
					defaultZoom={this.props.zoom}
				>
					<Marker
						// key={place.id}
						// text={place.name}
						lat={center.lat}
						lng={center.lng}
					/>
				</GoogleMapReact>
			</div>
		);
	}
}

export default SimpleMap;
