import React, { Component } from 'react';
import Round from './Round';
import MatchGroup from './MatchGroup';
import Match from './Match';
import scrollToComponent from 'react-scroll-to-component';
import { scrollTo } from '../utils/scrollTo';
import DragScroll from './DragScroll';
import { isoCountries } from '../utils';
import BracketLogic from './BracketLogic';
import DoubleEliminationBracketGenerator from '../utils/doubleEliminationBracketGenerator';

const styles = {
	bracketContainer: {
		// position: 'absolute',
		width: '100%',
		height: 700,
		// height: '100%',
		// overflow: 'auto',
		// width: '100%',
		// overflow: 'auto',
		cursor: 'move'
		// transform: 'scale(1)'
	},
	headerContainer: {
		display: 'flex',
		borderBottom: '1px solid #EDEDED'
	},
	header: {
		// display: 'flex',
		textAlign: 'center',
		padding: 10,
		backgroundColor: '#fff',
		minWidth: 300,
		borderBottom: '1px solid #EDEDED',
		marginBottom: 40
	},
	spacer: {}
};

let playerId = 0;
function createPlayer(lastName, firstName, country) {
	playerId += 1;
	return {
		id: playerId,
		firstName,
		lastName,
		country
	};
}

const participants = [
	createPlayer('Shaw', 'Jayson', 'Scotland'),
	createPlayer('Kaci', 'Eklent', 'Albania'),
	createPlayer('Sanchez-Ruiz', 'Francisco', 'Spain'),
	createPlayer('Chang', 'Jung-Lin', 'Spain'),
	createPlayer('Ko', 'Ping-Chung', 'Chinese Taipei'),
	createPlayer('Oi', 'Naoyuki', 'Japan'),
	createPlayer('De Luna', 'Jeffrey', 'Philippines'),
	createPlayer('Deuel', 'Corey', 'United States'),
	createPlayer('Hohmann', 'Thorsten', 'Germany'),
	createPlayer('Filler', 'Joshua', 'Germany'),
	createPlayer('Dang', 'Jin-Hu', 'China'),
	createPlayer('Thorpe', 'Billy', 'United States'),
	createPlayer('Van Den Berg', 'Nick', 'Netherlands'),
	createPlayer('Van Boening', 'Shane', 'United States'),
	createPlayer('Woodward', 'Skyler', 'United States'),
	createPlayer('Bergman', 'Justin', 'United States'),
	createPlayer('Immonen', 'Mika', 'Finland'),
	createPlayer('Ouschan', 'Albin', 'Austria'),
	createPlayer('Pagulayan', 'Alex', 'Canada'),
	createPlayer('Akagariyama', 'Yukio', 'Japan'),
	createPlayer('Lo', 'Li Wen', 'China'),
	createPlayer('Appleton', 'Darren', 'Great Britain'),
	createPlayer('Biado', 'Carlo', 'Philippines'),
	createPlayer('Morra', 'John', 'Canada'),
	createPlayer('He', 'Mario', 'Austria'),
	createPlayer('Kiamco', 'Warren', 'Philippines'),
	createPlayer('Wu', 'Jia-Qing', 'China'),
	createPlayer('Feijen', 'Niels', 'Netherlands'),
	createPlayer('Strickland', 'Earl', 'United States'),
	createPlayer('Babica', 'Radoslaw', 'Poland'),
	createPlayer('Juszczyszyn', 'Konrad', 'Poland'),
	createPlayer('Lim', 'Reymart', 'United States'),
	createPlayer('Ko', 'Pin-Yi', 'Chinese Taipei'),
	createPlayer('Orcollo', 'Dennis', 'Philippines'),
	createPlayer('Chang', 'Yu-Lung', 'Chinese Taipei'),
	createPlayer('Lui', 'Haitao', 'China'),
	createPlayer('Archer', 'Johnny', 'United States'),
	createPlayer('Daigle', 'Martin', 'Canada'),
	createPlayer('Morris', 'Rodney', 'United States'),
	createPlayer('Jones', 'Jeremy', 'United States'),
	createPlayer('Makkonen', 'Petri', 'Finland'),
	createPlayer('Melling', 'Chris', 'Great Britain'),
	createPlayer('Schmidt', 'John', 'United States'),
	createPlayer('Mallinen', 'Juha', 'Finland'),
	createPlayer('Pinegar', 'Jonathan', 'United States'),
	createPlayer('Aranas', 'Zoren James', 'Philippines'),
	createPlayer('Alcano', 'Ronnie', 'Philippines'),
	createPlayer('Delawder', 'Michael', 'United States'),
	createPlayer('Chinahov', 'Ruslan', 'Russia'),
	createPlayer('Matikainen', 'Casper', 'Finland'),
	createPlayer('Hatch', 'Dennis', 'United States'),
	createPlayer('Kennedy', 'Tommy', 'United States'),
	createPlayer('Vidal-Claramunt', 'Marc', 'Spain'),
	createPlayer('Chua', 'Johann', 'Philippines'),
	createPlayer('Majid', 'Imran', 'Great Britain'),
	createPlayer('Cheng', 'Kevin', 'Chinese Taipei'),
	createPlayer('Hsu', 'Kai Lun', 'Chinese Taipei'),
	createPlayer('Verkic', 'Milos', 'Serbia'),
	createPlayer('Takano', 'Tomoo', 'Japan'),
	createPlayer('Al Ghumayz', 'Muhannad', 'Saudi Arabia'),
	createPlayer('Dominguez', 'Oscar', 'United States'),
	createPlayer('Roberts', 'Josh', 'United States'),
	createPlayer('Jelatis', 'Demetrius', 'United States'),
	createPlayer('Malaj', 'Nick', 'Greece'),
	createPlayer('Souquet', 'Ralph', 'Germany'),
	createPlayer('Grabe', 'Denis', 'Estonia'),
	createPlayer('Jie', 'Chu Bing', 'China'),
	createPlayer('Hseih', 'Chia Chen', 'Chinese Taipei'),
	createPlayer('Wu', 'Kun Lin', 'Chinese Taipei'),
	createPlayer('Corteza', 'Lee Van', 'Philippines'),
	createPlayer('Xiang', 'Han Hao', 'China'),
	createPlayer('Zvi', 'Zion', 'Israel'),
	createPlayer('Shuff', 'Brandon', 'United States'),
	createPlayer('Eberle', 'Max', 'United States'),
	createPlayer('Klatt', 'Jason', 'Canada'),
	createPlayer('Hernandez', 'Frankie', 'United States'),
	createPlayer('Bermudez', 'David Alcaide', 'Spain'),
	createPlayer('Borukhovich', 'Alex', 'United States'),
	createPlayer('Hopkins', 'Allen', 'United States'),
	createPlayer('Owen', 'Gabe', 'United States'),
	createPlayer('Weissenberger', 'Geronimo', 'Germany'),
	createPlayer('Labonte', 'Randy', 'United States'),
	createPlayer('Willis', 'Kenny', 'United States'),
	createPlayer('Parks', 'Brian', 'United States'),
	createPlayer('Shearer', 'Bradley', 'United States'),
	createPlayer('Mills', 'Donnie', 'United States'),
	createPlayer('Salim', 'Can', 'Germany'),
	createPlayer('Can', 'Wang', 'China'),
	createPlayer('Woodside', 'Dario', 'Bahamas'),
	createPlayer('Corr', 'Karen', 'Ireland'),
	createPlayer('Alfawal', 'Abdullatif', 'Qatar'),
	createPlayer('Buckley', 'Benji', 'United Kingdom'),
	createPlayer('Stottlemeyer', 'Brett', 'United States'),
	createPlayer('Korsiak', 'Joey', 'United States'),
	createPlayer('Lim', 'Nilbert', 'Philippines'),
	createPlayer('Moody', 'John', 'United States'),
	createPlayer('De Ruyter', 'Tim', 'Netherlands'),
	createPlayer('Bekteshi', 'Brendon', 'Albania'),
	createPlayer('Siekkinen', 'Jani', 'Finland'),
	createPlayer('Kazakis', 'Alex', 'Greece'),
	createPlayer('Peach', 'Daryl', 'United Kingdom'),
	createPlayer('Walker', 'Bernard', 'United States'),
	createPlayer('Alammar', 'Abdulrahman', 'Saudi Arabia'),
	createPlayer('Krah', 'Matt', 'United States'),
	createPlayer('Rodriguez', 'Jorge', 'United States'),
	createPlayer('Hart', 'Rob', 'United States'),
	createPlayer('Wong', 'Michael', 'United States'),
	createPlayer('Kapela', 'Richard', 'United States'),
	createPlayer('Goddard', 'Robert', 'United States'),
	createPlayer('Mann', 'Brian', 'United States'),
	createPlayer('Sparkman', 'Greg', 'United States'),
	createPlayer('Inigawa', 'Yuichi', 'Japan'),
	createPlayer('Lee Collins', 'William', 'United States'),
	createPlayer('Cicero', 'Joey', 'Canada'),
	createPlayer('Fleming', 'Steve', 'United States'),
	createPlayer('Robinson Reinhold', 'Chris', 'United States'),
	createPlayer('Patrikios', 'Elias', 'United States'),
	createPlayer('Harvey', 'Mike', 'United States'),
	createPlayer('Laukka', 'Daniel', 'United States'),
	createPlayer('Hossain', 'Sayeem', 'Bangladesh'),
	createPlayer('West', 'Kevin', 'United States'),
	createPlayer('Colvin', 'Tim', 'United States'),
	createPlayer('Blackburn', 'James', 'United States'),
	createPlayer('Al-Shammari', 'Abdullah', 'Saudi Arabia'),
	createPlayer('Al Otaibi', 'Khaled', 'Kuwait'),
	createPlayer('Chappel', 'Tyler', 'United States'),
	createPlayer('Gentile', 'Chris', 'United States'),
	createPlayer('Pyle', 'Christopher', 'United States'),
	createPlayer('Giallourakis', 'Damianos', 'Greece'),
	createPlayer('Mscisz', 'Adam', 'Poland'),
	createPlayer('Phlegar', 'Larry', 'United States'),
	createPlayer('Cook', 'John', 'United States'),
	createPlayer('Yednak', 'Michael', 'United States'),
	createPlayer('Nikolin', 'Dalibor', 'Serbia'),
	createPlayer('Poteet', 'Devin', 'United States'),
	createPlayer('Gassett', 'Jimmy', 'United States'),
	createPlayer('Zampko', 'Ron', 'United States'),
	createPlayer('Dominguez', 'Ernesto', 'Mexico'),
	createPlayer('Chohan', 'Tony', 'United States')
].slice(0, 16);

// console.log(
// 	participants.map(participant => {
// 		return participant.firstName + ' ' + participant.lastName;
// 	})
// );

const NUMBER_OF_PARTICIPANTS = participants.length; // Set the number of participants

// http://udaikapila165.blogspot.com/2010/12/double-elimination-how-many-rounds.html
class Bracket extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bracket: null
		};
	}

	componentWillMount() {
		if (!String.prototype.format) {
			String.prototype.format = function() {
				var args = arguments;
				return this.replace(/{(\d+)}/g, function(match, number) {
					return typeof args[number] != 'undefined' ? args[number] : match;
				});
			};
		}

		var players = Array.from({ length: NUMBER_OF_PARTICIPANTS }, (v, k) => k + 1);
		// console.log(players);
		var bracket = this.getBracket(players);

		const doubleElimBracket = new DoubleEliminationBracketGenerator(participants);
		console.log(doubleElimBracket);
		// const bracketLogic = new BracketLogic();

		console.log(bracket);
		this.setState({
			bracket: bracket
		});
	}

	getBracket(players) {
		var participantsCount = players.length;
		var rounds = Math.ceil(Math.log(participantsCount) / Math.log(2));
		var bracketSize = Math.pow(2, rounds);
		var requiredByes = bracketSize - participantsCount;

		// console.log('Number of participants: {0}'.format(participantsCount));
		// console.log('Number of rounds: {0}'.format(rounds));
		// console.log('Bracket size: {0}'.format(bracketSize));
		// console.log('Required number of byes: {0}'.format(requiredByes));

		var upperPower = Math.ceil(Math.log(participantsCount) / Math.log(2));

		// Find out what is the number that is a power of 2 and lower than numPlayers.
		var countNodesUpperBound = Math.pow(2, upperPower);

		// Find out what is the number that is a power of 2 and higher than numPlayers.
		var countNodesLowerBound = countNodesUpperBound / 2;

		// This is the number of nodes that will not show in the 1st level.
		var countNodesHidden = participantsCount - countNodesLowerBound;

		// console.log({
		// 	upperPower,
		// 	countNodesUpperBound,
		// 	countNodesLowerBound,
		// 	countNodesHidden
		// });

		if (participantsCount < 2) {
			return [];
		}

		let matches = [[1, 2]];
		let newRounds = [];

		for (let i = rounds; i >= 1; i--) {
			newRounds.push(i);
		}

		for (var round = 1; round < rounds; round++) {
			var roundMatches = [];
			var sum = Math.pow(2, round + 1) + 1;

			for (var i = 0; i < matches.length; i++) {
				var home = this.changeIntoBye(matches[i][0], participantsCount);
				var away = this.changeIntoBye(sum - matches[i][0], participantsCount);
				roundMatches.push([home, away]);
				home = this.changeIntoBye(sum - matches[i][1], participantsCount);
				away = this.changeIntoBye(matches[i][1], participantsCount);
				roundMatches.push([home, away]);
			}
			matches = roundMatches;
		}

		console.log(matches);

		const matchesWithPlayers = matches.map((match, index) => {
			const player1 = participants[match[0] - 1];
			const player2 = participants[match[1] - 1];
			return {
				round: round,
				identifier: index + 1,
				player1: player1,
				player2: player2,
				winnerId: null,
				loserId: null
			};
		});

		console.log(matchesWithPlayers);

		return {
			seeds: matches,
			matches: matchesWithPlayers
		};
	}

	changeIntoBye(seed, participantsCount) {
		//return seed <= participantsCount ?  seed : '{0} (= bye)'.format(seed);
		return seed <= participantsCount ? seed : null;
	}

	renderDraw() {
		const bracket = this.state.bracket;

		return (
			<React.Fragment>
				{bracket.seeds.map((seed, index) => {
					const matchName = `W1-${index + 1}`;
					const match = seed.map(id => {
						const player = participants[id - 1];
						if (player) {
							return {
								playerName: `${player.firstName} ${player.lastName}`,
								playerNumber: player.id,
								score: '-',
								winner: false,
								country: isoCountries[player.country.toLowerCase()] || ''
							};
						} else {
							return null;
						}
					});

					return (
						<React.Fragment key={index}>
							<Match
								className={`match ${index % 2 === 0 ? 'top' : 'bottom'} r1-m${index + 1} completed`}
								number={index + 1}
								status="scheduled"
								players={match}
								style={{ minHeight: 66 }}
								// ref={this.r1m1}
							/>
							{index === 0 ? <div className="match-spacer">{matchName}</div> : null}
							{index > 0 && index % 2 === 0 ? (
								<div className="match-spacer">W1-{(index + 2) / 2}</div>
							) : null}

							{index < bracket.seeds.length - 1 && index % 2 === 1 ? (
								<div className="match-spacer no-connector" />
							) : null}
						</React.Fragment>
					);
				})}
				{/*<Match
					className="match top r1-m1 completed"
					number={1}
					status="completed"
					players={[
						{
							playerName: 'Sanchez-Ruiz',
							playerNumber: '1',
							score: 'W',
							winner: true,
							country: 'ESP'
						},
						{
							playerName: 'BYE',
							playerNumber: '2'
						}
					]}
					style={{ minHeight: 66 }}
					// ref={this.r1m1}
				/>
				<div className="match-spacer">W1-1</div>
				<Match
					className="match bottom r1-m2 completed"
					number={2}
					status="completed"
					players={[
						{
							playerName: 'Buckley B.',
							playerNumber: '3',
							score: 'W',
							winner: true,
							country: 'GBR'
						},
						{
							playerName: 'BYE',
							playerNumber: '4'
						}
					]}
					style={{ minHeight: 66 }}
				/>
				<div className="match-spacer no-connector" />*/}

				{/*<Match
					className="match top r1-m3 completed"
					number={3}
					status="completed"
					players={[
						{
							playerName: 'Majid I.',
							playerNumber: '5',
							score: 'W',
							winner: true,
							country: 'GBR'
						},
						{
							playerName: 'BYE',
							playerNumber: '6'
						}
					]}
					style={{ minHeight: 66 }}
				/>
				<div className="match-spacer">W1-2</div>
				<Match
					className="match bottom r1-m4 completed"
					number={4}
					status="completed"
					players={[
						{
							playerName: 'Al Shammari A.',
							playerNumber: '7',
							score: 'W',
							winner: true
						},
						{
							playerName: 'BYE',
							playerNumber: '8'
						}
					]}
					style={{ minHeight: 66 }}
				/>
				<div className="match-spacer no-connector" />
				<Match
					className="match top r1-m5 completed"
					number={5}
					status="completed"
					players={[
						{
							playerName: 'Otaibi Ka.',
							playerNumber: '7',
							score: 'W',
							winner: true,
							country: 'SA'
						},
						{
							playerName: 'BYE',
							playerNumber: '8'
						}
					]}
					style={{ minHeight: 66 }}
				/>
				<div className="match-spacer">W1-3</div>
				<Match
					className="match bottom r1-m6 completed"
					number={6}
					status="completed"
					players={[
						{
							playerName: 'Pinegar J.',
							playerNumber: '7',
							score: 'W',
							winner: true,
							country: 'US'
						},
						{
							playerName: 'BYE',
							playerNumber: '8'
						}
					]}
					style={{ minHeight: 66 }}
				/>
				<div className="match-spacer no-connector" />
				<Match
					className="match top r1-m7 completed"
					number={7}
					status="completed"
					players={[
						{
							playerName: 'Chua J.',
							playerNumber: '7',
							score: 'W',
							winner: true,
							country: 'PH'
						},
						{
							playerName: 'BYE',
							playerNumber: '8'
						}
					]}
					style={{ minHeight: 66 }}
				/>
				<div className="match-spacer">W1-4</div>
				<Match
					className="match bottom r1-m8 completed"
					number={8}
					players={[
						{
							playerName: 'Morris R.',
							playerNumber: '7',
							score: 'W',
							winner: true,
							country: 'US'
						},
						{
							playerName: 'BYE',
							playerNumber: '8'
						}
					]}
					style={{ minHeight: 66 }}
				/>*/}
			</React.Fragment>
		);
	}

	render() {
		// const { height } = this.props;

		return (
			<DragScroll id="bracket" height={700} width="100%">
				<div style={styles.bracketContainer}>
					<div className="rounds">
						<div className="draw">
							<Round className="round r1">
								<div className="round-header">Round 1</div>
								<div className="spacer" />
								{this.renderDraw()}
								<div className="spacer" />
							</Round>
						</div>
						<div className="a-side">
							<Round className="round w1">
								<div className="round-header">Winners Round 1</div>
								<div className="spacer" />
								<Match
									className="match top w1-m1 completed"
									number={9}
									status="completed"
									players={[
										{
											playerName: 'Sanchez-Ruiz',
											playerNumber: '1',
											score: 11,
											winner: true,
											country: 'ES'
										},
										{
											playerName: 'Buckley B.',
											playerNumber: '3',
											score: 3,
											winner: false,
											country: 'GB'
										}
									]}
									footerText="Loser to #20"
								/>
								<div className="match-spacer">W2-1</div>
								<Match
									className="match bottom w1-m2 completed"
									number={10}
									status="completed"
									players={[
										{
											playerName: 'Majid I.',
											playerNumber: '7',
											score: 11,
											winner: true,
											country: 'GB'
										},
										{
											playerName: 'Al Shammari A.',
											playerNumber: '7',
											score: 6,
											winner: false
										}
									]}
									footerText="Loser to #19"
								/>
								<div className="match-spacer no-connector" />
								<Match
									className="match top w1-m3 completed"
									number={11}
									status="completed"
									players={[
										{
											playerName: 'Otaibi Ka.',
											playerNumber: '7',
											score: 7,
											winner: false,
											country: 'SA'
										},
										{
											playerName: 'Pinegar J.',
											playerNumber: '7',
											score: 11,
											winner: true,
											country: 'US'
										}
									]}
									footerText="Loser to #18"
								/>
								<div className="match-spacer">W2-2</div>
								<Match
									className="match bottom w1-m4 completed"
									number={12}
									status="completed"
									players={[
										{
											playerName: 'Chua J.',
											playerNumber: '7',
											score: 7,
											winner: false,
											country: 'PH'
										},
										{
											playerName: 'Morris R.',
											playerNumber: '7',
											score: 11,
											winner: true,
											country: 'US'
										}
									]}
									footerText="Loser to #17"
								/>
								<div className="spacer" />
							</Round>
							<Round className="round w2">
								<div className="round-header">Winners Round 2</div>
								<div className="spacer" />
								<Match
									className="match top w2-m1 completed"
									number={21}
									status="completed"
									players={[
										{
											playerName: 'Sanchez-Ruiz',
											playerNumber: '1',
											score: 11,
											winner: true,
											country: 'ESP'
										},
										{
											playerName: 'Majid I.',
											playerNumber: '3',
											score: 8,
											winner: false,
											country: 'GBR'
										}
									]}
								/>
								<div className="match-spacer">W3-1</div>
								<Match
									className="match bottom w1-m2 completed"
									number={22}
									status="completed"
									players={[
										{
											playerName: 'Pinegar J.',
											playerNumber: '1',
											score: 6,
											winner: false,
											country: 'USA'
										},
										{
											playerName: 'Morris R.',
											playerNumber: '3',
											score: 11,
											winner: true,
											country: 'USA'
										}
									]}
								/>
								<div className="spacer" />
							</Round>
							<Round className="round w3">
								<div className="round-header">Winners Round 3</div>
								<div className="spacer" />
								<Match
									className="match w3-m1 live"
									number={26}
									isLive={true}
									isTvTable={true}
									status="in progress"
									players={[
										{
											playerName: 'Sanchez-Ruiz',
											playerNumber: '1',
											score: 11,
											winner: true,
											country: 'ESP'
										},
										{
											playerName: 'Morris R.',
											playerNumber: '3',
											score: 7,
											winner: false,
											country: 'USA'
										}
									]}
								/>
								<div className="spacer" />
							</Round>
						</div>
						<div className="b-side">
							<Round className="round l4">
								<div className="round-header">Losers Round 4</div>
								<div className="spacer" />
								<Match
									className="match l4-m1"
									number={25}
									players={[
										{ playerName: 'Jane Doe', playerNumber: '789', score: 7, winner: true },
										{ playerName: 'John Doe', playerNumber: '012', score: 5, winner: false }
									]}
									footerText="Winner to Quarter Final"
								/>
								<div className="spacer" />
							</Round>
							<Round className="round l3">
								<div className="round-header">Losers Round 3</div>
								<div className="spacer" />
								<Match
									className="match top l3-m1"
									number={23}
									players={[
										{ playerName: 'Jane Doe', playerNumber: '789', score: 7, winner: true },
										{ playerName: 'John Doe', playerNumber: '012', score: 5, winner: false }
									]}
								/>
								<div className="match-spacer">L3-1</div>
								<Match
									className="match bottom l3-m2"
									number={24}
									players={[
										{ playerName: 'Jane Doe', playerNumber: '789', score: 7, winner: true },
										{ playerName: 'John Doe', playerNumber: '012', score: 5, winner: false }
									]}
									style={{ minHeight: 198 }}
								/>

								<div className="spacer" />
							</Round>
							<Round className="round l2">
								<div className="round-header">Losers Round 2</div>
								<div className="spacer" />
								<Match
									className="match top l2-m1 no-right-connector"
									number={17}
									players={[
										{ playerName: 'Jane Doe', playerNumber: '789', score: 7, winner: true },
										{ playerName: 'John Doe', playerNumber: '012', score: 5, winner: false }
									]}
								/>
								<div className="match-spacer">L2-1</div>
								<Match
									className="match bottom l2-m2 no-right-connector"
									number={18}
									players={[
										{ playerName: 'Jane Doe', playerNumber: '789', score: 7, winner: true },
										{ playerName: 'John Doe', playerNumber: '012', score: 5, winner: false }
									]}
									style={{ minHeight: 198 }}
								/>
								<div className="match-spacer no-connector" />
								<Match
									className="match top l2-m3 no-right-connector"
									number={19}
									players={[
										{ playerName: 'Jane Doe', playerNumber: '789', score: 7, winner: true },
										{ playerName: 'John Doe', playerNumber: '012', score: 5, winner: false }
									]}
								/>
								<div className="match-spacer">L2-2</div>
								<Match
									className="match bottom l2-m4 no-right-connector"
									number={20}
									players={[
										{
											playerName: 'Buckley B.',
											playerNumber: '3',
											score: 'W',
											winner: true,
											country: 'GBR'
										},
										{ playerName: 'John Doe', playerNumber: '012', score: 5, winner: false }
									]}
									style={{ minHeight: 198 }}
								/>
								<div className="spacer" />
							</Round>
							<Round className="round l1">
								<div className="round-header">Losers Round 1</div>
								<div className="spacer" />
								<Match
									className="match top l1-m1 completed no-connectors"
									number={13}
									status="completed"
									players={[
										{
											playerName: 'BYE',
											playerNumber: '8'
										},
										{
											playerName: 'BYE',
											playerNumber: '8'
										}
									]}
									style={{ minHeight: 198 }}
								/>
								<div className="match-spacer no-connector">L1-1</div>
								<Match
									className="match bottom l1-m2 completed no-connectors"
									number={14}
									status="completed"
									players={[
										{
											playerName: 'BYE',
											playerNumber: '8'
										},
										{
											playerName: 'BYE',
											playerNumber: '8'
										}
									]}
									style={{ minHeight: 198 }}
								/>
								<div className="match-spacer no-connector" />
								<Match
									className="match top l1-m3 completed no-connectors"
									number={15}
									status="completed"
									players={[
										{
											playerName: 'BYE',
											playerNumber: '8'
										},
										{
											playerName: 'BYE',
											playerNumber: '8'
										}
									]}
									style={{ minHeight: 198 }}
								/>
								<div className="match-spacer no-connector">L1-2</div>
								<Match
									className="match bottom l1-m4 completed no-connectors"
									number={16}
									status="completed"
									players={[
										{
											playerName: 'BYE',
											playerNumber: '8'
										},
										{
											playerName: 'BYE',
											playerNumber: '8'
										}
									]}
									style={{ minHeight: 198 }}
								/>
								<div className="spacer" />
							</Round>
						</div>
					</div>
				</div>
			</DragScroll>
		);
	}
}

export default Bracket;
