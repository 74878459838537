import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withFormsy } from 'formsy-react';
import TextField from '@material-ui/core/TextField';
import { DateTimePicker } from 'material-ui-pickers';

const styles = theme => ({
	textField: {
		width: '100%',
		maxWidth: 400,
		backgroundColor: '#fafafa'
	},
	textFieldError: {
		width: '100%',
		maxWidth: 400,
		backgroundColor: '#fff5f5'
	}
});

const InputFeedback = ({ children }) => <div className="text-danger">{children}</div>;

class DateInput extends Component {
	constructor(props) {
		super(props);
		this.changeValue = this.changeValue.bind(this);
	}

	changeValue(date) {
		// setValue() will set the value of the component, which in
		// turn will validate it and the rest of the form
		// Important: Don't skip this step. This pattern is required
		// for Formsy to work.
		this.props.setValue(date);
	}

	// handleDateChange = date => {
	// 	const coin = this.props.coin;
	// 	let newState = Object.assign({}, this.state, { purchaseDate: date });
	// 	this.setState(newState);
	// 	this.props.onUpdateFavorite(newState, coin);
	// };

	render() {
		const { classes, value, name, label, getValue, getErrorMessage, autoFocus, required } = this.props;
		// An error message is returned only if the component is invalid
		const errorMessage = getErrorMessage();
		// const selectedValue = value && value.hasOwnProperty('id') ? value.id : value;

		return (
			<FormControl margin="dense" required fullWidth>
				<InputLabel htmlFor={`${name}-helper`}>{name}</InputLabel>
				{/*<TextField
					error={Boolean(errorMessage)}
					id={name}
					name={name}
					label={label}
					value={getValue() || ''}
					onChange={this.changeValue}
					type="datetime-local"
					// defaultValue="2017-05-24T10:30"
					// defaultValue="Default Value"
					className={errorMessage ? classes.textFieldError : classes.textField}
					// margin="dense"
					autoComplete={name}
					autoFocus={autoFocus ? true : false}
					required={required ? true : false}
					InputLabelProps={{
						shrink: true
					}}
				/>*/}

				<DateTimePicker
					error={Boolean(errorMessage)}
					id={name}
					name={name}
					label={label}
					value={getValue() || ''}
					// disableFuture={true}
					// disablePast
					onChange={this.changeValue}
					className={errorMessage ? classes.textFieldError : classes.textField}
					showTodayButton={true}
					clearable
					autoComplete={name}
					autoFocus={autoFocus ? true : false}
					required={required ? true : false}
					InputLabelProps={{
						shrink: true
					}}
				/>

				<InputFeedback>{errorMessage}</InputFeedback>
			</FormControl>
		);
	}
}

DateInput.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles)(DateInput);
export default withFormsy(WithStyles);
