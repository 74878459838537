import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';

class Message extends Component {
	render() {
		// Was the message sent by the current user. If so, add a css class
		const fromMe = this.props.fromMe ? 'from-me' : '';

		const { text, from, message, createdAt, username, typing } = this.props;
		return (
			<div className={`message ${fromMe}`}>
				<div style={{ display: 'flex', alignItems: 'flex-start' }}>
					<Avatar size="small" src={from && from.avatarImg} />
					{typing === true ? (
						<div style={{ marginLeft: 10 }}>
							<div className="message-body" style={{ paddingRight: 5 }}>
								<span className="dot" />
								<span className="dot" />
								<span className="dot" />
							</div>
						</div>
					) : (
						<div style={{ marginLeft: 10 }}>
							<div className="message-body">
								<div className="username">{from && from.name}</div>
								<div>{text}</div>
							</div>
							<div style={{ fontSize: 11, textAlign: 'right' }}>{moment(createdAt).fromNow()}</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default Message;
