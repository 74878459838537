import React, { Component } from 'react';
// import Slide from './Slide';
import { red, blue, green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import { Slide, AutoRotatingCarousel } from 'material-auto-rotating-carousel';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

// https://mui.wertarbyte.com/#autorotatingcarousel
class OnboardingCarousel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false
			// open: this.props.open || true
		};
	}
	render() {
		return (
			<React.Fragment>
				{/*<Button onClick={() => this.setState({ open: true })}>Open carousel</Button>*/}
				<AutoRotatingCarousel
					label="Get started"
					open={this.state.open}
					onClose={() => this.setState({ open: false })}
					// hideArrows={true}
					// landscape={true}
					mobile={isWidthDown('xs', this.props.width) ? true : false}
					style={{ position: 'absolute' }}
				>
					<Slide
						media={<img src="http://www.icons101.com/icon_png/size_256/id_79394/youtube.png" />}
						mediaBackgroundStyle={{ backgroundColor: red[400] }}
						style={{ backgroundColor: red[600] }}
						title="This is a very cool feature"
						subtitle="Just using this will blow your mind."
					/>
					<Slide
						media={<img src="http://www.icons101.com/icon_png/size_256/id_80975/GoogleInbox.png" />}
						mediaBackgroundStyle={{ backgroundColor: blue[400] }}
						style={{ backgroundColor: blue[600] }}
						title="Ever wanted to be popular?"
						subtitle="Well just mix two colors and your are good to go!"
					/>
					<Slide
						media={<img src="http://www.icons101.com/icon_png/size_256/id_76704/Google_Settings.png" />}
						mediaBackgroundStyle={{ backgroundColor: green[400] }}
						style={{ backgroundColor: green[600] }}
						title="May the force be with you"
						subtitle="The Force is a metaphysical and ubiquitous power in the Star Wars fictional universe."
					/>
				</AutoRotatingCarousel>
			</React.Fragment>
		);
	}
}

export default withWidth()(OnboardingCarousel);
