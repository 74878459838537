import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Formik, Form, Field, FieldArray } from 'formik';
import TextInput from '../../components/forms/TextInputFormik';
import SelectField from '../../components/forms/SelectFormik';
import MultiSelectField from '../../components/forms/MultiSelectFormik';
import SwitchInput from '../../components/forms/SwitchFormik';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { getTeamQuery } from './queries/getTeamQuery';
import { updateTeamMutation } from './mutations/updateTeamMutation';
import { createTeamMutation } from './mutations/createTeamMutation';
import _ from 'lodash';
import eightBall from '../../assets/images/ball/eight-ball.png';
import nineBall from '../../assets/images/ball/nine-ball.png';
import tenBall from '../../assets/images/ball/ten-ball.png';

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	}
});

const getInitialValues = team => ({
	id: (team && team.id) || '',
	name: (team && team.name) || '',
	// number: (team && team.number) || '',
	location: (team && team.location) || '',
	players: (team && team.players) || [],
	matches: (team && team.matches) || [],
	tournaments: (team && team.tournaments) || [],
	divisions: (team && team.divisions) || [],
	captain: (team && team.captain) || '',
	isActive: (team && team.isActive) || ''
});

const TeamSchema = Yup.object().shape({
	name: Yup.string()
		.min(3, 'Must be longer than 3 characters')
		.required('Required')
	// last_name: Yup.string()
	//   .min(2, 'Must be longer than 2 characters')
	//   .max(20, 'Nice try, nobody has a last name that long')
	//   .required('Required'),
	// birthday: Yup.date()
	//   .required('You should add a correct birthday date'),
	// job: Yup.string()
	//   .required('Job is required'),
	// email: Yup.string()
	//   .email('Invalid email address')
	//   .required('Required Email'),
	// password: Yup.string()
	//   .required('Required Password'),
	// password_confirmation: Yup.string()
	//   .oneOf([Yup.ref('password')], 'Password and confirmation must been the same')
	//   .required('Required Password Confirmation'),
});

export const getDivisionsQuery = gql`
	query GetDivisions {
		divisions {
			id
			name
			slug
			type
		}
	}
`;

export const getLocationsQuery = gql`
	query GetLocations {
		locations {
			id
			name
			slug
			# address
			# city
			# state
			# zip
			# country
			# lat
			# lon
			# createdAt
		}
	}
`;

export const getPlayersQuery = gql`
	query GetPlayers {
		users {
			id
			name
			username
			avatarImg
			# address
			# city
			# state
			# zip
			# country
			# lat
			# lon
			# createdAt
		}
	}
`;

class TeamCreateEditForm extends PureComponent {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	// shouldComponentUpdate(nextProps, nextState) {
	// 	if (nextProps !== this.props) {
	// 		return true;
	// 	} else {
	// 		return false;
	// 	}
	// }

	getIds(item) {
		if (item && _.isArray(item)) {
			return item.map(obj => {
				return obj.hasOwnProperty('id') ? obj.id : obj;
			});
		} else if (typeof item === 'string') {
			return item;
		} else if (typeof item === 'object' && !_.isArray(item)) {
			return item.id;
		}
		return item;
	}

	handleSubmit(values, { setSubmitting, setErrors }) {
		// console.log('Edit Team Form submitted', values);
		setSubmitting(true);
		if (!this.props.slug) {
			this.props
				.createTeam({
					mutation: createTeamMutation,
					variables: {
						input: {
							name: values.name,
							locationId: this.getIds(values.location),
							userIds: this.getIds(values.players),
							divisionIds: this.getIds(values.divisions),
							isActive: Boolean(values.isActive)
						}
					},
					refetchQueries: ['GetTeam']
				})
				.then(({ data }) => {
					// console.log(data);
					this.props.history.push(`/teams/${data.createTeam.slug}`);
				})
				.catch(error => {
					// console.log(error);
					setSubmitting(false);
					setErrors({ name: ' ', description: ' ', form: error });
				});
		} else {
			this.props
				.updateTeam({
					mutation: updateTeamMutation,
					variables: {
						input: {
							id: values.id,
							name: values.name,
							locationId: this.getIds(values.location),
							userIds: this.getIds(values.players),
							divisionIds: this.getIds(values.divisions),
							isActive: Boolean(values.isActive)
						}
					},
					refetchQueries: ['GetTeam']
					// update: (store, { data: { updateTeam } }) => {
					// 	this.props.updateStoreAfterUpdate(store, updateTeam, values.id);
					// }
					// update: (cache, { data: { updateTeam } }) => {
					// 	// const { team } = cache.readQuery({ query: getTeamQuery });
					// 	// console.log(team);
					// 	cache.writeQuery({
					// 		query: getTeamQuery,
					// 		data: { team: updateTeam }
					// 	});
					// }

					// optimisticResponse: {
					// 	__typename: 'Mutation',
					// 	updateTeam: {
					// 		id: values.id,
					// 		__typename: 'Team',
					// 		name: values.name,
					// 		slug: values.slug,
					// 		isActive: values.isActive,
					// 		location: values.location,
					// 		players: values.players
					// 	}
					// }
				})
				.then(({ data }) => {
					console.log(data);

					this.props.history.push(`/teams/${data.updateTeam.slug}`);
				})
				.catch(error => {
					// console.log(error);
					setSubmitting(false);
					setErrors({ name: ' ', description: ' ', form: error });
				});
		}
	}

	getGameType(type) {
		return type.indexOf('EIGHT_BALL') !== -1
			? eightBall
			: type.indexOf('NINE_BALL') !== -1 ? nineBall : type.indexOf('TEN_BALL') !== -1 ? tenBall : null;
	}

	getDivisions() {
		const { getDivisions: { divisions, loading, error } } = this.props;
		if (loading) return null;
		const formatted = divisions.map(division => {
			return {
				label: division.name,
				value: division.id,
				avatar: this.getGameType(division.type)
			};
		});
		return formatted;
	}

	getLocations() {
		const { getLocations: { locations, loading, error } } = this.props;
		if (loading) return null;
		const formatted = locations.map(location => {
			return {
				label: location.name,
				value: location.id
			};
		});
		return formatted;
	}

	getPlayers() {
		const { getPlayers: { users, loading, error } } = this.props;
		if (loading) return null;
		const formatted = users.map(player => {
			return {
				label: player.name,
				value: player.id,
				avatar: player.avatarImg
			};
		});
		return formatted;
	}

	render() {
		// console.log(this.props);
		const { classes, getTeam: { team, loading, error }, getLocations: { locations } } = this.props;

		if (loading) return null;
		// console.log('in team form', team);

		return (
			<Formik
				enableReinitialize
				initialValues={getInitialValues(team)}
				validationSchema={TeamSchema}
				onSubmit={this.handleSubmit}
				render={props => {
					const {
						values,
						// touched,
						errors,
						dirty,
						isSubmitting,
						// handleChange,
						// handleBlur,
						// handleSubmit,
						handleReset
					} = props;
					return (
						<Form>
							<Field type="text" name="name" label="Name" component={TextInput} />
							<div className="spacer" />
							<Field
								type="text"
								name="location"
								label="Location"
								selected={team && team.location.id}
								// value={team.location.id}
								component={SelectField}
								options={this.getLocations()}
							/>
							<div className="spacer" />
							<Field
								name="divisions"
								label="Divisions"
								selected={
									team &&
									team.divisions.map(division => {
										return division.id;
									})
								}
								component={MultiSelectField}
								options={this.getDivisions()}
							/>
							<div className="spacer" />
							<Field
								name="players"
								label="Players"
								selected={
									team &&
									team.players.map(player => {
										return player.id;
									})
								}
								component={MultiSelectField}
								options={this.getPlayers()}
							/>

							<div className="spacer" />
							<Field
								// type="checkbox"
								// checked={team.isActive}
								id="isActive"
								name="isActive"
								label="Active"
								component={SwitchInput}
							/>
							<div className="spacer" />
							{this.props.slug ? (
								<Button
									variant="contained"
									color="primary"
									disabled={isSubmitting || !isEmpty(errors) || !dirty}
									type="submit"
								>
									{isSubmitting ? 'Saving...' : 'Update'}
								</Button>
							) : (
								<Button
									variant="contained"
									color="primary"
									disabled={isSubmitting || !isEmpty(errors) || !dirty}
									type="submit"
								>
									{isSubmitting ? 'Saving...' : 'Create'}
								</Button>
							)}

							{/*<Button
								variant="outlined"
								onClick={handleReset}
								disabled={!dirty || isSubmitting}
								style={{ marginLeft: 10 }}
							>
								Reset
              </Button>*/}
						</Form>
					);
				}}
			/>
		);
	}
}

TeamCreateEditForm.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithGraphQL = compose(
	graphql(getTeamQuery, {
		options: ({ slug }) => ({
			variables: { slug },
			notifyOnNetworkStatusChange: true
			// fetchPolicy: 'network-only'
			// refetchQueries: [
			// 	{
			// 		query: getTeamQuery,
			// 		variables: { variables: { slug } }
			// 	}
			// ]
		}),
		name: 'getTeam'
	}),
	graphql(getDivisionsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getDivisions'
	}),
	graphql(getLocationsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getLocations'
	}),
	graphql(getPlayersQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getPlayers'
	}),
	graphql(createTeamMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'createTeam'
	}),
	graphql(updateTeamMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'updateTeam'
	})
)(TeamCreateEditForm);

const WithRouter = withRouter(WithGraphQL);

export default withStyles(styles)(WithRouter);
