import gql from 'graphql-tag';

export const updatePlayerMutation = gql`
	mutation UpdatePlayer($input: UserInput) {
		updateUser(input: $input) {
			id
			name
			slug
			username
			email
			playerNumber
			playerRatings {
				id
				type
				rating
				customName
			}
			leagues {
				id
				name
				slug
				type
			}
			isActive
			avatarImg
			matches {
				id
			}
			tournaments {
				id
				name
				slug
				startTime
			}
			teams {
				id
				name
				slug
				divisions {
					id
				}
			}
			divisions {
				id
				name
				slug
				type
			}
			role
			address
			city
			state
			zip
			country
			phone
			joinedAt
			createdAt
		}
	}
`;
