import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import { withAlert } from 'react-alert';
import { Query, graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import NavigationArrowBack from '@material-ui/icons/ArrowBack';
import SimpleGoogleMap from '../../components/SimpleGoogleMap';
import { StaticGoogleMap, Marker, Path } from 'react-static-google-map';
import LocationOn from '@material-ui/icons/LocationOn';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import eightBall from '../../assets/images/ball/eight-ball.png';
import nineBall from '../../assets/images/ball/nine-ball.png';
import tenBall from '../../assets/images/ball/ten-ball.png';
import { SectionHeader, SectionContent, Card, ZeroState } from '../../components/chrome';
import ConfirmDialog from '../../components/ConfirmDialog';
import { getLeagueQuery } from './queries/getLeagueQuery';
import { removeLeagueMutation } from './mutations/removeLeagueMutation';
import { joinLeagueMutation } from './mutations/joinLeagueMutation';
import { leaveLeagueMutation } from './mutations/leaveLeagueMutation';
import { isoCountries, getGameType, displayEnumType } from '../../utils';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import _ from 'lodash';
import eightBallHeader from '../../assets/images/eight-ball-header-side.png';
import nineBallHeader from '../../assets/images/nine-ball-header-side.png';
import tenBallHeader from '../../assets/images/ten-ball-header-side.png';
import currentUser from '../../helpers/currentUser';

const styles = theme => ({
	toolbarTitle: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	avatar: {
		backgroundColor: '#44475b',
		color: '#fff'
	},
	header: {
		[theme.breakpoints.up('sm')]: {
			backgroundImage: `url(${eightBallHeader})`
		},
		backgroundRepeat: 'no-repeat',
		backgroundSize: '20%',
		backgroundPosition: 'right'
	}
	// header: {
	// 	// paddingLeft: 22,
	// 	// paddingRight: 22,
	// 	// backgroundColor: '#1D2029',
	// 	// backgroundColor: '#1D2029',
	// 	// backgroundColor: '#14171d',
	// 	background: 'linear-gradient(#20242d, #14171d)',
	// 	// backgroundColor: '#fafbfb',
	// 	borderRadius: 0,
	// 	[theme.breakpoints.up('sm')]: {
	// 		backgroundImage: `url(${eightBallHeader})`
	// 	},
	// 	backgroundRepeat: 'no-repeat',
	// 	backgroundSize: '100%',
	// 	backgroundPosition: 'right'
	// }
});

class LeagueDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dialogOpen: false,
			leaveDialogOpen: false
		};
	}

	getGameType = type => {
		return type.indexOf('EIGHT_BALL') !== -1
			? eightBallHeader
			: type.indexOf('NINE_BALL') !== -1
				? nineBallHeader
				: type.indexOf('TEN_BALL') !== -1 ? tenBallHeader : null;
	};

	handleCloseDialog = () => {
		this.setState({
			dialogOpen: false
		});
	};

	handleLeaveCloseDialog = () => {
		this.setState({
			leaveDialogOpen: false
		});
	};

	handleLeaveOpenDialog = () => {
		this.setState({
			leaveDialogOpen: true
		});
	};

	handleConfirmDialog = () => {
		this.setState({
			dialogOpen: true
		});
	};

	isLeagueMember = league => {
		const currentUser = this.props.currentUser;
		if (currentUser) {
			const leagues = currentUser.leagues;
			const matches = _.filter(leagues, item => {
				return item.id === league.id;
			});
			return matches.length > 0;
		}
	};

	handleJoinLeague = league => {
		this.props
			.joinLeague({
				mutation: joinLeagueMutation,
				variables: { id: league.id },
				optimisticResponse: {
					__typename: 'Mutation',
					joinLeague: {
						__typename: 'League',
						id: league.id
					}
				}
				// refetchQueries: [{ query: getDivisionQuery, variables: { id: division.id } }]
			})
			.then(() => {
				this.props.alert.success(`You joined league: ${league.name}`);
				this.props.currentUser.refresh();
				console.log('joined league');
			})
			.catch(error => {
				console.log(error);
			});
	};

	handleLeaveLeague = league => {
		this.handleLeaveOpenDialog();
		// this.props
		// 	.leaveDivision({
		// 		mutation: leaveDivisionMutation,
		// 		variables: { id: division.id },
		// 		optimisticResponse: {
		// 			__typename: 'Mutation',
		// 			leaveDivision: {
		// 				__typename: 'Division',
		// 				id: division.id
		// 			}
		// 		}
		// 		// refetchQueries: [{ query: getDivisionQuery, variables: { id: division.id } }]
		// 	})
		// 	.then(() => {
		// 		this.props.alert.error(`You left division: ${division.name}`);
		// 		this.props.currentUser.refresh();
		// 		console.log('left division');
		// 	})
		// 	.catch(error => {
		// 		console.log(error);
		// 	});
	};

	render() {
		const { classes, match, history, currentUser } = this.props;

		return (
			<Query
				query={getLeagueQuery}
				fetchPolicy="cache-and-network"
				notifyOnNetworkStatusChange={true}
				variables={{ slug: match.params.slug }}
			>
				{({ loading, error, data, subscribeToMore }) => {
					if (loading) return <LinearProgress mode="indeterminate" color="primary" />;
					if (error) return <div style={styles.container}>Error: {error.message}</div>;
					if (data && !data.league) {
						return <div>League not found.</div>;
					}

					return (
						<SectionHeader
							title={data.league.name}
							backButton={true}
							backPath="/leagues"
							rightButton={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									{/*<div style={{ display: 'flex', alignItems: 'center', marginRight: 40 }}>
										<Button
											variant="outlined"
											color="inherit"
											onClick={e => {
												e.preventDefault();
												history.push(`/divisions/${data.division.slug}/edit`);
											}}
											style={{ minWidth: 'auto', borderColor: 'rgba(255,255,255,0.4)' }}
										>
											<EditIcon style={{ width: 20, height: 20 }} />
										</Button>
										<Button
											variant="outlined"
											color="inherit"
											onClick={e => {
												e.preventDefault();
												this.handleConfirmDialog(data.division);
											}}
											style={{
												minWidth: 'auto',
												marginLeft: 10,
												borderColor: 'rgba(255,255,255,0.4)'
											}}
										>
											<DeleteIcon style={{ width: 20, height: 20 }} />
										</Button>
										</div>*/}
									<div style={{ display: 'flex', alignItems: 'center' }}>
										{this.isLeagueMember(data.league) === true ? (
											<Button
												variant="outlined"
												color="inherit"
												onClick={() => this.handleLeaveLeague(data.league)}
												style={{ borderColor: 'rgba(255,255,255,0.7)' }}
											>
												Leave League
											</Button>
										) : (
											<Button
												variant="outlined"
												color="inherit"
												onClick={() => this.handleJoinLeague(data.league)}
												style={{ borderColor: 'rgba(255,255,255,0.7)' }}
											>
												Join League
											</Button>
										)}

										<Button
											variant="outlined"
											color="secondary"
											onClick={() => history.push('/leagues/new')}
											style={{ marginLeft: 10 }}
										>
											Create League
										</Button>
									</div>
								</div>
							}
						>
							<SectionContent>
								<Grid container justify="center" spacing={24}>
									{/*<Grid item xs={11} sm={12}>
										<Link to={'/divisions'} className={classes.backLink}>
											<Typography variant="subheading">
												<div
													style={{
														display: 'flex',
														alignItems: 'center'
													}}
												>
													<NavigationArrowBack style={{ width: 16, height: 16 }} />
													<span style={{ marginLeft: 8 }}>Back to Divisions list</span>
												</div>
											</Typography>
										</Link>
												</Grid>*/}
									<Grid item xs={12} sm={12} md={8}>
										<Grid container justify="center" spacing={24}>
											<Grid item xs={12}>
												<Card nopadding>
													<div
														className={classes.header}
														style={{
															backgroundImage: `url(${this.getGameType(
																data.league.type
															)})`
														}}
													>
														<Typography
															variant="title"
															color="inherit"
															className={classes.toolbarTitle}
															style={{
																paddingTop: 20,
																paddingLeft: 20,
																paddingRight: 20,
																paddingBottom: 20
															}}
														>
															<span>League Details</span>
															<div style={{ display: 'flex', alignItems: 'center' }}>
																<Button
																	variant="text"
																	color="primary"
																	size="small"
																	onClick={e => {
																		e.preventDefault();
																		history.push(
																			`/leagues/${data.league.slug}/edit`
																		);
																	}}
																>
																	Edit
																</Button>
																<Button
																	variant="text"
																	color="primary"
																	size="small"
																	onClick={e => {
																		e.preventDefault();
																		this.handleConfirmDialog(data.league);
																	}}
																	style={{
																		marginLeft: 10,
																		// borderColor: 'rgb(225, 0, 80)',
																		color: 'rgb(225, 0, 80)'
																	}}
																>
																	Remove
																</Button>
															</div>
														</Typography>

														<div
															style={{
																paddingTop: 0,
																paddingLeft: 20,
																paddingRight: 20,
																paddingBottom: 20
															}}
														>
															<Grid container spacing={16}>
																<Grid item xs={12} sm={4}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Name
																	</Typography>
																	<Typography variant="subheading" gutterBottom>
																		{data.league.name}
																	</Typography>
																</Grid>

																<Grid item xs={12} sm={4}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Type
																	</Typography>
																	<Typography variant="subheading" gutterBottom>
																		{data.league.type}
																	</Typography>
																</Grid>
															</Grid>
														</div>
													</div>
												</Card>
											</Grid>

											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="title"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20,
															paddingBottom: 20
														}}
													>
														Divisions
													</Typography>
													{data.league.divisions.length > 0 ? (
														<List
															defaultValue={0}
															style={{
																// marginTop: 20,
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															{data.league.divisions.map((division, index) => {
																return (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																		onClick={() =>
																			history.push(`/divisions/${division.slug}`)}
																		button
																	>
																		<Avatar size="small" className={classes.avatar}>
																			{division.name.slice(0, 1)}
																		</Avatar>
																		<ListItemText
																			primary={division.name}
																			secondary={division.slug}
																		/>
																	</ListItem>
																);
															})}
														</List>
													) : (
														<ZeroState
															title="This league does not have any divisions yet"
															style={{ margin: 15 }}
														>
															<Button
																variant="outlined"
																color="primary"
																size="small"
																onClick={e => {
																	e.preventDefault();
																	history.push(`/divisions/new`);
																}}
															>
																Add Division
															</Button>
														</ZeroState>
													)}
												</Card>
											</Grid>
											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="title"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20
														}}
													>
														Standings
													</Typography>
													<ZeroState
														title="No division matches have been played yet"
														style={{ margin: 15 }}
													/>
												</Card>
											</Grid>

											<Grid item xs={12} sm={6}>
												<Card nopadding style={{ height: 'auto' }}>
													<Typography
														variant="title"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 10,
															paddingLeft: 20,
															paddingRight: 10,
															paddingBottom: 10,
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between'
														}}
													>
														<div>Teams</div>
														<div>
															<IconButton
																onClick={e => {
																	e.preventDefault();
																	history.push(
																		`/teams/new/?leagueId=${data.league.id}`
																	);
																}}
															>
																<AddCircleOutline />
															</IconButton>
														</div>
													</Typography>
													{data.league.teams.length > 0 ? (
														<List
															defaultValue={0}
															style={{
																// marginTop: 20,
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															{data.league.teams.map((team, index) => {
																return (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																		onClick={() =>
																			history.push(`/teams/${team.slug}`)}
																		button
																	>
																		<Avatar size="small" className={classes.avatar}>
																			{team.name.slice(0, 1)}
																		</Avatar>
																		<ListItemText
																			primary={team.name}
																			secondary={team.slug}
																		/>
																	</ListItem>
																);
															})}
														</List>
													) : (
														<ZeroState
															title="This division does not have any teams yet"
															style={{ margin: 15 }}
														>
															<Button
																variant="outlined"
																color="primary"
																size="small"
																onClick={e => {
																	e.preventDefault();
																	history.push(
																		`/teams/new/?leagueId=${data.league.id}`
																	);
																}}
															>
																Add Team
															</Button>
														</ZeroState>
													)}
												</Card>
											</Grid>

											<Grid item xs={12} sm={6}>
												<Card nopadding style={{ height: 'auto' }}>
													<Typography
														variant="title"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 10,
															paddingLeft: 20,
															paddingRight: 10,
															paddingBottom: 10,
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between'
														}}
													>
														<div>Players</div>
														<div>
															<IconButton
																onClick={e => {
																	e.preventDefault();
																	history.push(
																		`/players/new/?leagueId=${data.league.id}`
																	);
																}}
															>
																<AddCircleOutline />
															</IconButton>
														</div>
													</Typography>
													{data.league.players.length > 0 ? (
														<List
															defaultValue={0}
															style={{
																// marginTop: 20,
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															{data.league.players.map((player, index) => {
																return (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																		onClick={() =>
																			history.push(`/players/${player.slug}`)}
																		button
																	>
																		<Avatar
																			size="small"
																			src={player.avatarImg}
																			className={classes.avatar}
																		>
																			{player.name.slice(0, 1)}
																		</Avatar>
																		<ListItemText
																			primary={player.name}
																			secondary={player.username}
																		/>
																	</ListItem>
																);
															})}
														</List>
													) : (
														<ZeroState
															title="This division does not have any teams yet"
															style={{ margin: 15 }}
														>
															<Button
																variant="outlined"
																color="primary"
																size="small"
																onClick={e => {
																	e.preventDefault();
																	history.push(`/teams/new`);
																}}
															>
																Add Team
															</Button>
														</ZeroState>
													)}
												</Card>
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Grid container justify="center" spacing={24}>
											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="title"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20,
															paddingBottom: 10
														}}
													>
														League Operator
													</Typography>

													<List>
														{data.league.operator && (
															<ListItem
																onClick={() =>
																	history.push(
																		`/players/${data.league.operator.slug}`
																	)}
																button
																className={classes.listItem}
															>
																<Avatar
																	size="small"
																	className={classes.avatar}
																	src={
																		data.league.operator &&
																		data.league.operator.avatarImg
																	}
																>
																	{data.league.operator &&
																		data.league.operator.name.slice(0, 1)}
																</Avatar>

																<ListItemText
																	primary={
																		data.league.operator &&
																		data.league.operator.name
																	}
																/>
															</ListItem>
														)}
													</List>
												</Card>
											</Grid>

											<Grid item xs={12}>
												<Card>
													<Typography
														variant="title"
														color="inherit"
														className={classes.toolbarTitle}
													>
														Contact Information
													</Typography>

													<div style={{ marginTop: 20 }}>
														<Typography variant="caption" className="caption" gutterBottom>
															Address
														</Typography>
														<Typography variant="subheading" gutterBottom>
															{data.league.address1 || 'N/A'}
														</Typography>
														<Typography variant="caption" className="caption" gutterBottom>
															Website
														</Typography>
														<Typography variant="subheading" gutterBottom>
															{data.league.website || 'N/A'}
														</Typography>
														<Typography variant="caption" className="caption" gutterBottom>
															Email
														</Typography>
														<Typography variant="subheading" gutterBottom>
															{data.league.email || 'N/A'}
														</Typography>
														<Typography variant="caption" className="caption" gutterBottom>
															Phone
														</Typography>
														<Typography variant="subheading" gutterBottom>
															{data.league.phone || 'N/A'}
														</Typography>
														<Typography variant="caption" className="caption" gutterBottom>
															Fax
														</Typography>
														<Typography variant="subheading" gutterBottom>
															{data.league.fax || 'N/A'}
														</Typography>
														<Typography variant="caption" className="caption" gutterBottom>
															Social Media
														</Typography>
														<Typography variant="subheading" gutterBottom>
															{data.league.facebook || 'N/A'}
														</Typography>
													</div>
												</Card>
											</Grid>

											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="title"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 10,
															paddingLeft: 20,
															paddingRight: 10,
															paddingBottom: 10,
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between'
														}}
													>
														<div>Locations</div>
														<div>
															<IconButton>
																<AddCircleOutline />
															</IconButton>
														</div>
													</Typography>
													{data.league.locations && data.league.locations.length > 0 ? (
														<List
															defaultValue={0}
															style={{
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															{data.league.locations.map((location, index) => {
																return (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																		onClick={() =>
																			history.push(`/locations/${location.slug}`)}
																		button
																	>
																		<Avatar size="small" className={classes.avatar}>
																			<LocationOn
																				style={{
																					height: 22
																				}}
																			/>
																		</Avatar>
																		<ListItemText
																			primary={location.name}
																			secondary={`${location.address}, ${location.city}, ${location.state} ${location.zip}`}
																		/>
																	</ListItem>
																);
															})}
														</List>
													) : (
														<ZeroState title="No locations yet" style={{ margin: 15 }} />
													)}
												</Card>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</SectionContent>
							<ConfirmDialog
								title="Confirm League Removal"
								open={this.state.dialogOpen}
								onClose={this.handleCloseDialog}
								onConfirm={() => {
									this.props
										.removeDivision({
											mutation: removeLeagueMutation,
											variables: { id: data.league.id },
											refetchQueries: ['GetLeagues']
										})
										.then(() => {
											this.setState({ dialogOpen: false });
											this.props.history.push(`/leagues`);
										})
										.catch(error => {
											console.log(error);
											this.setState({ dialogOpen: false });
										});
								}}
							>
								⚠️ Are you sure you want to delete <b>{data.league.name}</b>?
							</ConfirmDialog>

							<ConfirmDialog
								title="Confirm Leave League"
								open={this.state.leaveDialogOpen}
								onClose={this.handleLeaveCloseDialog}
								onConfirm={() => {
									this.props
										.leaveLeague({
											mutation: leaveLeagueMutation,
											variables: { id: data.league.id },
											optimisticResponse: {
												__typename: 'Mutation',
												leaveDivision: {
													__typename: 'League',
													id: data.league.id
												}
											},
											refetchQueries: [
												{ query: getLeagueQuery, variables: { id: data.league.id } }
											]
										})
										.then(() => {
											this.props.alert.error(`You left league: ${data.league.name}`);
											this.props.currentUser.refresh();
											this.setState({ leaveDialogOpen: false });
											console.log('left division');
											// this.props.history.push(`/divisions`);
										})
										.catch(error => {
											console.log(error);
											this.setState({ leaveDialogOpen: false });
										});

									// this.props
									// 	.leaveDivision({
									// 		mutation: leaveDivisionMutation,
									// 		variables: { id: data.division.id },
									// 		refetchQueries: ['GetDivision']
									// 	})
									// 	.then(() => {
									// 		this.props.alert.error(`You left division: ${data.division.name}`);
									// 		this.props.currentUser.refresh();
									// 		this.setState({ leaveDialogOpen: false });
									// 		this.props.history.push(`/divisions`);
									// 	})
									// 	.catch(error => {
									// 		console.log(error);
									// 		this.setState({ leaveDialogOpen: false });
									// 	});
								}}
							>
								⚠️ Are you sure you want to leave league: <b>{data.league.name}</b>?
							</ConfirmDialog>
						</SectionHeader>
					);
				}}
			</Query>
		);
	}
}

LeagueDetail.contextTypes = {
	authUser: PropTypes.object,
	getUserProfile: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	changeLoginState: PropTypes.func,
	signIn: PropTypes.func,
	signOut: PropTypes.func
};

LeagueDetail.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithGraphQL = compose(
	graphql(joinLeagueMutation, {
		options: ({ id }) => ({ variables: { id } }),
		name: 'joinLeague'
	}),
	graphql(leaveLeagueMutation, {
		options: ({ id }) => ({ variables: { id } }),
		name: 'leaveLeague'
	}),
	graphql(removeLeagueMutation, {
		options: ({ id }) => ({ variables: { id } }),
		name: 'removeLeague'
	})
)(LeagueDetail);

const WithRouter = withRouter(WithGraphQL);
const WithStyles = withStyles(styles)(WithRouter);
const WithAlert = withAlert(WithStyles);
export default currentUser(WithAlert);
