import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import * as routes from '../../config/routes';
import UserProfileCard from './cards/UserProfileCard2';
import OnboardingCarousel from './OnboardingCarousel';
// import Paper from "material-ui/Paper";
import NotificationsCard from './cards/NotificationsCard';
import MatchScheduleCard from './cards/MatchScheduleCard';
import TournamentScheduleCard from './cards/TournamentScheduleCard';
import ChallengesCard from './cards/ChallengesCard';
import DivisionsCard from './cards/DivisionsCard';
import TeamsCard from './cards/TeamsCard';
import MyStatsCard from './cards/MyStatsCard';
import TeamStatsCard from './cards/TeamStatsCard';
import Feed from '../feed/Feed';

const styles = theme => ({
	rootTabs: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: 'transparent'
	},
	tabContainer: {
		// backgroundColor: '#14171d',
		display: 'flex',
		justifyContent: 'flex-start',
		// backgroundColor: '#14171d',
		borderRadius: 0
		// backgroundImage: `url(${bgImage})`,
		// backgroundRepeat: 'no-repeat',
		// backgroundSize: '100%',
		// backgroundPosition: 'right',
	},
	tabs: {
		// display: 'flex',
		// flexGrow: 1,
		// width: '100%',
		borderTop: '1px solid rgba(255, 255, 255, 0.15)'
	},
	label: {
		fontSize: 13
		// fontWeight: 400
	},
	textColorPrimary: {
		color: '#fff'
	},
	textColorSecondary: {
		color: '#999',
		'&:hover': {
			color: '#fff',
			transition: 'all 300ms cubic-bezier(0.4, 0.0, 0.2, 1)'
		}
	},
	root: {
		margin: 0,
		padding: 0,
		width: '100%'
		// maxWidth: 360,
		// backgroundColor: theme.palette.background.paper
		// position: 'relative',
		// overflow: 'auto'
		// maxHeight: 300
	},
	container: {
		flexGrow: 1
	},
	titleBar: {
		// paddingLeft: 22,
		// paddingRight: 22,
		// backgroundColor: '#1D2029',
		// backgroundColor: '#1D2029',
		// backgroundColor: '#14171d',
		backgroundColor: '#14171d',
		borderRadius: 0,
		// backgroundImage: `url(${bgImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100%',
		backgroundPosition: 'right'
		// backgroundAttachment: 'fixed'
	},
	heading: {
		// margin: 0,
		// textAlign: 'center',
		// display: 'flex',
		// alignItems: 'center',
		// justifyContent: 'center',
		// fontSize: '2em',
		// color: 'white',
		// fontFamily: 'din_lightregular, Roboto, sans-serif',
		// fontWeight: 300
		margin: 0,
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		color: 'white'
		// fontFamily: 'din_medium, Roboto, sans-serif'
		// fontFamily: 'din_lightregular, Roboto, sans-serif',
		// fontWeight: 400
	},
	headerBar: {
		// marginLeft: 10,
		// marginRight: 10,
		paddingTop: 40,
		paddingBottom: 40
		// backgroundColor: '#14171d'
	},
	cardHeader: {
		paddingTop: 10,
		paddingLeft: 14,
		paddingRight: 14,
		paddingBottom: 0
	},
	card: {
		border: '1px solid #EAEDF3',
		boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.04)',
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
		paddingLeft: 20,
		paddingTop: 10,
		paddingRight: 20,
		paddingBottom: 10,
		flexGrow: 1,
		display: 'flex',
		height: '100%'
		// width: '100%',
		// flexDirection: 'column'
	},
	cardNopadding: {
		border: '1px solid #EAEDF3',
		boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.04)',
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
		padding: 0,
		flexGrow: 1,
		display: 'flex',
		height: '100%',
		width: '100%',
		flexDirection: 'column'
	},
	listItem: {
		// padding: 0
	},
	cardNarrow: {
		margin: '24px 0px',
		padding: '5px 10px'
	},
	leftColumn: {
		flex: 2,
		marginRight: 24
	},
	rightColumn: {
		flex: 1
	},
	leftIcon: {
		marginRight: theme.spacing.unit
	},
	avatar: {
		width: 30,
		height: 30
	},
	badgeOffline: {
		width: 12,
		height: 12,
		top: 8,
		right: 8,
		backgroundColor: 'red'
	},
	badgeOnline: {
		width: 12,
		height: 12,
		top: 8,
		right: 8,
		backgroundColor: 'green'
	}
});

function easeOutExpo(t, b, c, d) {
	return (c * (-Math.pow(2, (-10 * t) / d) + 1) * 1024) / 1023 + b;
}

const GET_USER_DASHBOARD = gql`
	query userDashboardQuery($userId: ID) {
		user(id: $userId) {
			id
			name
			username
			email
			playerNumber
			playerRatings {
				id
				type
				rating
				customName
			}
			isActive
			avatarImg
			firstRun
			city
			state
			country
			matches {
				id
				startTime
				gameType
				players {
					id
					name
				}
				location {
					id
					name
					slug
				}
				createdAt
			}
			tournaments {
				id
				name
				slug
				startTime
			}
			teams {
				id
				name
				slug
				divisions {
					id
				}
				players {
					id
					name
				}
			}
			divisions {
				id
				name
				slug
				type
			}
			challenges {
				id
				opponent {
					id
					name
					username
					avatarImg
				}
				challenger {
					id
					name
					username
					avatarImg
				}
				gameType
				matchFormat
				raceTo
				location {
					id
					name
				}
				division {
					id
					name
				}
				startTime
				notes
				accepted
				status
			}
			notifications {
				id
			}
			joinedAt
			createdAt
			# raw
		}
	}
`;

class HomeContainer extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			value: 0,
			height: 0,
			matchWinPercentage: 0,
			gameWinPercentage: 0
		};
		this.animate = this.animate.bind(this);
		// this.handleActive = this.handleActive.bind(this);
		this.handleChange = this.handleChange.bind(this);
		// this.isActive = this.isActive.bind(this);
	}

	componentDidMount() {
		this.animate();
		this.setState({
			matchWinPercentage: 65,
			gameWinPercentage: 72
		});
	}

	handleChange(event, value) {
		this.setState({ value });
		// const refName = `${value}Ref`;
		// const ref = this[refName].current;
		// this.handleActive(ref);
	}

	animate() {
		const { height } = this.state;
		this.setState({
			height: height === 0 ? 'auto' : 0
		});
		// this.setState(state => ({ height: state.height === 320 ? 40 : 320 }));
	}

	displayWelcomeMessage(user) {
		const authUser = this.context.authUser;
		if (authUser && user && user.name !== authUser.name) {
			this.context.signOut(authUser);
		}
		if (user && user.firstRun && user.firstRun === true) {
			return `Welcome to the Digital Pool League, ${user.name.split(' ')[0]}`;
		} else if (authUser) {
			return `Welcome back, ${authUser && authUser.name.split(' ')[0]}`;
		} else {
			return `Welcome to the Digital Pool League`;
		}
	}

	render() {
		const { value, height } = this.state;
		const { classes, match, title, history, ...rest } = this.props;
		const { authUser } = this.context;

		// console.log(authUser);

		return (
			<React.Fragment>
				<Query
					query={GET_USER_DASHBOARD}
					fetchPolicy="cache-and-network"
					notifyOnNetworkStatusChange={true}
					variables={{ userId: authUser.id }}
				>
					{({ loading, error, data, subscribeToMore }) => {
						// console.log({
						// 	loading,
						// 	error,
						// 	data
						// });
						if (loading) return <LinearProgress mode="indeterminate" color="primary" />;
						if (error) return <div style={styles.container}>Error: {error.message}</div>;
						if (!loading && data && !data.user) {
							return <div>User not found.</div>;
						}

						const { user } = data;

						return (
							<React.Fragment>
								<Paper className={classes.titleBar} elevation={0}>
									<div className={classes.headerBar}>
										<Grid container justify="center">
											<Grid item xs={12} sm={12} md={11} lg={10}>
												<h2 className={classes.heading}>{this.displayWelcomeMessage(user)}</h2>
											</Grid>
										</Grid>
									</div>
									<div className={classes.tabs}>
										<Grid container justify="center">
											<Grid item xs={12} sm={12} md={11} lg={10}>
												<Tabs
													value={value}
													onChange={this.handleChange}
													indicatorColor="secondary"
													textColor="secondary"
													scrollable
													scrollButtons="off"
													// className={classes.tabContainer}
													fullWidth
												>
													<Tab
														label="Dashboard"
														classes={{
															label: classes.label,
															textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
															textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
														}}
														style={{ maxWidth: 200 }}
													/>
													<Tab
														label="Feed"
														classes={{
															label: classes.label,
															textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
															textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
														}}
														style={{ maxWidth: 200 }}
													/>
												</Tabs>
											</Grid>
										</Grid>
									</div>
								</Paper>

								<div style={{ marginTop: 24, marginBottom: 24 }}>
									<Grid container justify="center" spacing={24}>
										<Grid item xs={12} sm={12} md={11} lg={10}>
											<Grid container justify="center" spacing={24}>
												<Grid item xs={12} sm={12} md={8} lg={9}>
													{value === 0 && (
														<Grid container spacing={24}>
															<Grid item xs={12}>
																<NotificationsCard notifications={user.notifications} />
															</Grid>
															<Grid item xs={12} sm={12} md={12} lg={6}>
																<MatchScheduleCard matches={user.matches} />
															</Grid>
															<Grid item xs={12} sm={12} md={12} lg={6}>
																<TournamentScheduleCard
																	tournaments={user.tournaments}
																/>
															</Grid>

															<Grid item xs={12} sm={6}>
																<MyStatsCard />
															</Grid>

															<Grid item xs={12} sm={6}>
																<TeamStatsCard />
															</Grid>

															<Grid item xs={12} sm={6}>
																<DivisionsCard divisions={user.divisions} />
															</Grid>
															<Grid item xs={12} sm={6}>
																<TeamsCard teams={user.teams} />
															</Grid>
														</Grid>
													)}

													{value === 1 && <Feed />}
												</Grid>

												<Grid item xs={12} sm={12} md={4} lg={3}>
													<Grid container spacing={24}>
														<Grid item xs={12} sm={6} md={12} lg={12}>
															<UserProfileCard />
														</Grid>

														<Grid item xs={12} sm={6} md={12} lg={12}>
															<ChallengesCard challenges={user.challenges} />
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									<OnboardingCarousel open={user.firstRun} />
								</div>
							</React.Fragment>
						);
					}}
				</Query>
			</React.Fragment>
		);
	}
}

HomeContainer.contextTypes = {
	authUser: PropTypes.object,
	getUserProfile: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	changeLoginState: PropTypes.func,
	signIn: PropTypes.func,
	signOut: PropTypes.func
};

HomeContainer.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles)(HomeContainer);
export default withRouter(WithStyles);
