import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Circle } from 'rc-progress';
import CountUp from 'react-countup';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({});

class StatContainer extends Component {
	render() {
		const { centered, title, winPercentage, strokeWidth, strokeColor, trailWidth, trailColor } = this.props;

		return (
			<div style={centered && { display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
				<Typography variant="caption" className="caption" gutterBottom>
					{title}
				</Typography>
				<div
					style={{
						marginTop: 10,
						marginBottom: 10,
						width: 110,
						position: 'relative',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<div style={{ position: 'absolute', textAlign: 'center' }}>
						<h2>
							<CountUp end={winPercentage} suffix="%" duration={2.75} />
						</h2>
					</div>
					<Circle
						percent={winPercentage}
						strokeWidth={strokeWidth || 3}
						trailWidth={trailWidth || 3}
						trailColor={trailColor || '#fff6cf'}
						strokeColor={strokeColor || '#ffcd00'}
						width={110}
					/>
				</div>

				<Grid container justify="center" spacing={16} style={centered && { textAlign: 'center' }}>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<Typography variant="caption" className="caption" gutterBottom>
							Wins
						</Typography>
						<Typography variant="subtitle1" gutterBottom style={{ whiteSpace: 'nowrap' }}>
							<CountUp end={120} duration={2.75} /> (<CountUp end={65} suffix="%" duration={2.75} />)
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<Typography variant="caption" className="caption" gutterBottom>
							Losses
						</Typography>
						<Typography variant="subtitle1" gutterBottom style={{ whiteSpace: 'nowrap' }}>
							<CountUp end={82} duration={2.75} /> (<CountUp end={35} suffix="%" duration={2.75} />)
						</Typography>
					</Grid>
				</Grid>
			</div>
		);
	}
}

StatContainer.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(StatContainer);
