import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const styles = theme => ({
	formControl: {
		marginTop: 8,
		marginBottom: 4,
		width: '100%',
		maxWidth: 400,
		backgroundColor: '#fafafa'
	}
});

const InputFeedback = ({ children }) => <span className="text-danger">{children}</span>;

class SelectField extends Component {
	constructor(props) {
		super(props);
		const { name, selected } = props;
		this.state = {
			[name]: selected
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	render() {
		const {
			field: { name, value, ...field }, // { name, value, onChange, onBlur }
			form: { touched, errors, ...form }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
			className,
			label,
			options,
			classes,
			...props
		} = this.props;

		const selectedValue = value && value.hasOwnProperty('id') ? value.id : value;

		const error = errors[name];
		const touch = touched[name];
		// const classes = classnames(
		// 	'form-group',
		// 	{
		// 		'animated shake error': !!error
		// 	},
		// 	className
		// );
		return (
			<div>
				<FormControl className={classes.formControl}>
					<InputLabel htmlFor={`${name}-helper`}>{label}</InputLabel>
					<Select
						{...field}
						value={selectedValue}
						onChange={event => {
							form.setFieldValue(name, event.target.value, false);
							this.setState({ [event.target.name]: event.target.value });
							field.onChange(event);
						}}
						// onChange={this.handleChange}
						input={<Input name={name} id={`${name}-helper`} />}
						fullWidth
						// displayEmpty
						className="form-control"
						// {...props}
					>
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
						{options &&
							options.map((item, index) => (
								<MenuItem key={index} value={item.value}>
									{item.label}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				{touch && error && <InputFeedback>{error}</InputFeedback>}
			</div>
		);
	}
}

SelectField.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SelectField);
