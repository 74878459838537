import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

const styles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	formControl: {
		marginTop: 8,
		marginBottom: 4,
		width: '100%',
		maxWidth: 400,
		backgroundColor: '#fafafa'
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing.unit / 4
	},
	icon: {
		width: 30,
		height: 30
	}
});

const InputFeedback = ({ children }) => <span className="text-danger">{children}</span>;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	}
};

class MultipleSelect extends React.Component {
	constructor(props) {
		super(props);
		const { name, selected } = props;
		this.state = {
			selected: selected || []
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	handleChange(event) {
		console.log(event.target.value);
		this.setState({ selected: event.target.value });
	}

	handleDelete(form, val, field) {
		const { field: { name } } = this.props;
		const filtered = this.state.selected.filter(item => item !== val);

		form.setFieldValue(name, filtered, false);
		// this.setState(state => {
		// 	const selected = state.selected;
		// 	const itemToDelete = selected.indexOf(val);
		// 	selected.splice(itemToDelete, 1);
		// 	return { selected };
		// });
		this.setState(prevState => ({ selected: prevState.selected.filter(item => item !== val) }));
		// field.onChange(parent);
	}

	render() {
		const {
			field: { name, value, ...field }, // { name, value, onChange, onBlur }
			form: { touched, errors, ...form }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
			label,
			options,
			classes,
			theme,
			...props
		} = this.props;

		const selectedValue = value && value.hasOwnProperty('id') ? value.id : value;
		const error = errors[name];
		const touch = touched[name];

		return (
			<div className={classes.root}>
				<FormControl className={classes.formControl}>
					<InputLabel htmlFor={`${name}-helper`}>{label}</InputLabel>
					<Select
						{...field}
						multiple
						value={this.state.selected}
						// onChange={this.handleChange}
						onChange={event => {
							// form.setFieldValue(name, event.target.value, false);
							this.setState({ selected: event.target.value });
							field.onChange(event);
						}}
						input={<Input name={name} id={`${name}-helper`} />}
						fullWidth
						renderValue={selected => (
							<div className={classes.chips}>
								{selected.map(val => {
									const filteredLabel =
										options && options.length
											? options.filter(option => option.value === val).map(option => option.label)
											: [];

									const filteredAvatar =
										options && options.length
											? options
													.filter(option => option.value === val)
													.map(option => option.avatar)
											: '';
									return (
										<Chip
											key={val}
											label={filteredLabel}
											clickable
											className={classes.chip}
											avatar={<Avatar size="small" src={filteredAvatar[0]} />}
											onDelete={event => this.handleDelete(form, val, field)}
										/>
									);
								})}
							</div>
						)}
						// MenuProps={MenuProps}
						className="form-control"
						// {...field}
						{...props}
					>
						{options &&
							options.map((item, index) => (
								<MenuItem
									key={index}
									value={item.value}
									// style={{
									// 	fontWeight:
									// 		this.state.name.indexOf(item.value) === -1
									// 			? theme.typography.fontWeightRegular
									// 			: theme.typography.fontWeightMedium
									// }}
								>
									{/*<ListItemIcon className={classes.icon}>
										<Avatar size="small" src={item.avatar} />
              </ListItemIcon>*/}
									<Checkbox checked={this.state.selected.indexOf(item.value) > -1} color="primary" />
									<ListItemText inset primary={item.label} />
								</MenuItem>
							))}
					</Select>
				</FormControl>
				{touch && error && <InputFeedback>{error}</InputFeedback>}
			</div>
		);
	}
}

MultipleSelect.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(MultipleSelect);
