import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { SectionHeader, Card, SectionContent } from '../../components/chrome';
import PoolTable from './PoolTable';

const styles = theme => ({
	toolbarTitle: {
		display: 'flex',
		justifyContent: 'space-between'
	}
});

class TableScoreCard extends Component {
	render() {
		const { classes, status, ...rest } = this.props;
		return (
			<Card nopadding>
				{/*<Typography
					variant="subheading"
					color="inherit"

					style={{
						paddingTop: 20,
						paddingLeft: 20,
						paddingRight: 20,
						paddingBottom: 10
					}}
				>
					Match 1
        </Typography>*/}
				<Grid
					container
					justify="center"
					spacing={16}
					style={{ paddingTop: 15, paddingLeft: 20, paddingRight: 20 }}
				>
					<Grid item xs>
						<Typography variant="caption" gutterBottom>
							Match
						</Typography>
						<Typography variant="subheading" gutterBottom>
							1
						</Typography>
					</Grid>
					<Grid item xs>
						<Typography variant="caption" gutterBottom>
							Table
						</Typography>
						<Typography variant="subheading" gutterBottom>
							03
						</Typography>
					</Grid>
					<Grid item xs>
						<Typography variant="caption" gutterBottom>
							Race to
						</Typography>
						<Typography variant="subheading" gutterBottom>
							7
						</Typography>
					</Grid>
				</Grid>
				<PoolTable style={{ margin: 10 }} status={status} {...rest} />
				<div
					style={{
						marginLeft: 10,
						marginRight: 10,
						marginBottom: 10,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<Link to={`/matches/1`}>
						<Button primary="true">View match</Button>
					</Link>
					{status === 'live' ? (
						<span className="live-badge" style={{ padding: '0px 8px' }}>
							LIVE
						</span>
					) : (
						<span className="completed-badge" style={{ padding: '0px 8px' }}>
							COMPLETE
						</span>
					)}
				</div>
			</Card>
		);
	}
}

TableScoreCard.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TableScoreCard);
