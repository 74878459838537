import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
	textField: {
		width: '100%',
		maxWidth: 400,
		backgroundColor: '#fafafa'
	},
	textFieldError: {
		width: '100%',
		maxWidth: 400,
		backgroundColor: '#fff5f5'
	}
});

const InputFeedback = ({ children }) => <div className="text-danger">{children}</div>;

const Label = ({ error, children, ...props }) => {
	return <label {...props}>{children}</label>;
};

const TextInput = ({
	field: { name, ...field }, // { name, value, onChange, onBlur }
	form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
	className,
	label,
	classes,
	...props
}) => {
	const error = errors[name];
	const touch = touched[name];
	// const classes = classnames(
	// 	'form-group',
	// 	{
	// 		'animated shake error': !!error
	// 	},
	// 	className
	// );
	return (
		<div>
			{/*<Label htmlFor={name} error={error} style={{ display: 'block' }}>
				{label}
	</Label>*/}
			<TextField
				error={Boolean(error)}
				// autoFocus
				value={name}
				onChange={event => this.setState({ name: event.target.value })}
				margin="dense"
				id={name}
				label={label}
				type="text"
				fullWidth
				className={error ? classes.textFieldError : classes.textField}
				{...field}
				{...props}
			/>
			{touch && error && <InputFeedback>{error}</InputFeedback>}
		</div>
	);
};

TextInput.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TextInput);
