import gql from 'graphql-tag';

export const getDivisionsQuery = gql`
	query GetDivisions($where: JSON, $offset: Int, $limit: Int, $order: [[String]]) {
		divisions(where: $where, offset: $offset, limit: $limit, order: $order) {
			id
			name
			slug
			description
			type
			season
			week
			sessionId
			isActive
			isPublic
			isPrivate
			startDate
			endDate
			league {
				id
				name
				slug
				type
			}
			locations {
				id
				name
				slug
				address
				city
				state
				zip
				country
				lat
				lon
			}
			operator {
				id
				name
				slug
				username
				avatarImg
			}
			teams {
				id
				name
				slug
			}
			players {
				id
				name
				username
				avatarImg
				slug
			}
			createdAt
		}
	}
`;
