import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Formsy from 'formsy-react';
import TextInput from '../../components/forms/TextInputFormsy';
import FileInput from '../../components/forms/FileInputFormsy';
import SelectField from '../../components/forms/SelectFormsy';
import SwitchField from '../../components/forms/SwitchFormsy';
import MultiSelectField from '../../components/forms/MultiSelectFormsy';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import Avatar from '@material-ui/core/Avatar';
import LocationOn from '@material-ui/icons/LocationOn';
import isEmpty from 'lodash/isEmpty';
import { getPlayerQuery } from './queries/getPlayerQuery';
import { updatePlayerMutation } from './mutations/updatePlayerMutation';
import { createPlayerMutation } from './mutations/createPlayerMutation';
import eightBall from '../../assets/images/ball/eight-ball.png';
import nineBall from '../../assets/images/ball/nine-ball.png';
import tenBall from '../../assets/images/ball/ten-ball.png';
import currentUser from '../../helpers/currentUser';
import queryString from 'query-string';

export const getLocationsQuery = gql`
	query GetLocations {
		locations {
			id
			name
			slug
		}
	}
`;

export const getLeaguesQuery = gql`
	query GetLeagues {
		leagues {
			id
			name
			slug
			type
		}
	}
`;

export const getTeamsQuery = gql`
	query GetTeams {
		teams {
			id
			name
			slug
		}
	}
`;

export const getDivisionsQuery = gql`
	query GetDivisions {
		divisions {
			id
			name
			slug
			type
		}
	}
`;

export const getTournamentsQuery = gql`
	query GetTournaments {
		tournaments {
			id
			name
		}
	}
`;

const currentUserQuery = gql`
	query {
		currentUser {
			id
			name
			username
			email
			avatarImg
			playerNumber
			online
			firstRun
			token
			divisions {
				id
				name
				slug
			}
		}
	}
`;

const styles = theme => ({
	error: {
		color: '#f44336'
	},
	content: {
		// padding: '10px 24px 20px 24px'
		// maxWidth: 600
	},
	input: {
		marginTop: 5
	},
	submit: {
		marginTop: 10
	},
	selectEmpty: {
		marginTop: theme.spacing.unit * 2
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	},
	avatar: {
		backgroundColor: '#44475b',
		color: '#fff'
	}
});

class PlayerCreateForm extends PureComponent {
	constructor(props) {
		super(props);
		this.disableButton = this.disableButton.bind(this);
		this.enableButton = this.enableButton.bind(this);
		this.state = { canSubmit: false, submitting: false };
		this.submit = this.submit.bind(this);
	}

	getGameType = type => {
		return type.indexOf('EIGHT_BALL') !== -1
			? eightBall
			: type.indexOf('NINE_BALL') !== -1
			? nineBall
			: type.indexOf('TEN_BALL') !== -1
			? tenBall
			: null;
	};

	getLeagues = () => {
		const {
			getLeagues: { leagues, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = leagues.map(league => {
			return {
				label: league.name,
				value: league.id
			};
		});
		return formatted;
	};

	getLocations = () => {
		const {
			getLocations: { locations, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = locations.map(location => {
			return {
				label: location.name,
				value: location.id
			};
		});
		return formatted;
	};

	getTeams = () => {
		const {
			getTeams: { teams, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = teams.map(team => {
			return {
				label: team.name,
				value: team.id
			};
		});
		return formatted;
	};

	getDivisions = () => {
		const {
			getDivisions: { divisions, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = divisions.map(division => {
			return {
				label: division.name,
				value: division.id,
				avatar: this.getGameType(division.type)
			};
		});
		return formatted;
	};

	getTournaments = () => {
		const {
			getTournaments: { tournaments, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = tournaments.map(tournament => {
			return {
				label: tournament.name,
				value: tournament.id
			};
		});
		return formatted;
	};

	disableButton() {
		this.setState({ canSubmit: false });
	}

	enableButton() {
		this.setState({ canSubmit: true });
	}

	submit(model) {
		console.log(model);
		// fetch('http://example.com/', {
		// 	method: 'post',
		// 	body: JSON.stringify(model)
		// });
		this.setState({
			submitting: true
		});
		if (!this.props.slug) {
			this.props
				.createPlayer({
					mutation: createPlayerMutation,
					variables: {
						input: {
							name: model.name,
							username: model.username,
							email: model.email,
							playerNumber: model.playerNumber,
							playerRatingIds: model.playerRatings,
							leagueIds: model.leagues,
							isActive: model.isActive,
							avatarImg: model.avatarImg,
							homeLocationId: model.homeLocation,
							teamIds: model.teams,
							userIds: model.players,
							divisionIds: model.divisions,
							role: model.role,
							address: model.address,
							city: model.city,
							state: model.state,
							zip: model.zip,
							country: model.country,
							phone: model.phone,
							avatarFile: model.avatarFile
						}
					},
					// refetchQueries: ['GetTeam']
					refetchQueries: [{ query: getPlayerQuery }]
				})
				.then(({ data }) => {
					// console.log(data);
					this.context.getUserProfile();
					this.props.history.push(`/players/${data.createUser.slug}`);
				})
				.catch(error => {
					console.log(error);
					this.setState({ submitting: false });
				});
		} else {
			const id = this.props.getPlayer.user.id;
			this.props
				.updatePlayer({
					mutation: updatePlayerMutation,
					variables: {
						input: {
							id: id,
							name: model.name,
							username: model.username,
							email: model.email,
							playerNumber: model.playerNumber,
							playerRatingIds: model.playerRatings,
							leagueIds: model.leagues,
							isActive: model.isActive,
							avatarImg: model.avatarImg,
							homeLocationId: model.homeLocation,
							teamIds: model.teams,
							userIds: model.players,
							divisionIds: model.divisions,
							role: model.role,
							address: model.address,
							city: model.city,
							state: model.state,
							zip: model.zip,
							country: model.country,
							phone: model.phone,
							avatarFile: model.avatarFile
						}
					},
					// refetchQueries: ['GetTeam'],
					refetchQueries: [{ query: getPlayerQuery, variables: { id: id } }]
					// update: (store, { data: { updateTeam } }) => {
					// 	this.props.updateStoreAfterUpdate(store, updateTeam, model.id);
					// }
					// update: (cache, { data: { updateTeam } }) => {
					// 	// const { team } = cache.readQuery({ query: getTeamQuery });
					// 	// console.log(team);
					// 	cache.writeQuery({
					// 		query: getTeamQuery,
					// 		data: { team: updateTeam }
					// 	});
					// }

					// optimisticResponse: {
					// 	__typename: 'Mutation',
					// 	updateTeam: {
					// 		id: model.id,
					// 		__typename: 'Team',
					// 		name: model.name,
					// 		slug: model.slug,
					// 		isActive: model.isActive,
					// 		location: model.location,
					// 		players: model.players
					// 	}
					// }
				})
				.then(({ data }) => {
					console.log(data);
					this.context.getUserProfile();
					this.props.history.push(`/players/${data.updateUser.slug}`);
				})
				.catch(error => {
					console.log(JSON.stringify(error));
					this.setState({ submitting: false });
				});
		}
	}

	render() {
		const {
			classes,
			getPlayer: { user, loading, error }
		} = this.props;
		const parsed = queryString.parse(this.props.location.search);
		const leagueId = parsed.leagueId;

		return (
			<React.Fragment>
				{loading === true ? (
					<div>Loading...</div>
				) : (
					<Formsy onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
						<TextInput
							name="name"
							label="Name"
							value={user && user.name}
							validations="isExisty"
							validationError="Name is required"
							required
							autoFocus
						/>

						<TextInput name="username" label="Username" value={user && user.username} />

						<TextInput name="email" label="Email" value={user && user.email} />

						<TextInput name="playerNumber" label="Player Number" value={user && user.playerNumber} />

						<MultiSelectField
							name="leagues"
							label="Leagues"
							value={
								(user &&
									user.leagues.map(league => {
										return league.id;
									})) ||
								leagueId
							}
							validations="isExisty"
							validationError="League is required"
							options={this.getLeagues()}
							required
						/>

						{/*<MultiSelectField
							name="homeLocation"
							label="Home Location"
							value={user && user.homeLocation.id}
							avatar={
								<Avatar size="small" className={classes.avatar}>
									<LocationOn
										style={{
											height: 22
										}}
									/>
								</Avatar>
							}
							options={this.getLocations()}
						/>*/}

						<MultiSelectField
							name="teams"
							label="Teams"
							value={
								user &&
								user.teams.map(team => {
									return team.id;
								})
							}
							options={this.getTeams()}
						/>

						<MultiSelectField
							name="divisions"
							label="Divisions"
							value={
								user &&
								user.divisions.map(division => {
									return division.id;
								})
							}
							options={this.getDivisions()}
						/>

						<SelectField
							name="homeLocation"
							label="Home Location"
							value={user && user.homeLocation && user.homeLocation.id}
							options={this.getLocations()}
						/>

						<SwitchField
							// type="checkbox"
							// checked={team.isActive}
							id="isActive"
							name="isActive"
							label="Active"
							value={user && user.isActive === true ? true : false}
						/>

						<TextInput name="avatarImg" label="Avatar Image" value={user && user.avatarImg} />

						<SelectField
							name="role"
							label="Role"
							value={user && user.role}
							options={[
								{
									label: 'User',
									value: 'USER'
								},
								{ label: 'Member', value: 'MEMBER' },
								{ label: 'Admin', value: 'ADMIN' },
								{ label: 'Super Admin', value: 'SUPER_ADMIN' }
							]}
							required
							validations="isExisty"
							validationError="Role is required"
						/>

						<TextInput name="address" label="Address" value={user && user.address} />

						<TextInput name="city" label="City" value={user && user.city} />

						<TextInput name="state" label="State" value={user && user.state} />

						<TextInput name="zip" label="Zip" value={user && user.zip} />

						<TextInput name="country" label="Country" value={user && user.country} />

						<TextInput name="phone" label="Phone" value={user && user.phone} />

						<FileInput name="avatarFile" label="Avatar File" value={user && user.avatarFile} />

						<div style={{ marginTop: 20 }}>
							{this.props.slug ? (
								<Button
									variant="contained"
									color="primary"
									disabled={!this.state.canSubmit}
									type="submit"
								>
									Update
								</Button>
							) : (
								<Button
									variant="contained"
									color="primary"
									disabled={!this.state.canSubmit}
									type="submit"
								>
									Create
								</Button>
							)}
						</div>
					</Formsy>
				)}
			</React.Fragment>
		);
	}
}

PlayerCreateForm.contextTypes = {
	authUser: PropTypes.object,
	getUserProfile: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	changeLoginState: PropTypes.func,
	signIn: PropTypes.func,
	signOut: PropTypes.func
};

const WithGraphQL = compose(
	graphql(getPlayerQuery, {
		options: ({ slug }) => ({
			variables: { slug },
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'no-cache'
			// refetchQueries: [
			// 	{
			// 		query: getTeamQuery,
			// 		variables: { variables: { slug } }
			// 	}
			// ]
		}),
		name: 'getPlayer'
	}),
	graphql(createPlayerMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'createPlayer'
	}),
	graphql(updatePlayerMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'updatePlayer'
	}),
	graphql(getLeaguesQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getLeagues'
	}),
	graphql(getLocationsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getLocations'
	}),
	graphql(getTeamsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getTeams'
	}),
	graphql(getDivisionsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getDivisions'
	}),
	graphql(getTournamentsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getTournaments'
	})
)(PlayerCreateForm);

const WithRouter = withRouter(WithGraphQL);
const WithStyles = withStyles(styles)(WithRouter);
export default currentUser(WithStyles);
