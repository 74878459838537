import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import client from '../../config/apolloClient';
import qs from 'qs';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Hidden from '@material-ui/core/Hidden';
import LinearProgress from '@material-ui/core/LinearProgress';
import { SectionHeader, SectionContent } from '../../components/chrome';
import { getChallengesQuery } from './queries/getChallengesQuery';
import FlashMassage from 'react-flash-message';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ChallengesList from './ChallengesList';

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	},

	margin: {
		[theme.breakpoints.up('sm')]: {
			marginTop: 24,
			marginBottom: 24
		}
	}
});

class ChallengesContainer extends Component {
	render() {
		const { classes, title, match, history, location } = this.props;
		const params = qs.parse(location.search.slice(1), { strictNullHandling: true });

		let newOrder;
		if (params.order) {
			newOrder = [];
			const orderSplit = params.order.split(',');
			newOrder.push([orderSplit[0], orderSplit[1]]);
		}

		let offset = parseFloat(params.offset) || 0;
		let limit = parseFloat(params.limit) || 10;

		return (
			<SectionHeader
				title="Challenges"
				rightButton={
					<Hidden smDown>
						<Button variant="outlined" color="secondary" onClick={() => history.push('/challenges/new')}>
							Create Challenge
						</Button>
					</Hidden>
				}
			>
				<Query
					query={getChallengesQuery}
					variables={{
						where: null,
						offset: offset,
						limit: limit,
						order: newOrder || [['createdAt', 'DESC']]
					}}
					fetchPolicy="no-cache"
					notifyOnNetworkStatusChange={true}
				>
					{({ loading, error, data, subscribeToMore }) => {
						if (loading) return <LinearProgress mode="indeterminate" color="primary" />;
						if (error) return <div style={styles.container}>Error: {error.message}</div>;

						return (
							<SectionContent style={{ overflow: 'auto' }}>
								{/*<FlashMassage duration={5000} persistOnHover={true}>
									<Paper elevation={0} style={{ padding: 20, marginBottom: 20 }}>
										<Typography component="p">
											Paper can be used to build surface or other elements for your application.
										</Typography>
									</Paper>
						</FlashMassage>*/}
								<ChallengesList
									data={data}
									subscribeToMore={subscribeToMore}
									match={match}
									history={history}
								/>
							</SectionContent>
						);
					}}
				</Query>
			</SectionHeader>
		);
	}
}

ChallengesContainer.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChallengesContainer);
