import gql from 'graphql-tag';

export const updateDivisionMutation = gql`
	mutation UpdateDivision($input: DivisionInput) {
		updateDivision(input: $input) {
			id
			name
			slug
			description
			type
			season
			week
			sessionId
			isActive
			isPublic
			isPrivate
			startDate
			endDate
			league {
				id
				name
				slug
				type
			}
			locations {
				id
				name
				slug
				address
				city
				state
				zip
				country
				lat
				lon
			}
			operator {
				id
				name
				slug
				username
				avatarImg
			}
			teams {
				id
				name
				slug
			}
			createdAt
		}
	}
`;
