import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';

const styles = theme => ({
	container: {
		marginLeft: 5,
		display: 'flex',
		flexWrap: 'wrap'
	},
	input: {
		margin: theme.spacing.unit,
		width: 300

		// fontSize: 14
	}
});
class ChatInput extends Component {
	constructor(props) {
		super(props);
		this.state = { value: '' };
		// React ES6 does not bind 'this' to event handlers by default
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	handleSubmit(event) {
		// Stop the form from refreshing the page on submit
		event.preventDefault();
		// Clear the input box
		this.setState({ value: '' });
		// Call the onSend callback with the value message
		this.props.onSend(this.state.value);
	}

	handleChange(event) {
		this.setState({ value: event.target.value });
	}

	render() {
		const { classes } = this.props;

		return (
			<form className="chat-input" onSubmit={this.handleSubmit} style={{ borderTop: '1px solid #eee' }}>
				<Input
					placeholder="Write a message..."
					className={classes.input}
					onChange={this.handleChange}
					value={this.state.value}
					required
					inputProps={{
						'aria-label': 'Filter'
					}}
					disableUnderline
				/>
			</form>
		);
	}
}

ChatInput.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChatInput);
