import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';

const styles = theme => ({});

class PrivacyPage extends Component {
	render() {
		return <div>Terms of Use</div>;
	}
}

PrivacyPage.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles)(PrivacyPage);

const WithAlert = withAlert(WithStyles);

export default withRouter(WithAlert);
