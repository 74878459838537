import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { withFormsy } from 'formsy-react';
import Autocomplete from './Autocomplete';
import _ from 'lodash';

const styles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	formControl: {
		marginTop: 8,
		marginBottom: 4,
		width: '100%',
		maxWidth: 400,
		backgroundColor: '#fafafa'
	}
});

const InputFeedback = ({ children }) => <span className="text-danger">{children}</span>;

class SelectField extends Component {
	constructor(props) {
		super(props);
		this.changeValue = this.changeValue.bind(this);
	}
	changeValue(obj) {
		// let returnValue;

		// if (obj && obj.length >= 1) {
		// 	returnValue = obj.map(item => {
		// 		return item.value;
		// 	});
		// } else {
		// 	returnValue = obj.value;
		// }

		// setValue() will set the value of the component, which in
		// turn will validate it and the rest of the form
		// Important: Don't skip this step. This pattern is required
		// for Formsy to work.
		this.props.setValue(obj);
	}

	render() {
		const {
			classes,
			value,
			options,
			name,
			label,
			placeholder,
			getValue,
			getErrorMessage,
			autoFocus,
			required,
			isMulti
		} = this.props;
		const errorMessage = getErrorMessage();
		// const selectedValue = value && value.hasOwnProperty('id') ? value.id : value;

		return (
			<div className={classes.root}>
				<FormControl className={classes.formControl}>
					<Autocomplete
						name={this.props.name}
						label={label}
						value={value}
						onChange={this.changeValue}
						placeholder={placeholder}
						suggestions={this.props.suggestions || []}
						isMulti={isMulti}
						required={required}
					/>
				</FormControl>
				<InputFeedback>{errorMessage}</InputFeedback>
			</div>
		);
	}
}

SelectField.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles)(SelectField);
export default withFormsy(WithStyles);
