import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Formsy from 'formsy-react';
import TextInput from '../../components/forms/TextInputFormsy';
import SelectField from '../../components/forms/SelectFormsy';
import SwitchField from '../../components/forms/SwitchFormsy';
import MultiSelectField from '../../components/forms/MultiSelectFormsy';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import isEmpty from 'lodash/isEmpty';
import { getLocationQuery } from './queries/getLocationQuery';
import { updateLocationMutation } from './mutations/updateLocationMutation';
import { createLocationMutation } from './mutations/createLocationMutation';
import eightBall from '../../assets/images/ball/eight-ball.png';
import nineBall from '../../assets/images/ball/nine-ball.png';
import tenBall from '../../assets/images/ball/ten-ball.png';
import currentUser from '../../helpers/currentUser';
import queryString from 'query-string';

export const getLeaguesQuery = gql`
	query GetLeagues {
		leagues {
			id
			name
			slug
			type
		}
	}
`;

export const getPlayersQuery = gql`
	query GetPlayers {
		users {
			id
			name
			slug
			username
			avatarImg
		}
	}
`;

const styles = theme => ({
	error: {
		color: '#f44336'
	},
	content: {
		// padding: '10px 24px 20px 24px'
		// maxWidth: 600
	},
	input: {
		marginTop: 5
	},
	submit: {
		marginTop: 10
	},
	selectEmpty: {
		marginTop: theme.spacing.unit * 2
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	}
});

class LocationCreateForm extends React.Component {
	constructor(props) {
		super(props);
		this.disableButton = this.disableButton.bind(this);
		this.enableButton = this.enableButton.bind(this);
		this.state = { canSubmit: false, submitting: false };
		this.submit = this.submit.bind(this);
	}

	getGameType = type => {
		return type.indexOf('EIGHT_BALL') !== -1
			? eightBall
			: type.indexOf('NINE_BALL') !== -1
			? nineBall
			: type.indexOf('TEN_BALL') !== -1
			? tenBall
			: null;
	};

	getLeagues = () => {
		const {
			getLeagues: { leagues, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = leagues.map(league => {
			return {
				label: league.name,
				value: league.id
			};
		});
		return formatted;
	};

	getPlayers = () => {
		const {
			getPlayers: { users, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = users.map(player => {
			return {
				label: player.name,
				value: player.id,
				avatar: player.avatarImg
			};
		});
		return formatted;
	};

	disableButton() {
		this.setState({ canSubmit: false });
	}

	enableButton() {
		this.setState({ canSubmit: true });
	}

	submit(model) {
		console.log(model);
		// fetch('http://example.com/', {
		// 	method: 'post',
		// 	body: JSON.stringify(model)
		// });
		this.setState({
			submitting: true
		});
		if (!this.props.slug) {
			this.props
				.createLocation({
					mutation: createLocationMutation,
					variables: {
						input: {
							name: model.name,
							type: model.type,
							leagueIds: model.leagues,
							ownerId: this.context.authUser.id,
							address: model.address,
							city: model.city,
							state: model.state,
							country: model.country,
							zip: model.zip,
							phone: model.phone,
							email: model.email,
							website: model.website,
							operatingHours: model.operatingHours,
							isPublic: model.isPublic,
							isPrivate: model.isPublic === false ? true : false
						}
					},
					refetchQueries: [{ query: getLocationQuery }]
				})
				.then(({ data }) => {
					console.log(data);
					this.props.history.push(`/locations/${data.createLocation.slug}`);
				})
				.catch(error => {
					console.log(error);
					console.log(error.message);
					this.setState({ submitting: false });
				});
		} else {
			const id = this.props.getLocation.location.id;
			this.props
				.updateLocation({
					mutation: updateLocationMutation,
					variables: {
						input: {
							id: id,
							name: model.name,
							type: model.type,
							leagueIds: model.leagues,
							ownerId: this.context.authUser.id,
							address: model.address,
							city: model.city,
							state: model.state,
							country: model.country,
							zip: model.zip,
							phone: model.phone,
							email: model.email,
							website: model.website,
							operatingHours: model.operatingHours,
							isPublic: model.isPublic,
							isPrivate: model.isPublic === false ? true : false
						}
					},
					refetchQueries: [{ query: getLocationQuery, variables: { id: id } }]
					// update: (store, { data: { updateTeam } }) => {
					// 	this.props.updateStoreAfterUpdate(store, updateTeam, model.id);
					// }
					// update: (cache, { data: { updateTeam } }) => {
					// 	// const { team } = cache.readQuery({ query: getTeamQuery });
					// 	// console.log(team);
					// 	cache.writeQuery({
					// 		query: getTeamQuery,
					// 		data: { team: updateTeam }
					// 	});
					// }

					// optimisticResponse: {
					// 	__typename: 'Mutation',
					// 	updateTeam: {
					// 		id: model.id,
					// 		__typename: 'Team',
					// 		name: model.name,
					// 		slug: model.slug,
					// 		isActive: model.isActive,
					// 		location: model.location,
					// 		players: model.players
					// 	}
					// }
				})
				.then(({ data }) => {
					// console.log(data);
					this.props.history.push(`/locations/${data.updateLocation.slug}`);
				})
				.catch(error => {
					console.log(error);
					this.setState({ submitting: false });
				});
		}
	}

	render() {
		const {
			classes,
			getLocation: { location, loading, error }
		} = this.props;
		const parsed = queryString.parse(this.props.location.search);
		const leagueId = parsed.leagueId;

		return (
			<React.Fragment>
				{loading === true ? (
					<div>Loading...</div>
				) : (
					<Formsy onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
						<TextInput
							name="name"
							label="Name"
							value={location && location.name}
							validations="isExisty"
							validationError="Name is required"
							required
							autoFocus
						/>

						<SelectField
							name="type"
							label="Type"
							value={location && location.type}
							options={[
								{
									label: 'House',
									value: 'HOUSE'
								},
								{ label: 'Pool Room', value: 'POOL_ROOM' },
								{ label: 'Business', value: 'BUSINESS' },
								{ label: 'Other', value: 'OTHER' }
							]}
						/>

						<SelectField
							name="owner"
							label="Owner"
							value={location && location.owner && location.owner.id}
							options={this.getPlayers()}
						/>

						<MultiSelectField
							name="leagues"
							label="Leagues"
							value={
								(location &&
									location.leagues.map(league => {
										return league.id;
									})) ||
								leagueId
							}
							validations="isExisty"
							validationError="League is required"
							options={this.getLeagues()}
							required
						/>

						<TextInput
							name="address"
							label="Address"
							value={location && location.address}
							validations="isExisty"
							validationError="Address is required"
							required
						/>

						<TextInput
							name="city"
							label="City"
							value={location && location.city}
							validations="isExisty"
							validationError="City is required"
							required
						/>

						<TextInput
							name="state"
							label="State"
							value={location && location.state}
							validations="isExisty"
							validationError="State is required"
							required
						/>

						<TextInput
							name="zip"
							label="Zip"
							value={location && location.zip}
							validations="isExisty"
							validationError="Zip is required"
							required
						/>

						<TextInput
							name="country"
							label="Country"
							value={location && location.country}
							validations="isExisty"
							validationError="Country is required"
							required
						/>

						<TextInput name="phone" label="Phone" value={location && location.phone} />
						<TextInput name="email" label="Email" value={location && location.email} />
						<TextInput name="website" label="Website" value={location && location.website} />
						<TextInput
							name="operatingHours"
							label="Operating Hours"
							value={location && location.operatingHours}
						/>

						<SwitchField
							id="isPublic"
							name="isPublic"
							label="Public Location"
							text={['Public', 'Private']}
							value={location && location.isPublic === true ? true : false}
						/>

						<div style={{ marginTop: 20 }}>
							{this.props.slug ? (
								<Button
									variant="contained"
									color="primary"
									disabled={!this.state.canSubmit}
									type="submit"
								>
									Update
								</Button>
							) : (
								<Button
									variant="contained"
									color="primary"
									disabled={!this.state.canSubmit}
									type="submit"
								>
									Create
								</Button>
							)}
						</div>
					</Formsy>
				)}
			</React.Fragment>
		);
	}
}

LocationCreateForm.contextTypes = {
	authUser: PropTypes.object,
	getUserProfile: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	changeLoginState: PropTypes.func,
	signIn: PropTypes.func,
	signOut: PropTypes.func
};

const WithGraphQL = compose(
	graphql(getLocationQuery, {
		options: ({ slug }) => ({
			variables: { slug },
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'no-cache'
			// refetchQueries: [
			// 	{
			// 		query: getTeamQuery,
			// 		variables: { variables: { slug } }
			// 	}
			// ]
		}),
		name: 'getLocation'
	}),
	graphql(getLeaguesQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getLeagues'
	}),
	graphql(getPlayersQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getPlayers'
	}),
	graphql(createLocationMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'createLocation'
	}),
	graphql(updateLocationMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'updateLocation'
	})
)(LocationCreateForm);

const WithRouter = withRouter(WithGraphQL);
const WithStyles = withStyles(styles)(WithRouter);
export default currentUser(WithStyles);
