// import { AsyncStorage } from 'react-native';

const AUTH_TOKEN = 'DPLToken';
const AUTH_USER = 'DPLAuthentication';

let token;

export const getToken = async () => {
	if (token) {
		return Promise.resolve(token);
	}
	token = await localStorage.getItem(AUTH_TOKEN);
	return token;
};

export const saveAuthToken = newToken => {
	token = newToken;
	return localStorage.setItem(AUTH_TOKEN, newToken);
};

export const removeAuthToken = () => {
	token = undefined;
	localStorage.removeItem(AUTH_USER);
	return localStorage.removeItem(AUTH_TOKEN);
};
