import React, { Component } from 'react';
import Flag from 'react-flags';

const styles = {
	match: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: '#e5e5e5'
	},
	matchNumber: {
		padding: 10
	},
	matchScores: {
		flex: 1,
		backgroundColor: '#fff'
	},
	player: {
		display: 'flex',
		justifyContent: 'space-between',
		borderBottom: '1px solid rgb(237, 237, 237)'
	},
	playerNumber: {
		alignItems: 'center',
		backgroundColor: '#fafafa',
		padding: '5px 10px 5px 10px'
	},
	playerName: {
		alignItems: 'center',
		flex: 1,
		textAlign: 'left',
		padding: '5px 10px 5px 10px'
	},
	playerScore: {
		alignItems: 'center',
		fontWeight: 600,
		padding: '5px 10px 5px 10px',
		backgroundColor: 'orange'
	},
	winningScore: {
		alignItems: 'center',
		fontWeight: 600,
		padding: '5px 10px 5px 10px',
		backgroundColor: 'orange'
	},
	losingScore: {
		alignItems: 'center',
		// fontWeight: 600,
		padding: '5px 10px 5px 10px',
		backgroundColor: '#ffcd01'
	},
	spacer: {}
};

class Match extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { className, number, players, isLive, isTvTable, footerText } = this.props;
		return (
			<div className={this.props.className}>
				<div className="match-info">
					<div className="time">
						Thu 10:00am - Table 1{' '}
						{isTvTable && (
							<a href="#" target="_blank">
								(TV Table)
							</a>
						)}
					</div>
					{isLive && <div className="live-text">LIVE</div>}
				</div>
				<div className="match-data">
					<div className="match-number">{number}</div>
					<div className="match-scores">
						{players.map((player, index) => {
							if (player) {
								return (
									<div
										key={index}
										className={`player p${index} ${player.winner ? 'winner' : 'loser'}`}
									>
										<div className="player-number">
											{player.playerNumber ? player.playerNumber : '-'}
										</div>
										<div className="player-name">
											<Flag
												name={player.country || 'Unknown'}
												format="png"
												pngSize={24}
												shiny={false}
												alt="Canada Flag"
												className="flag"
												basePath="/images/flags"
											/>
											<div className="truncate">
												{player.playerName ? player.playerName : '-'}
											</div>
										</div>
										<div className="player-score">{player.score ? player.score : '-'}</div>
									</div>
								);
							} else {
								return (
									<div key={index} className={`player p${index}`}>
										<div className="player-number">-</div>
										<div className="player-name">BYE</div>
										<div className="player-score">-</div>
									</div>
								);
							}
						})}
					</div>
				</div>
				<div className="match-footer">{footerText && footerText}</div>
			</div>
		);
	}
}

export default Match;
