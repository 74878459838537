import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import { Link, Redirect, withRouter } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { SignUpLink } from './signup';
import { PasswordForgetLink } from './PasswordForget';
import auth from '../../auth/gql';
import * as routes from '../../config/routes';
import client from '../../config/apolloClient';
import jwt from 'jsonwebtoken';
import gql from 'graphql-tag';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PasswordField from 'material-ui-password-field';

// import withAuthentication from '../../helpers/withAuthentication';

const styles = theme => ({
	layout: {
		width: 'auto',
		display: 'block', // Fix IE11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE11 issue.
		marginTop: theme.spacing.unit
	},
	submit: {
		marginTop: theme.spacing.unit * 3
	}
});

class SignInPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showProgress: false,
			redirectToReferrer: false
		};
	}

	handleLogin = () => {
		this.setState({
			redirectToReferrer: true
		});
	};

	showProgressBar = () => {
		this.setState({
			showProgress: true
		});
	};

	hideProgressBar = () => {
		this.setState({
			showProgress: false
		});
	};

	render() {
		const { classes, history, theme, ...rest } = this.props;
		const { from } = this.props.location.state || { from: { pathname: '/' } };
		const { redirectToReferrer } = this.state;

		if (redirectToReferrer === true) {
			return <Redirect to={from} />;
		}

		return (
			<div className="page-container">
				{this.state.showProgress === true && <LinearProgress mode="indeterminate" color="primary" />}

				<main className={classes.layout}>
					<Paper className={classes.paper}>
						<Avatar className={classes.avatar}>
							<LockIcon />
						</Avatar>
						<Typography variant="h5">Sign in</Typography>
						<SignInFormWithStyles
							showProgressBar={this.showProgressBar}
							hideProgressBar={this.hideProgressBar}
							onLogin={this.handleLogin}
							history={history}
							{...rest}
						/>
						<PasswordForgetLink />
						<SignUpLink />
					</Paper>
				</main>
			</div>
		);
	}
}

SignInPage.propTypes = {
	classes: PropTypes.object.isRequired
};

const INITIAL_STATE = {
	email: '',
	password: '',
	role: '',
	accountType: '',
	error: null,
	loggingIn: false
};

class SignInForm extends Component {
	constructor(props) {
		super(props);

		this.state = { ...INITIAL_STATE };
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(event) {
		event.preventDefault();
		this.setState({
			loggingIn: true
		});
		this.props.showProgressBar();

		const { email, password } = this.state;
		const { history, ...rest } = this.props;
		auth.signInWithEmailAndPassword(email, password)
			.then(result => {
				this.setState(() => ({ ...INITIAL_STATE }));
				this.props.hideProgressBar();
				this.props.alert.success('Successfully logged in');

				localStorage.setItem('DPLToken', result.data.login.token);
				localStorage.setItem('DPLAuthentication', JSON.stringify(result.data.login.user));
				this.context.changeLoginState(true, result.data.login, result.data.login.token);
				auth.setUserAuthToken(result.data.login.user.id, result.data.login.token)
					.then(res => {
						console.log(res);
					})
					.catch(error => {
						console.log(error);
					});
				this.props.onLogin();
				// history.push(routes.HOME);
			})
			.catch(error => {
				this.setState({ error: error, loggingIn: false });
				this.props.alert.error(`${error}`);
				this.props.hideProgressBar();
			});
	}

	render() {
		const { classes } = this.props;
		const { email, password, error } = this.state;
		const isInvalid = password === '' || email === '';

		return (
			<form className={classes.form} onSubmit={this.onSubmit}>
				<FormControl margin="dense" required fullWidth>
					<InputLabel htmlFor="email">Email Address</InputLabel>
					<Input
						error={Boolean(error)}
						id="email"
						name="email"
						value={email}
						onChange={event => this.setState({ email: event.target.value })}
						autoComplete="email"
						autoFocus
					/>
					{/*<FormHelperText error={Boolean(error)}>Enter your email address</FormHelperText>*/}
				</FormControl>
				<FormControl margin="dense" required fullWidth>
					<InputLabel htmlFor="password">Password</InputLabel>
					<PasswordField
						error={Boolean(error)}
						name="password"
						type="password"
						id="password"
						value={password}
						onChange={event => this.setState({ password: event.target.value })}
						autoComplete="current-password"
					/>

					{/*<FormHelperText error={Boolean(error)}>Enter your secret password</FormHelperText>*/}
				</FormControl>
				{/*<FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />*/}
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					disabled={isInvalid || this.state.loggingIn === true}
					className={classes.submit}
				>
					{this.state.loggingIn === false ? 'Sign In' : 'Logging in...'}
				</Button>
				{error && <p style={{ color: 'red' }}>ERROR: {error.message}</p>}
			</form>
		);
	}
}

const SignInLink = () => (
	<p>
		Already have an account?{' '}
		<Link to={routes.SIGN_IN} style={{ fontWeight: 700 }}>
			Sign In
		</Link>
	</p>
);

SignInForm.propTypes = {
	classes: PropTypes.object.isRequired
};

SignInForm.contextTypes = {
	authUser: PropTypes.object,
	getUserProfile: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	changeLoginState: PropTypes.func,
	signIn: PropTypes.func,
	signOut: PropTypes.func
};

const SignInFormWithStyles = withStyles(styles)(SignInForm);

const WithStyles = withStyles(styles)(SignInPage);

const WithAlert = withAlert(WithStyles);

export default withRouter(WithAlert);

export { SignInForm, SignInLink };
