import gql from 'graphql-tag';

export const getChallengesQuery = gql`
	query GetChallenges($offset: Int, $limit: Int, $order: [[String]]) {
		challenges(offset: $offset, limit: $limit, order: $order) {
			id
			opponent {
				id
				name
				slug
				username
				avatarImg
			}
			challenger {
				id
				name
				slug
				username
				avatarImg
			}
			matches {
				id
			}
			gameType
			matchFormat
			raceTo
			league {
				id
				name
				slug
				type
			}
			location {
				id
				slug
				name
				address
				city
				state
				zip
				country
			}
			division {
				id
				slug
				name
				type
			}
			comments {
				id
				message
				author {
					id
					name
					username
					avatarImg
				}
			}
			startTime
			notes
			accepted
			status
			createdAt
			updatedAt
		}
	}
`;
