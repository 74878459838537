import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Card } from '../../components/chrome';
import PlayerCreateEditForm from './PlayerCreateEditForm';
import { SectionHeader } from '../../components/chrome';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	toolbarTitle: {
		marginBottom: 15
	}
});

class PlayerCreate extends Component {
	render() {
		const { classes } = this.props;

		return (
			<SectionHeader title="Add Player" backButton>
				<div style={{ marginTop: 24, marginBottom: 24 }}>
					<Grid container justify="center" spacing={24}>
						<Grid item xs={12} md={11} lg={10}>
							<Grid container justify="center" spacing={24}>
								<Grid item xs={12}>
									<Card>
										<Typography variant="title" color="inherit" className={classes.toolbarTitle}>
											<span>Player Details</span>
										</Typography>
										<PlayerCreateEditForm />
									</Card>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</SectionHeader>
		);
	}
}

PlayerCreate.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PlayerCreate);
