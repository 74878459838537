import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import NavDrawer from './NavDrawer';
import AvatarMenu from './AvatarMenu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import Hidden from '@material-ui/core/Hidden';
import userImage from '../../assets/images/iwooten.jpg';
import dplLogo from '../../assets/logos/dpl-logo-new.png';
import dpLogo from '../../assets/logos/dp-logo.png';
import dpLogo2 from '../../assets/logos/dp-logo2.png';
import dpLogo3 from '../../assets/logos/dp-logo3.png';
import * as routes from '../../config/routes';
import Headroom from 'react-headroom';
import NotificationsMenu from './NotificationsMenu';

const theme1 = createMuiTheme({
	palette: {
		// type: 'dark',
		primary: {
			main: '#292C39',
			light: '#FFCC00',
			dark: '#000000'
		},
		secondary: {
			main: '#FFCC00',
			light: '#fff',
			dark: '#000000'
		}
	}
});

const styles = theme => ({
	// root: {
	// 	flexGrow: 1
	// },
	title: {
		color: 'white',
		flex: 1,
		fontSize: 17,
		marginRight: 10,
		fontWeight: 400
	},
	menuButton: {
		marginLeft: -12,
		marginRight: 10
	},
	avatar: {
		margin: 0,
		padding: 0
	},
	root: {
		flexGrow: 1,
		zIndex: 1,
		position: 'relative',
		// display: 'flex',
		width: '100%'
	},
	toolbar: theme.mixins.toolbar,
	badge: {
		// padding: theme.spacing.unit * 0.5
	},
	content: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column'
		// backgroundColor: theme.palette.background.default,
		// overflow: 'auto'
	},
	notificationContainer: {
		marginRight: 10
	}
});

class AppBarHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			top: false,
			left: false,
			bottom: false,
			right: false
		};
		this.toggleCloseNavigate = this.toggleCloseNavigate.bind(this);
	}

	isActive(link) {
		let urlPath = window.location.pathname;
		return urlPath === link ? true : false;
	}

	toggleDrawer = (side, open) => () => {
		this.setState({ [side]: open });
	};

	toggleClose = (side, open) => () => {
		this.setState({ [side]: false });
	};

	toggleCloseNavigate = (side, url) => {
		const { history } = this.props;
		this.setState({ [side]: false }, () => {
			history.push(url);
		});
	};

	loggedOutView() {
		const { classes, history, title, shadow } = this.props;
		return (
			<React.Fragment>
				<AppBar
					position="static"
					className="header"
					elevation={0}
					style={shadow === true ? { boxShadow: '0px -2px 15px 2px rgba(0, 0, 0, 0.5)' } : null}
				>
					<Toolbar>
						<IconButton
							className={classes.menuButton}
							color="inherit"
							aria-label="Menu"
							onClick={this.toggleDrawer('left', true)}
						>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" color="inherit" className={classes.title}>
							<Hidden smDown>Menu</Hidden>
						</Typography>

						<Hidden mdUp>
							<Link to="/" className="dpl-logo centered">
								<img src={dplLogo} alt="logo" width={100} style={{ position: 'relative', top: 3 }} />
							</Link>
						</Hidden>
						<Hidden smDown>
							<Link to="/" className="dpl-logo centered">
								<img src={dplLogo} alt="logo" width={140} style={{ position: 'relative', top: 3 }} />
							</Link>
						</Hidden>

						<div style={{ marginTop: 5 }}>
							<Link to={routes.SIGN_IN}>
								<Button default={true} style={{ color: '#fff' }}>
									Sign In
								</Button>
							</Link>
							<Link to={routes.SIGN_UP}>
								<Button default={true} style={{ color: '#fff' }}>
									Sign Up
								</Button>
							</Link>
						</div>
					</Toolbar>
				</AppBar>
				<MuiThemeProvider theme={theme1}>
					<NavDrawer
						{...this.state}
						toggleDrawer={this.toggleDrawer}
						toggleClose={this.toggleClose}
						toggleCloseNavigate={this.toggleCloseNavigate}
					/>
				</MuiThemeProvider>
			</React.Fragment>
		);
	}

	loggedInView() {
		const { classes, title, shadow } = this.props;
		return (
			<React.Fragment>
				<AppBar
					position="static"
					className="header"
					elevation={0}
					style={shadow === true ? { boxShadow: '0px -2px 15px 2px rgba(0, 0, 0, 0.5)' } : null}
				>
					<Toolbar>
						<IconButton
							onClick={this.toggleDrawer('left', true)}
							className={classes.menuButton}
							color="inherit"
							aria-label="Menu"
						>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" color="inherit" className={classes.title}>
							<Hidden smDown>Menu</Hidden>
						</Typography>

						<Hidden mdUp>
							<Link to="/" className="dpl-logo centered">
								<img src={dplLogo} alt="logo" width={100} style={{ position: 'relative', top: 3 }} />
							</Link>
						</Hidden>
						<Hidden smDown>
							<Link to="/" className="dpl-logo centered">
								{/*<img src={dpLogo} alt="logo" width={40} style={{ position: 'relative', top: 3 }} />*/}
								{/*<img src={dpLogo2} alt="logo" width={80} style={{ position: 'relative', top: 3 }} />*/}
								{/*<img src={dpLogo3} alt="logo" width={250} style={{ position: 'relative', top: 3 }} />*/}
								<img src={dplLogo} alt="logo" width={140} style={{ position: 'relative', top: 3 }} />
							</Link>
						</Hidden>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
								fontSize: 14
							}}
						>
							<NotificationsMenu />
							<AvatarMenu />
						</div>
					</Toolbar>
				</AppBar>
				<MuiThemeProvider theme={theme1}>
					<NavDrawer
						{...this.state}
						toggleDrawer={this.toggleDrawer}
						toggleClose={this.toggleClose}
						toggleCloseNavigate={this.toggleCloseNavigate}
					/>
				</MuiThemeProvider>
			</React.Fragment>
		);
	}

	render() {
		const { classes } = this.props;

		return (
			<div className={classes.root}>
				{/*<div style={{ marginLeft: 0, position: 'fixed', zIndex: 20, width: '100%' }}>
					{this.context.authUser ? this.loggedInView() : this.loggedOutView()}
		</div>*/}

				<Headroom
					style={{
						zIndex: 20
					}}
				>
					{this.context.authUser ? this.loggedInView() : this.loggedOutView()}
				</Headroom>

				<main className={classes.content}>
					{/*<div className={classes.toolbar} />*/}
					{this.props.children}
				</main>
			</div>
		);
	}
}

AppBarHeader.contextTypes = {
	authUser: PropTypes.object
};

AppBarHeader.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

const AppBarHeaderWithStyles = withStyles(styles, { withTheme: true })(AppBarHeader);
export default withRouter(AppBarHeaderWithStyles);
