import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class ConfirmDialog extends React.Component {
	state = {
		open: this.props.open || false
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.open !== this.props.open) {
			this.setState({ open: nextProps.open });
		}
	}

	handleClose = () => {
		this.setState({ open: false });
		this.props.onClose();
	};

	handleConfirm = () => {
		this.setState({ open: false });
		this.props.onConfirm();
	};

	render() {
		return (
			<Dialog
				open={this.state.open}
				onClose={this.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{this.props.children}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleClose} color="primary">
						Disagree
					</Button>
					<Button onClick={this.handleConfirm} color="primary" autoFocus>
						Agree
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default ConfirmDialog;
