import { auth } from './firebase';
// import { saveUser } from "./db";

// Sign Up
export const createUserWithEmailAndPassword = (email, password) => {
	return auth.createUserWithEmailAndPassword(email, password).catch(error => {
		console.error(error);
		// throw error;
		throw new Error('Unable to create new user account', JSON.stringify(error));
	});
	// auth.createUserWithEmailAndPassword(email, password).then(saveUser);
};
// See https://firebase.google.com/docs/auth/admin/create-custom-tokens
// This may not work on the client side because it requires using a service account
// export const createCustomToken = user => {
//   let additionalClaims = {
//     premiumAccount: true,
//     role: "admin"
//   };
//   auth
//     .createCustomToken(user.uid, additionalClaims)
//     .then(function(customToken) {
//       return customToken;
//       // Send token back to client
//     })
//     .catch(function(error) {
//       console.log("Error creating custom token:", error);
//     });
// };

// export const signInWithCustomToken = token => {
//   auth.signInWithCustomToken(token).catch(function(error) {
//     // Handle Errors here.
//     let errorCode = error.code;
//     let errorMessage = error.message;
//     // ...
//   });
// };

// Sign In
export const signInWithEmailAndPassword = (email, password) =>
	auth.signInWithEmailAndPassword(email, password).catch(error => {
		throw error;
	});
// auth.signInWithEmailAndPassword(email, password).then(signInWithCustomToken);

// Sign out
export const signOut = () => auth.signOut();

// Password Reset
export const resetPassword = email => auth.sendPasswordResetEmail(email);

// Password Change
export const updatePassword = password => auth.currentUser.updatePassword(password);
