import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Avatar from '@material-ui/core/Avatar';
import _ from 'lodash';
import eightBall from '../../assets/images/ball/eight-ball.png';
import nineBall from '../../assets/images/ball/nine-ball.png';
import tenBall from '../../assets/images/ball/ten-ball.png';
import ConfirmDialog from '../../components/ConfirmDialog';
import { removeChallengeMutation } from './mutations/removeChallengeMutation';
import { graphql, compose } from 'react-apollo';
import { isoCountries, getGameType, displayEnumType } from '../../utils';
import LocationOn from '@material-ui/icons/LocationOn';
import { getChallengesQuery } from './queries/getChallengesQuery';
import moment from 'moment';

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	},
	card: {
		// padding: 10,
		// margin: 24,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		border: '1px solid #EAEDF3',
		boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.04)',
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},
	cell: {
		padding: '4px 30px 4px 20px',
		fontSize: 13
		// fontSize: 14
	},
	avatar: {
		width: 30,
		height: 30,
		backgroundColor: '#44475b',
		color: '#fff'
	}
});
class ChallengesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dialogOpen: false,
			selectedChallenge: null,
			order: 'asc',
			orderBy: 'name',
			selected: [],
			page: 0,
			rowsPerPage: 10,
			limit: this.props.limit || 10,
			offset: this.props.offset || 0
		};
		this.handleCloseDialog = this.handleCloseDialog.bind(this);
		this.handleConfirmDialog = this.handleConfirmDialog.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.offset !== this.props.offset || nextProps.limit !== this.props.limit) {
			this.setState({
				offset: nextProps.offset,
				limit: nextProps.limit
			});
		}
	}

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}

		this.setState({ order, orderBy });
	};

	getGameType = type => {
		return type.indexOf('EIGHT_BALL') !== -1
			? eightBall
			: type.indexOf('NINE_BALL') !== -1 ? nineBall : type.indexOf('TEN_BALL') !== -1 ? tenBall : null;
	};

	handleChangePage = (event, page) => {
		this.setState({ page });
		this.props.onLoadMore();
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
		this.props.onLoadMore();
	};

	handleCloseDialog() {
		this.setState({
			dialogOpen: false,
			selectedChallenge: null
		});
	}

	handleConfirmDialog(division) {
		this.setState({
			selectedChallenge: division,
			dialogOpen: true
		});
	}

	handleChangePage(event, page) {
		// this.setState({ page });
	}

	handleChangeRowsPerPage(event) {
		// this.setState({ rowsPerPage: event.target.value });
	}

	render() {
		const {
			classes,
			match,
			history,
			data,
			loading,
			error,
			subscribeToMore,
			count,
			onLoadMore,
			offset,
			limit
		} = this.props;

		const { order, orderBy, selected, rowsPerPage, page } = this.state;
		let emptyRows;

		if (!loading && data.challenges) {
			emptyRows = rowsPerPage - Math.min(rowsPerPage, data.challenges.length - page * rowsPerPage);
		}

		return (
			<React.Fragment>
				<Paper elevation={0} className={classes.card}>
					<div className={classes.container}>
						{loading && (
							<div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15 }}>
								Loading...
							</div>
						)}
						{error && <div style={styles.container}>Error: {error.message}</div>}

						{!loading && data.challenges ? (
							<React.Fragment>
								{/*<DivisionToolbar data={data} />*/}
								<Table className={classes.table}>
									<TableHead>
										<TableRow>
											{/*<TableCell className={classes.cell}>ID</TableCell>*/}
											<TableCell className={classes.cell}>Players</TableCell>
											{/*<TableCell className={classes.cell}>Game Type</TableCell>*/}
											<TableCell className={classes.cell}>Format</TableCell>
											{/*<TableCell className={classes.cell}>Race To</TableCell>*/}
											<TableCell className={classes.cell}>Division</TableCell>
											<TableCell className={classes.cell}>Location</TableCell>
											<TableCell className={classes.cell}>Start Time</TableCell>
											{/*<TableCell className={classes.cell}>Notes</TableCell>*/}
											<TableCell className={classes.cell}>Status</TableCell>
											{/*<TableCell className={classes.cell}>Accepted</TableCell>*/}
											<TableCell className={classes.cell}>Actions</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{data.challenges &&
											data.challenges.map((challenge, index) => {
												const challenger = challenge.challenger.name;
												const opponent = challenge.opponent.name;
												const title = `${challenger} vs. ${opponent}`;

												return (
													<TableRow
														key={challenge.id}
														hover
														onClick={() => history.push(`/challenges/${challenge.id}`)}
														style={{ cursor: 'pointer' }}
													>
														{/*<TableCell className={classes.cell}>{challenge.id}</TableCell>*/}
														<TableCell className={classes.cell}>
															<div style={{ display: 'flex', alignItems: 'center' }}>
																<Avatar
																	size="small"
																	className={classes.avatar}
																	src={this.getGameType(challenge.gameType)}
																	style={{ marginRight: 10 }}
																/>

																{title}
															</div>
														</TableCell>

														{/*<TableCell className={classes.cell}>
															{displayEnumType(challenge.gameType)}
												</TableCell>*/}
														<TableCell className={classes.cell}>
															{displayEnumType(challenge.matchFormat)}
														</TableCell>
														{/*<TableCell className={classes.cell}>
															{challenge.raceTo}
												</TableCell>*/}
														<TableCell className={classes.cell}>
															{challenge.division.name}
														</TableCell>
														<TableCell className={classes.cell}>
															<div style={{ display: 'flex', alignItems: 'center' }}>
																<LocationOn style={{ height: 18, marginRight: 10 }} />{' '}
																{challenge.location.name}
															</div>
														</TableCell>
														<TableCell className={classes.cell}>
															{moment(challenge.startTime).format('MM/DD/YYYY hh:mm a')}
														</TableCell>
														{/*<TableCell className={classes.cell}>
															{_.truncate(challenge.notes, 100)}
												</TableCell>*/}
														<TableCell className={classes.cell}>
															{displayEnumType(challenge.status)}
														</TableCell>
														{/*<TableCell className={classes.cell}>
															{Boolean(challenge.accepted).toString()}
											</TableCell>*/}
														<TableCell className={classes.cell}>
															<div style={{ display: 'flex', alignItems: 'center' }}>
																<Button
																	color="primary"
																	size="small"
																	onClick={e => {
																		e.preventDefault();
																		e.stopPropagation();
																		history.push(
																			`/challenges/${challenge.id}/edit`
																		);
																	}}
																>
																	Edit
																</Button>
																<Button
																	color="primary"
																	size="small"
																	onClick={e => {
																		e.preventDefault();
																		e.stopPropagation();
																		this.handleConfirmDialog(challenge);
																	}}
																	style={{ color: 'rgb(225, 0, 80)' }}
																>
																	Remove
																</Button>
															</div>
														</TableCell>
													</TableRow>
												);
											})}
									</TableBody>
								</Table>
								<TablePagination
									component="div"
									count={data.challenges.length}
									rowsPerPage={50}
									rowsPerPageOptions={[50, 100, 200]}
									page={0}
									backIconButtonProps={{
										'aria-label': 'Previous Page'
									}}
									nextIconButtonProps={{
										'aria-label': 'Next Page'
									}}
									onChangePage={this.handleChangePage}
									onChangeRowsPerPage={this.handleChangeRowsPerPage}
								/>
								{this.state.dialogOpen === true && (
									<ConfirmDialog
										title="Confirm Challenge Removal"
										open={this.state.dialogOpen}
										onClose={this.handleCloseDialog}
										onConfirm={() => {
											this.props
												.removeChallenge({
													mutation: removeChallengeMutation,
													variables: { id: this.state.selectedChallenge.id },
													// refetchQueries: ['GetDivisions'],
													refetchQueries: [
														{
															query: getChallengesQuery
														}
													]
													// awaitRefetchQueries: true
												})
												.then(() => {
													this.setState({ dialogOpen: false });
												})
												.catch(error => {
													console.log(error);
													this.setState({ dialogOpen: false });
												});
										}}
									>
										⚠️ Are you sure you want to delete <b>{this.state.selectedChallenge.id}</b>?
									</ConfirmDialog>
								)}
							</React.Fragment>
						) : (
							<div>No challenges found</div>
						)}
					</div>
				</Paper>
			</React.Fragment>
		);
	}
}

ChallengesList.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithGraphQL = graphql(removeChallengeMutation, {
	options: ({ id }) => ({ variables: { id } }),
	name: 'removeChallenge'
})(ChallengesList);

export default withStyles(styles)(WithGraphQL);
