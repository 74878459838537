import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
// import DivisionToolbar from './DivisionToolbar';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
import ConfirmDialog from '../../components/ConfirmDialog';
import { removeLocationMutation } from './mutations/removeLocationMutation';
import { getLocationQuery } from './queries/getLocationQuery';
import { graphql, compose } from 'react-apollo';
import LocationOn from '@material-ui/icons/LocationOn';
import Avatar from '@material-ui/core/Avatar';

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	},
	card: {
		// padding: 10,
		// margin: 24,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},
	cell: {
		padding: '4px 30px 4px 20px',
		fontSize: 13
	},
	cellTitle: {
		// fontWeight: 500,
		padding: '4px 30px 4px 20px'
		// fontSize: 14
	},
	avatar: {
		width: 30,
		height: 30,
		fontSize: 14,
		backgroundColor: '#44475b',
		color: '#fff'
	}
});
class LocationsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dialogOpen: false,
			selectedLocation: null,
			order: 'asc',
			orderBy: 'name',
			selected: [],
			page: 0,
			rowsPerPage: 10,
			limit: this.props.limit || 10,
			offset: this.props.offset || 0
		};
		this.handleCloseDialog = this.handleCloseDialog.bind(this);
		this.handleConfirmDialog = this.handleConfirmDialog.bind(this);
	}

	handleCloseDialog() {
		this.setState({
			dialogOpen: false,
			selectedLocation: null
		});
	}

	handleConfirmDialog(match) {
		this.setState({
			selectedLocation: match,
			dialogOpen: true
		});
	}

	handleChangePage(event, page) {
		// this.setState({ page });
	}

	handleChangeRowsPerPage(event) {
		// this.setState({ rowsPerPage: event.target.value });
	}

	displayEnumType(type) {
		const typeSplit = type.split('_');
		const prefix = _.capitalize(typeSplit[0]);
		const suffix = _.capitalize(typeSplit[1]);
		let combined = '';
		typeSplit.forEach(part => {
			combined += `${_.capitalize(part)} `;
		});
		return combined;
	}

	render() {
		const { classes, match, history, data, loading, error, subscribeToMore } = this.props;
		return (
			<Paper elevation={0} className={classes.card}>
				<div className={classes.container}>
					{/*<DivisionToolbar data={data} />*/}
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								{/*<TableCell className={classes.cell}>#</TableCell>*/}
								<TableCell className={classes.cell}>Name</TableCell>
								<TableCell className={classes.cell}>Address</TableCell>
								<TableCell className={classes.cell}>City</TableCell>
								<TableCell className={classes.cell}>State</TableCell>
								<TableCell className={classes.cell}>Zip</TableCell>
								<TableCell className={classes.cell}>Country</TableCell>
								<TableCell className={classes.cell}>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.locations.map((location, index) => {
								return (
									<TableRow
										key={location.id}
										hover
										onClick={() => history.push(`${match.url}/${location.slug}`)}
										style={{ cursor: 'pointer' }}
									>
										{/*<TableCell className={classes.cell}>{index + 1}</TableCell>*/}
										<TableCell className={classes.cell}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<span style={{ marginRight: 15, display: 'flex' }}>
													<Avatar size="small" className={classes.avatar}>
														{location.name.slice(0, 1)}
													</Avatar>
												</span>
												<span>{location.name}</span>
											</div>
										</TableCell>
										<TableCell className={classes.cell}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<LocationOn style={{ height: 18, marginRight: 10 }} />{' '}
												{location.address}
											</div>
										</TableCell>
										<TableCell className={classes.cell}>{location.city}</TableCell>
										<TableCell className={classes.cell}>{location.state}</TableCell>
										<TableCell className={classes.cell}>{location.zip}</TableCell>
										<TableCell className={classes.cell}>{location.country}</TableCell>
										<TableCell className={classes.cell}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<Button
													color="primary"
													size="small"
													onClick={e => {
														e.preventDefault();
														e.stopPropagation();
														history.push(`/locations/${location.slug}/edit`);
													}}
												>
													Edit
												</Button>
												<Button
													color="primary"
													size="small"
													onClick={e => {
														e.preventDefault();
														e.stopPropagation();
														this.handleConfirmDialog(location);
													}}
													style={{ color: 'rgb(225, 0, 80)' }}
												>
													Remove
												</Button>
											</div>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
					<TablePagination
						component="div"
						count={data.locations.length}
						rowsPerPage={50}
						rowsPerPageOptions={[50, 100, 200]}
						page={0}
						backIconButtonProps={{
							'aria-label': 'Previous Page'
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page'
						}}
						onChangePage={this.handleChangePage}
						onChangeRowsPerPage={this.handleChangeRowsPerPage}
					/>
					{this.state.dialogOpen === true && (
						<ConfirmDialog
							title="Confirm Location Removal"
							open={this.state.dialogOpen}
							onClose={this.handleCloseDialog}
							onConfirm={() => {
								this.props
									.deleteLocation({
										mutation: removeLocationMutation,
										variables: { id: this.state.selectedLocation.id },
										// refetchQueries: ['GetDivisions'],
										refetchQueries: [
											{
												query: getLocationQuery
											}
										]
										// awaitRefetchQueries: true
									})
									.then(() => {
										this.setState({ dialogOpen: false });
									})
									.catch(error => {
										console.log(error);
										this.setState({ dialogOpen: false });
									});
							}}
						>
							⚠️ Are you sure you want to delete <b>{this.state.selectedLocation.name}</b>?
						</ConfirmDialog>
					)}
				</div>
			</Paper>
		);
	}
}

LocationsList.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithGraphQL = graphql(removeLocationMutation, {
	options: ({ id }) => ({ variables: { id } }),
	name: 'deleteLocation'
})(LocationsList);

export default withStyles(styles)(WithGraphQL);
