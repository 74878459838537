import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import SimpleGoogleMap from '../../components/SimpleGoogleMap';
import { StaticGoogleMap, Marker, Path } from 'react-static-google-map';
import LocationOn from '@material-ui/icons/LocationOn';
import userImage from '../../assets/images/iwooten.jpg';
import eightBall from '../../assets/images/ball/eight-ball.png';
import nineBall from '../../assets/images/ball/nine-ball.png';
import tenBall from '../../assets/images/ball/ten-ball.png';
import { SectionHeader, Card, ZeroState } from '../../components/chrome';
import _ from 'lodash';
import currentUser from '../../helpers/currentUser';
import NavigationArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import ProfileTabs from './ProfileTabs';

const styles = theme => ({
	toolbarTitle: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	avatar: {
		backgroundColor: '#44475b',
		color: '#fff'
	}
});

const GET_USER_PROFILE = gql`
	query userProfileQuery($userId: ID) {
		user(id: $userId) {
			id
			name
			username
			email
			playerNumber
			playerRatings {
				id
				type
				rating
				customName
			}
			isActive
			avatarImg
			firstRun
			city
			state
			country
			matches {
				id
			}
			tournaments {
				id
				name
				slug
				startTime
			}
			teams {
				id
				name
				slug
				divisions {
					id
				}
			}
			divisions {
				id
				name
				slug
				type
			}
			notifications {
				id
			}
			joinedAt
			createdAt
			# raw
		}
	}
`;

class DivisionDetail extends Component {
	getGameType(type) {
		return type.indexOf('EIGHT_BALL') !== -1
			? eightBall
			: type.indexOf('NINE_BALL') !== -1 ? nineBall : type.indexOf('TEN_BALL') !== -1 ? tenBall : null;
	}

	displayEnumType(type) {
		if (type) {
			const typeSplit = type.split('_');
			const prefix = _.capitalize(typeSplit[0]);
			const suffix = _.capitalize(typeSplit[1]);
			let combined = '';
			typeSplit.forEach(part => {
				combined += `${_.capitalize(part)} `;
			});
			return combined;
		}
	}

	render() {
		const { classes, match, history, currentUser } = this.props;

		return (
			<Query query={GET_USER_PROFILE} variables={{ userId: currentUser.id }}>
				{({ loading, error, data, subscribeToMore }) => {
					if (loading) return <LinearProgress mode="indeterminate" color="primary" />;
					if (error) return <div style={styles.container}>Error: {error.message}</div>;

					return (
						<React.Fragment>
							<Paper className={classes.titleBar} elevation={0}>
								<div className={classes.headerBar}>
									<Grid container justify="center">
										<Grid item xs={12} sm={12} md={11} lg={10}>
											<div
												style={{
													marginTop: 30,
													marginBottom: 30,
													display: 'flex',
													alignItems: 'center'
												}}
											>
												<Avatar
													src={userImage}
													style={{ width: 60, height: 60, marginRight: 15 }}
												/>
												<div>
													<div style={{ fontSize: 24, fontWeight: 600 }}>
														{data.user.name}
													</div>
													<div>{data.user.email}</div>
												</div>
											</div>
										</Grid>
										<Grid item xs={12}>
											<ProfileTabs />
										</Grid>
									</Grid>
								</div>
							</Paper>
						</React.Fragment>
					);
				}}
			</Query>
		);
	}
}

DivisionDetail.contextTypes = {
	authUser: PropTypes.object
};

DivisionDetail.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles)(DivisionDetail);
export default currentUser(WithStyles);
