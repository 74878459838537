import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Formsy from 'formsy-react';
import TextInput from '../../components/forms/TextInputFormsy';
import DateTimeInput from '../../components/forms/DateTimeInputFormsy';
import SelectField from '../../components/forms/SelectFormsy';
import SwitchField from '../../components/forms/SwitchFormsy';
import MultiSelectField from '../../components/forms/MultiSelectFormsy';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import Avatar from '@material-ui/core/Avatar';
import LocationOn from '@material-ui/icons/LocationOn';
import isEmpty from 'lodash/isEmpty';
import { getChallengeQuery } from './queries/getChallengeQuery';
import { updateChallengeMutation } from './mutations/updateChallengeMutation';
import { createChallengeMutation } from './mutations/createChallengeMutation';
import eightBall from '../../assets/images/ball/eight-ball.png';
import nineBall from '../../assets/images/ball/nine-ball.png';
import tenBall from '../../assets/images/ball/ten-ball.png';
import queryString from 'query-string';

const styles = theme => ({
	error: {
		color: '#f44336'
	},
	content: {
		// padding: '10px 24px 20px 24px'
		// maxWidth: 600
	},
	input: {
		marginTop: 5
	},
	submit: {
		marginTop: 10
	},
	selectEmpty: {
		marginTop: theme.spacing.unit * 2
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	},
	avatar: {
		backgroundColor: '#44475b',
		color: '#fff'
	}
});

export const getLeaguesQuery = gql`
	query GetLeagues {
		leagues {
			id
			name
			slug
			type
		}
	}
`;

export const getDivisionsQuery = gql`
	query GetDivisions {
		divisions {
			id
			name
			slug
			type
		}
	}
`;

export const getLocationsQuery = gql`
	query GetLocations {
		locations {
			id
			name
			slug
			# address
			# city
			# state
			# zip
			# country
			# lat
			# lon
			# createdAt
		}
	}
`;

export const getPlayersQuery = gql`
	query GetPlayers {
		users {
			id
			name
			username
			avatarImg
			# address
			# city
			# state
			# zip
			# country
			# lat
			# lon
			# createdAt
		}
	}
`;

class ChallengeCreateForm extends React.Component {
	constructor(props) {
		super(props);
		this.disableButton = this.disableButton.bind(this);
		this.enableButton = this.enableButton.bind(this);
		this.state = { canSubmit: false, submitting: false };
		this.submit = this.submit.bind(this);
	}

	getGameType = type => {
		return type.indexOf('EIGHT_BALL') !== -1
			? eightBall
			: type.indexOf('NINE_BALL') !== -1
			? nineBall
			: type.indexOf('TEN_BALL') !== -1
			? tenBall
			: null;
	};

	getLeagues = () => {
		const {
			getLeagues: { leagues, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = leagues.map(league => {
			return {
				label: league.name,
				value: league.id
			};
		});
		return formatted;
	};

	getDivisions = () => {
		const {
			getDivisions: { divisions, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = divisions.map(division => {
			return {
				label: division.name,
				value: division.id,
				avatar: this.getGameType(division.type)
			};
		});
		return formatted;
	};

	getLocations = () => {
		const {
			getLocations: { locations, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = locations.map(location => {
			return {
				label: location.name,
				value: location.id
			};
		});
		return formatted;
	};

	getPlayers = () => {
		const {
			getPlayers: { users, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = users.map(player => {
			return {
				label: player.name,
				value: player.id,
				avatar: player.avatarImg
			};
		});
		return formatted;
	};

	disableButton() {
		this.setState({ canSubmit: false });
	}

	enableButton() {
		this.setState({ canSubmit: true });
	}

	submit(model) {
		console.log(model);
		// fetch('http://example.com/', {
		// 	method: 'post',
		// 	body: JSON.stringify(model)
		// });
		this.setState({
			submitting: true
		});
		if (!this.props.id) {
			this.props
				.createChallenge({
					mutation: createChallengeMutation,
					variables: {
						input: {
							opponentId: model.opponent,
							challengerId: model.challenger || this.context.authUser.id,
							leagueId: model.league,
							divisionId: model.division,
							locationId: model.location,
							gameType: model.gameType,
							raceTo: parseInt(model.raceTo),
							matchFormat: model.matchFormat,
							startTime: model.startTime,
							notes: model.notes
						}
					},
					refetchQueries: [{ query: getChallengeQuery }]
				})
				.then(({ data }) => {
					// console.log(data);
					this.props.history.push(`/challenges/${data.createChallenge.id}`);
				})
				.catch(error => {
					console.log(error);
					this.setState({ submitting: false });
				});
		} else {
			const id = this.props.getChallenge.challenge.id;
			this.props
				.updateChallenge({
					mutation: updateChallengeMutation,
					variables: {
						input: {
							id: id,
							opponentId: model.opponent,
							challengerId: model.challenger || this.context.authUser.id,
							leagueId: model.league,
							divisionId: model.division,
							locationId: model.location,
							gameType: model.gameType,
							raceTo: parseInt(model.raceTo),
							matchFormat: model.matchFormat,
							startTime: model.startTime,
							notes: model.notes
						}
					},
					// refetchQueries: ['GetTeam'],
					refetchQueries: [{ query: getChallengeQuery, variables: { id: id } }]
					// update: (store, { data: { updateTeam } }) => {
					// 	this.props.updateStoreAfterUpdate(store, updateTeam, model.id);
					// }
					// update: (cache, { data: { updateTeam } }) => {
					// 	// const { team } = cache.readQuery({ query: getTeamQuery });
					// 	// console.log(team);
					// 	cache.writeQuery({
					// 		query: getTeamQuery,
					// 		data: { team: updateTeam }
					// 	});
					// }

					// optimisticResponse: {
					// 	__typename: 'Mutation',
					// 	updateTeam: {
					// 		id: model.id,
					// 		__typename: 'Team',
					// 		name: model.name,
					// 		slug: model.slug,
					// 		isActive: model.isActive,
					// 		location: model.location,
					// 		players: model.players
					// 	}
					// }
				})
				.then(({ data }) => {
					console.log(data);
					this.props.history.push(`/challenges/${data.updateChallenge.id}`);
				})
				.catch(error => {
					console.log(error);
					this.setState({ submitting: false });
				});
		}
	}

	render() {
		const {
			classes,
			match,
			getChallenge: { challenge, loading, error }
		} = this.props;

		const parsed = queryString.parse(this.props.location.search);
		const challengerId = parsed.challengerId;
		const opponentId = parsed.opponentId;

		return (
			<React.Fragment>
				{loading === true ? (
					<div>Loading...</div>
				) : (
					<Formsy onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
						<SelectField
							name="challenger"
							label="Challenger"
							value={
								challengerId ||
								(challenge && challenge.challenger.id) ||
								(this.context.authUser && this.context.authUser.id)
							}
							validations="isExisty"
							validationError="Challenger is required"
							options={this.getPlayers()}
							required
						/>

						<SelectField
							name="opponent"
							label="Opponent"
							value={opponentId || (challenge && challenge.opponent.id)}
							validations="isExisty"
							validationError="Opponent is required"
							options={this.getPlayers()}
							required
						/>

						<SelectField
							name="league"
							label="League"
							value={challenge && challenge.league && challenge.league.id}
							validations="isExisty"
							validationError="League is required"
							options={this.getLeagues()}
							required
						/>

						<SelectField
							name="division"
							label="Division"
							value={challenge && challenge.division && challenge.division.id}
							validations="isExisty"
							validationError="Division is required"
							options={this.getDivisions()}
							required
						/>

						<SelectField
							name="location"
							label="Location"
							value={challenge && challenge.location && challenge.location.id}
							validations="isExisty"
							validationError="Location is required"
							options={this.getLocations()}
							required
						/>

						<SelectField
							name="matchFormat"
							label="Match Format"
							value={challenge && challenge.matchFormat}
							options={[
								{
									label: 'Singles',
									value: 'SINGLES'
								},
								{ label: 'Doubles', value: 'DOUBLES' },
								{ label: 'Scotch Doubles', value: 'SCOTCH_DOUBLES' },
								{ label: 'Three Person Teams', value: 'THREE_PERSON_TEAMS' },
								{ label: 'Five Person Teams', value: 'FIVE_PERSON_TEAMS' }
							]}
							required
							validations="isExisty"
							validationError="Match Format is required"
						/>

						<SelectField
							name="gameType"
							label="Game Type"
							value={challenge && challenge.gameType}
							options={[
								{
									label: 'Ten Ball',
									value: 'TEN_BALL'
								},
								{ label: 'Nine Ball', value: 'NINE_BALL' },
								{ label: 'Eight Ball', value: 'EIGHT_BALL' },
								{ label: 'One Pocket', value: 'ONE_POCKET' },
								{
									label: 'American Rotation',
									value: 'AMERICAN_ROTATION'
								},
								{ label: 'Straight Pool', value: 'STRAIGHT_POOL' },
								{ label: 'Mixed', value: 'MIXED' }
							]}
							required
							validations="isExisty"
							validationError="Game Type is required"
						/>

						<TextInput name="raceTo" label="Race To" value={challenge && challenge.raceTo} />

						<DateTimeInput
							name="startTime"
							label="Start Time"
							value={challenge && challenge.startTime ? challenge.startTime : null}
							validations="isExisty"
							validationError="Start Time is required"
							required
						/>

						<TextInput multiline rows={4} name="notes" label="Notes" value={challenge && challenge.notes} />

						<div style={{ marginTop: 20 }}>
							{this.props.id ? (
								<Button
									variant="contained"
									color="primary"
									disabled={!this.state.canSubmit}
									type="submit"
								>
									Update
								</Button>
							) : (
								<Button
									variant="contained"
									color="primary"
									disabled={!this.state.canSubmit}
									type="submit"
								>
									Submit
								</Button>
							)}
						</div>
					</Formsy>
				)}
			</React.Fragment>
		);
	}
}

ChallengeCreateForm.contextTypes = {
	authUser: PropTypes.object,
	getUserProfile: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	changeLoginState: PropTypes.func,
	signIn: PropTypes.func,
	signOut: PropTypes.func
};

const WithGraphQL = compose(
	graphql(getChallengeQuery, {
		options: ({ id }) => ({
			variables: { id },
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'no-cache'
			// refetchQueries: [
			// 	{
			// 		query: getTeamQuery,
			// 		variables: { variables: { slug } }
			// 	}
			// ]
		}),
		name: 'getChallenge'
	}),
	graphql(createChallengeMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'createChallenge'
	}),
	graphql(updateChallengeMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'updateChallenge'
	}),
	graphql(getLeaguesQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getLeagues'
	}),
	graphql(getDivisionsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getDivisions'
	}),
	graphql(getLocationsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getLocations'
	}),
	graphql(getPlayersQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getPlayers'
	})
)(ChallengeCreateForm);

const WithRouter = withRouter(WithGraphQL);
export default withStyles(styles)(WithRouter);
