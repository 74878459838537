import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import qs from 'qs';
import LocationsList from './LocationsList';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import bgImage from '../../assets/images/abstract5.jpg';
import { getLocationsQuery } from './queries/getLocationsQuery';
import { SectionHeader } from '../../components/chrome';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	},
	headerLight: {
		marginTop: 0,
		marginBottom: 20,
		textAlign: 'left',
		display: 'flex',
		color: 'black',
		fontFamily: 'din_lightregular, Roboto, sans-serif',
		fontWeight: 400,
		fontSize: 30,
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	titleBar: {
		backgroundColor: '#14171d',
		borderRadius: 0,
		backgroundImage: `url(${bgImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100%',
		backgroundPosition: 'right'
	},
	heading: {
		margin: 0,
		textAlign: 'center',
		display: 'flex',
		color: 'white',
		fontFamily: 'din_medium, Roboto, sans-serif',
		alignItems: 'center',
		justifyContent: 'space-between'
		// fontWeight: 300
	},

	headerBar: {
		paddingTop: 30,
		paddingBottom: 30
	}
});

class LocationsContainer extends Component {
	render() {
		const { classes, title, match, history, location } = this.props;
		const params = qs.parse(location.search.slice(1), { strictNullHandling: true });

		let newOrder;
		if (params.order) {
			newOrder = [];
			const orderSplit = params.order.split(',');
			newOrder.push([orderSplit[0], orderSplit[1]]);
		}

		let offset = parseFloat(params.offset) || 0;
		let limit = parseFloat(params.limit) || 10;

		return (
			<SectionHeader
				title="Locations"
				rightButton={
					<Button variant="outlined" color="secondary" onClick={() => history.push('/locations/new')}>
						Create Location
					</Button>
				}
			>
				<Query
					query={getLocationsQuery}
					variables={{
						where: null,
						offset: offset,
						limit: limit,
						order: newOrder || [['name', 'ASC']]
					}}
					fetchPolicy="cache-and-network"
					notifyOnNetworkStatusChange={true}
				>
					{({ loading, error, data, subscribeToMore, fetchMore }) => {
						if (loading) return <LinearProgress mode="indeterminate" color="primary" />;
						if (error) return <div style={styles.container}>Error: {error.message}</div>;

						return (
							<div style={{ marginTop: 24 }}>
								<Grid container justify="center" spacing={24}>
									<Grid item xs={12} sm={12} md={11} lg={10} style={{ overflow: 'auto' }}>
										<LocationsList
											data={data}
											subscribeToMore={subscribeToMore}
											match={match}
											history={history}
											title={title}
											limit={limit}
											offset={offset}
											loading={loading}
											error={error}
											onLoadMore={() =>
												fetchMore({
													variables: {
														offset: data.locations.length
														// offset: offset + limit
													},
													updateQuery: (prev, { fetchMoreResult }) => {
														if (!fetchMoreResult) return prev;
														return Object.assign({}, prev, {
															locations: [...prev.locations, ...fetchMoreResult.locations]
														});
													}
												})}
										/>
									</Grid>
								</Grid>
							</div>
						);
					}}
				</Query>
			</SectionHeader>
		);
	}
}

LocationsContainer.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LocationsContainer);
