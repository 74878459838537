import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';
import * as routes from '../config/routes';
import { signIn, signOut, getToken } from './authUtils';

const currentUserQuery = gql`
	query {
		currentUser {
			id
			name
			username
			email
			avatarImg
			playerNumber
			online
			firstRun
			token
			divisions {
				id
				name
				slug
			}
		}
	}
`;

export default ComponentToExtend => {
	class CurrentUserHOC extends Component {
		// componentDidMount() {
		// 	const {
		// 		currentUserData: { loading, error, currentUser, refetch, networkStatus },
		// 		history,
		// 		location
		// 	} = this.props;

		// 	const currentLocation = location.pathname;

		// 	console.log({
		// 		loading,
		// 		currentUser
		// 	});

		// 	if (!loading && !currentUser) {
		// 		if (currentLocation !== routes.SIGN_IN && currentLocation !== routes.SIGN_UP) {
		// 			history.push(routes.SIGN_IN);
		// 		}
		// 	}

		// 	if (error) {
		// 		signOut();
		// 		history.push(routes.SIGN_IN);
		// 		return `Error!: ${error}`;
		// 	}
		// }

		shouldComponentUpdate(nextProps, nextState) {
			if (nextProps.currentUserData !== this.props.currentUserData) {
				return true;
			} else {
				return false;
			}
		}

		refresh = () => {
			const currentUser = this.props.currentUserData.refetch();
			return currentUser;
		};

		render() {
			const {
				currentUserData: { loading, error, currentUser, refetch, networkStatus },
				history,
				...rest
			} = this.props;

			if (networkStatus === 4) return null;
			if (loading) return null;

			// console.log(currentUser);

			// if (error) {
			// 	signOut();
			// 	// history.push(routes.SIGN_IN);
			// 	return `Error!: ${error}`;
			// }

			let user = {
				...currentUser,
				refresh: refetch
			};

			return <ComponentToExtend currentUser={user} {...rest} />;
		}
	}

	CurrentUserHOC.contextTypes = {
		router: PropTypes.object.isRequired
	};

	return graphql(currentUserQuery, {
		name: 'currentUserData',
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-first', notifyOnNetworkStatusChange: false })
	})(withRouter(CurrentUserHOC));
};
