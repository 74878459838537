import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import TeamsTable from './TeamsTable';
import client from '../../config/apolloClient';
import qs from 'qs';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Hidden from '@material-ui/core/Hidden';
import LinearProgress from '@material-ui/core/LinearProgress';
import { SectionHeader, SectionContent } from '../../components/chrome';
import { getTeamsQuery } from './queries/getTeamsQuery';
import FlashMassage from 'react-flash-message';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const GET_TEAMS_META = gql`
	query {
		_allTeamsMeta {
			count
		}
	}
`;

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	},

	margin: {
		[theme.breakpoints.up('sm')]: {
			marginTop: 24,
			marginBottom: 24
		}
	}
});

class TeamsContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			count: 0
		};
	}

	componentDidMount() {
		client
			.query({
				query: gql`
					{
						_allTeamsMeta {
							count
						}
					}
				`
			})
			.then(response => {
				const data = response.data;
				this.setState({
					count: data._allTeamsMeta.count
				});
			});
	}

	render() {
		const { classes, title, match, history, location } = this.props;
		const params = qs.parse(location.search.slice(1), { strictNullHandling: true });

		let newOrder;
		if (params.order) {
			newOrder = [];
			const orderSplit = params.order.split(',');
			newOrder.push([orderSplit[0], orderSplit[1]]);
		}

		let offset = parseFloat(params.offset) || 0;
		let limit = parseFloat(params.limit) || 10;

		// const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

		return (
			<SectionHeader
				title="Teams"
				rightButton={
					<Hidden smDown>
						<Button variant="outlined" color="secondary" onClick={() => history.push('/teams/new')}>
							Create Team
						</Button>
					</Hidden>
				}
			>
				<Query
					query={getTeamsQuery}
					variables={{
						where: null,
						offset: offset,
						limit: limit,
						order: newOrder || [['name', 'ASC']]
					}}
					fetchPolicy="cache-and-network"
					notifyOnNetworkStatusChange={true}
				>
					{({ loading, error, data, subscribeToMore, fetchMore }) => {
						// if (loading) return <LinearProgress mode="indeterminate" color="primary" />;
						// if (error) return <div style={styles.container}>Error: {error.message}</div>;

						return (
							<SectionContent style={{ overflow: 'auto' }}>
								{/*<FlashMassage duration={5000} persistOnHover={true}>
									<Paper elevation={0} style={{ padding: 20, marginBottom: 20 }}>
										<Typography component="p">
											Paper can be used to build surface or other elements for your application.
										</Typography>
									</Paper>
						</FlashMassage>*/}
								<TeamsTable
									data={data}
									subscribeToMore={subscribeToMore}
									match={match}
									history={history}
									count={this.state.count}
									limit={limit}
									offset={offset}
									loading={loading}
									error={error}
									onLoadMore={() =>
										fetchMore({
											variables: {
												offset: data.teams.length,
												limit: 100
												// offset: offset + limit
											},
											updateQuery: (prev, { fetchMoreResult }) => {
												if (!fetchMoreResult) return prev;
												return Object.assign({}, prev, {
													teams: [...prev.teams, ...fetchMoreResult.teams]
												});
											}
										})}
								/>
							</SectionContent>
						);
					}}
				</Query>
				<Hidden mdUp>
					<Button
						variant="fab"
						color="primary"
						aria-label="add"
						style={{ position: 'fixed', bottom: 20, right: 20 }}
						onClick={() => history.push('/teams/new')}
						className="scale-transition"
					>
						<AddIcon />
					</Button>
				</Hidden>
			</SectionHeader>
		);
	}
}

TeamsContainer.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TeamsContainer);
