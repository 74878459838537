import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { withFormsy } from 'formsy-react';

const styles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	formControl: {
		marginTop: 8,
		marginBottom: 4,
		width: '100%',
		maxWidth: 400,
		backgroundColor: '#fafafa'
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	chip: {
		margin: theme.spacing.unit / 4
	},
	icon: {
		width: 30,
		height: 30
	},
	avatar: {
		backgroundColor: '#44475b',
		color: '#fff'
	}
});

const InputFeedback = ({ children }) => <span className="text-danger">{children}</span>;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	}
};

class MultipleSelect extends PureComponent {
	constructor(props) {
		super(props);
		const { name, value } = props;
		this.state = {
			selected: value || []
		};

		this.changeValue = this.changeValue.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	changeValue(event) {
		this.setState({ selected: event.target.value });
		// setValue() will set the value of the component, which in
		// turn will validate it and the rest of the form
		// Important: Don't skip this step. This pattern is required
		// for Formsy to work.
		this.props.setValue(event.target.value);
	}

	handleDelete(event, val) {
		this.setState(prevState => ({ selected: prevState.selected.filter(item => item !== val) }));
		const filtered = this.state.selected.filter(item => item !== val);
		this.props.setValue(filtered);
	}

	render() {
		const {
			classes,
			value,
			avatar,
			options,
			name,
			label,
			getValue,
			getErrorMessage,
			autoFocus,
			required
		} = this.props;
		const errorMessage = getErrorMessage();

		const val = getValue() || [];

		return (
			<div className={classes.root}>
				<FormControl className={classes.formControl}>
					<InputLabel htmlFor={`${name}-helper`}>{label}</InputLabel>
					<Select
						multiple
						error={Boolean(errorMessage)}
						id={name}
						name={name}
						label={label}
						value={val}
						onChange={this.changeValue}
						input={<Input name={name} id={`${name}-helper`} />}
						className="form-control"
						autoComplete={name}
						autoFocus={autoFocus ? true : false}
						required={required ? true : false}
						renderValue={selected => (
							<div className={classes.chips}>
								{selected.map(val => {
									const filteredLabel =
										options && options.length
											? options.filter(option => option.value === val).map(option => option.label)
											: [];

									const filteredAvatar =
										options && options.length
											? options
													.filter(option => option.value === val)
													.map(option => option.avatar)
											: '';
									return (
										<Chip
											key={val}
											label={filteredLabel}
											clickable
											className={classes.chip}
											avatar={
												avatar ? (
													avatar
												) : (
													<Avatar
														size="small"
														className={classes.avatar}
														src={filteredAvatar[0]}
													>
														{filteredLabel[0] && filteredLabel[0].slice(0, 1)}
													</Avatar>
												)
											}
											onDelete={event => this.handleDelete(event, val)}
										/>
									);
								})}
							</div>
						)}
					>
						{options &&
							options.map((item, index) => (
								<MenuItem
									key={index}
									value={item.value}
									// style={{
									// 	fontWeight:
									// 		this.state.name.indexOf(item.value) === -1
									// 			? theme.typography.fontWeightRegular
									// 			: theme.typography.fontWeightMedium
									// }}
								>
									{/*<ListItemIcon className={classes.icon}>
										<Avatar size="small" src={item.avatar} />
              </ListItemIcon>*/}
									<Checkbox checked={this.state.selected.indexOf(item.value) > -1} color="primary" />
									<ListItemText inset primary={item.label} />
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<InputFeedback>{errorMessage}</InputFeedback>
			</div>
		);
	}
}

MultipleSelect.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles, { withTheme: true })(MultipleSelect);
export default withFormsy(WithStyles);
