import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Formsy from 'formsy-react';
import TextInput from '../../components/forms/TextInputFormsy';
import SelectField from '../../components/forms/SelectFormsy';
import SwitchField from '../../components/forms/SwitchFormsy';
import MultiSelectField from '../../components/forms/MultiSelectFormsy';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import Avatar from '@material-ui/core/Avatar';
import LocationOn from '@material-ui/icons/LocationOn';
import isEmpty from 'lodash/isEmpty';
import { getLeagueQuery } from './queries/getLeagueQuery';
import { updateLeagueMutation } from './mutations/updateLeagueMutation';
import { createLeagueMutation } from './mutations/createLeagueMutation';
import eightBall from '../../assets/images/ball/eight-ball.png';
import nineBall from '../../assets/images/ball/nine-ball.png';
import tenBall from '../../assets/images/ball/ten-ball.png';

export const getLocationsQuery = gql`
	query GetLocations {
		locations {
			id
			name
			slug
		}
	}
`;

export const getDivisionsQuery = gql`
	query GetDivisions {
		divisions {
			id
			name
			slug
		}
	}
`;

export const getTeamsQuery = gql`
	query GetTeams {
		teams {
			id
			name
			slug
		}
	}
`;

export const getPlayersQuery = gql`
	query GetPlayers {
		users {
			id
			name
			username
			avatarImg
		}
	}
`;

const styles = theme => ({
	error: {
		color: '#f44336'
	},
	content: {
		// padding: '10px 24px 20px 24px'
		// maxWidth: 600
	},
	input: {
		marginTop: 5
	},
	submit: {
		marginTop: 10
	},
	selectEmpty: {
		marginTop: theme.spacing.unit * 2
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	},
	avatar: {
		backgroundColor: '#44475b',
		color: '#fff'
	}
});

class LeagueCreateEditForm extends React.Component {
	constructor(props) {
		super(props);
		this.disableButton = this.disableButton.bind(this);
		this.enableButton = this.enableButton.bind(this);
		this.state = { canSubmit: false, submitting: false };
		this.submit = this.submit.bind(this);
	}

	getGameType = type => {
		return type.indexOf('EIGHT_BALL') !== -1
			? eightBall
			: type.indexOf('NINE_BALL') !== -1 ? nineBall : type.indexOf('TEN_BALL') !== -1 ? tenBall : null;
	};

	getLocations = () => {
		const { getLocations: { locations, loading, error } } = this.props;
		if (loading) return null;
		const formatted = locations.map(location => {
			return {
				label: location.name,
				value: location.id
			};
		});
		return formatted;
	};

	getTeams = () => {
		const { getTeams: { teams, loading, error } } = this.props;
		if (loading) return null;
		const formatted = teams.map(team => {
			return {
				label: team.name,
				value: team.id
			};
		});
		return formatted;
	};

	getDivisions = () => {
		const { getDivisions: { divisions, loading, error } } = this.props;
		if (loading) return null;
		const formatted = divisions.map(division => {
			return {
				label: division.name,
				value: division.id
			};
		});
		return formatted;
	};

	getPlayers = () => {
		const { getPlayers: { users, loading, error } } = this.props;
		if (loading) return null;
		const formatted = users.map(player => {
			return {
				label: player.name,
				value: player.id,
				avatar: player.avatarImg
			};
		});
		return formatted;
	};

	disableButton() {
		this.setState({ canSubmit: false });
	}

	enableButton() {
		this.setState({ canSubmit: true });
	}

	submit(model) {
		console.log(model);
		// fetch('http://example.com/', {
		// 	method: 'post',
		// 	body: JSON.stringify(model)
		// });
		this.setState({
			submitting: true
		});
		if (!this.props.slug) {
			this.props
				.createLeague({
					mutation: createLeagueMutation,
					variables: {
						input: {
							name: model.name,
							type: model.type,
							ownerId: this.context.authUser.id,
							operatorId: this.context.authUser.id,
							locationIds: model.locations,
							divisionIds: model.divisions,
							teamIds: model.teams,
							userIds: model.players,
							address1: model.address1,
							address2: model.address2,
							city: model.city,
							state: model.state,
							zip: model.zip,
							county: model.county,
							country: model.country,
							phone: model.phone,
							fax: model.fax,
							email: model.email,
							website: model.website,
							facebook: model.facebook,
							twitter: model.twitter,
							instagram: model.instagram,
							logo: model.logo
						}
					},
					refetchQueries: [{ query: getLeagueQuery }]
				})
				.then(({ data }) => {
					// console.log(data);
					this.props.history.push(`/leagues/${data.createLeague.slug}`);
				})
				.catch(error => {
					console.log(error);
					this.setState({ submitting: false });
				});
		} else {
			const id = this.props.getLeague.league.id;
			this.props
				.updateLeague({
					mutation: updateLeagueMutation,
					variables: {
						input: {
							id: id,
							name: model.name,
							type: model.type,
							ownerId: this.context.authUser.id,
							operatorId: this.context.authUser.id,
							locationIds: model.locations,
							divisionIds: model.divisions,
							teamIds: model.teams,
							userIds: model.players,
							address1: model.address1,
							address2: model.address2,
							city: model.city,
							state: model.state,
							zip: model.zip,
							county: model.county,
							country: model.country,
							phone: model.phone,
							fax: model.fax,
							email: model.email,
							website: model.website,
							facebook: model.facebook,
							twitter: model.twitter,
							instagram: model.instagram,
							logo: model.logo
						}
					},
					refetchQueries: [{ query: getLeagueQuery, variables: { id: id } }]
				})
				.then(({ data }) => {
					console.log(data);
					this.props.history.push(`/leagues/${data.updateLeague.slug}`);
				})
				.catch(error => {
					console.log(error);
					this.setState({ submitting: false });
				});
		}
	}

	render() {
		const { classes, getLeague: { league, loading, error } } = this.props;

		return (
			<React.Fragment>
				{loading === true ? (
					<div>Loading...</div>
				) : (
					<Formsy onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
						<TextInput
							name="name"
							label="Name"
							value={league && league.name}
							validations="isExisty"
							validationError="Name is required"
							required
							autoFocus
						/>

						<SelectField
							name="type"
							label="Type"
							value={league && league.type}
							options={[
								{
									label: 'APA',
									value: 'APA'
								},
								{ label: 'BCAPL', value: 'BCAPL' },
								{ label: 'USAPL', value: 'USAPL' },
								{ label: 'DPL', value: 'DPL' },
								{ label: 'NAPA', value: 'NAPA' },
								{ label: 'UPL', value: 'UPL' },
								{ label: 'TAP', value: 'TAP' },
								{ label: 'CSL', value: 'CSL' }
							]}
							required
							validations="isExisty"
							validationError="Type is required"
						/>

						<MultiSelectField
							name="locations"
							label="Locations"
							value={
								league &&
								league.locations.map(location => {
									return location.id;
								})
							}
							avatar={
								<Avatar size="small" className={classes.avatar}>
									<LocationOn
										style={{
											height: 22
										}}
									/>
								</Avatar>
							}
							options={this.getLocations()}
						/>

						<MultiSelectField
							name="divisions"
							label="Divisions"
							value={
								league &&
								league.divisions.map(division => {
									return division.id;
								})
							}
							options={this.getDivisions()}
						/>

						<MultiSelectField
							name="teams"
							label="Teams"
							value={
								league &&
								league.teams.map(team => {
									return team.id;
								})
							}
							options={this.getTeams()}
						/>

						<MultiSelectField
							name="players"
							label="Players"
							value={
								league &&
								league.players.map(player => {
									return player.id;
								})
							}
							options={this.getPlayers()}
						/>

						<TextInput name="address1" label="Address 1" value={league && league.address1} />

						<TextInput name="address2" label="Address 2" value={league && league.address2} />

						<TextInput name="city" label="City" value={league && league.city} />

						<TextInput name="state" label="State" value={league && league.state} />

						<TextInput name="zip" label="Zip" value={league && league.zip} />

						<TextInput name="country" label="Country" value={league && league.country} />

						<TextInput name="phone" label="Phone" value={league && league.phone} />

						<TextInput name="fax" label="Fax" value={league && league.fax} />

						<TextInput name="email" label="Email" value={league && league.email} />

						<TextInput name="website" label="Website" value={league && league.website} />

						<TextInput name="facebook" label="Facebook" value={league && league.facebook} />

						<TextInput name="twitter" label="Twitter" value={league && league.twitter} />

						<TextInput name="instagram" label="Instagram" value={league && league.instagram} />

						<TextInput name="logo" label="Logo" value={league && league.logo} />

						<div style={{ marginTop: 20 }}>
							{this.props.slug ? (
								<Button
									variant="contained"
									color="primary"
									disabled={!this.state.canSubmit}
									type="submit"
								>
									Update
								</Button>
							) : (
								<Button
									variant="contained"
									color="primary"
									disabled={!this.state.canSubmit}
									type="submit"
								>
									Create
								</Button>
							)}
						</div>
					</Formsy>
				)}
			</React.Fragment>
		);
	}
}

LeagueCreateEditForm.contextTypes = {
	authUser: PropTypes.object,
	getUserProfile: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	changeLoginState: PropTypes.func,
	signIn: PropTypes.func,
	signOut: PropTypes.func
};

const WithGraphQL = compose(
	graphql(getLeagueQuery, {
		options: ({ slug }) => ({
			variables: { slug },
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'no-cache'
			// refetchQueries: [
			// 	{
			// 		query: getTeamQuery,
			// 		variables: { variables: { slug } }
			// 	}
			// ]
		}),
		name: 'getLeague'
	}),
	graphql(createLeagueMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'createLeague'
	}),
	graphql(updateLeagueMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'updateLeague'
	}),
	graphql(getLocationsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getLocations'
	}),
	graphql(getTeamsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getTeams'
	}),
	graphql(getPlayersQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getPlayers'
	}),
	graphql(getDivisionsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getDivisions'
	})
)(LeagueCreateEditForm);

const WithRouter = withRouter(WithGraphQL);
export default withStyles(styles)(WithRouter);
