import gql from 'graphql-tag';

export const getPlayerQuery = gql`
	query GetPlayer($id: ID, $slug: String) {
		user(id: $id, slug: $slug) {
			id
			name
			slug
			username
			email
			playerNumber
			playerRatings {
				id
				type
				rating
				customName
			}
			leagues {
				id
				name
				slug
				type
			}
			isActive
			avatarImg
			matches {
				id
			}
			tournaments {
				id
				name
				slug
				startTime
			}
			teams {
				id
				name
				slug
				divisions {
					id
				}
			}
			divisions {
				id
				name
				slug
				type
			}
			role
			address
			city
			state
			zip
			country
			phone
			joinedAt
			createdAt
		}
	}
`;
