import React from 'react';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

const styles = {
	card: {
		padding: 30,
		marginTop: 24,
		marginBottom: 24,
		border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	}
};

const Stats = ({ match, tournament }) => (
	<Grid container justify="center">
		<Grid item xs={12} sm={12} md={11} lg={10}>
			<div className="main-content">
				<Card style={styles.card} elevation={0}>
					<h5>Stats</h5>
					<pre>{JSON.stringify(match, null, 2)}</pre>
					<pre>{JSON.stringify(tournament, null, 2)}</pre>
				</Card>
			</div>
		</Grid>
	</Grid>
);

export default Stats;
