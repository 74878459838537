import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const styles = theme => ({
	root: {
		color: '#999'
	},
	selected: {
		color: '#fff'
	}
});

class FilterToggleButtons extends React.Component {
	state = {
		selected: 'all'
	};

	handleToggle = (event, selected) => this.setState({ selected });

	render() {
		const { classes } = this.props;
		const { selected } = this.state;

		return (
			<ToggleButtonGroup
				value={selected}
				exclusive
				onChange={this.handleToggle}
				style={{ background: 'none', boxShadow: 'none' }}
				className={classes.selected}
			>
				<ToggleButton
					value="upcoming"
					className={selected === 'upcoming' ? classNames(classes.root, classes.selected) : classes.root}
				>
					Upcoming
				</ToggleButton>
				<ToggleButton
					value="completed"
					className={selected === 'completed' ? classNames(classes.root, classes.selected) : classes.root}
				>
					Completed
				</ToggleButton>
				<ToggleButton
					value="all"
					disabled={false}
					className={selected === 'all' ? classNames(classes.root, classes.selected) : classes.root}
				>
					All
				</ToggleButton>
			</ToggleButtonGroup>
		);
	}
}

FilterToggleButtons.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FilterToggleButtons);
