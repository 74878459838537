import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import CommunicationChatBubble from '@material-ui/icons/ChatBubble';
import _ from 'lodash';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

const styles = theme => ({
	containerStyle: {
		// backgroundColor: '#131825'
	},
	menuItem: {
		// fontWeight: 700,
		paddingLeft: 21,
		'&:focus': {
			backgroundColor: theme.palette.secondary.main,
			'& $primary, & $icon': {
				color: theme.palette.common.black
			}
		}
	},
	selected: {
		paddingLeft: 18,
		backgroundColor: 'rgba(255,203,1,0.1)',
		color: '#ffcb01',
		borderLeft: '3px solid #ffcb01',

		'& $primary': {
			fontWeight: 700
		},
		'& $primary, & $icon': {
			color: theme.palette.secondary.main
		},
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.08)'
		}
	},
	primary: {
		fontWeight: 400
	},
	icon: {}
});
class ConversationList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedIndex: 0
		};
		this.handleListItemClick = this.handleListItemClick.bind(this);
	}

	componentDidMount(nextProps) {
		const messages = this.props.data.conversations[0].messages;
		this.props.onConversationClicked(messages);
	}

	handleListItemClick(messages, index) {
		this.setState({ selectedIndex: index });
		this.props.onConversationClicked(messages);
	}

	render() {
		const { classes, data, selectedIndex, onConversationClicked } = this.props;
		return (
			<List style={{ borderRight: '1px solid #eee', overflowY: 'auto' }}>
				{data.conversations &&
					data.conversations.map(({ id, participants, time, messages, name }, index) => {
						// const userId1 = participants[0].user_id;
						// const userId2 = participants[1].user_id;
						const lastParticipant = participants[participants.length - 1];
						const lastMessage = messages[messages.length - 1];
						const lastParticipantName = lastParticipant && lastParticipant.name.split(' ')[0];

						let avatars = participants.map((participant, index) => {
							return participant.avatarImg;
						});

						if (participants.length > 4) {
							avatars.slice(participants.length - 4);
						}

						return (
							<ListItem
								key={id}
								value={index}
								button
								selected={this.state.selectedIndex === index}
								onClick={() => this.handleListItemClick(messages, index)}
								className={this.state.selectedIndex === index ? classes.selected : classes.menuItem}
								// leftAvatar={<Avatar src={`/images/avatars/${lastMessage && lastMessage.from.avatarImg}`} />}
								// rightAvatar={
								// 	<div style={{ fontSize: 10 }}>{lastMessage && moment(lastMessage.updatedAt).fromNow()}</div>
								// }
							>
								<React.Fragment>
									{/*{avatars.map((avatar, index) => {
										if (index > 0) {
											return (
												<Avatar
													key={index}
													src={avatar}
													style={{ position: 'relative', marginLeft: -35 }}
												/>
											);
										} else {
											return <Avatar key={index} src={avatar} />;
										}
									})}*/}
									<div style={{ display: 'flex', alignItems: 'center' }}>
										{lastMessage &&
											lastMessage.read === false && (
												<span
													style={{
														backgroundColor: '#ffcb02',
														display: 'inline-block',
														width: 8,
														height: 8,
														borderRadius: '50%',
														overflow: 'hidden',
														marginRight: 10
													}}
												/>
											)}
										{lastMessage ? <Avatar src={lastMessage.from.avatarImg} /> : <Avatar>-</Avatar>}
									</div>

									<ListItemText
										primary={
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'space-between'
												}}
											>
												<span>
													{lastMessage && participants.length > 1
														? `${lastMessage.from.name} and ${participants.length -
																1} others`
														: lastMessage ? lastMessage.from.name : 'N/A'}
												</span>

												<span style={{ fontSize: 11 }}>
													{lastMessage && moment(lastMessage.updatedAt).fromNow()}
												</span>
											</div>
										}
										secondary={
											lastMessage &&
											`${lastParticipantName}: ${_.truncate(lastMessage.text, { length: 30 })}`
										}
									/>
								</React.Fragment>
								{/*<ListItemSecondaryAction style={{ marginRight: 24 }}>
							<div style={{ marginTop: -30, fontSize: 10 }}>
								{lastMessage && moment(lastMessage.createdAt).format('ll')}
							</div>
						</ListItemSecondaryAction>*/}
							</ListItem>
							// <Query
							// 	key={id}
							// 	query={gql`
							//   {
							//     user(id: ${lastMessage.user.id}) {
							//       id
							//       name
							//       email
							//       avatar_img
							//     }
							//   }
							// `}
							// >
							// 	{({ loading, error, data }) => {
							// 		if (loading) {
							// 			return 'Loading...';
							// 		} else if (error) {
							// 			return `Error! ${error.message}`;
							// 		} else {
							// 			const { id, name, email, avatar_img } = data.user;
							// 			return (
							// 				<ListItem
							// 					key={id}
							// 					value={index}
							// 					onClick={() => onConversationClicked(messages)}
							// 					primaryText={name}
							// 					secondaryText={lastMessage.body}
							// 					leftAvatar={<Avatar src={`/images/avatars/${avatar_img}`} />}
							// 					rightAvatar={<div>{moment(lastMessage.time * 1000).fromNow()}</div>}
							// 				/>
							// 			);
							// 		}
							// 	}}
							// </Query>
						);
					})}
			</List>
		);
	}
}

ConversationList.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles)(ConversationList);

export default WithStyles;
