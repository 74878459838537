import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import MatchesList from './MatchesList';
import client from '../../config/apolloClient';
import qs from 'qs';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import bgImage from '../../assets/images/abstract5.jpg';
import { getMatchesQuery } from './queries/getMatchesQuery';
import { SectionHeader, FilterToggleButtons } from '../../components/chrome';
import LinearProgress from '@material-ui/core/LinearProgress';
import currentUser from '../../helpers/currentUser';

const GET_MATCHES_META = gql`
	query {
		_allTeamsMeta {
			count
		}
	}
`;

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	},
	headerLight: {
		marginTop: 0,
		marginBottom: 20,
		textAlign: 'left',
		display: 'flex',
		color: 'black',
		fontFamily: 'din_lightregular, Roboto, sans-serif',
		fontWeight: 400,
		fontSize: 30,
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	titleBar: {
		backgroundColor: '#14171d',
		borderRadius: 0,
		backgroundImage: `url(${bgImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100%',
		backgroundPosition: 'right'
	},
	heading: {
		margin: 0,
		textAlign: 'center',
		display: 'flex',
		color: 'white',
		fontFamily: 'din_medium, Roboto, sans-serif',
		alignItems: 'center',
		justifyContent: 'space-between'
		// fontWeight: 300
	},
	headerBar: {
		paddingTop: 30,
		paddingBottom: 30
	},
	label: {
		fontSize: 13
		// fontWeight: 400
	},
	textColorPrimary: {
		color: '#fff'
	},
	textColorSecondary: {
		color: '#999',
		'&:hover': {
			color: '#fff',
			transition: 'all 300ms cubic-bezier(0.4, 0.0, 0.2, 1)'
		}
	},
	tab: {
		maxWidth: 150
	}
});

class MatchesContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			count: 0,
			selectedTab: 0
		};
		this.handleChange = this.handleChange.bind(this);
	}

	// componentDidMount() {
	// 	client
	// 		.query({
	// 			query: gql`
	// 				{
	// 					_allTeamsMeta {
	// 						count
	// 					}
	// 				}
	// 			`
	// 		})
	// 		.then(response => {
	// 			const data = response.data;
	// 			this.setState({
	// 				count: data._allTeamsMeta.count
	// 			});
	// 		});
	// }

	handleChange(event, selectedTab) {
		this.setState({ selectedTab });
	}

	render() {
		const { selectedTab } = this.state;
		const { classes, title, match, history, location } = this.props;
		const params = qs.parse(location.search.slice(1), { strictNullHandling: true });

		let newOrder;
		if (params.order) {
			newOrder = [];
			const orderSplit = params.order.split(',');
			newOrder.push([orderSplit[0], orderSplit[1]]);
		}

		// const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

		return (
			<SectionHeader
				title="Matches"
				tabs={
					<Tabs
						value={selectedTab}
						onChange={this.handleChange}
						indicatorColor="secondary"
						textColor="secondary"
						scrollable
						scrollButtons="off"
						// className={classes.tabContainer}
						fullWidth
					>
						<Tab
							label="Upcoming"
							classes={{
								root: classes.tab,
								label: classes.label,
								textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
								textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
							}}
						/>
						<Tab
							label="Completed"
							classes={{
								root: classes.tab,
								label: classes.label,
								textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
								textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
							}}
						/>
						<Tab
							label="All"
							classes={{
								root: classes.tab,
								label: classes.label,
								textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
								textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
							}}
						/>
					</Tabs>
				}
				rightButton={
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						{/*<FilterToggleButtons />*/}
						<Button
							variant="outlined"
							color="secondary"
							onClick={() => history.push('/matches/new')}
							style={{ marginLeft: 20 }}
						>
							Create Match
						</Button>
					</div>
				}
			>
				<Query
					query={getMatchesQuery}
					variables={{
						where:
							selectedTab === 0
								? { status: 'SCHEDULED' }
								: selectedTab === 1 ? { status: 'COMPLETED' } : null,

						offset: params.offset || 0,
						limit: params.limit || 10,
						order: newOrder || [['createdAt', 'DESC']]
					}}
					fetchPolicy="cache-and-network"
					notifyOnNetworkStatusChange={true}
				>
					{({ loading, error, data, subscribeToMore }) => {
						if (loading) return <LinearProgress mode="indeterminate" color="secondary" />;
						if (error) return <div style={styles.container}>Error: {error.message}</div>;

						return (
							<div style={{ marginTop: 24 }}>
								<Grid container justify="center" spacing={24}>
									<Grid item xs={12} sm={12} md={11} lg={10} style={{ overflow: 'auto' }}>
										<MatchesList
											data={data}
											subscribeToMore={subscribeToMore}
											match={match}
											history={history}
											count={this.state.count}
										/>
									</Grid>
								</Grid>
							</div>
						);
					}}
				</Query>
			</SectionHeader>
		);
	}
}

MatchesContainer.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithRouter = withRouter(MatchesContainer);
const WithStyles = withStyles(styles)(WithRouter);
export default currentUser(WithStyles);
