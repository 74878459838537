import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { withFormsy } from 'formsy-react';

const styles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	formControl: {
		marginTop: 8,
		marginBottom: 4,
		width: '100%',
		maxWidth: 400,
		backgroundColor: '#fafafa'
	}
});

const InputFeedback = ({ children }) => <span className="text-danger">{children}</span>;

class SelectField extends Component {
	constructor(props) {
		super(props);
		this.changeValue = this.changeValue.bind(this);
	}
	changeValue(event) {
		// setValue() will set the value of the component, which in
		// turn will validate it and the rest of the form
		// Important: Don't skip this step. This pattern is required
		// for Formsy to work.
		this.props.setValue(event.target.value);
	}

	render() {
		const { classes, value, options, name, label, getValue, getErrorMessage, autoFocus, required } = this.props;
		const errorMessage = getErrorMessage();
		// const selectedValue = value && value.hasOwnProperty('id') ? value.id : value;

		return (
			<div className={classes.root}>
				<FormControl className={classes.formControl}>
					<InputLabel htmlFor={`${name}-helper`}>{label}</InputLabel>
					<Select
						error={Boolean(errorMessage)}
						id={name}
						name={name}
						label={label}
						value={getValue() ? getValue().toString() : ''}
						// renderValue={value => {
						// 	const selected = options.filter(option => {
						// 		if (option.value === value.toString()) {
						// 			return option;
						// 		}
						// 	});
						// 	return selected[0].label;
						// }}
						onChange={this.changeValue}
						// onChange={this.handleChange}
						input={<Input name={name} id={`${name}-helper`} />}
						// fullWidth
						// displayEmpty
						className="form-control"
						autoComplete={name}
						autoFocus={autoFocus ? true : false}
						required={required ? true : false}
					>
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
						{options &&
							options.map((item, index) => (
								<MenuItem key={index} value={item.value} label={item.label}>
									{item.label}
								</MenuItem>
							))}
					</Select>
				</FormControl>
				<InputFeedback>{errorMessage}</InputFeedback>
			</div>
		);
	}
}

SelectField.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles)(SelectField);
export default withFormsy(WithStyles);
