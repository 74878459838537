import gql from 'graphql-tag';

export const createLeagueMutation = gql`
	mutation CreateLeague($input: LeagueInput) {
		createLeague(input: $input) {
			id
			name
			slug
			type
			owner {
				id
				name
				slug
				username
				avatarImg
			}
			operator {
				id
				name
				slug
				username
				avatarImg
			}
			locations {
				id
				name
				slug
				address
				city
				state
				zip
				country
				lat
				lon
			}
			divisions {
				id
				name
				slug
			}
			teams {
				id
				name
				slug
			}
			players {
				id
				name
				username
				avatarImg
				slug
			}
			address1
			address2
			city
			state
			zip
			county
			country
			phone
			fax
			email
			website
			facebook
			twitter
			instagram
			logo
			createdAt
		}
	}
`;
