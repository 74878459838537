export const LANDING = '/landing';
export const SIGN_UP = '/register';
export const SIGN_IN = '/login';
export const HOME = '/';
export const ACCOUNT = '/account';
export const PROFILE = '/profile';
export const PASSWORD_FORGET = '/pw-forget';
export const PASSWORD_CHANGE = '/pw-change';
export const MATCHES = '/matches';
export const STATS = '/stats';
export const STATS_ME = '/stats/me';
export const STATS_TEAM = '/stats/team';
export const TOURNAMENTS = '/tournaments';
export const LEAGUES = '/leagues';
export const LEAGUE_CREATE = '/leagues/new';
export const DIVISIONS = '/divisions';
export const DIVISION_CREATE = '/divisions/new';
export const TEAMS = '/teams';
export const CHALLENGES = '/challenges';
export const NOTIFICATIONS = '/notifications';
export const USER_PROFILE = '/profile';

// Admin routes
export const ADMIN = '/admin';
export const ADMIN_USERS = '/admin/users';
