import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { SectionHeader, Card, SectionContent } from '../../../components/chrome';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import StandingsList from '../StandingsList';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	toolbarTitle: {
		display: 'flex',
		justifyContent: 'space-between'
	}
});

class Standings extends Component {
	render() {
		const { classes, match, tournament } = this.props;

		return (
			<SectionContent>
				<Grid container justify="center" spacing={24}>
					<Grid item xs={12}>
						<Card nopadding>
							<Typography
								variant="title"
								color="inherit"
								// className={classes.toolbarTitle}
								style={{
									paddingTop: 20,
									paddingLeft: 20,
									paddingRight: 20
								}}
							>
								Standings
							</Typography>
							<br />
							<StandingsList />
						</Card>
					</Grid>
				</Grid>
			</SectionContent>
		);
	}
}

Standings.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Standings);
