import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import NavigationArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import bgImage from '../../assets/images/abstract5.jpg';
import SimpleGoogleMap from '../../components/SimpleGoogleMap';
import { StaticGoogleMap, Marker, Path } from 'react-static-google-map';
import LocationOn from '@material-ui/icons/LocationOn';
import moment from 'moment';
import { getLocationQuery } from './queries/getLocationQuery';
import { SectionHeader, SectionContent, Card, ZeroState } from '../../components/chrome';
import ConfirmDialog from '../../components/ConfirmDialog';
import { removeLocationMutation } from './mutations/removeLocationMutation';
import { graphql, compose } from 'react-apollo';

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	},
	titleBar: {
		// paddingLeft: 22,
		// paddingRight: 22,
		// backgroundColor: '#1D2029',
		// backgroundColor: '#1D2029',
		backgroundColor: '#14171d',
		borderRadius: 0,
		backgroundImage: `url(${bgImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100%',
		backgroundPosition: 'right'
	},
	heading: {
		// margin: 0,
		// textAlign: 'center',
		// display: 'flex',
		// alignItems: 'center',
		// justifyContent: 'center',
		// fontSize: '2em',
		// color: 'white',
		// fontFamily: 'din_lightregular, Roboto, sans-serif',
		// fontWeight: 300
		margin: 0,
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		color: 'white',
		fontFamily: 'din_medium, Roboto, sans-serif'
		// fontWeight: 300
	},
	headerBar: {
		// marginLeft: 10,
		// marginRight: 10,
		paddingTop: 20,
		paddingBottom: 20
		// backgroundColor: '#14171d'
	},
	card: {
		padding: 20,
		fontWeight: 400,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},
	cardNopadding: {
		padding: 0,
		fontWeight: 400,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},
	toolbarTitle: {
		// color: '#000',
		// fontWeight: 400
	},
	list: {
		paddingTop: 0,
		paddingBottom: 10
	},
	avatar: {
		backgroundColor: '#44475b',
		color: '#fff'
	}
});

class LocationDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dialogOpen: false
		};
		this.handleCloseDialog = this.handleCloseDialog.bind(this);
		this.handleConfirmDialog = this.handleConfirmDialog.bind(this);
	}

	handleCloseDialog() {
		this.setState({
			dialogOpen: false
		});
	}

	handleConfirmDialog(location) {
		this.setState({
			dialogOpen: true
		});
	}

	render() {
		const { classes, match, history } = this.props;

		return (
			<Query query={getLocationQuery} variables={{ slug: match.params.slug }}>
				{({ loading, error, data, subscribeToMore }) => {
					console.log(data);
					if (loading) return <LinearProgress mode="indeterminate" color="primary" />;
					if (error) return <div style={styles.container}>Error: {error.message}</div>;
					if (data && !data.location) {
						return <div>Location not found.</div>;
					}

					return (
						<SectionHeader
							title="Location Details"
							backPath="/locations"
							backButton={true}
							rightButton={
								<Button
									variant="outlined"
									color="secondary"
									onClick={() => history.push('/locations/new')}
								>
									Create Location
								</Button>
							}
						>
							<SectionContent>
								<Grid container justify="center" spacing={24}>
									{/*<Grid item xs={11} sm={12}>
										<Link to={'/locations'} className={classes.backLink}>
											<Typography variant="subtitle1" gutterBottom>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<NavigationArrowBack style={{ width: 16, height: 16 }} />
													<span style={{ marginLeft: 5 }}>Back to Locations list</span>
												</div>
											</Typography>
										</Link>
						</Grid>*/}
									<Grid item xs={12} sm={12}>
										<Grid container justify="center" spacing={24}>
											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															padding: 20,
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between'
														}}
													>
														<span>{data.location.name}</span>
														<div style={{ display: 'flex', alignItems: 'center' }}>
															<Button
																variant="text"
																color="primary"
																size="small"
																onClick={e => {
																	e.preventDefault();
																	history.push(
																		`/locations/${data.location.slug}/edit`
																	);
																}}
															>
																Edit
															</Button>
															<Button
																variant="text"
																color="primary"
																size="small"
																onClick={e => {
																	e.preventDefault();
																	this.handleConfirmDialog(data.location);
																}}
																style={{
																	marginLeft: 10,
																	borderColor: 'rgb(225, 0, 80)',
																	color: 'rgb(225, 0, 80)'
																}}
															>
																Remove
															</Button>
														</div>
													</Typography>

													{data.location.lat && data.location.lon && (
														<SimpleGoogleMap
															width={'100%'}
															geodata={data.location.geodata}
															lat={data.location.lat}
															lon={data.location.lon}
														/>
													)}

													{/*<h3
														style={{
															fontSize: 30,
															// fontWeight: 500,
															paddingTop: 20,
															paddingLeft: 20,
															paddingBottom: 20
														}}
													>
														{data.location.name}
                          </h3>*/}

													<Grid
														container
														spacing={16}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20,
															paddingBottom: 20
														}}
													>
														<Grid item xs={12} sm={4}>
															<Typography
																variant="caption"
																className="caption"
																gutterBottom
															>
																Address
															</Typography>
															<Typography variant="subtitle1" gutterBottom>
																{data.location.address}
															</Typography>
														</Grid>
														<Grid item xs={12} sm={4}>
															<Typography
																variant="caption"
																className="caption"
																gutterBottom
															>
																City
															</Typography>
															<Typography variant="subtitle1" gutterBottom>
																{data.location.city}
															</Typography>
														</Grid>
														<Grid item xs={12} sm={4}>
															<Typography
																variant="caption"
																className="caption"
																gutterBottom
															>
																State
															</Typography>
															<Typography variant="subtitle1" gutterBottom>
																{data.location.state}
															</Typography>
														</Grid>
														<Grid item xs={12} sm={4}>
															<Typography
																variant="caption"
																className="caption"
																gutterBottom
															>
																Zip Code
															</Typography>
															<Typography variant="subtitle1" gutterBottom>
																{data.location.zip}
															</Typography>
														</Grid>
														<Grid item xs={12} sm={4}>
															<Typography
																variant="caption"
																className="caption"
																gutterBottom
															>
																Country
															</Typography>
															<Typography variant="subtitle1" gutterBottom>
																{data.location.country}
															</Typography>
														</Grid>
													</Grid>
												</Card>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Card nopadding style={{ height: 'auto' }}>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20,
															paddingBottom: 20
														}}
													>
														Teams
													</Typography>

													{data.location.teams && data.location.teams.length > 0 ? (
														<List defaultValue={0} className={classes.list}>
															{data.location.teams.map((team, index) => {
																return (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																		onClick={() =>
																			history.push(`/teams/${team.slug}`)
																		}
																		button
																	>
																		<Avatar size="small" className={classes.avatar}>
																			{team.name.slice(0, 1)}
																		</Avatar>
																		<ListItemText
																			primary={team.name}
																			secondary={`${
																				team.divisions.length
																			} Divisions`}
																		/>
																	</ListItem>
																);
															})}
														</List>
													) : (
														<div style={{ marginLeft: 20, marginBottom: 20 }}>N/A</div>
													)}
												</Card>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Card nopadding style={{ height: 'auto' }}>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															padding: 20
														}}
													>
														Tournaments
													</Typography>

													{data.location.tournaments &&
													data.location.tournaments.length > 0 ? (
														<List defaultValue={0} className={classes.list}>
															{data.location.tournaments.map((tournament, index) => {
																return (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																		onClick={() =>
																			history.push(
																				`/tournaments/${tournament.slug}`
																			)
																		}
																		button
																	>
																		<Avatar size="small" className={classes.avatar}>
																			{tournament.name.slice(0, 1)}
																		</Avatar>
																		<ListItemText
																			primary={tournament.name}
																			secondary={moment(
																				tournament.startTime
																			).format('lll')}
																		/>
																	</ListItem>
																);
															})}
														</List>
													) : (
														<div style={{ marginLeft: 20, marginBottom: 20 }}>N/A</div>
													)}
												</Card>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</SectionContent>
							<ConfirmDialog
								title="Confirm Location Removal"
								open={this.state.dialogOpen}
								onClose={this.handleCloseDialog}
								onConfirm={() => {
									this.props
										.removeLocation({
											mutation: removeLocationMutation,
											variables: { id: data.location.id },
											refetchQueries: ['GetLocations']
										})
										.then(() => {
											this.setState({ dialogOpen: false });
											this.props.history.push(`/locations`);
										})
										.catch(error => {
											console.log(error);
											this.setState({ dialogOpen: false });
										});
								}}
							>
								⚠️ Are you sure you want to delete <b>{data.location.name}</b>?
							</ConfirmDialog>
						</SectionHeader>
					);
				}}
			</Query>
		);
	}
}

LocationDetail.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithGraphQL = graphql(removeLocationMutation, {
	options: ({ id }) => ({ variables: { id } }),
	name: 'removeLocation'
})(LocationDetail);

export default withStyles(styles)(WithGraphQL);
