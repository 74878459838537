import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
// import client from '../../config/apolloClient';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

export const createInvoiceMutation = gql`
	mutation CreateInvoice($input: InvoiceInput) {
		createInvoice(input: $input) {
			number
			expiration
			code
			firstName
			lastName
			company
			address
			city
			state
			zip
			country
		}
	}
`;

const styles = theme => ({
	form: {
		width: '100%', // Fix IE11 issue.
		marginTop: theme.spacing.unit
	},
	submit: {
		marginTop: theme.spacing.unit * 3
	}
});

const INITIAL_STATE = {
	number: '',
	expiration: '',
	code: '',
	firstName: '',
	lastName: '',
	company: '',
	address: '',
	city: '',
	state: '',
	zip: '',
	country: '',
	error: null,
	submitting: false
};

class InvoiceCreateForm extends Component {
	constructor(props) {
		super(props);

		this.state = { ...INITIAL_STATE };
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(event) {
		event.preventDefault();
		this.setState({
			submitting: true
		});
		this.props.showProgressBar();

		const {
			number,
			expiration,
			code,
			firstName,
			lastName,
			company,
			address,
			city,
			state,
			zip,
			country
		} = this.state;

		const { history, ...rest } = this.props;

		console.log(this.state);

		this.props
			.mutate({
				mutation: createInvoiceMutation,
				variables: {
					input: {
						number: number,
						expiration: expiration,
						code: code,
						firstName: firstName,
						lastName: lastName,
						company: company,
						address: address,
						city: city,
						state: state,
						zip: zip,
						country: country
					}
				}
			})
			.then(({ data }) => {
				console.log(data);
				this.props.history.push(`/invoices`);
			})
			.catch(error => {
				console.log(error);
			});
	}

	render() {
		const { classes } = this.props;
		const {
			number,
			expiration,
			code,
			firstName,
			lastName,
			company,
			address,
			city,
			state,
			zip,
			country,
			error
		} = this.state;

		const isInvalid =
			number === '' ||
			expiration === '' ||
			code === '' ||
			firstName === '' ||
			lastName === '' ||
			address === '' ||
			city === '' ||
			state === '' ||
			zip === '' ||
			country === '';

		return (
			<form className={classes.form} onSubmit={this.onSubmit}>
				<FormControl margin="normal" required fullWidth>
					<InputLabel htmlFor="number">Credit Card Number</InputLabel>
					<Input
						error={Boolean(error)}
						id="number"
						name="number"
						value={number}
						onChange={event => this.setState({ number: event.target.value })}
						autoComplete="number"
						autoFocus
					/>
					<FormHelperText error={Boolean(error)}>Enter your credit card number</FormHelperText>
				</FormControl>
				<FormControl margin="normal" required fullWidth>
					<InputLabel htmlFor="expiration">Expiration Date</InputLabel>
					<Input
						error={Boolean(error)}
						id="expiration"
						name="expiration"
						value={expiration}
						onChange={event => this.setState({ expiration: event.target.value })}
						autoComplete="expiration"
						autoFocus
					/>
					<FormHelperText error={Boolean(error)}>Enter the expiration date</FormHelperText>
				</FormControl>
				<FormControl margin="normal" required fullWidth>
					<InputLabel htmlFor="code">CVV Code</InputLabel>
					<Input
						error={Boolean(error)}
						id="code"
						name="code"
						value={code}
						onChange={event => this.setState({ code: event.target.value })}
						autoComplete="code"
						autoFocus
					/>
					<FormHelperText error={Boolean(error)}>Enter the CVV code</FormHelperText>
				</FormControl>
				<FormControl margin="normal" required fullWidth>
					<InputLabel htmlFor="firstName">First Name</InputLabel>
					<Input
						error={Boolean(error)}
						id="firstName"
						name="firstName"
						value={firstName}
						onChange={event => this.setState({ firstName: event.target.value })}
						autoComplete="firstName"
						autoFocus
					/>
					<FormHelperText error={Boolean(error)}>Enter your first name</FormHelperText>
				</FormControl>
				<FormControl margin="normal" required fullWidth>
					<InputLabel htmlFor="lastName">Last Name</InputLabel>
					<Input
						error={Boolean(error)}
						id="lastName"
						name="lastName"
						value={lastName}
						onChange={event => this.setState({ lastName: event.target.value })}
						autoComplete="lastName"
						autoFocus
					/>
					<FormHelperText error={Boolean(error)}>Enter your last name</FormHelperText>
				</FormControl>
				<FormControl margin="normal" fullWidth>
					<InputLabel htmlFor="company">Company</InputLabel>
					<Input
						error={Boolean(error)}
						id="company"
						name="company"
						value={company}
						onChange={event => this.setState({ company: event.target.value })}
						autoComplete="company"
						autoFocus
					/>
					<FormHelperText error={Boolean(error)}>Enter your company (optional)</FormHelperText>
				</FormControl>
				<FormControl margin="normal" required fullWidth>
					<InputLabel htmlFor="address">Address</InputLabel>
					<Input
						error={Boolean(error)}
						id="address"
						name="address"
						value={address}
						onChange={event => this.setState({ address: event.target.value })}
						autoComplete="address"
						autoFocus
					/>
					<FormHelperText error={Boolean(error)}>Enter your address</FormHelperText>
				</FormControl>
				<FormControl margin="normal" required fullWidth>
					<InputLabel htmlFor="city">City</InputLabel>
					<Input
						error={Boolean(error)}
						id="city"
						name="city"
						value={city}
						onChange={event => this.setState({ city: event.target.value })}
						autoComplete="city"
						autoFocus
					/>
					<FormHelperText error={Boolean(error)}>Enter your city</FormHelperText>
				</FormControl>
				<FormControl margin="normal" required fullWidth>
					<InputLabel htmlFor="state">State</InputLabel>
					<Input
						error={Boolean(error)}
						id="state"
						name="state"
						value={state}
						onChange={event => this.setState({ state: event.target.value })}
						autoComplete="state"
						autoFocus
					/>
					<FormHelperText error={Boolean(error)}>Enter your state</FormHelperText>
				</FormControl>
				<FormControl margin="normal" required fullWidth>
					<InputLabel htmlFor="zip">Zip Code</InputLabel>
					<Input
						error={Boolean(error)}
						id="zip"
						name="zip"
						value={zip}
						onChange={event => this.setState({ zip: event.target.value })}
						autoComplete="zip"
						autoFocus
					/>
					<FormHelperText error={Boolean(error)}>Enter your zip code</FormHelperText>
				</FormControl>
				<FormControl margin="normal" required fullWidth>
					<InputLabel htmlFor="country">Country</InputLabel>
					<Input
						error={Boolean(error)}
						id="country"
						name="country"
						value={country}
						onChange={event => this.setState({ country: event.target.value })}
						autoComplete="country"
						autoFocus
					/>
					<FormHelperText error={Boolean(error)}>Enter your country</FormHelperText>
				</FormControl>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					disabled={isInvalid || this.state.submitting === true}
					className={classes.submit}
				>
					{this.state.submitting === false ? 'Submit' : 'Submitting...'}
				</Button>
				{error && <p style={{ color: 'red' }}>ERROR: {error.message}</p>}
			</form>
		);
	}
}

InvoiceCreateForm.propTypes = {
	classes: PropTypes.object.isRequired
};

InvoiceCreateForm.contextTypes = {
	authUser: PropTypes.object
};

const WithGraphQL = compose(
	graphql(createInvoiceMutation, {
		options: ({ input }) => ({ variables: { input } })
	})
)(InvoiceCreateForm);

export default withStyles(styles)(WithGraphQL);
