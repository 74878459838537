import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
// import currentUser from '../../helpers/currentUser';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import * as routes from '../../config/routes';
import CloseIcon from '@material-ui/icons/Close';
import bgImage from '../../assets/images/abstract.jpg';
import '../../assets/css/app.css';

const styles = theme => ({
	avatarLarge: {
		width: 60,
		height: 60,
		backgroundColor: '#44475b',
		color: '#fff'
	},
	closeButton: {
		position: 'absolute',
		right: 0
	}
});

class UserProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: 3
		};
	}

	handleChange = (event, index, value) => this.setState({ value });

	render() {
		const { classes } = this.props;
		const { authUser } = this.context;

		return (
			<div className="user-profile">
				<div className="background">
					<img alt="" src={bgImage} />
				</div>
				<Button
					color="inherit"
					onClick={this.props.onCloseDrawer}
					aria-label="Close"
					className={classes.closeButton}
				>
					<CloseIcon style={{ marginRight: 10, color: 'rgba(255,255,255,0.7)' }} />
				</Button>
				{authUser && (
					<React.Fragment>
						<Avatar size="large" className={classes.avatarLarge} src={authUser.avatarImg}>
							{authUser.name && authUser.name.slice(0, 1)}
						</Avatar>
						<h3 className="white-text" style={{ marginTop: 20, marginBottom: 0 }}>
							{authUser.name}
						</h3>
						<div className="white-text">{authUser.email}</div>
					</React.Fragment>
				)}
			</div>
		);
	}
}

UserProfile.contextTypes = {
	authUser: PropTypes.object,
	loggedIn: PropTypes.bool,
	handleChangeLoginState: PropTypes.func
};

UserProfile.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles)(UserProfile);
export default withRouter(WithStyles);
