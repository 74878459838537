import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { SectionHeader, Card, SectionContent } from '../../../components/chrome';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import liveStream from '../../../assets/images/live-stream.png';

const styles = theme => ({
	toolbarTitle: {
		display: 'flex',
		justifyContent: 'space-between'
	}
});

class Stream extends Component {
	render() {
		const { classes } = this.props;

		return (
			<SectionContent>
				<Grid container justify="center" spacing={24}>
					<Grid item xs={12} sm={12} md={8}>
						<Grid container justify="center" spacing={24}>
							<Grid item xs={12}>
								<Card nopadding>
									<Typography
										variant="title"
										color="inherit"
										// className={classes.toolbarTitle}
										style={{
											paddingTop: 20,
											paddingLeft: 20,
											paddingRight: 20
										}}
									>
										Live Stream
									</Typography>
									<br />
									<div>
										<img src={liveStream} alt="Live Stream" width="100%" />
									</div>
								</Card>
							</Grid>

							<Grid item xs={12}>
								<Card>
									<Typography
										variant="title"
										color="inherit"
										// className={classes.toolbarTitle}
									>
										Live Stats
									</Typography>
									<br />
									Stats
								</Card>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} sm={12} md={4}>
						<Grid container justify="center" spacing={24}>
							<Grid item xs={12}>
								<Card>
									<Typography variant="title" color="inherit" className={classes.toolbarTitle}>
										Chat
									</Typography>
									<br />
									Chat
								</Card>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</SectionContent>
		);
	}
}

Stream.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Stream);
