import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	container: {
		padding: 20,
		// backgroundColor: '#fafafa',
		backgroundColor: '#f0f8ff',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		alignSelf: 'center',
		textAlign: 'center'
		// minHeight: 100
	},
	text: {
		// color: '#8a8a8a',
		color: '#7c8e9e'
		// color: '#4e5d6b'
	}
});

class ZeroState extends Component {
	render() {
		const { classes, title, children, style } = this.props;

		return (
			<div className={classes.container} style={style}>
				<Typography variant="subtitle1" className={classes.text}>
					{title}
				</Typography>
				{children && <div style={{ marginTop: 15 }}>{children}</div>}
			</div>
		);
	}
}

ZeroState.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ZeroState);
