import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

class OtherActionsButton extends React.Component {
	state = {
		anchorEl: null
	};

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	render() {
		const { style } = this.props;
		const { anchorEl } = this.state;

		return (
			<React.Fragment>
				<Button
					variant="outlined"
					color="primary"
					size="large"
					aria-owns={anchorEl ? 'simple-menu' : undefined}
					aria-haspopup="true"
					onClick={this.handleClick}
				>
					Other actions
				</Button>
				<Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
					<MenuItem onClick={this.handleClose}>Contact opponent</MenuItem>
					<MenuItem onClick={this.handleClose}>Reschedule</MenuItem>
					<MenuItem onClick={this.handleClose}>Request cancellation</MenuItem>
					<MenuItem onClick={this.handleClose}>Forfeit</MenuItem>
				</Menu>
			</React.Fragment>
		);
	}
}

export default OtherActionsButton;
