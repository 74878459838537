import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { withAlert } from 'react-alert';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import ListSubheader from '@material-ui/core/ListSubheader';
import NotificationsIcon from '@material-ui/icons/Notifications';

const styles = theme => ({
	notificationContainer: {
		marginRight: 10
	},
	badge: {
		// padding: theme.spacing.unit * 0.5
	}
});

const notificationsList = [
	{
		id: 1,
		type: 'alert',
		severity: 'low',
		message: 'BTC Price Increase Alert: +2% in last hour'
	}
];

class NotificationsMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null,
			notifications: notificationsList
		};
	}

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	clearNotifications = () => {
		if (this.state.notifications && this.state.notifications.length > 0) {
			this.props.alert.show('All notifications cleared');
		}
		this.setState({
			notifications: []
		});
		this.handleClose();
	};

	render() {
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);
		const { classes } = this.props;
		return (
			<React.Fragment>
				<IconButton
					color="inherit"
					aria-label="Notifications"
					aria-owns={open ? 'long-menu' : null}
					aria-haspopup="true"
					onClick={this.handleClick}
					className={classes.notificationContainer}
				>
					{this.state.notifications && this.state.notifications.length ? (
						<Badge
							badgeContent={<b>{this.state.notifications.length}</b>}
							color="secondary"
							className={classes.badge}
						>
							<NotificationsIcon />
						</Badge>
					) : (
						<NotificationsIcon />
					)}
				</IconButton>

				<Menu
					id="long-menu"
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					anchorReference="anchorEl"
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={this.handleClose}
					PaperProps={{
						style: {
							minWidth: 400,
							maxHeight: 48 * 4.5
						}
					}}
				>
					<ListSubheader
						style={{
							margin: 0,

							paddingLeft: 20,
							paddingRight: 20,
							outline: 'none',
							display: 'flex',
							justifyContent: 'space-between'
						}}
					>
						<span>Notifications</span>

						<Button
							size="small"
							onClick={this.clearNotifications}
							disabled={this.state.notifications && this.state.notifications.length === 0 ? true : false}
						>
							Clear all
						</Button>
					</ListSubheader>
					{this.state.notifications && this.state.notifications.length ? (
						this.state.notifications.map((notification, index) => (
							<MenuItem
								key={index}
								selected={true}
								onClick={() => console.log('item clicked')}
								style={{ paddingLeft: 20, paddingRight: 20 }}
							>
								{notification.message}
							</MenuItem>
						))
					) : (
						<MenuItem disabled style={{ paddingLeft: 20, paddingRight: 20 }}>
							No notifications
						</MenuItem>
					)}
				</Menu>
			</React.Fragment>
		);
	}
}

NotificationsMenu.contextTypes = {
	classes: PropTypes.object
};

const WithRouter = withRouter(NotificationsMenu);
const WithAlert = withAlert(WithRouter);
export default withStyles(styles)(WithAlert);
