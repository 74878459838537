import _ from 'lodash';

export const displayEnumType = type => {
	const typeSplit = type.split('_');
	// const prefix = _.capitalize(typeSplit[0]);
	// const suffix = _.capitalize(typeSplit[1]);
	let combined = '';
	typeSplit.forEach(part => {
		combined += `${_.capitalize(part)} `;
	});
	return combined;
};
