import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Formsy from 'formsy-react';
import TextInput from '../../components/forms/TextInputFormsy';
import DateTimeInput from '../../components/forms/DateTimeInputFormsy';
import SelectField from '../../components/forms/SelectFormsy';
import SwitchField from '../../components/forms/SwitchFormsy';
import MultiSelectField from '../../components/forms/MultiSelectFormsy';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import Avatar from '@material-ui/core/Avatar';
import LocationOn from '@material-ui/icons/LocationOn';
import isEmpty from 'lodash/isEmpty';
import { getMatchQuery } from './queries/getMatchQuery';
// import { getPlayersQuery } from './queries/getPlayersQuery';
import { updateMatchMutation } from './mutations/updateMatchMutation';
import { createMatchMutation } from './mutations/createMatchMutation';
import eightBall from '../../assets/images/ball/eight-ball.png';
import nineBall from '../../assets/images/ball/nine-ball.png';
import tenBall from '../../assets/images/ball/ten-ball.png';
import currentUser from '../../helpers/currentUser';
import AutocompleteField from '../../components/forms/AutocompleteFormsy';
import _ from 'lodash';

export const getDivisionsQuery = gql`
	query GetDivisions {
		divisions {
			id
			name
			slug
			type
		}
	}
`;

export const getLocationsQuery = gql`
	query GetLocations {
		locations {
			id
			name
			slug
		}
	}
`;

export const getTeamsQuery = gql`
	query GetTeams {
		teams {
			id
			name
			slug
		}
	}
`;

export const getPlayersQuery = gql`
	query GetPlayers {
		users {
			id
			name
			slug
		}
	}
`;

const styles = theme => ({
	error: {
		color: '#f44336'
	},
	content: {
		// padding: '10px 24px 20px 24px'
		// maxWidth: 600
	},
	input: {
		marginTop: 5
	},
	submit: {
		marginTop: 10
	},
	selectEmpty: {
		marginTop: theme.spacing.unit * 2
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	},
	avatar: {
		backgroundColor: '#44475b',
		color: '#fff'
	}
});

class MatchCreateForm extends React.Component {
	constructor(props) {
		super(props);
		this.disableButton = this.disableButton.bind(this);
		this.enableButton = this.enableButton.bind(this);
		this.state = { canSubmit: false, submitting: false };
		this.submit = this.submit.bind(this);
	}

	getGameType = type => {
		return type.indexOf('EIGHT_BALL') !== -1
			? eightBall
			: type.indexOf('NINE_BALL') !== -1
			? nineBall
			: type.indexOf('TEN_BALL') !== -1
			? tenBall
			: null;
	};

	getDivisions = () => {
		const {
			getDivisions: { divisions, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = divisions.map(division => {
			return {
				label: division.name,
				value: division.id,
				avatar: this.getGameType(division.type)
			};
		});
		return formatted;
	};

	getLocations = () => {
		const {
			getLocations: { locations, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = locations.map(location => {
			return {
				label: location.name,
				value: location.id
			};
		});
		return formatted;
	};

	getTeams = () => {
		const {
			getTeams: { teams, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = teams.map(team => {
			return {
				label: team.name,
				value: team.id
			};
		});
		return formatted;
	};

	getPlayers = () => {
		const {
			getPlayers: { users, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = users.map(player => {
			return {
				label: player.name,
				value: player.id
				// avatar: player.avatarImg
			};
		});
		return formatted;
	};

	disableButton() {
		this.setState({ canSubmit: false });
	}

	enableButton() {
		this.setState({ canSubmit: true });
	}

	submit(model) {
		console.log(model);
		this.setState({
			submitting: true
		});
		if (!this.props.id) {
			this.props
				.createMatch({
					mutation: createMatchMutation,
					variables: {
						input: {
							// number: model.number,
							type: model.type,
							gameType: model.gameType,
							format: model.format,
							divisionId: model.division.value,
							interDivision: model.interDivision,
							outerDivision: model.outerDivision,
							tournamentId: model.tournament,
							locationId: model.location.value,
							userIds: model.players.map(item => item.value),
							teamIds: model.teams.map(item => item.value),
							gameIds: model.games,
							homeTeamId: model.homeTeam,
							awayTeamId: model.awayTeam,
							startTime: model.startTime,
							status: model.status,
							verified: model.verified
						}
					},
					refetchQueries: [{ query: getMatchQuery }]
				})
				.then(({ data }) => {
					// console.log(data);
					this.props.history.push(`/matches/${data.createMatch.id}`);
				})
				.catch(error => {
					console.log(error);
					this.setState({ submitting: false });
				});
		} else {
			const id = this.props.getMatch.match.id;
			this.props
				.updateMatch({
					mutation: updateMatchMutation,
					variables: {
						input: {
							id: id,
							// number: model.number,
							type: model.type,
							gameType: model.gameType,
							format: model.format,
							divisionId: model.division.value,
							interDivision: model.interDivision,
							outerDivision: model.outerDivision,
							tournamentId: model.tournament,
							locationId: model.location.value,
							userIds: model.players.map(item => item.value),
							teamIds: model.teams.map(item => item.value),
							gameIds: model.games,
							homeTeamId: model.homeTeam,
							awayTeamId: model.awayTeam,
							startTime: model.startTime,
							status: model.status,
							verified: model.verified
						}
					},
					refetchQueries: [{ query: getMatchQuery, variables: { id: id } }]
				})
				.then(({ data }) => {
					console.log(data);
					this.props.history.push(`/matches/${data.updateMatch.id}`);
				})
				.catch(error => {
					console.log(error);
					this.setState({ submitting: false });
				});
		}
	}

	render() {
		const {
			classes,
			getMatch: { match, loading, error },
			currentUser
		} = this.props;

		return (
			<React.Fragment>
				{loading === true ? (
					<div>Loading...</div>
				) : (
					<Formsy onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
						<SelectField
							name="type"
							label="Type"
							value={match && match.type}
							options={[
								{
									label: 'League',
									value: 'LEAGUE'
								},
								{ label: 'Tournament', value: 'TOURNAMENT' },
								{ label: 'Action', value: 'ACTION' },
								{ label: 'Leisure', value: 'LEISURE' }
							]}
							required
							validations="isExisty"
							validationError="Type is required"
						/>

						<SelectField
							name="gameType"
							label="Game Type"
							value={match && match.gameType}
							options={[
								{
									label: '10-Ball',
									value: 'TEN_BALL'
								},
								{
									label: '9-Ball',
									value: 'NINE_BALL'
								},
								{ label: '8-Ball', value: 'EIGHT_BALL' },
								{ label: 'One Pocket', value: 'ONE_POCKET' },
								{ label: 'American Rotation', value: 'AMERICAN_ROTATION' },
								{
									label: 'Straight Pool',
									value: 'STRAIGHT_POOL'
								},
								{ label: 'Mixed Formats', value: 'MIXED' }
							]}
							required
							validations="isExisty"
							validationError="Game Type is required"
						/>

						<SelectField
							name="format"
							label="Format"
							value={match && match.format}
							options={[
								{
									label: 'Singles',
									value: 'SINGLES'
								},
								{ label: 'Doubles', value: 'DOUBLES' },
								{ label: 'Scotch Doubles', value: 'SCOTCH_DOUBLES' },
								{ label: 'Three Person Teams', value: 'THREE_PERSON_TEAMS' },
								{ label: 'Five Person Teams', value: 'FIVE_PERSON_TEAMS' }
							]}
							required
							validations="isExisty"
							validationError="Format is required"
						/>

						{/* <MultiSelectField
							name="players"
							label="Players"
							value={
								match &&
								match.players.map(player => {
									return player.id;
								})
							}
							options={this.getPlayers()}
						/> */}

						<AutocompleteField
							name="players"
							label="Players"
							isMulti={true}
							placeholder="Select multiple players"
							value={
								match &&
								match.players.map(player => {
									return {
										label: player.name,
										value: player.id
									};
								})
							}
							suggestions={this.getPlayers()}
						/>

						<AutocompleteField
							name="teams"
							label="Teams"
							isMulti={true}
							placeholder="Select multiple teams"
							value={
								match &&
								match.teams.map(team => {
									return {
										label: team.name,
										value: team.id
									};
								})
							}
							suggestions={this.getTeams()}
						/>

						{/* <MultiSelectField
							name="teams"
							label="Teams"
							value={
								match &&
								match.teams.map(team => {
									return team.id;
								})
							}
							options={this.getTeams()}
						/> */}

						{/* <SelectField
							name="division"
							label="Division"
							value={match && match.division && match.division.id}
							options={this.getDivisions()}
							required
						/> */}

						<AutocompleteField
							name="division"
							label="Division"
							placeholder="Select division"
							value={match && match.division && { label: match.division.name, value: match.division.id }}
							suggestions={this.getDivisions()}
							required
						/>

						<AutocompleteField
							name="location"
							label="Location"
							placeholder="Select location"
							value={match && match.location && { label: match.location.name, value: match.location.id }}
							suggestions={this.getLocations()}
							required
						/>

						{/* <SelectField
							name="location"
							label="Location"
							value={match && match.location && match.location.id}
							options={this.getLocations()}
						/> */}

						<DateTimeInput
							name="startTime"
							label="Start Time"
							value={match && match.startTime ? match.startTime : null}
							validations="isExisty"
							validationError="Start Time is required"
							required
						/>

						<SelectField
							name="status"
							label="Status"
							value={match && match.status}
							options={[
								{
									label: 'Not Started',
									value: 'NOT_STARTED'
								},
								{ label: 'In Progress', value: 'IN_PROGRESS' },
								{ label: 'Paused', value: 'PAUSED' },
								{ label: 'Forfeited', value: 'FORFEITED' },
								{ label: 'Completed', value: 'COMPLETED' },
								{ label: 'Scheduled', value: 'SCHEDULED' },
								{ label: 'Pending Review', value: 'PENDING_REVIEW' }
							]}
							required
							validations="isExisty"
							validationError="Status is required"
						/>

						<SwitchField
							// type="checkbox"
							// checked={team.isActive}
							id="outerDivision"
							name="outerDivision"
							label="Outer Division Match"
							text={['Outer Division Match', 'Inter Division Match']}
							value={match && match.outerrDivision === true ? true : false}
						/>

						<div style={{ marginTop: 20 }}>
							{this.props.id ? (
								<Button
									variant="contained"
									color="primary"
									disabled={!this.state.canSubmit}
									type="submit"
								>
									Update
								</Button>
							) : (
								<Button
									variant="contained"
									color="primary"
									disabled={!this.state.canSubmit}
									type="submit"
								>
									Create
								</Button>
							)}
						</div>
					</Formsy>
				)}
			</React.Fragment>
		);
	}
}

const WithGraphQL = compose(
	graphql(getMatchQuery, {
		options: ({ id }) => ({
			variables: { id },
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'no-cache'
			// refetchQueries: [
			// 	{
			// 		query: getTeamQuery,
			// 		variables: { variables: { slug } }
			// 	}
			// ]
		}),
		name: 'getMatch'
	}),
	graphql(createMatchMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'createMatch'
	}),
	graphql(updateMatchMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'updateMatch'
	}),
	graphql(getDivisionsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getDivisions'
	}),
	graphql(getLocationsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getLocations'
	}),
	graphql(getTeamsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getTeams'
	}),
	graphql(getPlayersQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getPlayers'
	})
)(MatchCreateForm);

const WithRouter = withRouter(WithGraphQL);
const WithStyles = withStyles(styles)(WithRouter);
export default currentUser(WithStyles);
