import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TournamentToolbar from './TournamentToolbar';
import TournamentsList from './TournamentsList';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	},
	card: {
		// padding: 10,
		// margin: 24,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},
	cell: {
		padding: '4px 30px 4px 20px'
	}
});

class TournamentTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			order: 'asc',
			orderBy: 'id',
			selected: [],
			page: 0,
			rowsPerPage: 10,
			limit: this.props.limit || 10,
			offset: this.props.offset || 0
		};
	}

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}

		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => {
		this.setState({ page: page, offset: this.state.offset + this.state.limit });
		this.props.onLoadMore(this.state.offset + this.state.limit);
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};

	render() {
		const { classes, match, data, loading, error, subscribeToMore, onLoadMore, offset, limit } = this.props;
		const { order, orderBy, selected, rowsPerPage, page } = this.state;
		const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.tournaments.length - page * rowsPerPage);

		return (
			<Paper elevation={0} className={classes.card}>
				<div className={classes.container}>
					{/*<TournamentToolbar loading={loading} error={error} data={data} />*/}
					<Table>
						<TableHead>
							<TableRow>
								{/*<TableCell className={classes.cell}>#</TableCell>*/}
								<TableCell className={classes.cell}>Name</TableCell>
								<TableCell className={classes.cell}>Progress</TableCell>
								<TableCell className={classes.cell}>Location</TableCell>
								<TableCell className={classes.cell}>Game Type</TableCell>

								<TableCell className={classes.cell}>Status</TableCell>
								<TableCell className={classes.cell} numeric>
									Players
								</TableCell>
								<TableCell className={classes.cell}>Date</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TournamentsList
								tournaments={data.tournaments}
								subscribeToMore={subscribeToMore}
								match={match}
								rowsPerPage={rowsPerPage}
								page={page}
								order={order}
								orderBy={orderBy}
							/>
							{emptyRows > 0 && (
								<TableRow style={{ height: 49 * emptyRows }}>
									<TableCell colSpan={7} />
								</TableRow>
							)}
						</TableBody>
					</Table>
					<TablePagination
						component="div"
						// count={data.tournaments.length}
						count={30}
						rowsPerPage={limit}
						rowsPerPageOptions={[10, 25, 50]}
						page={0}
						backIconButtonProps={{
							'aria-label': 'Previous Page'
						}}
						nextIconButtonProps={{
							'aria-label': 'Next Page'
						}}
						onChangePage={this.handleChangePage}
						onChangeRowsPerPage={this.handleChangeRowsPerPage}
					/>
				</div>
			</Paper>
		);
	}
}

TournamentTable.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TournamentTable);
