import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Formsy from 'formsy-react';
import Button from '@material-ui/core/Button';
import TextInput from '../../components/forms/TextInputFormsy';

const styles = theme => ({
	form: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
});

class CommentForm extends Component {
	constructor(props) {
		super(props);
		this.state = { canSubmit: false };

		this.submit = this.submit.bind(this);
		this.disableButton = this.disableButton.bind(this);
		this.enableButton = this.enableButton.bind(this);
	}

	disableButton() {
		this.setState({ canSubmit: false });
	}

	enableButton() {
		this.setState({ canSubmit: true });
	}

	submit(model) {
		this.props.addComment(model.comment, this.props.challenge);
	}

	render() {
		const { classes, data } = this.props;

		return (
			<Formsy
				onValidSubmit={this.submit}
				onValid={this.enableButton}
				onInvalid={this.disableButton}
				className={classes.form}
			>
				<TextInput
					// multiline
					// rows={4}
					name="comment"
					label="Leave a comment"
					value={data && data.comment}
					style={{ maxWidth: '100%' }}
					variant="filled"
					required
				/>

				<Button
					variant="contained"
					color="primary"
					disabled={!this.state.canSubmit}
					type="submit"
					style={{ marginLeft: 10, marginTop: 20 }}
				>
					Submit
				</Button>
			</Formsy>
		);
	}
}

export default withStyles(styles)(CommentForm);
