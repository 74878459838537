import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
// import Card from '@material-ui/core/Card';
import moment from 'moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import AttachMoney from '@material-ui/icons/AttachMoney';
import { ZeroState, Card } from '../../../components/chrome';
import eightBall from '../../../assets/images/ball/eight-ball.png';
import nineBall from '../../../assets/images/ball/nine-ball.png';
import tenBall from '../../../assets/images/ball/ten-ball.png';
import oneBall from '../../../assets/images/ball/one-ball.png';
import * as routes from '../../../config/routes';

const styles = theme => ({
	listItem: {
		// padding: 0
	}
});

class NotificationsCard extends Component {
	render() {
		const { classes, notifications } = this.props;

		return (
			<Card nopadding>
				<div
					style={{
						marginLeft: 24,
						marginRight: 24,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<h3
						style={{
							marginTop: 20,
							marginBottom: 20
						}}
					>
						Notifications
					</h3>
					<div>{moment().format('lll')}</div>
				</div>

				<List defaultValue={0} style={{ padding: 0, flex: 1 }}>
					{notifications &&
						notifications.length === 0 && (
							<ZeroState
								title="You have 0 new notifications"
								style={{ marginLeft: 15, marginRight: 15, marginBottom: 15 }}
							/>
						)}
					{notifications &&
						notifications.length > 0 &&
						notifications.map((notification, index) => {
							return (
								<React.Fragment key={index}>
									<ListItem className={classes.listItem} button>
										<Avatar size="small" src={oneBall} />
										<ListItemText
											primary={
												<div>
													<span>DPL National Championships are Live from Las Vegas, NV</span>
													<span className="live-badge" style={{ marginLeft: 10 }}>
														LIVE
													</span>
												</div>
											}
										/>
									</ListItem>
									<ListItem className={classes.listItem} button>
										<Avatar size="small" src={oneBall} />
										<ListItemText primary="DPL Season One starts Jan 1, 2019" />
										<ListItemSecondaryAction style={{ marginRight: 24 }}>
											<Button size="small" variant="outlined" color="primary">
												Join now
											</Button>
										</ListItemSecondaryAction>
									</ListItem>
									<ListItem className={classes.listItem} button>
										<Avatar size="small" style={{ backgroundColor: 'orange' }}>
											<AttachMoney />
										</Avatar>

										<ListItemText primary="Annual membership fee of $35 is past due" />
										<ListItemSecondaryAction style={{ marginRight: 24 }}>
											<Button size="small" variant="outlined" color="primary">
												Pay now
											</Button>
										</ListItemSecondaryAction>
									</ListItem>
								</React.Fragment>
							);
						})}
				</List>
				{notifications &&
					notifications.length > 0 && (
						<Link to={`${routes.NOTIFICATIONS}`}>
							<Button primary="true" style={{ margin: 10 }}>
								View all notifications
							</Button>
						</Link>
					)}
			</Card>
		);
	}
}

NotificationsCard.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NotificationsCard);
