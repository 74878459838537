import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { withAlert } from 'react-alert';
import { withTheme, withStyles } from '@material-ui/core/styles';
// import { Link, NavLink } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import RaisedButton from 'material-ui/RaisedButton';
// import RemoveRedEye from 'material-ui/svg-icons/image/remove-red-eye';
// import PersonAdd from 'material-ui/svg-icons/social/person-add';
// import ContentLink from 'material-ui/svg-icons/content/link';
// import ContentCopy from 'material-ui/svg-icons/content/content-copy';
// import Download from 'material-ui/svg-icons/file/file-download';
// import Delete from 'material-ui/svg-icons/action/delete';
import ActionHome from '@material-ui/icons/Home';
import ActionSettings from '@material-ui/icons/Settings';
import ActionEvent from '@material-ui/icons/Event';
import ActionLock from '@material-ui/icons/Lock';
import ActionLockOpen from '@material-ui/icons/LockOpen';
import ActionPowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import ActionHelp from '@material-ui/icons/Help';
import ActionExitToApp from '@material-ui/icons/ExitToApp';
import AttachMoney from '@material-ui/icons/AttachMoney';
// import ActionGroupWork from "material-ui/svg-icons/action/group-work";
// import ActionFeedback from "material-ui/svg-icons/action/feedback";
import CommunicationBusiness from '@material-ui/icons/Business';
import CommunicationMessage from '@material-ui/icons/Message';
import AvAlbum from '@material-ui/icons/Album';
import AvEqualizer from '@material-ui/icons/Equalizer';
import Stars from '@material-ui/icons/Stars';
import Star from '@material-ui/icons/Star';
import MapsPlace from '@material-ui/icons/Place';
import SocialPerson from '@material-ui/icons/Person';
import PublicIcon from '@material-ui/icons/Public';
import SocialPeople from '@material-ui/icons/People';
import WbSunny from '@material-ui/icons/WbSunny';
import Chat from '@material-ui/icons/Chat';
// import FontIcon from "material-ui/FontIcon";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Dashboard from '@material-ui/icons/Dashboard';
import ListIcon from '@material-ui/icons/List';
import Divider from '@material-ui/core/Divider';
import UserProfile from './UserProfile';
import auth from '../../auth/gql';
import EnhancedMenu from './nav/EnhancedMenu';
import SubMenu from './nav/SubMenu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as routes from '../../config/routes';

// const iconColor = '#00BCD4';
const iconColor = '#757575';
// const iconSize = "35px";

const styles = theme => ({
	containerStyle: {
		// backgroundColor: '#131825'
	},
	menuItem: {
		// fontWeight: 700,
		paddingLeft: 21,
		'&:focus': {
			backgroundColor: theme.palette.secondary.main,
			'& $primary, & $icon': {
				color: theme.palette.common.black
			}
		}
	},
	selected: {
		paddingLeft: 18,
		backgroundColor: 'rgba(255,203,1,0.1) !important',
		color: '#ffcb01',
		borderLeft: '3px solid #ffcb01',

		'& $primary': {
			fontWeight: 700
		},
		'& $primary, & $icon': {
			color: theme.palette.secondary.main
		},
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.08)'
		}
	},
	drawerPaper: {
		// top: 64,
		// backgroundColor: '#14171d'
		// width: drawerWidth,
		// overflowX: 'hidden',
		// transition: theme.transitions.create('width', {
		// 	easing: theme.transitions.easing.sharp,
		// 	duration: theme.transitions.duration.leavingScreen
		// }),
		// width: theme.spacing.unit * 7,
		// [theme.breakpoints.up('sm')]: {
		// 	width: theme.spacing.unit * 9
		// },
		// [theme.breakpoints.up('md')]: {
		// 	position: 'fixed'
		// }
	},
	primary: {
		fontWeight: 400
	},
	icon: {}
});

const menuItems = [
	{
		key: '1',
		caption: 'Home',
		icon: PublicIcon,
		onClick: () => {}
	},
	{
		key: 'league-management',
		caption: 'League Management',
		subMenuItems: [
			{
				key: '2',
				caption: 'Leagues',
				onClick: () => {}
			},
			{
				key: '3',
				caption: 'Divisions',
				onClick: () => {}
			},
			{
				key: '4',
				caption: 'Locations',
				onClick: () => {}
			},
			{
				key: '5',
				caption: 'Teams',
				onClick: () => {}
			},
			{
				key: '6',
				caption: 'Players',
				onClick: () => {}
			}
		]
	},
	{
		key: 'my-stuff',
		caption: 'My Stuff',
		subMenuItems: [
			{
				key: '7',
				caption: 'Challenges',
				onClick: () => {}
			},
			{
				key: '8',
				caption: 'Messages',
				onClick: () => {}
			},
			{
				key: '9',
				caption: 'Matches',
				onClick: () => {}
			},
			{
				key: '10',
				caption: 'Stats',
				onClick: () => {}
			}
		]
	},
	{
		key: '11',
		caption: 'Tournaments',
		icon: PublicIcon,
		onClick: () => {}
	}
];

const navItems = [
	{
		name: 'leagues',
		title: 'Leagues',
		route: '/leagues',
		icon: PublicIcon
	},
	{
		name: 'divisions',
		title: 'Divisions',
		route: '/divisions',
		icon: CommunicationBusiness
	},
	{
		name: 'locations',
		title: 'Locations',
		route: '/locations',
		icon: MapsPlace
	},
	{
		name: 'matches',
		title: 'Matches',
		route: '/matches',
		icon: AvAlbum
	},
	{
		name: 'messages',
		title: 'Messages',
		route: '/messages',
		icon: CommunicationMessage
	},
	{
		name: 'challenges',
		title: 'Challenges',
		route: '/challenges',
		icon: Star
	},
	{
		name: 'stats',
		title: 'Stats',
		route: '/stats',
		icon: AvEqualizer
	},
	{
		name: 'teams',
		title: 'Teams',
		route: '/teams',
		icon: SocialPeople
	},
	{
		name: 'players',
		title: 'Players',
		route: '/players',
		icon: SocialPerson
	},
	{
		name: 'tournaments',
		title: 'Tournaments',
		route: '/tournaments',
		icon: ActionEvent
	}
].sort((a, b) => (a.name < b.name ? -1 : b.name > a.name ? 1 : 0));

class NavDrawer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			left: false
		};
		this.isActive = this.isActive.bind(this);
		this.navigate = this.navigate.bind(this);
		this.signOut = this.signOut.bind(this);
	}

	// isActive(link) {
	// 	let urlPath = window.location.pathname;
	// 	let pathArray = urlPath.split('/');
	// 	let linkArray = link.split('/');
	// 	pathArray.shift();
	// 	linkArray.shift();
	// 	const currentPath = pathArray[0];
	// 	const currentLink = linkArray[0];
	// 	return currentPath === currentLink;
	// 	// let strRegExPattern = "\\b" + link + "\\b";
	// 	// let match = urlPath.match(new RegExp(strRegExPattern, "g"));
	// 	// console.log(match);
	// 	// return urlPath === link ? true : false;
	// }

	isActive(link) {
		const urlPath = window.location.pathname;
		const splitPath = urlPath.split('/');
		return splitPath[1] === link ? true : false;
	}

	navigate(loc) {
		const { history } = this.props;
		history.push(loc);
	}

	signOut() {
		const { history } = this.props;
		localStorage.removeItem('DPLToken');
		this.context.signOut(this.context.authUser);
		history.push(routes.SIGN_IN);
		// auth
		// 	.signOut()
		// 	.then(result => {
		// 		history.push(routes.SIGN_IN);
		// 	})
		// 	.catch(error => {
		// 		console.log(error);
		// 	});
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			left: nextProps.left
		});
	}

	// handleToggle = () => this.setState({open: !this.state.open});
	// handleClose = () => this.setState({open: false});

	// <RaisedButton
	//   label="Open Drawer"
	//   onTouchTap={this.props.handleToggle}
	// />

	// <MenuItem primaryText="Preview" leftIcon={<RemoveRedEye />} />
	// <MenuItem primaryText="Share" leftIcon={<PersonAdd />} />
	// <MenuItem primaryText="Get links" leftIcon={<ContentLink />} />
	// <Divider />
	// <MenuItem primaryText="Make a copy" leftIcon={<ContentCopy />} />
	// <MenuItem primaryText="Download" leftIcon={<Download />} />
	// <Divider />
	// <MenuItem primaryText="Remove" leftIcon={<Delete />} />

	loggedInView() {
		const { history, classes, theme, ...rest } = this.props;

		return (
			<div>
				{/* <List>
					{menuItems.map(menuItem => {
						if (menuItem.hasOwnProperty('subMenuItems')) {
							return (
								<SubMenu
									key={menuItem.key}
									caption={menuItem.caption}
									menuItems={menuItem.subMenuItems}
								/>
							);
						}

						return (
							<MenuItem key={menuItem.key} onClick={menuItem.onClick}>
								{menuItem.caption}
							</MenuItem>
						);
					})}
				</List> */}

				<ListItem
					className={this.isActive('') ? classes.selected : classes.menuItem}
					button
					onClick={() => this.props.toggleCloseNavigate('left', '/')}
					onKeyDown={this.props.toggleDrawer('left', false)}
					selected={this.isActive('')}
				>
					<ListItemIcon className={classes.icon}>
						<ActionHome />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.primary }} inset primary="Home" />
				</ListItem>

				{navItems.map((item, index) => {
					const { icon: Icon } = item;

					return (
						<ListItem
							key={index}
							className={
								this.isActive(item.route === '/' ? '' : item.name) ? classes.selected : classes.menuItem
							}
							button
							onClick={() => this.props.toggleCloseNavigate('left', item.route)}
							onKeyDown={this.props.toggleDrawer('left', false)}
							selected={this.isActive(item.route === '/' ? '' : item.name)}
						>
							<ListItemIcon className={classes.icon}>
								<Icon />
							</ListItemIcon>
							<ListItemText classes={{ primary: classes.primary }} inset primary={item.title} />
						</ListItem>
					);
				})}

				<Divider style={{ marginTop: 10 }} />
				<ListItem
					className={this.isActive('help') ? classes.selected : classes.menuItem}
					button
					onClick={() => this.props.toggleCloseNavigate('left', '/help')}
					onKeyDown={this.props.toggleDrawer('left', false)}
					selected={this.isActive('help')}
				>
					<ListItemIcon className={classes.icon}>
						<ActionHelp />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.primary }} inset primary="League Rules & FAQ" />
				</ListItem>
				{!!this.context.authUser && this.context.authUser.role === 'admin' ? (
					<ListItem
						className={this.isActive('admin') ? classes.selected : classes.menuItem}
						button
						onClick={() => this.props.toggleCloseNavigate('left', '/admin')}
						onKeyDown={this.props.toggleDrawer('left', false)}
						selected={this.isActive('admin')}
					>
						<ListItemIcon className={classes.icon}>
							<ActionLock />
						</ListItemIcon>
						<ListItemText classes={{ primary: classes.primary }} inset primary="Admin" />
					</ListItem>
				) : null}
				<Divider />
				<ListSubheader>Account</ListSubheader>
				<ListItem
					className={this.isActive('pw-change') ? classes.selected : classes.menuItem}
					button
					onClick={() => this.props.toggleCloseNavigate('left', '/pw-change')}
					onKeyDown={this.props.toggleDrawer('left', false)}
					selected={this.isActive('pw-change')}
				>
					<ListItemIcon className={classes.icon}>
						<ActionLockOpen />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.primary }} inset primary="Change Password" />
				</ListItem>
				<ListItem
					className={this.isActive('profile') ? classes.selected : classes.menuItem}
					button
					onClick={() => this.props.toggleCloseNavigate('left', '/profile')}
					onKeyDown={this.props.toggleDrawer('left', false)}
					selected={this.isActive('profile')}
				>
					<ListItemIcon className={classes.icon}>
						<SocialPerson />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.primary }} inset primary="Edit Profile" />
				</ListItem>
				<ListItem
					className={this.isActive('settings') ? classes.selected : classes.menuItem}
					button
					onClick={() => this.props.toggleCloseNavigate('left', '/settings')}
					onKeyDown={this.props.toggleDrawer('left', false)}
					selected={this.isActive('settings')}
				>
					<ListItemIcon className={classes.icon}>
						<ActionSettings />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.primary }} inset primary="Settings" />
				</ListItem>
				<ListItem
					className={this.isActive('payments') ? classes.selected : classes.menuItem}
					button
					onClick={() => this.props.toggleCloseNavigate('left', '/payments')}
					onKeyDown={this.props.toggleDrawer('left', false)}
					selected={this.isActive('payments')}
				>
					<ListItemIcon className={classes.icon}>
						<AttachMoney />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.primary }} inset primary="Payments" />
				</ListItem>
				<ListItem className={classes.menuItem} button onClick={this.signOut}>
					<ListItemIcon className={classes.icon}>
						<ActionExitToApp />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.primary }} inset primary="Sign Out" />
				</ListItem>

				{/*<MenuItem
					primaryText="Locations"
					leftIcon={
						<MapsPlace
							color={
								this.isActive('/locations') === true
									? this.props.muiTheme.palette.accent1Color
									: iconColor
							}
						/>
					}
					onTouchTap={this.props.handleClose}
					style={{ color: this.props.muiTheme.palette.textColor }}
					className={this.isActive('/locations') === true ? 'menu-item active' : 'menu-item'}
					containerElement={<Link exact={true.toString()} to="/locations" />}
				/>
				<MenuItem
					primaryText="Matches"
					leftIcon={
						<AvAlbum
							color={
								this.isActive('/matches') === true
									? this.props.muiTheme.palette.accent1Color
									: iconColor
							}
						/>
					}
					onTouchTap={this.props.handleClose}
					style={{ color: this.props.muiTheme.palette.textColor }}
					className={this.isActive('/matches') === true ? 'menu-item active' : 'menu-item'}
					containerElement={<Link exact={true.toString()} to="/matches" />}
				/>
				<MenuItem
					primaryText="Messages"
					leftIcon={
						<CommunicationMessage
							color={
								this.isActive('/messages') === true
									? this.props.muiTheme.palette.accent1Color
									: iconColor
							}
						/>
					}
					onTouchTap={this.props.handleClose}
					style={{ color: this.props.muiTheme.palette.textColor }}
					className={this.isActive('/messages') === true ? 'menu-item active' : 'menu-item'}
					containerElement={<Link exact={true.toString()} to="/messages" />}
				/>

				<MenuItem
					primaryText="Stats"
					leftIcon={
						<AvEqualizer
							color={
								this.isActive('/stats') === true ? this.props.muiTheme.palette.accent1Color : iconColor
							}
						/>
					}
					onTouchTap={this.props.handleClose}
					style={{ color: this.props.muiTheme.palette.textColor }}
					className={this.isActive('/stats') === true ? 'menu-item active' : 'menu-item'}
					containerElement={<Link exact={true.toString()} to="/stats" />}
				/>
				<MenuItem
					primaryText="Teams"
					leftIcon={
						<SocialPeople
							color={
								this.isActive('/teams') === true ? this.props.muiTheme.palette.accent1Color : iconColor
							}
						/>
					}
					onTouchTap={this.props.handleClose}
					style={{ color: this.props.muiTheme.palette.textColor }}
					className={this.isActive('/teams') === true ? 'menu-item active' : 'menu-item'}
					containerElement={<Link exact={true.toString()} to="/teams" />}
				/>
				<MenuItem
					primaryText="Tournaments"
					leftIcon={
						<ActionEvent
							color={
								this.isActive('/tournaments') === true
									? this.props.muiTheme.palette.accent1Color
									: iconColor
							}
						/>
					}
					onTouchTap={this.props.handleClose}
					style={{
						marginBottom: 8,
						color: this.props.muiTheme.palette.textColor
					}}
					className={this.isActive('/tournaments') === true ? 'menu-item active' : 'menu-item'}
					containerElement={<Link exact={true.toString()} to="/tournaments" />}
				/>
				<Divider />
				<MenuItem
					primaryText="Help & Feedback"
					leftIcon={
						<ActionHelp
							color={
								this.isActive('/help') === true ? this.props.muiTheme.palette.accent1Color : iconColor
							}
						/>
					}
					onTouchTap={this.props.handleClose}
					style={{
						marginTop: 8,
						marginBottom: 8,
						color: this.props.muiTheme.palette.textColor
					}}
					className={this.isActive('/help') === true ? 'menu-item active' : 'menu-item'}
					containerElement={<Link exact={true.toString()} to="/help" />}
				/>
				<Divider />
				<ListSubheader>Account</ListSubheader>
				<MenuItem
					primaryText="Change password"
					leftIcon={
						<ActionLockOpen
							color={
								this.isActive(routes.PASSWORD_CHANGE) === true
									? this.props.muiTheme.palette.accent1Color
									: iconColor
							}
						/>
					}
					onTouchTap={this.props.handleClose}
					style={{ color: this.props.muiTheme.palette.textColor }}
					className={this.isActive(routes.PASSWORD_CHANGE) === true ? 'menu-item active' : 'menu-item'}
					containerElement={<Link exact={true.toString()} to={routes.PASSWORD_CHANGE} />}
				/>
				<MenuItem
					primaryText="Edit profile"
					leftIcon={
						<SocialPerson
							color={
								this.isActive('/profile') === true
									? this.props.muiTheme.palette.accent1Color
									: iconColor
							}
						/>
					}
					onTouchTap={this.props.handleClose}
					style={{ color: this.props.muiTheme.palette.textColor }}
					className={this.isActive('/profile') === true ? 'menu-item active' : 'menu-item'}
					containerElement={<Link exact={true.toString()} to="/profile" />}
				/>
				<MenuItem
					primaryText="Settings"
					leftIcon={
						<ActionSettings
							color={
								this.isActive('/settings') === true
									? this.props.muiTheme.palette.accent1Color
									: iconColor
							}
						/>
					}
					onTouchTap={this.props.handleClose}
					style={{ color: this.props.muiTheme.palette.textColor }}
					className={this.isActive('/settings') === true ? 'menu-item active' : 'menu-item'}
					containerElement={<Link exact={true.toString()} to="/settings" />}
				/>
				<MenuItem
					primaryText="Sign out"
					leftIcon={
						<ActionExitToApp
							color={
								this.isActive('/sign-out') === true
									? this.props.muiTheme.palette.accent1Color
									: iconColor
							}
						/>
					}
					onTouchTap={() => {
						auth.signOut(() => {
							this.props.handleClose();
							this.props.alert.success('Successfully logged out');
							history.push(routes.SIGN_IN);
						});
					}}
					style={{
						paddingLeft: 10,
						color: this.props.muiTheme.palette.textColor
					}}
					className={this.isActive('/sign-out') === true ? 'menu-item active' : 'menu-item'}
        />*/}
			</div>
		);
	}

	loggedOutView() {
		const { classes, theme } = this.props;

		return (
			<div>
				{/*<ListItem
					button
					className={this.isActive('') ? classes.selected : classes.menuItem}
					onClick={() => this.props.toggleCloseNavigate('left', '/')}
					// onKeyDown={this.props.toggleDrawer('left', false)}
					selected={this.isActive('')}
				>
					<ListItemIcon className={classes.icon}>
						<ActionHome />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.primary }} inset primary="Home" />
				</ListItem>

				<Divider />*/}
				<ListSubheader>Account</ListSubheader>

				<ListItem
					className={this.isActive('login') ? classes.selected : classes.menuItem}
					button
					onClick={() => this.props.toggleCloseNavigate('left', '/login')}
					onKeyDown={this.props.toggleDrawer('left', false)}
					selected={this.isActive('login')}
				>
					<ListItemIcon className={classes.icon}>
						<ActionPowerSettingsNew />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.primary }} inset primary="Sign In" />
				</ListItem>
				<ListItem
					className={this.isActive('register') ? classes.selected : classes.menuItem}
					button
					onClick={() => this.props.toggleCloseNavigate('left', '/register')}
					onKeyDown={this.props.toggleDrawer('left', false)}
					selected={this.isActive('register')}
				>
					<ListItemIcon className={classes.icon}>
						<ActionExitToApp />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.primary }} inset primary="Sign Up" />
				</ListItem>
				<ListItem
					className={this.isActive('pw-forget') ? classes.selected : classes.menuItem}
					button
					onClick={() => this.props.toggleCloseNavigate('left', '/pw-forget')}
					onKeyDown={this.props.toggleDrawer('left', false)}
					selected={this.isActive('pw-forget')}
				>
					<ListItemIcon className={classes.icon}>
						<ActionLock />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.primary }} inset primary="Forgot Password" />
				</ListItem>

				{/*<MenuItem
					primaryText="Sign Up"
					leftIcon={
						<SocialPerson
							color={
								this.isActive(routes.SIGN_UP) === true
									? this.props.muiTheme.palette.accent1Color
									: iconColor
							}
						/>
					}
					onTouchTap={this.props.handleClose}
					style={{ color: this.props.muiTheme.palette.textColor }}
					className={this.isActive(routes.SIGN_UP) === true ? 'menu-item active' : 'menu-item'}
					containerElement={<Link exact={true.toString()} to={routes.SIGN_UP} />}
				/>
				<MenuItem
					primaryText="Forgot Password"
					leftIcon={
						<ActionLock
							color={
								this.isActive(routes.PASSWORD_FORGET) === true
									? this.props.muiTheme.palette.accent1Color
									: iconColor
							}
						/>
					}
					onTouchTap={this.props.handleClose}
					style={{ color: this.props.muiTheme.palette.textColor }}
					className={this.isActive(routes.PASSWORD_FORGET) === true ? 'menu-item active' : 'menu-item'}
					containerElement={<Link exact={true.toString()} to={routes.PASSWORD_FORGET} />}
				/>*/}
			</div>
		);
	}

	render() {
		const { classes, theme } = this.props;
		return (
			<Drawer
				// width={350}
				open={this.state.left}
				onClose={this.props.toggleDrawer('left', false)}
				// containerstyle={styles.containerStyle}
				className="drawer"
				classes={{
					paper: classes.drawerPaper
				}}
				ModalProps={{
					keepMounted: true // Better open performance on mobile.
				}}
			>
				<React.Fragment>
					{this.context.authUser && <UserProfile onCloseDrawer={this.props.toggleDrawer('left', false)} />}

					<List component="nav" style={{ width: 300 }}>
						{this.context.authUser ? this.loggedInView() : this.loggedOutView()}
					</List>
				</React.Fragment>
				{/*<UserProfile />
					<Menu>
						<MenuItem
							primaryText="Home"
							leftIcon={
								<ActionHome
									color={this.isActive('/') === true ? theme.palette.primary.main : iconColor}
								/>
							}
							onTouchTap={this.props.handleClose}
							style={{ color: theme.palette.text.primary }}
							className={this.isActive('/') === true ? 'menu-item active' : 'menu-item'}
							containerElement={<Link exact={true.toString()} to="/" />}
						/>

						{this.context.authUser ? this.loggedInView() : this.loggedOutView()}
						</Menu>*/}
			</Drawer>
		);
	}
}

NavDrawer.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

NavDrawer.contextTypes = {
	authUser: PropTypes.object,
	getUserProfile: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	checkAuthStatus: PropTypes.func,
	changeLoginState: PropTypes.func,
	signIn: PropTypes.func,
	signOut: PropTypes.func
};

const WithStyles = withStyles(styles)(NavDrawer);
const WithTheme = withTheme()(WithStyles);
const WithAlert = withAlert(WithTheme);
export default withRouter(WithAlert);
