// import { ApolloClient } from 'apollo-client';
// import { createHttpLink, HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { SubscriptionClient } from 'subscriptions-transport-ws';
// import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { getMainDefinition } from 'apollo-utilities';
import { ApolloLink, split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';

/**
 * Allows client to communicate to GraphQL server
 * @type {HttpLink}
 */
const httpLink = new HttpLink({
	uri: process.env.NODE_ENV === 'production' ? 'https://api.digitalpoolleague.com' : 'http://localhost:4000/graphql'
});

const uploadLink = new createUploadLink({
	uri: process.env.NODE_ENV === 'production' ? 'https://api.digitalpoolleague.com' : 'http://localhost:4000/graphql'
	// credentials: 'same-origin'
});

const middlewareLink = new ApolloLink((operation, forward) => {
	// get the authentication token from local storage if it exists
	const tokenValue = localStorage.getItem('DPLToken');
	// return the headers to the context so httpLink can read them
	operation.setContext({
		headers: {
			Authorization: tokenValue ? `Bearer ${tokenValue}` : ''
		}
	});
	return forward(operation);
});

const httpLinkAuth = middlewareLink.concat(httpLink);
const uploadLinkAuth = middlewareLink.concat(uploadLink);

// const authLink = setContext((_, { headers }) => {
// 	// get the authentication token from local storage if it exists
// 	const token = localStorage.getItem('DPLToken');
// 	// return the headers to the context so httpLink can read them
// 	return {
// 		headers: {
// 			...headers,
// 			authorization: token ? `Bearer ${token}` : ''
// 		}
// 	};
// });

/**
 * Allows for subscriptions to listen on web socket channel
 * @type {WebSocketLink}
 */
const wsLink = new WebSocketLink({
	uri:
		process.env.NODE_ENV === 'production'
			? 'wss://api.digitalpoolleague.com/graphql'
			: 'ws://localhost:4000/graphql',
	options: {
		reconnect: true,
		connectionParams: {
			Authorization: `Bearer ${localStorage.getItem('DPLToken')}`
		}
	}
});

/**
 * Dispatches query to appropriate link (ws or http)
 * @param  {Object} ({ query         } GraphQL query
 * @return {HttpLink | WebSocketLink} appropriate link based on query type
 */
const link = split(
	({ query }) => {
		const { kind, operation } = getMainDefinition(query);
		return kind === 'OperationDefinition' && operation === 'subscription';
	},
	wsLink,
	uploadLinkAuth,
	// authLink,
	httpLinkAuth
);

/**
 * GraphQL client for making requests to GraphQL server
 * @type {ApolloClient}
 */
const client = new ApolloClient({
	link: ApolloLink.from([link]),
	cache: new InMemoryCache(),
	// cache: new InMemoryCache({
	// 	dataIdFromObject: object => object.id
	// }),
	connectToDevTools: true,
	onError: e => {
		console.log(e.graphQLErrors);
	}
});

// import gql from "graphql-tag";

// https://www.npmjs.com/package/apollo-link-http
// const httpLink = createHttpLink({
//   uri: "http://localhost:5000/graphql",
//   credentials: "same-origin"
// });
// const httpLink = createHttpLink({ uri: 'http://localhost:4000', credentials: 'include' });
// const httpLink = new HttpLink({
// 	uri: process.env.NODE_ENV === 'production' ? 'https://api.digitalpoolleague.com' : 'http://localhost:4000'
// });

// // new HttpLink({
// // 	uri: 'https://w5xlvm3vzz.lp.gql.zone/graphql',
// // 	credentials: 'same-origin'
// // })

// const wsLink = new WebSocketLink({
// 	uri:
// 		process.env.NODE_ENV === 'production'
// 			? 'wss://api.digitalpoolleague.com/graphql'
// 			: `ws://localhost:4000/graphql`,
// 	options: {
// 		reconnect: true
// 	}
// });

// const authLink = setContext((_, { headers }) => {
// 	// get the authentication token from local storage if it exists
// 	// const token = localStorage.getItem('token');
// 	const token =
// 		process.env.NODE_ENV === 'production'
// 			? 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJjamg0ZHdtenAwMDcxMDc5NjI4N2k0bWg5IiwiaWF0IjoxNTI2Mjc5MDU2fQ.HWoAem27RbaRbSYRXiNIl4lTmv1XRiVhPKgT3yvwXeQ'
// 			: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InNlcnZpY2UiOiJkcGwtZ3JhcGhxbEBwcm9kIiwicm9sZXMiOlsiYWRtaW4iXX0sImlhdCI6MTUyNTkzNjIyOCwiZXhwIjoxNTI2NTQxMDI4fQ.6R0R1RakBXne_XRQmOHfB95_VBwmQaVxa2_eMTLd-OI';
// 	// return the headers to the context so httpLink can read them
// 	return {
// 		headers: {
// 			...headers,
// 			authorization: token ? `Bearer ${token}` : ''
// 		}
// 	};
// });

// const terminatingLink = split(
// 	({ query }) => {
// 		const { kind, operation } = getMainDefinition(query);
// 		return kind === 'OperationDefinition' && operation === 'subscription';
// 	},
// 	wsLink,
// 	httpLink,
// 	authLink
// );
// const GRAPHQL_ENDPOINT =
// 	process.env.NODE_ENV === 'production' ? 'wss://api.digitalpoolleague.com/graphql' : `ws://localhost:4000/graphql`;
// const wsClient = new SubscriptionClient(GRAPHQL_ENDPOINT, {
// 	reconnect: true
// });

// const client = new ApolloClient({
// 	link: ApolloLink.from([
// 		onError(({ graphQLErrors, networkError }) => {
// 			if (graphQLErrors)
// 				graphQLErrors.map(({ message, locations, path }) =>
// 					console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
// 				);
// 			if (networkError) console.log(`[Network error]: ${networkError}`);
// 		}),
// 		new HttpLink({
// 			uri: process.env.NODE_ENV === 'production' ? 'https://api.digitalpoolleague.com' : 'http://localhost:4000'
// 		}),
// 		new WebSocketLink(wsClient)
// 		// setContext((_, { headers }) => {
// 		// 	// get the authentication token from local storage if it exists
// 		// 	// const token = localStorage.getItem('token');
// 		// 	const token =
// 		// 		process.env.NODE_ENV === 'production'
// 		// 			? 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJjamg0ZHdtenAwMDcxMDc5NjI4N2k0bWg5IiwiaWF0IjoxNTI2Mjc5MDU2fQ.HWoAem27RbaRbSYRXiNIl4lTmv1XRiVhPKgT3yvwXeQ'
// 		// 			: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InNlcnZpY2UiOiJkcGwtZ3JhcGhxbEBwcm9kIiwicm9sZXMiOlsiYWRtaW4iXX0sImlhdCI6MTUyNTkzNjIyOCwiZXhwIjoxNTI2NTQxMDI4fQ.6R0R1RakBXne_XRQmOHfB95_VBwmQaVxa2_eMTLd-OI';
// 		// 	// return the headers to the context so httpLink can read them
// 		// 	return {
// 		// 		headers: {
// 		// 			...headers,
// 		// 			authorization: token ? `Bearer ${token}` : ''
// 		// 		}
// 		// 	};
// 		// })
// 	]),
// 	cache: new InMemoryCache(),
// 	// ssrMode: true,
// 	// ssrForceFetchDelay: 100,
// 	connectToDevTools: true,
// 	queryDeduplication: true
// });

// client
//   .query({
//     query: gql`
//       {
//         allPosts {
//           title
//           views
//         }
//       }
//     `
//   })
//   .then(console.log);

export default client;
