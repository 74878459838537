import React, { Component } from 'react';
import Message from './message';
import ChatInput from './ChatInput';

class MessagesContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			messages: this.props.messages || []
		};
		this.sendHandler = this.sendHandler.bind(this);
	}

	sendHandler(message) {
		const messageObject = {
			// username: this.props.username,
			text: message,
			avatarImg: null
		};
		messageObject.fromMe = true;
		this.addMessage(messageObject);
	}

	addMessage(message) {
		this.setState({ messages: this.state.messages.concat(message) });
	}

	componentDidUpdate() {
		// There is a new message in the state, scroll to bottom of list
		const objDiv = document.getElementById('messages');
		objDiv.scrollTop = objDiv.scrollHeight;
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.messages !== this.props.messages) {
			this.setState({
				messages: nextProps.messages
			});
		}
	}

	render() {
		const { messages } = this.state;
		// const { height } = this.props;
		const messages2 = messages.concat({ ...messages[messages.length - 1], typing: true });
		return (
			<div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
				<div
					id="messages"
					className="messages-container"
					style={{ flexGrow: 1, backgroundColor: '#fff', borderRadius: '0px 8px 0px 0px', maxHeight: 600 }}
				>
					{messages2.map((message, index) => <Message key={index} {...message} />)}
				</div>

				<ChatInput onSend={this.sendHandler} />
			</div>
		);
	}
}

export default MessagesContainer;
