import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const styles = {
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: 'transparent'
		// borderTop: '1px solid rgba(255, 255, 255, 0.15)'
	},
	label: {
		fontSize: 13
		// fontWeight: 400
	},
	textColorPrimary: {
		color: '#fff'
	},
	textColorSecondary: {
		color: '#999',
		'&:hover': {
			color: '#fff',
			transition: 'all 300ms cubic-bezier(0.4, 0.0, 0.2, 1)'
		}
	}
};

class CenteredTabs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: 0
		};
		this.overviewRef = React.createRef();
		this.bracketsRef = React.createRef();
		this.standingsRef = React.createRef();
		this.scoresRef = React.createRef();
		this.streamRef = React.createRef();
		this.statsRef = React.createRef();
		this.playersRef = React.createRef();

		this.handleActive = this.handleActive.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.isActive = this.isActive.bind(this);
	}

	handleActive(tab) {
		const route = tab.props['data-route'] || '/';
		// const match = tab.props['data-match'];
		let urlPath = window.location.href;
		let parsed = urlPath.split('/');
		let newUrl = `/${parsed[3]}/${parsed[4]}${route}`;
		const location = {
			pathname: newUrl
			// state: { fromDashboard: true }
		};
		this.props.history.replace(location);
	}

	handleChange(event, value) {
		this.setState({ value });
		const refName = `${value}Ref`;
		const ref = this[refName].current;
		this.handleActive(ref);
	}

	isActive(tab) {
		const activeTab = this.state.activeTab;
		return tab === activeTab ? 'tab active' : 'tab';
	}

	componentWillMount() {
		let urlPath = window.location.pathname;
		let currentTab = urlPath.split('/')[3];
		this.setState({ value: currentTab || 'overview' });
	}

	render() {
		const { classes, theme } = this.props;

		return (
			<Paper className={classes.root} elevation={0}>
				<Tabs
					value={this.state.value}
					onChange={this.handleChange}
					indicatorColor="secondary"
					textColor="secondary"
					scrollable
					scrollButtons="off"
					fullWidth
					// centered
					// scrollable
					// scrollButtons="on"
					// style={{ display: 'flex', justifyContent: 'center' }}
				>
					<Tab
						label="Overview"
						data-route="/overview"
						value="overview"
						data-match={this.props.match}
						ref={this.overviewRef}
						classes={{
							label: classes.label,
							textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
							textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
						}}
						className={`${this.isActive('overview')}`}
					/>
					<Tab
						label="Brackets"
						data-route="/brackets"
						value="brackets"
						data-match={this.props.match}
						ref={this.bracketsRef}
						classes={{
							label: classes.label,
							textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
							textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
						}}
						className={`${this.isActive('brackets')}`}
					/>
					<Tab
						label="Standings"
						data-route="/standings"
						value="standings"
						data-match={this.props.match}
						ref={this.standingsRef}
						classes={{
							label: classes.label,
							textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
							textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
						}}
						className={`${this.isActive('standings')}`}
					/>
					<Tab
						label="Live Scores"
						data-route="/scores"
						value="scores"
						data-match={this.props.match}
						ref={this.scoresRef}
						classes={{
							label: classes.label,
							textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
							textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
						}}
						className={`${this.isActive('scores')}`}
					/>
					<Tab
						label="Live Stream"
						data-route="/stream"
						value="stream"
						data-match={this.props.match}
						ref={this.streamRef}
						classes={{
							label: classes.label,
							textColorPrimary: classes.textColorPrimary,
							textColorSecondary: classes.textColorSecondary
						}}
						className={`${this.isActive('stream')}`}
					/>
					<Tab
						label="Stats"
						data-route="/stats"
						value="stats"
						data-match={this.props.match}
						ref={this.statsRef}
						classes={{
							label: classes.label,
							textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
							textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
						}}
						className={`${this.isActive('stats')}`}
					/>
					<Tab
						label="Players"
						data-route="/players"
						value="players"
						data-match={this.props.match}
						ref={this.playersRef}
						classes={{
							label: classes.label,
							textColorPrimary: classes.textColorPrimary,
							textColorSecondary: classes.textColorSecondary
						}}
						className={`${this.isActive('players')}`}
					/>
				</Tabs>
			</Paper>
		);
	}
}

CenteredTabs.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CenteredTabs);
