import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
// import Card from '@material-ui/core/Card';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { ZeroState, Card } from '../../../components/chrome';
import eightBall from '../../../assets/images/ball/eight-ball.png';
import nineBall from '../../../assets/images/ball/nine-ball.png';
import tenBall from '../../../assets/images/ball/ten-ball.png';
import oneBall from '../../../assets/images/ball/one-ball.png';
import * as routes from '../../../config/routes';
import StatContainer from '../StatContainer';

const styles = theme => ({
	listItem: {
		// padding: 0
	},
	cardNarrow: {
		margin: '24px 0px',
		padding: '5px 10px'
	}
});

class TeamStatsCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			matchWinPercentage: 0,
			gameWinPercentage: 0
		};
	}

	componentDidMount() {
		this.setState({
			matchWinPercentage: 65,
			gameWinPercentage: 72
		});
	}

	render() {
		const { classes, stats } = this.props;

		return (
			<Card nopadding>
				<Grid container spacing={24} style={{ marginBottom: 0 }}>
					<Grid item xs={12} sm={12}>
						<h3 style={{ marginLeft: 24, marginTop: 20, marginBottom: 20 }}>Team stats</h3>

						<Grid container spacing={24}>
							<Grid item xs={6}>
								<StatContainer
									title="Matches"
									winPercentage={this.state.matchWinPercentage}
									trailColor="#d2e7ff"
									strokeColor="#3186ea"
									centered
								/>
							</Grid>
							<Grid item xs={6}>
								<StatContainer
									title="Games"
									winPercentage={this.state.gameWinPercentage}
									trailColor="#fff6cf"
									strokeColor="#ffcd00"
									centered
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Link to={`${routes.STATS_TEAM}`}>
					<Button primary="true" style={{ margin: 10 }}>
						View team stats
					</Button>
				</Link>
			</Card>
		);
	}
}

TeamStatsCard.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TeamStatsCard);
