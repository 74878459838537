import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { auth } from '../../firebase';
import * as routes from '../../config/routes';

const styles = {
	container: {
		position: 'relative'
	},
	heading: {
		marginTop: 0,
		marginBottom: 0,
		textAlign: 'left',
		display: 'inline-block',
		color: 'black',
		fontFamily: 'Roboto, sans-serif',
		fontWeight: 300,
		fontSize: 30
	},
	headerLight: {
		marginRight: 50,
		marginTop: 30,
		marginBottom: 10,
		textAlign: 'left',
		display: 'inline-block',
		color: 'black',
		fontFamily: 'din_lightregular, Roboto, sans-serif',
		fontWeight: 300,
		fontSize: 30
	},
	card: {
		margin: '24px 0px',
		padding: '24px'
	}
};

const PasswordForgetPage = () => <PasswordForgetForm />;

const updateByPropertyName = (propertyName, value) => () => ({
	[propertyName]: value
});

const INITIAL_STATE = {
	email: '',
	error: null
};

class PasswordForgetForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			dialogMessage: '',
			...INITIAL_STATE
		};
	}

	handleClose = () => {
		this.setState({
			open: false
		});
	};

	handleClick = () => {
		this.setState({
			open: true
		});
	};

	onSubmit = event => {
		const { email } = this.state;

		auth
			.resetPassword(email)
			.then(() => {
				this.setState({
					open: true,
					dialogMessage: `Password reset instructions sent to ${email}.`,
					...INITIAL_STATE
				});
			})
			.catch(error => {
				this.setState(updateByPropertyName('error', error));
			});

		event.preventDefault();
	};

	render() {
		const { email, error, open, dialogMessage } = this.state;

		const isInvalid = email === '';

		return (
			<div className="page-container">
				<div style={styles.container} className="main-content container">
					<h2 style={styles.headerLight}>Forgot password</h2>
					<Card style={styles.card}>
						<Dialog
							title="Password Reset"
							actions={
								<Button color="primary" onClick={this.handleClose}>
									OK
								</Button>
							}
							modal={false}
							open={open}
							onRequestClose={this.handleClose}
						>
							{dialogMessage}
						</Dialog>

						<form onSubmit={this.onSubmit}>
							<input
								value={this.state.email}
								onChange={event => this.setState(updateByPropertyName('email', event.target.value))}
								type="text"
								placeholder="Email Address"
							/>
							<Button label="Reset My Password" primary={true} disabled={isInvalid} type="submit" />
							{error && <p style={{ color: 'red' }}>ERROR: {error.message}</p>}
						</form>
					</Card>
				</div>
			</div>
		);
	}
}

const PasswordForgetLink = () => (
	<p>
		<Link to={routes.PASSWORD_FORGET} style={{ fontWeight: 700 }}>
			Forgot Password?
		</Link>
	</p>
);

export default PasswordForgetPage;

export { PasswordForgetForm, PasswordForgetLink };
