import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Flag from 'react-flags';
import { isoCountries } from '../../utils/isoCountryCodes';

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function sort(arr, prop) {
	console.log(arr);
	prop = prop.split('.');
	var len = prop.length;

	arr.sort(function(a, b) {
		var i = 0;
		while (i < len) {
			a = a[prop[i]];
			b = b[prop[i]];
			i++;
		}
		if (a < b) {
			return -1;
		} else if (a > b) {
			return 1;
		} else {
			return 0;
		}
	});
	return arr;
}

function stableSort(array, cmp) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	},
	card: {
		// padding: 10,
		// margin: 24,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 0,
		[theme.breakpoints.up('sm')]: {
			borderRadius: 8
		},
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},
	cell: {
		padding: '4px 30px 4px 20px',
		fontSize: 13
	},
	avatar: {
		width: 30,
		height: 30,
		fontSize: 14,
		backgroundColor: '#44475b',
		color: '#fff'
	}
});
class TeamsList extends Component {
	render() {
		const { classes, history, teams, order, orderBy, page, rowsPerPage } = this.props;

		return (
			<React.Fragment>
				{stableSort(teams, getSorting(order, orderBy))
					.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					.map((team, index) => {
						const countryName = isoCountries[team.location.country.toLowerCase()] || '';

						if (team) {
							return (
								<TableRow
									key={team.id}
									hover
									onClick={() => history.push(`${this.props.match.url}/${team.slug}`)}
									style={{ cursor: 'pointer', transition: 'all 150ms ease' }}
									tabIndex={-1}
								>
									{/*<TableCell className={classes.cell}>{index + 1}</TableCell>*/}
									<TableCell className={classes.cell}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<span style={{ marginRight: 15, display: 'flex' }}>
												<Avatar size="small" className={classes.avatar}>
													{team.name.slice(0, 1)}
												</Avatar>
											</span>
											<span>{team.name}</span>
										</div>
									</TableCell>
									<TableCell className={classes.cell} numeric>
										{team.number}
									</TableCell>
									<TableCell className={classes.cell}>
										{team.isActive === true ? 'Active' : 'Not Active'}
									</TableCell>
									<TableCell className={classes.cell}>
										{(team.captain && team.captain.name) || 'N/A'}
									</TableCell>
									<TableCell className={classes.cell}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<Flag
												name={countryName || 'Unknown'}
												format="png"
												pngSize={24}
												shiny={false}
												alt={team.location.country}
												className="flag"
												basePath="/images/flags"
											/>
											<span style={{ marginLeft: 10 }}>
												{(team.location && team.location.name) || 'N/A'}
											</span>
										</div>
									</TableCell>

									{/*<TableCell className={classes.cell}>{team.tournaments.length}</TableCell>*/}
									<TableCell className={classes.cell}>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<Button
												color="primary"
												size="small"
												onClick={e => {
													e.preventDefault();
													e.stopPropagation();
													history.push(`/teams/${team.slug}/edit`);
												}}
											>
												Edit
											</Button>
											<Button
												color="primary"
												size="small"
												onClick={e => {
													e.preventDefault();
													e.stopPropagation();
													this.handleConfirmDialog(team);
													// history.push(`/teams/${team.slug}/edit`);
												}}
												style={{ color: 'rgb(225, 0, 80)' }}
											>
												Remove
											</Button>
										</div>
									</TableCell>
								</TableRow>
							);
						}
					})}
			</React.Fragment>
		);
	}
}

TeamsList.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles, { withTheme: true })(TeamsList);
export default withRouter(WithStyles);
