import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { SectionHeader, SectionContent, Card, ZeroState } from '../../components/chrome';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import NavigationArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import bgImage from '../../assets/images/abstract5.jpg';
import SimpleGoogleMap from '../../components/SimpleGoogleMap';
import { StaticGoogleMap, Marker, Path } from 'react-static-google-map';
import LocationOn from '@material-ui/icons/LocationOn';
import { getMatchQuery } from './queries/getMatchQuery';
import * as routes from '../../config/routes';
import { isoCountries, getGameType, displayEnumType } from '../../utils';
import eightBallHeader from '../../assets/images/eight-ball-header-side.png';
import nineBallHeader from '../../assets/images/nine-ball-header-side.png';
import tenBallHeader from '../../assets/images/ten-ball-header-side.png';
import currentUser from '../../helpers/currentUser';
import moment from 'moment';

const styles = theme => ({
	toolbarTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	avatar: {
		backgroundColor: '#44475b',
		color: '#fff'
	},
	avatarLarge: {
		width: 60,
		height: 60,
		backgroundColor: '#44475b',
		color: '#fff'
	},
	backLink: {
		display: 'inline-block'
	},
	label: {
		fontSize: 13
		// fontWeight: 400
	},
	textColorPrimary: {
		color: '#fff'
	},
	textColorSecondary: {
		color: '#999',
		'&:hover': {
			color: '#fff',
			transition: 'all 300ms cubic-bezier(0.4, 0.0, 0.2, 1)'
		}
	},
	header: {
		[theme.breakpoints.up('sm')]: {
			backgroundImage: `url(${eightBallHeader})`
		},
		backgroundRepeat: 'no-repeat',
		backgroundSize: '20%',
		backgroundPosition: 'right'
	}
});

class MatchDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: 0
		};
		// this.handleActive = this.handleActive.bind(this);
		this.handleChange = this.handleChange.bind(this);
		// this.isActive = this.isActive.bind(this);
		this.handleCloseDialog = this.handleCloseDialog.bind(this);
		this.handleConfirmDialog = this.handleConfirmDialog.bind(this);
	}

	getGameType = type => {
		return type.indexOf('EIGHT_BALL') !== -1
			? eightBallHeader
			: type.indexOf('NINE_BALL') !== -1
			? nineBallHeader
			: type.indexOf('TEN_BALL') !== -1
			? tenBallHeader
			: null;
	};

	handleChange(event, value) {
		this.setState({ value });
		// const refName = `${value}Ref`;
		// const ref = this[refName].current;
		// this.handleActive(ref);
	}

	handleCloseDialog() {
		this.setState({
			dialogOpen: false
		});
	}

	handleConfirmDialog() {
		this.setState({
			dialogOpen: true
		});
	}

	renderTabs() {
		const { value } = this.state;
		const { classes } = this.props;
		return (
			<Tabs
				value={value}
				onChange={this.handleChange}
				indicatorColor="secondary"
				textColor="secondary"
				scrollable
				scrollButtons="off"
				// className={classes.tabContainer}
				fullWidth
			>
				<Tab
					label="Details"
					classes={{
						label: classes.label,
						textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
						textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
					}}
					style={{ maxWidth: 200 }}
				/>
				<Tab
					label="Stats"
					classes={{
						label: classes.label,
						textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
						textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
					}}
					style={{ maxWidth: 200 }}
				/>
				<Tab
					label="Timeline"
					classes={{
						label: classes.label,
						textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
						textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
					}}
					style={{ maxWidth: 200 }}
				/>
			</Tabs>
		);
	}

	render() {
		const { classes, match, history, currentUser } = this.props;

		return (
			<Query
				query={getMatchQuery}
				fetchPolicy="cache-and-network"
				notifyOnNetworkStatusChange={true}
				variables={{ id: match.params.id }}
			>
				{({ loading, error, data, subscribeToMore }) => {
					if (loading) return <LinearProgress mode="indeterminate" color="primary" />;
					if (error) return <div style={styles.container}>Error: {error.message}</div>;
					if (data && !data.match) {
						return <div>Match not found.</div>;
					}

					// const title = data.match.players
					// 	.map(player => {
					// 		return player.name;
					// 	})
					// 	.join(' vs. ');

					return (
						<SectionHeader
							title="Match Overview"
							backButton={true}
							backPath="/matches"
							rightButton={
								<Button
									variant="outlined"
									color="secondary"
									onClick={() => history.push('/matches/new')}
								>
									Create Match
								</Button>
							}
							// tabs={this.renderTabs()}
						>
							<SectionContent>
								<Grid container justify="center" spacing={24}>
									{/*<Grid item xs={11} sm={12}>
										<Link to={'/challenges'} className={classes.backLink}>
											<Typography variant="subtitle1" gutterBottom>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<NavigationArrowBack style={{ width: 16, height: 16 }} />
													<span style={{ marginLeft: 5 }}>Back to Challenges list</span>
												</div>
											</Typography>
										</Link>
						</Grid>*/}

									<Grid item xs={12} sm={8}>
										<Grid container justify="center" spacing={24}>
											<Grid item xs={12}>
												<Card nopadding>
													<div
														className={classes.header}
														style={{
															backgroundImage: `url(${this.getGameType(
																data.match.gameType
															)})`
														}}
													>
														<Typography
															variant="h6"
															color="inherit"
															className={classes.toolbarTitle}
															style={{
																paddingTop: 20,
																paddingLeft: 20,
																paddingRight: 20,
																paddingBottom: 20
															}}
														>
															<span>Match Details</span>
															<div style={{ display: 'flex', alignItems: 'center' }}>
																<Button
																	variant="text"
																	color="primary"
																	size="small"
																	onClick={e => {
																		e.preventDefault();
																		history.push(`/matches/${data.match.id}/edit`);
																	}}
																>
																	Edit
																</Button>
																<Button
																	variant="text"
																	color="primary"
																	size="small"
																	onClick={e => {
																		e.preventDefault();
																		this.handleConfirmDialog(data.match);
																	}}
																	style={{
																		marginLeft: 10,
																		// borderColor: 'rgb(225, 0, 80)',
																		color: 'rgb(225, 0, 80)'
																	}}
																>
																	Remove
																</Button>
															</div>
														</Typography>

														<div
															style={{
																paddingTop: 0,
																paddingLeft: 20,
																paddingRight: 20,
																paddingBottom: 20
															}}
														>
															<Grid container spacing={16}>
																<Grid item xs={12} sm={4}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Type
																	</Typography>
																	<Typography variant="subtitle1" gutterBottom>
																		{displayEnumType(data.match.type) || 'N/A'}
																	</Typography>
																</Grid>
																<Grid item xs={12} sm={4}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Game Type
																	</Typography>
																	<Typography variant="subtitle1" gutterBottom>
																		{displayEnumType(data.match.gameType) || 'N/A'}
																	</Typography>
																</Grid>
																<Grid item xs={12} sm={4}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Format
																	</Typography>
																	<Typography variant="subtitle1" gutterBottom>
																		{displayEnumType(data.match.format) || 'N/A'}
																	</Typography>
																</Grid>
																<Grid item xs={12} sm={4}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Status
																	</Typography>
																	<Typography variant="subtitle1" gutterBottom>
																		{displayEnumType(data.match.status) || 'N/A'}
																	</Typography>
																</Grid>
																<Grid item xs={12} sm={4}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Verified
																	</Typography>
																	<Typography variant="subtitle1" gutterBottom>
																		{data.match.verified === true
																			? 'Verified'
																			: 'Not Verified'}
																	</Typography>
																</Grid>
																<Grid item xs={12} sm={4}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Start Time
																	</Typography>
																	<Typography variant="subtitle1" gutterBottom>
																		{moment(data.match.startTime).format('lll')}
																	</Typography>
																</Grid>
															</Grid>
														</div>
													</div>
													<div
														style={{
															padding: 20,
															display: 'flex',
															alignItems: 'center',
															backgroundColor: '#fffcf0'
														}}
													>
														<Button
															variant="contained"
															color="primary"
															size="large"
															style={{ width: '100%' }}
															onClick={event => this.acceptChallenge(data.challenge)}
														>
															RSVP
														</Button>
														<Button
															variant="outlined"
															color="primary"
															size="large"
															style={{
																width: '100%',
																marginLeft: 10
																// borderColor: 'rgb(225, 0, 80)',
																// color: 'rgb(225, 0, 80)'
															}}
															onClick={() =>
																this.handleDeclineConfirmDialog(data.challenge)
															}
															// onClick={event => this.declineChallenge(data.challenge)}
														>
															Check-in
														</Button>
													</div>
												</Card>
											</Grid>

											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20
														}}
													>
														Scores
													</Typography>

													{data.match.games.length > 0 ? (
														<List
															defaultValue={0}
															style={{
																// marginTop: 20,
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															{data.match.games.map((game, index) => {
																return (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																		onClick={() =>
																			history.push(`/games/${game.id}`)
																		}
																		button
																	>
																		<Avatar size="small" className={classes.avatar}>
																			{index + 1}
																		</Avatar>
																		<ListItemText
																			primary={game.id}
																			secondary={moment(game.createdAt).format(
																				'lll'
																			)}
																		/>
																	</ListItem>
																);
															})}
														</List>
													) : (
														<ZeroState
															title="No scores exist for this match yet"
															style={{ margin: 15 }}
														/>
													)}
												</Card>
											</Grid>

											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20
														}}
													>
														Stats
													</Typography>

													{data.match.games.length > 0 ? (
														<List
															defaultValue={0}
															style={{
																// marginTop: 20,
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															{data.match.games.map((game, index) => {
																return (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																		onClick={() =>
																			history.push(`/games/${game.id}`)
																		}
																		button
																	>
																		<Avatar size="small" className={classes.avatar}>
																			{index + 1}
																		</Avatar>
																		<ListItemText
																			primary={game.id}
																			secondary={moment(game.createdAt).format(
																				'lll'
																			)}
																		/>
																	</ListItem>
																);
															})}
														</List>
													) : (
														<ZeroState
															title="No stats exist for this match yet"
															style={{ margin: 15 }}
														/>
													)}
												</Card>
											</Grid>

											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20
														}}
													>
														Games
													</Typography>

													{data.match.games.length > 0 ? (
														<List
															defaultValue={0}
															style={{
																// marginTop: 20,
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															{data.match.games.map((game, index) => {
																return (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																		onClick={() =>
																			history.push(`/games/${game.id}`)
																		}
																		button
																	>
																		<Avatar size="small" className={classes.avatar}>
																			{index + 1}
																		</Avatar>
																		<ListItemText
																			primary={game.id}
																			secondary={moment(game.createdAt).format(
																				'lll'
																			)}
																		/>
																	</ListItem>
																);
															})}
														</List>
													) : (
														<ZeroState
															title="No games have been played yet"
															style={{ margin: 15 }}
														/>
													)}
												</Card>
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Grid container justify="center" spacing={24}>
											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20
														}}
													>
														Division
													</Typography>

													{data.match.division ? (
														<List
															defaultValue={0}
															style={{
																marginTop: 20,
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															<ListItem
																className={classes.listItem}
																onClick={() =>
																	history.push(
																		`/divisions/${data.match.division.slug}`
																	)
																}
																button
															>
																<Avatar
																	size="small"
																	src={getGameType(data.match.division.type)}
																	className={classes.avatar}
																/>
																<ListItemText
																	primary={data.match.division.name}
																	secondary={displayEnumType(
																		data.match.division.type
																	)}
																/>
															</ListItem>
														</List>
													) : (
														<ZeroState
															title="This match does not have a division"
															style={{ margin: 15 }}
														>
															<Button
																variant="outlined"
																color="primary"
																size="small"
																onClick={e => {
																	e.preventDefault();
																	history.push(`/divisions/new`);
																}}
															>
																Add Division
															</Button>
														</ZeroState>
													)}
												</Card>
											</Grid>

											<Grid item xs={12}>
												<Card className={classes.card} elevation={0}>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
													>
														Tournament
													</Typography>
													<br />
													<Grid container spacing={16}>
														<Grid item xs={12}>
															<Typography
																variant="caption"
																className="caption"
																gutterBottom
															>
																Name
															</Typography>
															<Typography variant="subtitle1" gutterBottom>
																{(data.match.tournament &&
																	data.match.tournament.name) ||
																	'N/A'}
															</Typography>
														</Grid>
													</Grid>
												</Card>
											</Grid>

											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20,
															paddingBottom: 20
														}}
													>
														Location
													</Typography>
													{data.match && data.match.location ? (
														<List
															defaultValue={0}
															style={{
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															<ListItem
																className={classes.listItem}
																onClick={() =>
																	history.push(
																		`/locations/${data.match.location.slug}`
																	)
																}
																button
															>
																<Avatar size="small" className={classes.avatar}>
																	<LocationOn
																		style={{
																			height: 22
																		}}
																	/>
																</Avatar>
																<ListItemText
																	primary={data.match.location.name}
																	secondary={`${data.match.location.address}, ${
																		data.match.location.city
																	}, ${data.match.location.state} ${
																		data.match.location.zip
																	}`}
																/>
															</ListItem>
														</List>
													) : (
														<ZeroState
															title="No location added yet"
															style={{
																marginLeft: 15,
																marginRight: 15,
																marginBottom: 15
															}}
														/>
													)}
												</Card>
											</Grid>

											<Grid item xs={12}>
												<Card nopadding style={{ height: 'auto' }}>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20
														}}
													>
														Players
													</Typography>
													<br />
													<List
														defaultValue={0}
														style={{
															paddingTop: 0,
															paddingLeft: 0,
															paddingRight: 0,
															paddingBottom: 10
														}}
													>
														{data.match.players.map((player, index) => {
															return (
																<ListItem
																	key={index}
																	className={classes.listItem}
																	onClick={() =>
																		history.push(`/players/${player.username}`)
																	}
																	button
																>
																	<Avatar
																		size="small"
																		className={classes.avatar}
																		src={player.avatarImg}
																	>
																		{player.name.slice(0, 1)}
																	</Avatar>
																	<ListItemText
																		primary={player.name}
																		secondary={player.email}
																	/>
																</ListItem>
															);
														})}
													</List>
												</Card>
											</Grid>
											<Grid item xs={12}>
												<Card nopadding style={{ height: 'auto' }}>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20
														}}
													>
														Teams
													</Typography>
													<br />
													<List
														defaultValue={0}
														style={{
															paddingTop: 0,
															paddingLeft: 0,
															paddingRight: 0,
															paddingBottom: 10
														}}
													>
														{data.match.teams.map((team, index) => {
															return (
																<ListItem
																	key={index}
																	className={classes.listItem}
																	onClick={() => history.push(`/teams/${team.slug}`)}
																	button
																>
																	<Avatar size="small" className={classes.avatar}>
																		{team.name.slice(0, 1)}
																	</Avatar>
																	<ListItemText primary={team.name} />
																</ListItem>
															);
														})}
													</List>
												</Card>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</SectionContent>
						</SectionHeader>
					);
				}}
			</Query>
		);
	}
}

MatchDetail.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithRouter = withRouter(MatchDetail);
const WithStyles = withStyles(styles)(WithRouter);
export default currentUser(WithStyles);
