import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { SectionHeader, Card, ZeroState } from '../../components/chrome';
import Button from '@material-ui/core/Button';

function TabContainer(props) {
	return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired
};

const styles = theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		borderTop: '1px solid rgba(0, 0, 0, 0.12)',
		borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
	}
});

class SimpleTabs extends React.Component {
	state = {
		value: 0
	};

	handleChange = (event, value) => {
		this.setState({ value });
	};

	render() {
		const { classes } = this.props;
		const { value } = this.state;

		return (
			<div>
				<div className={classes.root}>
					<Grid container justify="center">
						<Grid item xs={12} sm={12} md={11} lg={10}>
							<Tabs value={value} onChange={this.handleChange}>
								<Tab label="Item One" />
								<Tab label="Item Two" />
								<Tab label="Item Three" />
							</Tabs>
						</Grid>
					</Grid>
				</div>
				<div>
					{value === 0 && (
						<TabContainer>
							<div style={{ paddingTop: 24, paddingBottom: 24, backgroundColor: '#f2f4f5' }}>
								<Grid container justify="center">
									<Grid item xs={12} md={11} lg={10}>
										<Grid container justify="center" spacing={24}>
											<Grid item xs={12} sm={4}>
												<Grid container justify="center" spacing={24}>
													<Grid item xs={12}>
														<Card>
															<Typography
																variant="title"
																color="inherit"
																className={classes.toolbarTitle}
															>
																Division Operator
															</Typography>
															<br />
															<Grid container spacing={16}>
																<Grid item xs={12}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Name
																	</Typography>
																	<Typography variant="subheading" gutterBottom>
																		name
																	</Typography>
																</Grid>
															</Grid>
														</Card>
													</Grid>
												</Grid>
											</Grid>

											<Grid item xs={12} sm={8}>
												<Grid container justify="center" spacing={24}>
													<Grid item xs={12}>
														<Card>
															<Typography
																variant="title"
																color="inherit"
																className={classes.toolbarTitle}
															>
																<span>Division Details</span>
															</Typography>
															<br />
															<Grid container spacing={16}>
																<Grid item xs={12} sm={4}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Name
																	</Typography>
																	<Typography variant="subheading" gutterBottom>
																		name
																	</Typography>
																</Grid>
																<Grid item xs={12} sm={8}>
																	<Typography
																		variant="caption"
																		className="caption"
																		gutterBottom
																	>
																		Description
																	</Typography>
																	<Typography variant="subheading" gutterBottom>
																		description
																	</Typography>
																</Grid>
															</Grid>
														</Card>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</div>
						</TabContainer>
					)}
					{value === 1 && <TabContainer>Item Two</TabContainer>}
					{value === 2 && <TabContainer>Item Three</TabContainer>}
				</div>
			</div>
		);
	}
}

SimpleTabs.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleTabs);
