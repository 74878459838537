import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	card: {
		padding: 20,
		fontWeight: 400,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 0,
		[theme.breakpoints.up('sm')]: {
			borderRadius: 8
		},

		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},
	cardNopadding: {
		padding: 0,
		fontWeight: 400,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 0,
		[theme.breakpoints.up('sm')]: {
			borderRadius: 8
		},
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},

	cardBorder: {
		// border: '1px solid #EAEDF3',
		// boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.04)',
		boxShadow: '0 0px 5px 0 rgba(0, 0, 0, 0.1)',
		border: '1px solid rgba(0,0,0,0.1)',
		borderRadius: 8,
		padding: 20,
		fontWeight: 400,
		// border: '1px solid rgba(0,0,0,0)',
		[theme.breakpoints.up('sm')]: {
			borderRadius: 8
		},

		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
		flexGrow: 1,
		height: '100%'
	},
	cardNopaddingBorder: {
		boxShadow: '0 0px 5px 0 rgba(0, 0, 0, 0.1)',
		border: '1px solid rgba(0,0,0,0.1)',
		// border: '1px solid #EAEDF3',
		// boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.04)',
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		padding: 0,
		fontWeight: 400,
		// border: '1px solid rgba(0,0,0,0)',
		[theme.breakpoints.up('sm')]: {
			borderRadius: 8
		},
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
		flexGrow: 1,
		height: '100%'
	}
});

class CustomCard extends Component {
	render() {
		const { classes, nopadding, style } = this.props;

		return (
			<Card className={nopadding ? classes.cardNopaddingBorder : classes.cardBorder} elevation={0} style={style}>
				{this.props.children}
			</Card>
		);
	}
}

CustomCard.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomCard);
