import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import { SectionHeader, Card, SectionContent } from '../../../components/chrome';
import Typography from '@material-ui/core/Typography';
import Flag from 'react-flags';
import LocationOn from '@material-ui/icons/LocationOn';
import ResultsList from '../ResultsList';
import Schedule from '../Schedule';
import poolCuesImg from '../../../assets/images/backgrounds/rack-wallpaper2.jpg';
import { isoCountries, getGameType, displayEnumType } from '../../../utils';
import moment from 'moment';

const styles = theme => ({
	toolbarTitle: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	backLink: {
		display: 'inline-block',
		[theme.breakpoints.down('xs')]: {
			marginTop: 24
		}
	},
	avatar: {
		backgroundColor: '#44475b',
		color: '#fff'
	},
	headerImg: {
		height: 250,
		overflow: 'hidden',
		backgroundColor: '#14171d',
		borderRadius: 0,
		backgroundImage: `url(${poolCuesImg})`,
		[theme.breakpoints.up('md')]: {
			backgroundAttachment: 'fixed'
		},

		backgroundRepeat: 'no-repeat',
		backgroundSize: '100%',
		// backgroundPosition: '50% -200px',
		opacity: 0.7
	}
});

class Overview extends Component {
	render() {
		const { classes, match, tournament, history } = this.props;
		return (
			<React.Fragment>
				<div style={{ backgroundColor: '#13151b' }}>
					<div className={classes.headerImg}>
						{/*<img
											src={USAPL}
											alt="usapl"
											width="100%"
											style={{ transform: 'translateY(-37%)' }}
										/>*/}
					</div>
				</div>

				<SectionContent>
					<Grid container justify="center" spacing={24}>
						<Grid item xs={12} sm={12} md={8}>
							<Grid container justify="center" spacing={24}>
								<Grid item xs={12}>
									<Card nopadding>
										<div style={{ padding: 20 }}>
											<Typography
												variant="title"
												color="inherit"
												className={classes.toolbarTitle}
											>
												<span>Tournament Details</span>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<Button
														color="primary"
														size="small"
														onClick={e => {
															e.preventDefault();
															history.push(`/tournaments/${tournament.slug}/edit`);
														}}
													>
														Edit
													</Button>
													<Button
														color="primary"
														size="small"
														onClick={e => {
															e.preventDefault();
															this.handleConfirmDialog(tournament);
														}}
														style={{
															marginLeft: 10,
															borderColor: 'rgb(225, 0, 80)',
															color: 'rgb(225, 0, 80)'
														}}
													>
														Remove
													</Button>
												</div>
											</Typography>
											<br />
											<Grid container spacing={24}>
												<Grid item xs={12} sm={12}>
													<Typography variant="caption" className="caption" gutterBottom>
														General information
													</Typography>
													<table width="100%" className="info-table">
														<tbody>
															<tr>
																<td>Name</td>
																<td>{tournament.name}</td>
															</tr>

															<tr>
																<td>Location</td>
																<td>
																	{tournament.location.name}
																	<br />
																	{tournament.location.address},
																	{tournament.location.city}{' '}
																	{tournament.location.state}{' '}
																	{tournament.location.zip}
																</td>
															</tr>
															<tr>
																<td>Start time</td>
																<td>
																	{moment(tournament.startTime).format('llll')} (local
																	time)
																</td>
															</tr>
														</tbody>
													</table>
												</Grid>

												{/*<Grid item xs>
											<Divider light />
										</Grid>*/}

												<Grid item xs={12} sm={12}>
													<Typography variant="caption" className="caption" gutterBottom>
														Format
													</Typography>
													<table width="100%" className="info-table">
														<tbody>
															<tr>
																<td>Type</td>
																<td>
																	{tournament.totalPlayers} player{' '}
																	{displayEnumType(tournament.type)}
																</td>
															</tr>

															<tr>
																<td>Games Played</td>
																<td>{displayEnumType(tournament.gameType)}</td>
															</tr>
															<tr>
																<td>A-side race (Winners)</td>
																<td>{tournament.winnersRaceTo}</td>
															</tr>
															<tr>
																<td>B-side race (Losers)</td>
																<td>{tournament.losersRaceTo}</td>
															</tr>
															<tr>
																<td>Handicap</td>
																<td>{tournament.handicap}</td>
															</tr>
														</tbody>
													</table>
												</Grid>

												<Grid item xs={12} sm={12}>
													<Typography variant="caption" className="caption" gutterBottom>
														Additional info
													</Typography>
													<Typography
														variant="body1"
														gutterBottom
														style={{ marginTop: 5, lineHeight: '1.8em' }}
													>
														The 8-Ball Classic Championship is the third event of the
														four-tournament 2018 World Pool Series. This event will be held
														at IDM Club in Bucharest, Romania from August 7th to 10th 2018.
														The World Pool Series is sponsored by Rasson, Aramith, Predator,
														Iwan Simonis, Tiger, and Kamui. Our suppliers and partners are
														Billiards Digest, CueScore, Outsville, UpState Al, and the WPA.
														<br />
														<br />
														Total prize money - 21,750 euros
														<br />
														Champion - 5,000
														<br />
														Runner-up - 3,150
														<br />
														Semi-finalists - 2,000
														<br />
														Quarter-finalists - 1,200
														<br />
														Last 16 - 600
													</Typography>
												</Grid>
											</Grid>
										</div>
										{/*<pre style={{ fontSize: 12 }}>{JSON.stringify(data, null, 2)}</pre>*/}
									</Card>
								</Grid>
								<Grid item xs={12}>
									<Card nopadding>
										<Typography
											variant="title"
											color="inherit"
											className={classes.toolbarTitle}
											style={{
												paddingTop: 20,
												paddingLeft: 20,
												paddingRight: 20
											}}
										>
											Results
										</Typography>
										<br />
										<ResultsList />
									</Card>
								</Grid>
								<Grid item xs={12}>
									<Card nopadding>
										<Typography
											variant="title"
											color="inherit"
											className={classes.toolbarTitle}
											style={{
												paddingTop: 20,
												paddingLeft: 20,
												paddingRight: 20
											}}
										>
											Schedule
										</Typography>
										<br />
										<Schedule />
									</Card>
								</Grid>

								<Grid item xs={12}>
									<Card>
										<Typography variant="title" color="inherit" className={classes.toolbarTitle}>
											Comments
										</Typography>
										<br />
										Comments
									</Card>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} sm={12} md={4}>
							<Grid container justify="center" spacing={24}>
								<Grid item xs={12}>
									<Card nopadding>
										<Typography
											variant="title"
											color="inherit"
											className={classes.toolbarTitle}
											style={{
												paddingTop: 20,
												paddingLeft: 20,
												paddingRight: 20
											}}
										>
											Players
										</Typography>
										<br />
										<List
											defaultValue={0}
											style={{
												paddingTop: 0,
												paddingLeft: 0,
												paddingRight: 0,
												paddingBottom: 10
											}}
										>
											{tournament.players.map((player, index) => {
												const countryName = isoCountries[player.country.toLowerCase()] || '';

												return (
													<ListItem
														key={index}
														className={classes.listItem}
														onClick={() => history.push(`/players/${player.username}`)}
														button
													>
														<Avatar size="small" src={player.avatarImg} />
														<ListItemText
															primary={player.name}
															secondary={player.username}
														/>
														<ListItemSecondaryAction style={{ marginRight: 24 }}>
															<Flag
																name={countryName || 'Unknown'}
																format="png"
																pngSize={24}
																shiny={false}
																alt="Canada Flag"
																className="flag"
																basePath="/images/flags"
															/>
														</ListItemSecondaryAction>
													</ListItem>
												);
											})}
										</List>
									</Card>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</SectionContent>
			</React.Fragment>
		);
	}
}

Overview.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Overview);
