import gql from 'graphql-tag';

export const createChallengeMutation = gql`
	mutation CreateChallenge($input: ChallengeInput) {
		createChallenge(input: $input) {
			id
			opponent {
				id
				name
				slug
				username
				avatarImg
			}
			challenger {
				id
				name
				slug
				username
				avatarImg
			}
			gameType
			matchFormat
			raceTo
			league {
				id
				name
				slug
				type
			}
			location {
				id
				name
			}
			division {
				id
				name
			}
			startTime
			notes
			accepted
			status
			createdAt
			updatedAt
		}
	}
`;
