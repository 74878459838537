import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import LinearProgress from '@material-ui/core/LinearProgress';
import SimpleGoogleMap from '../../components/SimpleGoogleMap';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import { StaticGoogleMap, Marker, Path } from 'react-static-google-map';
import LocationOn from '@material-ui/icons/LocationOn';
import moment from 'moment';
import { SectionHeader, Card, SectionContent, ZeroState } from '../../components/chrome';
import ConfirmDialog from '../../components/ConfirmDialog';
import { graphql, compose } from 'react-apollo';
import { removeTeamMutation } from './mutations/removeTeamMutation';
import { getTeamQuery } from './queries/getTeamQuery';
import Flag from 'react-flags';
import { isoCountries, getGameType, displayEnumType } from '../../utils';
import * as routes from '../../config/routes';
import eightBallHeader from '../../assets/images/eight-ball-header-side.png';
import nineBallHeader from '../../assets/images/nine-ball-header-side.png';
import tenBallHeader from '../../assets/images/ten-ball-header-side.png';
import _ from 'lodash';

const styles = theme => ({
	toolbarTitle: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	backLink: {
		display: 'inline-block',
		[theme.breakpoints.down('xs')]: {
			marginTop: 24
		}
	},
	avatar: {
		backgroundColor: '#44475b',
		color: '#fff'
	},
	header: {
		[theme.breakpoints.up('sm')]: {
			backgroundImage: `url(${eightBallHeader})`
		},
		backgroundRepeat: 'no-repeat',
		backgroundSize: '20%',
		backgroundPosition: 'right'
	}
});

class TeamDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dialogOpen: false
		};
		this.handleCloseDialog = this.handleCloseDialog.bind(this);
		this.handleConfirmDialog = this.handleConfirmDialog.bind(this);
	}

	getGameType = type => {
		return type.indexOf('EIGHT_BALL') !== -1
			? eightBallHeader
			: type.indexOf('NINE_BALL') !== -1
				? nineBallHeader
				: type.indexOf('TEN_BALL') !== -1 ? tenBallHeader : null;
	};

	handleCloseDialog() {
		this.setState({
			dialogOpen: false
		});
	}

	handleConfirmDialog(team) {
		this.setState({
			dialogOpen: true
		});
	}

	isTeamMember(team) {
		const currentUser = this.props.currentUser;
		if (currentUser) {
			const teams = currentUser.teams;
			const matches = _.filter(teams, item => {
				return item.id === team.id;
			});
			return matches.length > 0;
		}
	}

	render() {
		const { classes, match, history } = this.props;

		return (
			<Query
				query={getTeamQuery}
				fetchPolicy="cache-and-network"
				notifyOnNetworkStatusChange={true}
				variables={{ slug: match.params.slug }}
			>
				{({ loading, error, data, subscribeToMore }) => {
					if (loading) return <LinearProgress mode="indeterminate" color="primary" />;
					if (error) return <div style={styles.container}>Error: {error.message}</div>;
					if (data && !data.team) {
						return <div>Team not found.</div>;
					}
					console.log(data);
					return (
						<SectionHeader
							title={data.team.name}
							backButton={true}
							backPath="/teams"
							rightButton={
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										{this.isTeamMember(data.team) === true ? (
											<Button
												variant="outlined"
												color="inherit"
												onClick={() => this.handleLeaveTeam(data.division)}
												style={{ borderColor: 'rgba(255,255,255,0.7)' }}
											>
												Resign from Team
											</Button>
										) : (
											<Button
												variant="outlined"
												color="inherit"
												onClick={() => this.handleJoinTeam(data.division)}
												style={{ borderColor: 'rgba(255,255,255,0.7)' }}
											>
												Request to Join Team
											</Button>
										)}

										<Button
											variant="outlined"
											color="secondary"
											onClick={() => history.push('/teams/new')}
											style={{ marginLeft: 10 }}
										>
											Create Team
										</Button>
									</div>
								</div>
							}
						>
							<SectionContent>
								<Grid container justify="center" spacing={24}>
									{/*<Grid item xs={11} sm={12}>
										<Link to={'/teams'} className={classes.backLink}>
											<Typography variant="subheading" gutterBottom>
												&laquo; Back to teams list
											</Typography>
										</Link>
					</Grid>*/}
									<Grid item xs={12} sm={12} md={8}>
										<Grid container justify="center" spacing={24}>
											<Grid item xs={12}>
												<Card>
													<Typography
														variant="title"
														color="inherit"
														className={classes.toolbarTitle}
													>
														<span>Team Details</span>
														<div style={{ display: 'flex', alignItems: 'center' }}>
															<Button
																variant="text"
																color="primary"
																size="small"
																onClick={e => {
																	e.preventDefault();
																	history.push(`/teams/${data.team.slug}/edit`);
																}}
															>
																<EditIcon style={{ width: 20, height: 20 }} />
																<span style={{ marginLeft: 5 }}>Edit</span>
															</Button>
															<Button
																variant="text"
																color="primary"
																size="small"
																onClick={e => {
																	e.preventDefault();
																	this.handleConfirmDialog(data.team);
																}}
																style={{
																	marginLeft: 10,
																	borderColor: 'rgb(225, 0, 80)',
																	color: 'rgb(225, 0, 80)'
																}}
															>
																<DeleteIcon style={{ width: 20, height: 20 }} />
																<span style={{ marginLeft: 5 }}>Remove</span>
															</Button>
														</div>
													</Typography>
													<br />
													<Grid container spacing={16}>
														{/*<Grid item xs={12} sm={12}>
															<table width="100%" className="info-table">
																<tbody>
																	<tr>
																		<td>Name</td>
																		<td>{data.team.name}</td>
																	</tr>

																	<tr>
																		<td>Team Number</td>
																		<td>{data.team.number}</td>
																	</tr>

																	<tr>
																		<td>Status</td>
																		<td>
																			{data.team.isActive === true
																				? 'Active'
																				: 'Not Active'}
																		</td>
																	</tr>
																</tbody>
															</table>
														</Grid>*/}
														<Grid item xs={12} sm={4}>
															<Typography
																variant="caption"
																className="caption"
																gutterBottom
															>
																Name
															</Typography>
															<Typography variant="subheading" gutterBottom>
																{data.team.name}
															</Typography>
														</Grid>
														<Grid item xs={12} sm={4}>
															<Typography
																variant="caption"
																className="caption"
																gutterBottom
															>
																Team Number
															</Typography>
															<Typography variant="subheading" gutterBottom>
																{data.team.number}
															</Typography>
														</Grid>
														<Grid item xs={12} sm={4}>
															<Typography
																variant="caption"
																className="caption"
																gutterBottom
															>
																Status
															</Typography>
															<Typography variant="subheading" gutterBottom>
																{data.team.isActive === true ? 'Active' : 'Not Active'}
															</Typography>
														</Grid>
													</Grid>
												</Card>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Card nopadding>
													<Typography
														variant="title"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20
														}}
													>
														Divisions
													</Typography>
													{data.team.divisions.length > 0 ? (
														<List
															defaultValue={0}
															style={{
																marginTop: 20,
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															{data.team.divisions.map((division, index) => {
																return (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																		onClick={() =>
																			history.push(`/divisions/${division.slug}`)}
																		button
																	>
																		<Avatar
																			size="small"
																			src={getGameType(division.type)}
																			className={classes.avatar}
																		/>
																		<ListItemText
																			primary={division.name}
																			secondary={displayEnumType(division.type)}
																		/>
																	</ListItem>
																);
															})}
														</List>
													) : (
														<ZeroState
															title="This team does not have any divisions yet"
															style={{ margin: 15 }}
														>
															<Button
																variant="outlined"
																color="primary"
																size="small"
																onClick={e => {
																	e.preventDefault();
																	history.push(`/divisions/new`);
																}}
															>
																Add Division
															</Button>
														</ZeroState>
													)}
												</Card>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Card nopadding>
													<Typography
														variant="title"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20
														}}
													>
														Players
													</Typography>
													{data.team.players.length > 0 ? (
														<List
															defaultValue={0}
															style={{
																marginTop: 20,
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															{data.team.players.map((player, index) => {
																return (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																		onClick={() =>
																			history.push(`/players/${player.username}`)}
																		button
																	>
																		<Avatar
																			size="small"
																			src={player.avatarImg}
																			className={classes.avatar}
																		>
																			{player && player.name.slice(0, 1)}
																		</Avatar>
																		<ListItemText
																			primary={player.name}
																			secondary={player.username}
																		/>
																	</ListItem>
																);
															})}
														</List>
													) : (
														<ZeroState
															title="This team does not have any players yet"
															style={{ margin: 15 }}
														>
															<Button
																variant="outlined"
																color="primary"
																size="small"
																onClick={e => {
																	e.preventDefault();
																	history.push(`/players/new`);
																}}
															>
																Add Player
															</Button>
														</ZeroState>
													)}
												</Card>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Card nopadding>
													<Typography
														variant="title"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20
														}}
													>
														Matches
													</Typography>

													{data.team.matches && data.team.matches.length > 0 ? (
														<List
															defaultValue={0}
															style={{
																marginTop: 20,
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															{data.team.matches.map((match, index) => {
																const teams = match.teams.map(team => {
																	return team;
																});

																return (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																		onClick={() =>
																			history.push(`/matches/${match.id}`)}
																		button
																	>
																		<Avatar size="small" className={classes.avatar}>
																			M
																		</Avatar>
																		<ListItemText
																			primary={`${teams[0].name} vs. ${teams[1]
																				.name}`}
																			secondary={`${match.division &&
																				match.division.name} - ${moment(
																				match.createdAt
																			).format('lll')}`}
																		/>
																	</ListItem>
																);
															})}
														</List>
													) : (
														<ZeroState title="No matches yet." style={{ margin: 15 }} />
													)}
												</Card>
											</Grid>
											<Grid item xs={12} sm={6}>
												<Card nopadding>
													<Typography
														variant="title"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20
														}}
													>
														Tournaments
													</Typography>
													{data.team.tournaments && data.team.tournaments.length > 0 ? (
														<List
															defaultValue={0}
															style={{
																marginTop: 20,
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															{data.team.tournaments.map((tournament, index) => {
																return (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																		onClick={() =>
																			history.push(
																				`/tournaments/${tournament.slug}`
																			)}
																		button
																	>
																		<Avatar size="small" className={classes.avatar}>
																			{tournament.name.slice(0, 1)}
																		</Avatar>
																		<ListItemText
																			primary={tournament.name}
																			secondary={moment(
																				tournament.startTime
																			).format('lll')}
																		/>
																	</ListItem>
																);
															})}
														</List>
													) : (
														<ZeroState title="No tournaments yet." style={{ margin: 15 }} />
													)}
												</Card>
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={12} sm={12} md={4}>
										<Grid container justify="center" spacing={24}>
											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="title"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20,
															paddingBottom: 10
														}}
													>
														Team Captain
													</Typography>
													<List>
														{data.team.captain && (
															<ListItem
																onClick={() =>
																	history.push(`/players/${data.team.captain.slug}`)}
																button
																className={classes.listItem}
															>
																<Avatar size="small" className={classes.avatar}>
																	{data.team.captain &&
																		data.team.captain.name.slice(0, 1)}
																</Avatar>

																<ListItemText
																	primary={data.team && data.team.captain.name}
																/>
															</ListItem>
														)}
													</List>
												</Card>
											</Grid>

											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="title"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20,
															paddingBottom: 10
														}}
													>
														Team Co-captain
													</Typography>
													<List>
														{data.team.coCaptain && (
															<ListItem
																onClick={() =>
																	history.push(
																		`/players/${data.team.coCaptain.slug}`
																	)}
																button
																className={classes.listItem}
															>
																<Avatar size="small" className={classes.avatar}>
																	{data.team.coCaptain &&
																		data.team.coCaptain.name.slice(0, 1)}
																</Avatar>

																<ListItemText
																	primary={data.team && data.team.coCaptain.name}
																/>
															</ListItem>
														)}
													</List>
												</Card>
											</Grid>

											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="title"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20,
															paddingBottom: 20
														}}
													>
														Location
													</Typography>
													{data.team && data.team.location ? (
														<List
															defaultValue={0}
															style={{
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															<ListItem
																className={classes.listItem}
																onClick={() =>
																	history.push(
																		`/locations/${data.team.location.slug}`
																	)}
																button
															>
																<Avatar size="small" className={classes.avatar}>
																	<LocationOn
																		style={{
																			height: 22
																		}}
																	/>
																</Avatar>
																<ListItemText
																	primary={data.team.location.name}
																	secondary={`${data.team.location.address}, ${data
																		.team.location.city}, ${data.team.location
																		.state} ${data.team.location.zip}`}
																/>
															</ListItem>
														</List>
													) : (
														<ZeroState
															title="No location yet"
															style={{
																marginLeft: 15,
																marginRight: 15,
																marginBottom: 15
															}}
														/>
													)}
												</Card>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</SectionContent>
							<ConfirmDialog
								title="Confirm Team Removal"
								open={this.state.dialogOpen}
								onClose={this.handleCloseDialog}
								onConfirm={() => {
									this.props
										.removeTeam({
											mutation: removeTeamMutation,
											variables: { id: data.team.id },
											refetchQueries: ['GetTeams']
										})
										.then(({ removedTeam }) => {
											this.setState({ dialogOpen: false });
											this.props.history.push(`/teams`);
										})
										.catch(error => {
											console.log(error);
											this.setState({ dialogOpen: false });
										});
								}}
							>
								Are you sure you want to delete this team <b>{data.team.name}</b> ?
							</ConfirmDialog>
						</SectionHeader>
					);
				}}
			</Query>
		);
	}
}

TeamDetail.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithGraphQL = graphql(removeTeamMutation, {
	options: ({ id }) => ({ variables: { id } }),
	name: 'removeTeam'
})(TeamDetail);

export default withStyles(styles)(WithGraphQL);
