import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InvoiceCreateForm from './InvoiceCreateForm';
import AttachMoney from '@material-ui/icons/AttachMoney';

const styles = theme => ({
	layout: {
		width: 'auto',
		display: 'block', // Fix IE11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	paper: {
		marginTop: 50,
		marginBottom: 50,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main
	}
});

class InvoiceCreate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showProgress: false
		};

		this.showProgressBar = this.showProgressBar.bind(this);
		this.hideProgressBar = this.hideProgressBar.bind(this);
	}

	showProgressBar() {
		this.setState({
			showProgress: true
		});
	}

	hideProgressBar() {
		this.setState({
			showProgress: false
		});
	}

	render() {
		const { classes, history, theme, ...rest } = this.props;
		return (
			<div className="page-container">
				{this.state.showProgress === true && <LinearProgress mode="indeterminate" color="primary" />}

				<main className={classes.layout}>
					<Paper className={classes.paper}>
						<Avatar className={classes.avatar}>
							<AttachMoney />
						</Avatar>
						<Typography variant="headline">Create Invoice</Typography>
						<InvoiceCreateForm
							showProgressBar={this.showProgressBar}
							hideProgressBar={this.hideProgressBar}
							history={history}
							{...rest}
						/>
					</Paper>
				</main>
			</div>
		);
	}
}

InvoiceCreate.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles)(InvoiceCreate);

const WithAlert = withAlert(WithStyles);

export default withRouter(WithAlert);
