import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Flag from 'react-flags';
import { isoCountries } from '../../utils';
import Podium from './Podium';

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto'
	},
	table: {
		minWidth: 700
	},
	cell: {
		padding: '4px 30px 4px 20px',
		fontSize: 13
	}
});

// let id = 0;
function createData(id, country, name, matches, games, winPercentage, points) {
	// id += 1;
	return { id, country, name, matches, games, winPercentage, points };
}

const rows = [
	createData(1, 'United Kingdom', 'Chris Melling', '7 (6/1)', '139 (85/54)', 61, 100),
	createData(2, 'Scotland', 'Jayson Shaw', '6 (5/1)', '120 (73/47)', 61, 70),
	createData(3, 'Albania', 'Klenti KaCi', '7 (5/2)', '113 (68/45)', 60, 50),
	createData(3, 'Romania', 'Ioan Ladanyi', '8 (6/2)', '150 (84/66)', 56, 50),
	createData(5, 'Russia', 'Fedor Gorst', '4 (3/1)', '85 (46/39)', 54, 40),
	createData(5, 'Germany', 'Thorsten Hohmann', '5 (3/2)', '98 (52/46)', 53, 40),
	createData(5, 'Poland', 'Wojciech Szewczyk', '5 (4/1)', '98 (55/43)', 56, 40),
	createData(5, 'Poland', 'Daniel Maciol', '6 (4/2)', '117 (60/57)', 51, 40)
];

function getRowColor(row) {
	return row === 1 ? '#fff9df' : row === 2 ? '#f9fafb' : row === 3 ? '#fff5e8' : null;
}

function SimpleTable(props) {
	const { classes } = props;

	return (
		<React.Fragment>
			<Podium />
			<Divider light />
			<div style={{ overflow: 'auto' }}>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell numeric className={classes.cell}>
								#
							</TableCell>
							<TableCell className={classes.cell}>Name</TableCell>
							<TableCell numeric className={classes.cell}>
								Matches (W/L)
							</TableCell>
							<TableCell numeric className={classes.cell}>
								Games (W/L)
							</TableCell>
							<TableCell className={classes.cell}>Win percentage</TableCell>
							<TableCell numeric className={classes.cell}>
								Points
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row, index) => {
							const countryName = isoCountries[row.country.toLowerCase()] || '';

							return (
								<TableRow
									key={index}
									hover
									style={{
										cursor: 'pointer',
										// backgroundColor: getRowColor(row.id),
										transition: 'all 150ms ease'
										// '&:hover': {
										// 	backgroundColor: `darken(${getRowColor(row.id)}, 50%)`
										// }
									}}
								>
									<TableCell component="th" scope="row" numeric className={classes.cell}>
										{row.id}
									</TableCell>
									<TableCell className={classes.cell}>
										<div
											style={{
												display: 'flex',
												alignItems: 'center'
											}}
										>
											<Flag
												name={countryName || 'Unknown'}
												format="png"
												pngSize={24}
												shiny={false}
												alt="Canada Flag"
												className="flag"
												basePath="/images/flags"
											/>
											<span style={{ marginLeft: 10 }}>{row.name}</span>
										</div>
									</TableCell>
									<TableCell numeric className={classes.cell}>
										{row.matches}
									</TableCell>
									<TableCell numeric className={classes.cell}>
										{row.games}
									</TableCell>
									<TableCell className={classes.cell}>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'flex-start',
												width: '100%'
											}}
										>
											<span
												style={{
													width: '100%',
													textAlign: 'left',
													backgroundColor: '#ececec',
													height: 20
												}}
											>
												<span
													style={{
														display: 'inline-block',
														// backgroundColor: '#bac2cc',
														backgroundColor: '#292d3a',
														width: `${(row.winPercentage || 0) / 100 * 100}%`,
														height: '100%',
														position: 'relative',
														transition: 'background-color .3s ease',
														cursor: 'pointer'
													}}
												/>
											</span>
											<span style={{ width: 80, marginLeft: 10 }}>{row.winPercentage || 0}%</span>
										</div>
									</TableCell>
									<TableCell numeric className={classes.cell}>
										{row.points}
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</div>
		</React.Fragment>
	);
}

SimpleTable.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleTable);
