import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
// import DivisionToolbar from './DivisionToolbar';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import eightBall from '../../assets/images/ball/eight-ball.png';
import nineBall from '../../assets/images/ball/nine-ball.png';
import tenBall from '../../assets/images/ball/ten-ball.png';
import _ from 'lodash';
import ConfirmDialog from '../../components/ConfirmDialog';
import { SectionHeader, SectionContent, Card, ZeroState } from '../../components/chrome';
import { deleteMatchMutation } from './mutations/deleteMatchMutation';
import { getMatchQuery } from './queries/getMatchQuery';
import { graphql, compose } from 'react-apollo';
import LocationOn from '@material-ui/icons/LocationOn';
import moment from 'moment';

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	},
	card: {
		// padding: 10,
		// margin: 24,
		border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},
	cell: {
		padding: '4px 30px 4px 20px',
		fontSize: 13
	}
});
class MatchesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dialogOpen: false,
			selectedMatch: null,
			order: 'asc',
			orderBy: 'name',
			selected: [],
			page: 0,
			rowsPerPage: 10,
			limit: this.props.limit || 10,
			offset: this.props.offset || 0
		};
		this.handleCloseDialog = this.handleCloseDialog.bind(this);
		this.handleConfirmDialog = this.handleConfirmDialog.bind(this);
	}

	handleCloseDialog() {
		this.setState({
			dialogOpen: false,
			selectedMatch: null
		});
	}

	handleConfirmDialog(match) {
		this.setState({
			selectedMatch: match,
			dialogOpen: true
		});
	}

	handleChangePage(event, page) {
		// this.setState({ page });
	}

	handleChangeRowsPerPage(event) {
		// this.setState({ rowsPerPage: event.target.value });
	}

	getGameType(args) {
		return args.gameType === 'EIGHT_BALL'
			? eightBall
			: args.gameType === 'NINE_BALL'
			? nineBall
			: args.gameType === 'TEN_BALL'
			? tenBall
			: null;
	}

	displayEnumType(type) {
		const typeSplit = type.split('_');
		const prefix = _.capitalize(typeSplit[0]);
		const suffix = _.capitalize(typeSplit[1]);
		let combined = '';
		typeSplit.forEach(part => {
			combined += `${_.capitalize(part)} `;
		});
		return combined;
	}

	render() {
		const { classes, match, history, data, loading, error, subscribeToMore, count } = this.props;
		const routerMatch = match;

		return (
			<Paper elevation={0} className={classes.card}>
				{data.matches && data.matches.length > 0 ? (
					<div className={classes.container}>
						{/*<DivisionToolbar data={data} />*/}
						<Table className={classes.table}>
							<TableHead>
								<TableRow>
									{/*<TableCell className={classes.cell}>#</TableCell>*/}
									<TableCell className={classes.cell}>Title</TableCell>
									<TableCell className={classes.cell}>Type</TableCell>
									<TableCell className={classes.cell}>Game Type</TableCell>
									<TableCell className={classes.cell}>Format</TableCell>
									{/*<TableCell className={classes.cell}>Division</TableCell>
								<TableCell className={classes.cell}>Tournament</TableCell>
								<TableCell className={classes.cell}>Players</TableCell>
								<TableCell className={classes.cell}>Teams</TableCell>
    <TableCell className={classes.cell}>Games</TableCell>*/}
									<TableCell className={classes.cell}>Status</TableCell>
									<TableCell className={classes.cell}>Verified</TableCell>
									<TableCell className={classes.cell}>Created At</TableCell>
									<TableCell className={classes.cell}>Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data.matches.map((match, index) => {
									const title = match.players
										.map(player => {
											return player.name;
										})
										.join(' vs. ');

									return (
										<TableRow
											key={match.id}
											hover
											onClick={() => history.push(`matches/${match.id}`)}
											style={{ cursor: 'pointer' }}
										>
											{/*<TableCell className={classes.cell}>{index + 1}</TableCell>*/}
											<TableCell className={classes.cell}>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<span style={{ marginRight: 15, display: 'flex' }}>
														<img
															src={this.getGameType(match)}
															width={30}
															height={30}
															alt=""
														/>
													</span>
													<span>{title}</span>
												</div>
											</TableCell>
											<TableCell className={classes.cell}>
												{match.type && this.displayEnumType(match.type)}
											</TableCell>
											<TableCell className={classes.cell}>
												{match.gameType && this.displayEnumType(match.gameType)}
											</TableCell>
											<TableCell className={classes.cell}>
												{match.format && this.displayEnumType(match.format)}
											</TableCell>
											{/*<TableCell className={classes.cell}>{match.division.name}</TableCell>
										<TableCell className={classes.cell}>
											{(match.tournament && match.tournament.name) || 'N/A'}
										</TableCell>
										<TableCell className={classes.cell}>{match.players.length}</TableCell>
										<TableCell className={classes.cell}>{match.teams.length}</TableCell>
                <TableCell className={classes.cell}>{match.games.length}</TableCell>*/}
											<TableCell className={classes.cell}>
												{match.status && this.displayEnumType(match.status)}
											</TableCell>
											<TableCell className={classes.cell}>
												{match.verified === true ? 'Verified' : 'Not Verified'}
											</TableCell>
											<TableCell className={classes.cell}>
												{moment(match.createdAt).format('ll')}
											</TableCell>
											<TableCell className={classes.cell}>
												<div style={{ display: 'flex', alignItems: 'center' }}>
													<Button
														color="primary"
														size="small"
														onClick={e => {
															e.preventDefault();
															e.stopPropagation();
															history.push(`/matches/${match.id}/edit`);
														}}
													>
														Edit
													</Button>
													<Button
														color="primary"
														size="small"
														onClick={e => {
															e.preventDefault();
															e.stopPropagation();
															this.handleConfirmDialog(match);
														}}
														style={{ color: 'rgb(225, 0, 80)' }}
													>
														Remove
													</Button>
												</div>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
						<TablePagination
							component="div"
							count={count}
							rowsPerPage={50}
							rowsPerPageOptions={[50, 100, 200]}
							page={0}
							backIconButtonProps={{
								'aria-label': 'Previous Page'
							}}
							nextIconButtonProps={{
								'aria-label': 'Next Page'
							}}
							onChangePage={this.handleChangePage}
							onChangeRowsPerPage={this.handleChangeRowsPerPage}
						/>
					</div>
				) : (
					<ZeroState title="No matches found" style={{ margin: 15 }} />
				)}

				{this.state.dialogOpen === true && (
					<ConfirmDialog
						title="Confirm Match Removal"
						open={this.state.dialogOpen}
						onClose={this.handleCloseDialog}
						onConfirm={() => {
							this.props
								.deleteMatch({
									mutation: deleteMatchMutation,
									variables: { id: this.state.selectedMatch.id },
									// refetchQueries: ['GetDivisions'],
									refetchQueries: [
										{
											query: getMatchQuery
										}
									]
									// awaitRefetchQueries: true
								})
								.then(() => {
									this.setState({ dialogOpen: false });
								})
								.catch(error => {
									console.log(error);
									this.setState({ dialogOpen: false });
								});
						}}
					>
						⚠️ Are you sure you want to delete <b>{this.state.selectedMatch.name}</b>?
					</ConfirmDialog>
				)}
			</Paper>
		);
	}
}

MatchesList.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithGraphQL = graphql(deleteMatchMutation, {
	options: ({ id }) => ({ variables: { id } }),
	name: 'deleteMatch'
})(MatchesList);

const WithStyles = withStyles(styles)(WithGraphQL);
export default withRouter(WithStyles);
