import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { SectionHeader, Card, SectionContent } from '../../../components/chrome';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PoolTable from '../PoolTable';
import liveStream from '../../../assets/images/live-stream.png';
import TableScoreCard from '../TableScoreCard';

const styles = theme => ({
	toolbarTitle: {
		display: 'flex',
		justifyContent: 'space-between'
	}
});

class Scores extends Component {
	render() {
		const { classes } = this.props;

		return (
			<SectionContent>
				<Grid container justify="center" spacing={24}>
					<Grid item xs={12}>
						<Grid container spacing={24}>
							{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => {
								return (
									<Grid key={index} item xs={12} sm={6} md={4} lg={3}>
										<TableScoreCard status="live" />
									</Grid>
								);
							})}
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TableScoreCard status="completed" />
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TableScoreCard status="completed" />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</SectionContent>
		);
	}
}

Scores.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Scores);
