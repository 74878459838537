import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import avatarImg1 from '../../assets/images/kolage-128.jpg';
import avatarImg2 from '../../assets/images/contemplative-reptile.jpg';
import tableBlue from '../../assets/images/table/table-blue.png';
import tableGrey from '../../assets/images/table/table-grey.png';
import '../../assets/css/pool-table.css';

const styles = theme => ({
	avatar: {
		margin: 0
	},
	bigAvatar: {
		width: 30,
		height: 30
	}
});

class PoolTable extends Component {
	render() {
		const { classes, status, ...rest } = this.props;

		return (
			<div className="pool-table" {...rest}>
				<a href="#" className="match-link">
					<div className="match-info">
						<div className="item winning winner">
							<div className="content">
								<Avatar
									size="small"
									src={avatarImg1}
									className={classNames(classes.avatar, classes.bigAvatar)}
								/>
								<div className="player-info">
									<b>8 Trackers</b>
								</div>
							</div>
							<div className="score">1</div>
						</div>

						<div className="item">
							<div className="content">
								<Avatar
									size="small"
									src={avatarImg2}
									className={classNames(classes.avatar, classes.bigAvatar)}
								/>
								<div className="player-info">
									<b>9-Ballers</b>
								</div>
							</div>
							<div className="score">0</div>
						</div>
					</div>
					{status === 'live' ? (
						<img src={tableBlue} width="100%" alt="Pool Table" />
					) : (
						<img src={tableGrey} width="100%" alt="Pool Table" style={{ opacity: 0.8 }} />
					)}
				</a>
			</div>
		);
	}
}

PoolTable.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PoolTable);
