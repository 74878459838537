import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Formsy from 'formsy-react';
import TextInput from '../../components/forms/TextInputFormsy';
import DateTimeInput from '../../components/forms/DateTimeInputFormsy';
import SelectField from '../../components/forms/SelectFormsy';
import SwitchField from '../../components/forms/SwitchFormsy';
import MultiSelectField from '../../components/forms/MultiSelectFormsy';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import Avatar from '@material-ui/core/Avatar';
import LocationOn from '@material-ui/icons/LocationOn';
import isEmpty from 'lodash/isEmpty';
import { getDivisionQuery } from './queries/getDivisionQuery';
import { updateDivisionMutation } from './mutations/updateDivisionMutation';
import { createDivisionMutation } from './mutations/createDivisionMutation';
import eightBall from '../../assets/images/ball/eight-ball.png';
import nineBall from '../../assets/images/ball/nine-ball.png';
import tenBall from '../../assets/images/ball/ten-ball.png';
import queryString from 'query-string';

export const getLeaguesQuery = gql`
	query GetLeagues {
		leagues {
			id
			name
			slug
			type
		}
	}
`;

export const getLocationsQuery = gql`
	query GetLocations {
		locations {
			id
			name
			slug
		}
	}
`;

export const getTeamsQuery = gql`
	query GetTeams {
		teams {
			id
			name
			slug
		}
	}
`;

export const getPlayersQuery = gql`
	query GetPlayers {
		users {
			id
			name
			username
			avatarImg
		}
	}
`;

const styles = theme => ({
	error: {
		color: '#f44336'
	},
	content: {
		// padding: '10px 24px 20px 24px'
		// maxWidth: 600
	},
	input: {
		marginTop: 5
	},
	submit: {
		marginTop: 10
	},
	selectEmpty: {
		marginTop: theme.spacing.unit * 2
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	},
	avatar: {
		backgroundColor: '#44475b',
		color: '#fff'
	}
});

class DivisionCreateForm extends React.Component {
	constructor(props) {
		super(props);
		this.disableButton = this.disableButton.bind(this);
		this.enableButton = this.enableButton.bind(this);
		this.state = { canSubmit: false, submitting: false };
		this.submit = this.submit.bind(this);
	}

	getGameType = type => {
		return type.indexOf('EIGHT_BALL') !== -1
			? eightBall
			: type.indexOf('NINE_BALL') !== -1
			? nineBall
			: type.indexOf('TEN_BALL') !== -1
			? tenBall
			: null;
	};

	getLeagues = () => {
		const {
			getLeagues: { leagues, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = leagues.map(league => {
			return {
				label: league.name,
				value: league.id
			};
		});
		return formatted;
	};

	getLocations = () => {
		const {
			getLocations: { locations, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = locations.map(location => {
			return {
				label: location.name,
				value: location.id
			};
		});
		return formatted;
	};

	getTeams = () => {
		const {
			getTeams: { teams, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = teams.map(team => {
			return {
				label: team.name,
				value: team.id
			};
		});
		return formatted;
	};

	getPlayers = () => {
		const {
			getPlayers: { users, loading, error }
		} = this.props;
		if (loading) return null;
		const formatted = users.map(player => {
			return {
				label: player.name,
				value: player.id,
				avatar: player.avatarImg
			};
		});
		return formatted;
	};

	disableButton() {
		this.setState({ canSubmit: false });
	}

	enableButton() {
		this.setState({ canSubmit: true });
	}

	submit(model) {
		console.log(model);
		// fetch('http://example.com/', {
		// 	method: 'post',
		// 	body: JSON.stringify(model)
		// });
		this.setState({
			submitting: true
		});
		if (!this.props.slug) {
			this.props
				.createDivision({
					mutation: createDivisionMutation,
					variables: {
						input: {
							name: model.name,
							leagueId: model.league,
							description: model.description,
							type: model.type,
							isActive: model.isActive,
							operatorId: this.context.authUser.id,
							locationIds: model.locations,
							teamIds: model.teams,
							userIds: model.players,
							sessionId: model.sessionId,
							season: model.season,
							week: model.week,
							startDate: model.startDate,
							endDate: model.endDate,
							isPublic: model.isPublic,
							isPrivate: model.isPublic === false ? true : false
						}
					},
					// refetchQueries: ['GetTeam']
					refetchQueries: [{ query: getDivisionQuery }]
				})
				.then(({ data }) => {
					// console.log(data);
					this.props.history.push(`/divisions/${data.createDivision.slug}`);
				})
				.catch(error => {
					console.log(error);
					this.setState({ submitting: false });
				});
		} else {
			const id = this.props.getDivision.division.id;
			this.props
				.updateDivision({
					mutation: updateDivisionMutation,
					variables: {
						input: {
							id: id,
							name: model.name,
							leagueId: model.league,
							description: model.description,
							type: model.type,
							isActive: model.isActive,
							operatorId: this.context.authUser.id,
							locationIds: model.locations,
							teamIds: model.teams,
							userIds: model.players,
							sessionId: model.sessionId,
							season: model.season,
							week: model.week,
							startDate: model.startDate,
							endDate: model.endDate,
							isPublic: model.isPublic,
							isPrivate: model.isPublic === false ? true : false
						}
					},
					// refetchQueries: ['GetTeam'],
					refetchQueries: [{ query: getDivisionQuery, variables: { id: id } }]
					// update: (store, { data: { updateTeam } }) => {
					// 	this.props.updateStoreAfterUpdate(store, updateTeam, model.id);
					// }
					// update: (cache, { data: { updateTeam } }) => {
					// 	// const { team } = cache.readQuery({ query: getTeamQuery });
					// 	// console.log(team);
					// 	cache.writeQuery({
					// 		query: getTeamQuery,
					// 		data: { team: updateTeam }
					// 	});
					// }

					// optimisticResponse: {
					// 	__typename: 'Mutation',
					// 	updateTeam: {
					// 		id: model.id,
					// 		__typename: 'Team',
					// 		name: model.name,
					// 		slug: model.slug,
					// 		isActive: model.isActive,
					// 		location: model.location,
					// 		players: model.players
					// 	}
					// }
				})
				.then(({ data }) => {
					console.log(data);
					this.props.history.push(`/divisions/${data.updateDivision.slug}`);
				})
				.catch(error => {
					console.log(error);
					this.setState({ submitting: false });
				});
		}
	}

	render() {
		const {
			classes,
			getDivision: { division, loading, error }
		} = this.props;
		const parsed = queryString.parse(this.props.location.search);
		const leagueId = parsed.leagueId;

		return (
			<React.Fragment>
				{loading === true ? (
					<div>Loading...</div>
				) : (
					<Formsy onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
						<TextInput
							name="name"
							label="Name"
							value={division && division.name}
							validations="isExisty"
							validationError="Name is required"
							required
							autoFocus
						/>

						<TextInput
							name="description"
							label="Description"
							multiline={true}
							rows={4}
							value={division && division.description}
							// validations="isExisty"
							// validationError="Name is required"
						/>

						<SelectField
							name="league"
							label="League"
							value={(division && division.league.id) || leagueId}
							validations="isExisty"
							validationError="League is required"
							options={this.getLeagues()}
							required
						/>

						<SelectField
							name="type"
							label="Type"
							value={division && division.type}
							options={[
								{
									label: '9-Ball Singles',
									value: 'NINE_BALL_SINGLES'
								},
								{ label: '9-Ball Doubles', value: 'NINE_BALL_DOUBLES' },
								{ label: '9-Ball Scotch Doubles', value: 'NINE_BALL_SCOTCH_DOUBLES' },
								{ label: '9-Ball Teams', value: 'NINE_BALL_TEAMS' },
								{
									label: '8-Ball Singles',
									value: 'EIGHT_BALL_SINGLES'
								},
								{ label: '8-Ball Doubles', value: 'EIGHT_BALL_DOUBLES' },
								{ label: '8-Ball Scotch Doubles', value: 'EIGHT_BALL_SCOTCH_DOUBLES' },
								{ label: '8-Ball Teams', value: 'EIGHT_BALL_TEAMS' }
							]}
							required
							validations="isExisty"
							validationError="Type is required"
						/>

						<MultiSelectField
							name="locations"
							label="Locations"
							value={
								division &&
								division.locations.map(location => {
									return location.id;
								})
							}
							avatar={
								<Avatar size="small" className={classes.avatar}>
									<LocationOn
										style={{
											height: 22
										}}
									/>
								</Avatar>
							}
							options={this.getLocations()}
						/>

						<MultiSelectField
							name="teams"
							label="Teams"
							value={
								division &&
								division.teams.map(team => {
									return team.id;
								})
							}
							options={this.getTeams()}
						/>

						<MultiSelectField
							name="players"
							label="Players"
							value={
								division &&
								division.players.map(player => {
									return player.id;
								})
							}
							options={this.getPlayers()}
						/>

						<TextInput name="sessionId" label="Session ID" value={division && division.sessionId} />
						<TextInput name="season" label="Season" value={division && division.season} />
						<TextInput name="week" label="Week" value={division && division.week} />

						<DateTimeInput
							name="startDate"
							label="Start Date"
							value={division && division.startDate ? division.startDate : null}
						/>

						<DateTimeInput
							name="endDate"
							label="End Date"
							value={division && division.endDate ? division.endDate : null}
						/>

						<SwitchField
							// type="checkbox"
							// checked={team.isActive}
							id="isActive"
							name="isActive"
							label="Active"
							value={division && division.isActive === true ? true : false}
						/>

						<SwitchField
							id="isPublic"
							name="isPublic"
							label="Public"
							value={division && division.isPublic === true ? true : false}
						/>

						<div style={{ marginTop: 20 }}>
							{this.props.slug ? (
								<Button
									variant="contained"
									color="primary"
									disabled={!this.state.canSubmit}
									type="submit"
								>
									Update
								</Button>
							) : (
								<Button
									variant="contained"
									color="primary"
									disabled={!this.state.canSubmit}
									type="submit"
								>
									Create
								</Button>
							)}
						</div>
					</Formsy>
				)}
			</React.Fragment>
		);
	}
}

DivisionCreateForm.contextTypes = {
	authUser: PropTypes.object,
	getUserProfile: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	changeLoginState: PropTypes.func,
	signIn: PropTypes.func,
	signOut: PropTypes.func
};

const WithGraphQL = compose(
	graphql(getDivisionQuery, {
		options: ({ slug }) => ({
			variables: { slug },
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'no-cache'
			// refetchQueries: [
			// 	{
			// 		query: getTeamQuery,
			// 		variables: { variables: { slug } }
			// 	}
			// ]
		}),
		name: 'getDivision'
	}),
	graphql(getLeaguesQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getLeagues'
	}),
	graphql(createDivisionMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'createDivision'
	}),
	graphql(updateDivisionMutation, {
		options: ({ input }) => ({ variables: { input } }),
		name: 'updateDivision'
	}),
	graphql(getLocationsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getLocations'
	}),
	graphql(getTeamsQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getTeams'
	}),
	graphql(getPlayersQuery, {
		options: ({ data }) => ({ ...data, fetchPolicy: 'cache-and-network', notifyOnNetworkStatusChange: true }),
		name: 'getPlayers'
	})
)(DivisionCreateForm);

const WithRouter = withRouter(WithGraphQL);
export default withStyles(styles)(WithRouter);
