import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
// import { withRouter } from 'react-router-dom';
// import Button from '@material-ui/core/Button';
// import Formsy from 'formsy-react';
// import TextInput from '../../components/forms/TextInputFormsy';
// import SelectField from '../../components/forms/SelectFormsy';
// import SwitchField from '../../components/forms/SwitchFormsy';
// import MultiSelectField from '../../components/forms/MultiSelectFormsy';
// import { graphql, compose } from 'react-apollo';
// import gql from 'graphql-tag';
// import isEmpty from 'lodash/isEmpty';
// import { getPlayerQuery } from './queries/getPlayerQuery';
// import { updatePlayerMutation } from './mutations/updatePlayerMutation';
// import { createPlayerMutation } from './mutations/createPlayerMutation';
// import eightBall from '../../assets/images/ball/eight-ball.png';
// import nineBall from '../../assets/images/ball/nine-ball.png';
// import tenBall from '../../assets/images/ball/ten-ball.png';
// import currentUser from '../../helpers/currentUser';

// const styles = theme => ({
//   error: {
//     color: '#f44336'
//   },
//   content: {
//     // padding: '10px 24px 20px 24px'
//     // maxWidth: 600
//   },
//   input: {
//     marginTop: 5
//   },
//   submit: {
//     marginTop: 10
//   },
//   selectEmpty: {
//     marginTop: theme.spacing.unit * 2
//   },
//   formControl: {
//     margin: theme.spacing.unit,
//     minWidth: 120
//   }
// });

// class PlayerCreateForm extends React.Component {
//   constructor(props) {
//     super(props);
//     this.disableButton = this.disableButton.bind(this);
//     this.enableButton = this.enableButton.bind(this);
//     this.state = { canSubmit: false, submitting: false };
//     this.submit = this.submit.bind(this);
//   }

//   getGameType = type => {
//     return type.indexOf('EIGHT_BALL') !== -1
//       ? eightBall
//       : type.indexOf('NINE_BALL') !== -1 ? nineBall : type.indexOf('TEN_BALL') !== -1 ? tenBall : null;
//   };

//   disableButton() {
//     this.setState({ canSubmit: false });
//   }

//   enableButton() {
//     this.setState({ canSubmit: true });
//   }

//   submit(model) {
//     console.log(model);
//     // fetch('http://example.com/', {
//     // 	method: 'post',
//     // 	body: JSON.stringify(model)
//     // });
//     this.setState({
//       submitting: true
//     });
//     if (!this.props.slug) {
//       this.props
//         .createDivision({
//           mutation: createDivisionMutation,
//           variables: {
//             input: {
//               name: model.name,
//               description: model.description,
//               type: model.type,
//               isActive: model.isActive,
//               operatorId: this.props.currentUser.id
//               // matchFormat: model.matchFormat
//             }
//           },
//           // refetchQueries: ['GetTeam']
//           refetchQueries: [{ query: getDivisionQuery }]
//         })
//         .then(({ data }) => {
//           // console.log(data);
//           this.props.history.push(`/divisions/${data.createDivision.slug}`);
//         })
//         .catch(error => {
//           console.log(error);
//           this.setState({ submitting: false });
//         });
//     } else {
//       const id = this.props.getDivision.division.id;
//       this.props
//         .updateDivision({
//           mutation: updateDivisionMutation,
//           variables: {
//             input: {
//               id: id,
//               name: model.name,
//               description: model.description,
//               type: model.type,
//               isActive: model.isActive
//               // matchFormat: model.matchFormat
//             }
//           },
//           // refetchQueries: ['GetTeam'],
//           refetchQueries: [{ query: getDivisionQuery, variables: { id: id } }]
//           // update: (store, { data: { updateTeam } }) => {
//           // 	this.props.updateStoreAfterUpdate(store, updateTeam, model.id);
//           // }
//           // update: (cache, { data: { updateTeam } }) => {
//           // 	// const { team } = cache.readQuery({ query: getTeamQuery });
//           // 	// console.log(team);
//           // 	cache.writeQuery({
//           // 		query: getTeamQuery,
//           // 		data: { team: updateTeam }
//           // 	});
//           // }

//           // optimisticResponse: {
//           // 	__typename: 'Mutation',
//           // 	updateTeam: {
//           // 		id: model.id,
//           // 		__typename: 'Team',
//           // 		name: model.name,
//           // 		slug: model.slug,
//           // 		isActive: model.isActive,
//           // 		location: model.location,
//           // 		players: model.players
//           // 	}
//           // }
//         })
//         .then(({ data }) => {
//           // console.log(data);
//           this.props.history.push(`/divisions/${data.updateDivision.slug}`);
//         })
//         .catch(error => {
//           console.log(error);
//           this.setState({ submitting: false });
//         });
//     }
//   }

//   render() {
//     const { classes, getDivision: { division, loading, error } } = this.props;

//     return (
//       <React.Fragment>
//         {loading === true ? (
//           <div>Loading...</div>
//         ) : (
//             <Formsy onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
//               <TextInput
//                 name="name"
//                 label="Name"
//                 value={division && division.name}
//                 validations="isExisty"
//                 validationError="Name is required"
//                 required
//                 autoFocus
//               />

//               <TextInput
//                 name="description"
//                 label="Description"
//                 value={division && division.description}
//               // validations="isExisty"
//               // validationError="Name is required"
//               />

//               <SelectField
//                 name="type"
//                 label="Type"
//                 value={division && division.type}
//                 options={[
//                   {
//                     label: '9-Ball Singles',
//                     value: 'NINE_BALL_SINGLES'
//                   },
//                   { label: '9-Ball Doubles', value: 'NINE_BALL_DOUBLES' },
//                   { label: '9-Ball Scotch Doubles', value: 'NINE_BALL_SCOTCH_DOUBLES' },
//                   { label: '9-Ball Teams', value: 'NINE_BALL_TEAMS' },
//                   {
//                     label: '8-Ball Singles',
//                     value: 'EIGHT_BALL_SINGLES'
//                   },
//                   { label: '8-Ball Doubles', value: 'EIGHT_BALL_DOUBLES' },
//                   { label: '8-Ball Scotch Doubles', value: 'EIGHT_BALL_SCOTCH_DOUBLES' },
//                   { label: '8-Ball Teams', value: 'EIGHT_BALL_TEAMS' }
//                 ]}
//                 required
//                 validations="isExisty"
//                 validationError="Type is required"
//               />

//               <SwitchField
//                 // type="checkbox"
//                 // checked={team.isActive}
//                 id="isActive"
//                 name="isActive"
//                 label="Active"
//                 value={division && division.isActive === true ? true : false}
//               />

//               <br />

//               {this.props.slug ? (
//                 <Button variant="contained" color="primary" disabled={!this.state.canSubmit} type="submit">
//                   Update
// 							</Button>
//               ) : (
//                   <Button variant="contained" color="primary" disabled={!this.state.canSubmit} type="submit">
//                     Create
// 							</Button>
//                 )}
//             </Formsy>
//           )}
//       </React.Fragment>
//     );
//   }
// }

// const WithGraphQL = compose(
//   graphql(getDivisionQuery, {
//     options: ({ slug }) => ({
//       variables: { slug },
//       notifyOnNetworkStatusChange: true,
//       fetchPolicy: 'no-cache'
//       // refetchQueries: [
//       // 	{
//       // 		query: getTeamQuery,
//       // 		variables: { variables: { slug } }
//       // 	}
//       // ]
//     }),
//     name: 'getDivision'
//   }),
//   graphql(createDivisionMutation, {
//     options: ({ input }) => ({ variables: { input } }),
//     name: 'createDivision'
//   }),
//   graphql(updateDivisionMutation, {
//     options: ({ input }) => ({ variables: { input } }),
//     name: 'updateDivision'
//   })
// )(PlayerCreateForm);

// const WithRouter = withRouter(WithGraphQL);
// const WithStyles = withStyles(styles)(WithRouter);
// export default currentUser(WithStyles);

class TournamentCreateForm extends React.Component {
	render() {
		return <div>Create Tournament Form</div>;
	}
}

export default TournamentCreateForm;
