import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { saveAuthToken, removeAuthToken, getToken } from './authUtils';
import client from '../config/apolloClient';
import gql from 'graphql-tag';

const GET_CURRENT_USER_QUERY = gql`
	query UserInfo {
		currentUser {
			id
			name
			username
			email
			avatarImg
			playerNumber
			online
			firstRun
			token
			divisions {
				id
				name
				slug
			}
		}
	}
`;

const USER_CONNECTED_MUTATION = gql`
	mutation UserConnected($userId: ID) {
		triggerUserConnected(userId: $userId) {
			id
			name
			username
		}
	}
`;

const USER_DISCONNECTED_MUTATION = gql`
	mutation UserDisconnected($userId: ID) {
		triggerUserDisconnected(userId: $userId) {
			id
			name
			username
		}
	}
`;

const withAuthentication = Component => {
	class WithAuthentication extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				authUser: null,
				isAuthenticated: false,
				checkAuthStatus: this.checkAuthStatus,
				changeLoginState: this.handleChangeLoginState,
				signIn: this.handleSignIn,
				signOut: this.handleSignOut,
				getUserProfile: this.getUserProfile
			};
		}

		getChildContext() {
			return {
				authUser: this.state.authUser,
				isAuthenticated: this.state.isAuthenticated,
				checkAuthStatus: this.checkAuthStatus,
				changeLoginState: this.handleChangeLoginState,
				signIn: this.handleSignIn,
				signOut: this.handleSignOut,
				getUserProfile: this.getUserProfile
				// authUser: JSON.parse(localStorage.getItem("authUser")) || null
			};
		}

		handleChangeLoginState = (isAuthenticated, userObj, jwt) => {
			console.log({
				isAuthenticated,
				...userObj,
				jwt
			});
			if (isAuthenticated === true) {
				this.handleSignIn(userObj.user, jwt);
			} else {
				this.handleSignOut(userObj.user);
			}
		};

		handleSignIn = (user, jwt) => {
			saveAuthToken(jwt);
			this.handleUserConnected(user.id);
			this.setState({ authUser: user, isAuthenticated: true });
		};

		handleSignOut = user => {
			removeAuthToken();
			this.handleUserDisconnected(user.id);
			this.setState({ authUser: null, isAuthenticated: false });
		};

		checkAuthStatus = () => {
			return !!localStorage.getItem('DPLToken');
		};

		getUserProfile = async id => {
			return await client
				.query({
					query: GET_CURRENT_USER_QUERY,
					variables: { userId: id }
				})
				.then(res => {
					const currentUser = res.data.currentUser;
					this.setState({ authUser: currentUser });
					localStorage.setItem('DPLAuthentication', JSON.stringify(currentUser));
					return currentUser;
				});
		};

		handleUserConnected = async id => {
			return await client
				.mutate({
					mutation: USER_CONNECTED_MUTATION,
					variables: { userId: id }
				})
				.then(res => {
					console.log('User connected', res);
					return res.data;
				})
				.catch(error => {
					console.log(error);
					return error;
				});
		};

		handleUserDisconnected = async id => {
			return await client
				.mutate({
					mutation: USER_DISCONNECTED_MUTATION,
					variables: { userId: id }
				})
				.then(res => {
					console.log('User disconnected', res);
					return res.data;
				})
				.catch(error => {
					console.log(error);
					return error;
				});
		};

		async componentWillMount() {
			// const token = await getToken();
			// if (token) {
			// 	this.setState({ isAuthenticated: true });
			// }

			try {
				const isLogin = !!localStorage.getItem('DPLToken');
				if (isLogin) {
					const token = localStorage.getItem('DPLToken');
					const authUser = JSON.parse(localStorage.getItem('DPLAuthentication'));
					console.log(token);
					console.log(authUser);

					this.setState({ isAuthenticated: true, authUser });

					// const data = await this.getUserProfile(authUser.id);
					// if (data) {
					// 	console.log(data);
					// 	this.setState({ authUser: data.currentUser });
					// }
					// this.setState(() => ({ authUser: authUser }));
				}
				// firebase.auth.onAuthStateChanged(authUser => {
				// 	let mergedUser = {};

				// 	if (authUser) {
				// 		// Grab additional metadata from users table in Firebase realtime db
				// 		db.getUser(authUser.uid).then(metadata => {
				// 			mergedUser = _.assign({}, authUser, metadata);
				// 			localStorage.setItem(
				// 				'authentication',
				// 				JSON.stringify({
				// 					id: authUser.uid,
				// 					username: mergedUser.username,
				// 					fullName: mergedUser.fullName,
				// 					email: mergedUser.email,
				// 					role: mergedUser.role,
				// 					accountType: mergedUser.accountType
				// 				})
				// 			);
				// 			this.setState(() => ({ authUser: mergedUser }));
				// 		});
				// 	} else {
				// 		localStorage.removeItem('authentication');
				// 		this.setState(() => ({ authUser: null }));
				// 	}
				// });
			} catch (e) {
				console.error(e.message);
			}
		}

		render() {
			return <Component />;
		}
	}

	WithAuthentication.childContextTypes = {
		authUser: PropTypes.object,
		getUserProfile: PropTypes.func,
		isAuthenticated: PropTypes.bool,
		checkAuthStatus: PropTypes.func,
		changeLoginState: PropTypes.func,
		signIn: PropTypes.func,
		signOut: PropTypes.func
	};

	return WithAuthentication;
};

export default withAuthentication;
