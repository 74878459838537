import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import AppBarHeader from '../components/chrome/AppBarHeader';
import SubHeader from '../components/chrome/SubHeader';
import NavDrawer from '../components/chrome/NavDrawer';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import bgImage from '../assets/images/abstract.jpg';
// import NavDrawerMini from "../components/chrome/nav_drawer_mini";

const styles = theme => ({
	titleBar: {
		backgroundColor: '#14171d',
		borderRadius: 0,
		backgroundImage: `url(${bgImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100%',
		backgroundPosition: 'right'
	},
	heading: {
		margin: 0,
		textAlign: 'center',
		display: 'inline-block',
		color: 'white',
		fontFamily: 'din_medium, Roboto, sans-serif'
		// fontWeight: 300
	},

	headerBar: {
		paddingTop: 30,
		paddingBottom: 30
	},
	card: {
		margin: '24px 0px',
		// padding: '5px 24px',

		color: 'rgb(117, 117, 117)',
		backgroundColor: 'rgb(255, 255, 255)',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
		boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		borderRadius: 5,
		zIndex: 1
	}
});

class SubLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			top: false,
			left: true,
			bottom: false,
			right: false,
			navDrawerVisible: false,
			snackbar: false,
			snackbarMessage: ''
		};
	}

	// handleToggle = () => this.setState({ open: !this.state.open });
	// handleClose = () => this.setState({ open: false });

	handleSnackbarToggle = message => {
		this.setState({
			snackbar: !this.state.stackbar,
			snackbarMessage: message || ''
		});
	};

	handleSnackbarClose = () => {
		this.setState({
			snackbar: false
		});
	};

	render() {
		const { classes, component: Component, title, ...rest } = this.props;

		return (
			<Route
				{...rest}
				render={matchProps => (
					<div className="wrapper">
						<div className="content-container">
							<AppBarHeader title={title} {...matchProps} {...rest} />
							{/*<AppBarHeader title={title} {...matchProps} {...rest}>
								<SubHeader title={title} {...matchProps} />
				</AppBarHeader>*/}
						</div>

						<Component title={title} {...matchProps} />

						<Snackbar
							open={this.state.snackbar}
							message={this.state.snackbarMessage}
							autoHideDuration={4000}
							onRequestClose={this.handleSnackbarClose}
						/>
					</div>
				)}
			/>
		);
	}
}

SubLayout.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

SubLayout.contextTypes = {
	authUser: PropTypes.object
};

export default withStyles(styles, { withTheme: true })(SubLayout);
