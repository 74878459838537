import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { withFormsy } from 'formsy-react';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
	textField: {
		width: '100%',
		maxWidth: 400,
		backgroundColor: '#fafafa'
	},
	textFieldError: {
		width: '100%',
		maxWidth: 400,
		backgroundColor: '#fff5f5'
	}
});

const InputFeedback = ({ children }) => <div className="text-danger">{children}</div>;

class FileInput extends Component {
	constructor(props) {
		super(props);
		this.changeValue = this.changeValue.bind(this);
	}

	initElementRef = element => {
		this.element = element;
	};

	changeValue(event) {
		const target = event.currentTarget;
		// setValue() will set the value of the component, which in
		// turn will validate it and the rest of the form
		// Important: Don't skip this step. This pattern is required
		// for Formsy to work.
		this.props.setValue(target.files);
	}

	render() {
		const {
			classes,
			value,
			multiline,
			rows,
			name,
			label,
			getValue,
			getErrorMessage,
			autoFocus,
			required,
			style,
			variant
		} = this.props;
		// An error message is returned only if the component is invalid
		const errorMessage = getErrorMessage();
		// const selectedValue = value && value.hasOwnProperty('id') ? value.id : value;

		return (
			<FormControl margin="dense" required fullWidth>
				<InputLabel htmlFor={`${name}-helper`}>{name}</InputLabel>
				{/* <TextField
          error={Boolean(errorMessage)}
          id={name}
          name={name}
          label={label}
          value={getValue() || ''}
          onChange={this.changeValue}
          type="text"
          // defaultValue="Default Value"
          className={errorMessage ? classes.textFieldError : classes.textField}
          // margin="dense"
          autoComplete={name}
          autoFocus={autoFocus ? true : false}
          required={required ? true : false}
          multiline={multiline ? true : false}
          rows={rows ? rows : 0}
          style={style}
          variant={variant ? variant : 'standard'}
        /> */}

				<input
					id={name}
					name={name}
					accept="image/*"
					className={classes.input}
					style={{ display: 'none' }}
					// id="raised-button-file"
					// multiple
					onChange={this.changeValue}
					type="file"
					hidden
					ref={this.initElementRef}
				/>
				<label htmlFor={name}>
					<Button variant="contained" component="span" className={classes.button}>
						Upload
					</Button>
				</label>

				<InputFeedback>{errorMessage}</InputFeedback>
			</FormControl>
		);
	}
}

FileInput.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles)(FileInput);
export default withFormsy(WithStyles);
