import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Flag from 'react-flags';
import { isoCountries } from '../../utils';

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto'
	},
	table: {
		minWidth: 700
	},
	cell: {
		padding: '4px 20px 4px 20px',
		// padding: '4px 30px 4px 20px',
		// padding: '4px 30px 4px 20px',
		fontSize: 13
	}
});

let matchId = 0;
function createMatch(
	player1Country,
	player1Name,
	player1Score,
	player2Score,
	player2Country,
	player2Name,
	time,
	table,
	live
) {
	matchId += 1;
	return {
		matchId,
		player1Country,
		player1Name,
		player1Score,
		player2Score,
		player2Country,
		player2Name,
		time,
		table,
		live
	};
}

let roundId = 0;
function createRound(name, matches) {
	roundId += 1;
	return { roundId, name, matches: matches || [] };
}

const rounds = [
	createRound('Round 1', [
		createMatch('Poland', 'Daniel Maciol', 11, 7, 'Albania', 'Dorjan Maknori', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Zoltan Kersco', '-', '-', '', 'Walk Over', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Mario Toader', 4, 11, 'Poland', 'Wojciech Szewczyk', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Ciprian Gindac', 11, 9, 'Serbia', 'Andreja Klasovic', 'TUE 11:56', 'Table 03'),
		createMatch('Albania', 'Arjan Matrizi', 4, 11, 'Greece', 'Nick Malaj', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Gabi Gosa', 1, 11, 'Greece', 'Konstantinos Koukiadakis', 'TUE 11:56', 'Table 03'),
		createMatch('Bulgaria', 'Ivaylo Simeonov', 4, 11, 'Romania', 'Ioan Ladanyi', 'TUE 11:56', 'Table 03'),
		createMatch('Greece', 'Dimitris Loukatos', 1, 11, 'Poland', 'Konrad Juszczyszyn', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Toni Judet', 11, 7, 'Albania', 'Edmond Zaja', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Nic Berendea', 7, 11, 'Romania', 'Raul Maican', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Babken Melkonyan', 5, 11, 'Bulgaria', 'Stanimir Ruslanov', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Cristian Surdea', 11, 6, 'Poland', 'Mieszko Fortunski', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Robert Braga', 11, 9, 'Greece', 'PANAGIOTIS KSIPOLITEAS', 'TUE 11:56', 'Table 03'),
		createMatch('Germany', 'Thomas Pöschl', 3, 11, 'Austria', 'Albin Ouschan', 'TUE 11:56', 'Table 03'),
		createMatch('Poland', 'Marek Kudlik', 11, 3, 'Israel', 'Dan Afriat', 'TUE 11:56', 'Table 03'),
		createMatch('Greece', 'Thomas Charmantzis', 7, 11, 'Poland', 'Wiktor Zielinski', 'TUE 11:56', 'Table 03')
	]),
	createRound('Winners Side - Round 1', [
		createMatch('Albania', 'Eklent Kaci', 6, 11, 'Poland', 'Daniel Maciol', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Zoltan Kersco', 5, 11, 'Netherlands', 'Marc Bijsterbosch', 'TUE 11:56', 'Table 03'),
		createMatch('Germany', 'Ralf Souquet', 8, 11, 'Poland', 'Wojciech Szewczyk', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Ciprian Gindac', 11, 10, 'Finland', 'Petri Makkonen', 'TUE 11:56', 'Table 03'),
		createMatch('Russia', 'Ruslan Chinahov', 11, 5, 'Greece', 'Nick Malaj', 'TUE 11:56', 'Table 03'),
		createMatch(
			'Greece',
			'Konstantinos Koukiadakis',
			2,
			11,
			'United Kingdom',
			'Imran Majid',
			'TUE 11:56',
			'Table 03'
		),
		createMatch('Russia', 'Fedor Gorst', 11, 9, 'Romania', 'Ioan Ladanyi', 'TUE 11:56', 'Table 03'),
		createMatch('Poland', 'Konrad Juszczyszyn', 10, 11, 'Estonia', 'Edmond Zaja', 'TUE 11:56', 'Table 03'),
		createMatch('Estonia', 'Denis Grabe', 5, 11, 'Romania', 'Toni Judet', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Raul Maican', 3, 11, 'Austria', 'Mario He', 'TUE 11:56', 'Table 03'),
		createMatch('Germany', 'Joshua Filler', 11, 6, 'Bulgaria', 'Stanimir Ruslanov', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Cristian Surdea', 6, 11, 'United Kingdom', 'Darren Appleton', 'TUE 11:56', 'Table 03'),
		createMatch('Scotland', 'Jayson Shaw', 11, 5, 'Romania', 'Robert Braga', 'TUE 11:56', 'Table 03'),
		createMatch('Austria', 'Albin Ouschan', 9, 11, 'Germany', 'Thorsten Hohmann', 'TUE 11:56', 'Table 03'),
		createMatch('Netherlands', 'Niels Feijen', 9, 11, 'Poland', 'Marek Kudlik', 'TUE 11:56', 'Table 03'),
		createMatch('Poland', 'Wiktor Zielinski', 9, 11, 'Greece', 'Alex Kazakis', 'TUE 11:56', 'Table 03')
	]),
	createRound('Losers Side - round 1', [
		createMatch('Albania', 'Dorjan Maknori', 3, 11, 'Poland', 'Wiktor Zielinski', 'TUE 11:56', 'Table 03'),
		createMatch('-', 'Walk Over', '-', '-', 'Netherlands', 'Niels Feijen', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Mario Toader', 6, 11, 'Austria', 'Albin Ouschan', 'TUE 11:56', 'Table 03'),
		createMatch('Serbia', 'Andreja Klasovic', 11, 7, 'Romania', 'Robert Braga', 'TUE 11:56', 'Table 03'),
		createMatch('Albania', 'Arjan Matrizi', 8, 11, 'Romania', 'Cristian Surdea', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Gabi Gosa', 5, 11, 'Bulgaria', 'Stanimir Ruslanov', 'TUE 11:56', 'Table 03'),
		createMatch('Bulgaria', 'Ivaylo Simeonov', 11, 10, 'Romania', 'Raul Maican', 'TUE 11:56', 'Table 03'),
		createMatch('Greece', 'Dimitris Loukatos', 8, 11, 'Estonia', 'Denis Grabe', 'TUE 11:56', 'Table 03'),
		createMatch('Albania', 'Edmond Zaja', 9, 11, 'Poland', 'Konrad Juszczyszyn', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Nic Berendea', 7, 11, 'Romania', 'Ioan Ladanyi', 'TUE 11:56', 'Table 03'),
		createMatch(
			'Romania',
			'Babken Melkonyan',
			11,
			6,
			'Greece',
			'Konstantinos Koukiadakis',
			'TUE 11:56',
			'Table 03'
		),
		createMatch('Poland', 'Mieszko Fortunski', 11, 10, 'Greece', 'Nick Malaj', 'TUE 11:56', 'Table 03'),
		createMatch('Greece', 'PANAGIOTIS KSIPOLITEAS', 3, 11, 'Finland', 'Petri Makkonen', 'TUE 11:56', 'Table 03'),
		createMatch('Germany', 'Thomas Pöschl', 4, 11, 'Germany', 'Ralf Souquet', 'TUE 11:56', 'Table 03'),
		createMatch('Israel', 'Dan Afriat', 11, 9, 'Romania', 'Zoltan Kersco', 'TUE 11:56', 'Table 03'),
		createMatch('Greece', 'Thomas Charmantzis', 2, 11, 'Albania', 'Eklent Kaci', 'TUE 11:56', 'Table 03')
	]),
	createRound('Winners Qualification', [
		createMatch('Poland', 'Daniel Maciol', 10, 11, 'Netherlands', 'Marc Bijsterbosch', 'TUE 11:56', 'Table 03'),
		createMatch('Poland', 'Wojciech Szewczyk', 11, 9, 'Romania', 'Ciprian Gindac', 'TUE 11:56', 'Table 03'),
		createMatch('Russia', 'Ruslan Chinahov', 11, 8, 'United Kingdoom', 'Imran Majid', 'TUE 11:56', 'Table 03'),
		createMatch('Russia', 'Fedor Gorst', 11, 7, 'United Kingdom', 'Chris Melling', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Toni Judet', 10, 11, 'Austria', 'Mario He', 'TUE 11:56', 'Table 03'),
		createMatch('Germany', 'Joshua Filler', 5, 11, 'United Kingdom', 'Darren Appleton', 'TUE 11:56', 'Table 03'),
		createMatch('Scotland', 'Jayson Shaw', 11, 5, 'Germany', 'Thorsten Hohmann', 'TUE 11:56', 'Table 03'),
		createMatch('Poland', 'Marek Kudlik', 7, 11, 'Greece', 'Alex Kazakis', 'TUE 11:56', 'Table 03')
	]),
	createRound('Losers Side - round 2', [
		createMatch('Poland', 'Wiktor Zielinski', 11, 10, 'Netherlands', 'Niels Feijen', 'TUE 11:56', 'Table 03'),
		createMatch('Austria', 'Albin Ouschan', 11, 4, 'Serbia', 'Andreja Klasovic', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Cristian Surdea', 9, 11, 'Bulgaria', 'Stanimir Ruslanov', 'TUE 11:56', 'Table 03'),
		createMatch('Bulgaria', 'Ivaylo Simeonov', 5, 11, 'Estonia', 'Denis Grabe', 'TUE 11:56', 'Table 03'),
		createMatch('Poland', 'Konrad Juszczyszyn', 7, 11, 'Romania', 'Ioan Ladanyi', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Babken Melkonyan', 8, 11, 'Poland', 'Mieszko Fortunski', 'TUE 11:56', 'Table 03'),
		createMatch('Finland', 'Petri Makkonen', 11, 9, 'Germany', 'Ralf Souquet', 'TUE 11:56', 'Table 03'),
		createMatch('Israel', 'Dan Afriat', 6, 11, 'Albania', 'Eklent Kaci', 'TUE 11:56', 'Table 03')
	]),
	createRound('Losers Qualification', [
		createMatch('Poland', 'Wiktor Zielinski', 3, 11, 'United Kingdom', 'Chris Melling', 'TUE 11:56', 'Table 03'),
		createMatch('Austria', 'Albin Ouschan', 11, 9, 'United Kingdom', 'Imran Majid', 'TUE 11:56', 'Table 03'),
		createMatch('Bulgaria', 'Stanimir Ruslanov', 10, 11, 'Romania', 'Ciprian Gindac', 'TUE 11:56', 'Table 03'),
		createMatch('Estonia', 'Denis Grabe', 9, 11, 'Poland', 'Daniel Maciol', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Ioan Ladanyi', 11, 7, 'Poland', 'Marek Kudlik', 'TUE 11:56', 'Table 03'),
		createMatch('Poland', 'Mieszko Fortunski', 8, 11, 'Germany', 'Thorsten Hohmann', 'TUE 11:56', 'Table 03'),
		createMatch('Finland', 'Petri Makkonen', 11, 3, 'Germany', 'Joshua Filler', 'TUE 11:56', 'Table 03'),
		createMatch('Albania', 'Eklent Kaci', 11, 1, 'Romania', 'Toni Judet', 'TUE 11:56', 'Table 03')
	]),
	createRound('Last Sixteen', [
		createMatch(
			'Netherlands',
			'Marc Bijsterbosch',
			4,
			13,
			'United Kingdom',
			'Chris Melling',
			'TUE 11:56',
			'Table 03'
		),
		createMatch('Poland', 'Wojciech Szewczyk', 13, 9, 'Austria', 'Albin Ouschan', 'TUE 11:56', 'Table 03'),
		createMatch('Russia', 'Ruslan Chinahov', 6, 13, 'Romania', 'Ioan Ladanyi', 'TUE 11:56', 'Table 03'),
		createMatch('Russia', 'Fedor Gorst', 13, 10, 'Finland', 'Petri Makkonen', 'TUE 11:56', 'Table 03'),
		createMatch('Austria', 'Mario He', 8, 13, 'Albania', 'Eklent Kaci', 'TUE 11:56', 'Table 03'),
		createMatch('United Kingdom', 'Darren Appleton', 11, 13, 'Poland', 'Daniel Maciol', 'TUE 11:56', 'Table 03'),
		createMatch('Scotland', 'Jayson Shaw', 13, 5, 'Romania', 'Ciprian Gindac', 'TUE 11:56', 'Table 03'),
		createMatch('Greece', 'Alex Kazakis', 5, 13, 'Germany', 'Thorsten Hohmann', 'TUE 11:56', 'Table 03')
	]),
	createRound('Quarter Final', [
		createMatch('United Kingdom', 'Chris Melling', 13, 9, 'Poland', 'Wojciech Szewczyk', 'TUE 11:56', 'Table 03'),
		createMatch('Romania', 'Ioan Ladanyi', 13, 11, 'Russia', 'Fedor Gorst', 'TUE 11:56', 'Table 03'),
		createMatch('Albania', 'Eklent Kaci', 13, 4, 'Poland', 'Daniel Maciol', 'TUE 11:56', 'Table 03'),
		createMatch('Scotland', 'Jayson Shaw', 13, 12, 'Germany', 'Thorsten Hohmann', 'TUE 11:56', 'Table 03')
	]),
	createRound('Semi Final', [
		createMatch('United Kingdom', 'Chris Melling', 13, 5, 'Romania', 'Ioan Ladanyi', 'TUE 11:56', 'Table 03'),
		createMatch('Albania', 'Eklent Kaci', 3, 13, 'Scotland', 'Jayson Shaw', 'TUE 11:56', 'Table 03')
	]),
	createRound('Final', [
		createMatch('United Kingdom', 'Chris Melling', 17, 12, 'Scotland', 'Jayson Shaw', 'TUE 11:56', 'Table 03', true)
	])
].reverse();

class Schedule extends Component {
	render() {
		const { classes } = this.props;

		return (
			<div style={{ overflow: 'auto' }}>
				{rounds.map((round, index) => {
					return (
						<React.Fragment key={index}>
							<h4 style={{ marginLeft: 20, marginTop: 0, marginBottom: 0, marginRight: 20 }}>
								{round.name}
							</h4>
							<div className="round">
								<Table className={classes.table}>
									<TableBody>
										{round.matches.map((row, index2) => {
											const player1CountryName =
												isoCountries[row.player1Country.toLowerCase()] || '';
											const player2CountryName =
												isoCountries[row.player2Country.toLowerCase()] || '';

											return (
												<TableRow
													key={index2}
													hover
													style={{
														cursor: 'pointer',
														// backgroundColor: getRowColor(row.id),
														backgroundColor: row.live === true ? '#ffeaea' : null,
														transition: 'all 150ms ease'
														// '&:hover': {
														// 	backgroundColor: `darken(${getRowColor(row.id)}, 50%)`
														// }
													}}
												>
													<TableCell className={classes.cell} style={{ width: 50 }}>
														{row.matchId}
													</TableCell>
													<TableCell className={classes.cell} style={{ width: 200 }}>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'flex-end',
																textAlign: 'right'
															}}
														>
															<span style={{ marginRight: 10 }}>{row.player1Name}</span>
															<Flag
																name={player1CountryName || 'Unknown'}
																format="png"
																pngSize={24}
																shiny={false}
																alt="Canada Flag"
																className="flag"
																basePath="/images/flags"
															/>
														</div>
													</TableCell>
													<TableCell
														className={classes.cell}
														style={{
															textAlign: 'center',
															padding: '4px 10px 4px 10px',
															backgroundColor:
																row.player1Score > row.player2Score
																	? 'rgba(0,0,0,0.08)'
																	: 'rgba(0,0,0,0.02)'
														}}
													>
														{row.player1Score > row.player2Score ? (
															<b>{row.player1Score}</b>
														) : (
															row.player1Score
														)}
													</TableCell>
													<TableCell
														className={classes.cell}
														style={{
															textAlign: 'center',
															padding: '4px 10px 4px 10px',
															backgroundColor:
																row.player2Score > row.player1Score
																	? 'rgba(0,0,0,0.08)'
																	: 'rgba(0,0,0,0.02)'
														}}
													>
														{row.player2Score > row.player1Score ? (
															<b>{row.player2Score}</b>
														) : (
															row.player2Score
														)}
													</TableCell>
													<TableCell className={classes.cell} style={{ width: 200 }}>
														<div
															style={{
																display: 'flex',
																alignItems: 'center'
															}}
														>
															<Flag
																name={player2CountryName || 'Unknown'}
																format="png"
																pngSize={24}
																shiny={false}
																alt="Canada Flag"
																className="flag"
																basePath="/images/flags"
															/>
															<span style={{ marginLeft: 10 }}>{row.player2Name}</span>
														</div>
													</TableCell>
													<TableCell className={classes.cell}>{row.time}</TableCell>
													<TableCell className={classes.cell}>
														{row.live ? (
															<div>
																<span>{row.table}</span>
																<span className="live-badge" style={{ marginLeft: 10 }}>
																	LIVE
																</span>
															</div>
														) : (
															<span>{row.table}</span>
														)}
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</div>
						</React.Fragment>
					);
				})}
			</div>
		);
	}
}

Schedule.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Schedule);
