import gql from 'graphql-tag';

export const getTeamQuery = gql`
	query GetTeam($id: ID, $slug: String) {
		team(id: $id, slug: $slug) {
			id
			name
			slug
			number
			isActive
			captain {
				id
				name
				slug
				username
				email
			}
			coCaptain {
				id
				name
				slug
				username
				email
			}
			league {
				id
				name
				slug
				type
			}
			divisions {
				id
				name
				slug
				type
			}
			players {
				id
				name
				username
				email
				avatarImg
			}
			tournaments {
				id
				name
				slug
				startTime
			}
			matches {
				id
				teams {
					id
					name
				}
				division {
					id
					name
					slug
				}
				createdAt
			}
			location {
				id
				name
				slug
				address
				city
				state
				zip
				country
				# lat
				# lon
			}
			createdAt
		}
	}
`;
