import gql from 'graphql-tag';

export const getLeaguesQuery = gql`
	query GetLeagues($where: JSON, $offset: Int, $limit: Int, $order: [[String]]) {
		leagues(where: $where, offset: $offset, limit: $limit, order: $order) {
			id
			name
			slug
			type
			owner {
				id
				name
				slug
				username
				avatarImg
			}
			operator {
				id
				name
				slug
				username
				avatarImg
			}
			locations {
				id
				name
				slug
				address
				city
				state
				zip
				country
				lat
				lon
			}
			divisions {
				id
				name
				slug
			}
			teams {
				id
				name
				slug
			}
			players {
				id
				name
				username
				avatarImg
				slug
			}
			address1
			address2
			city
			state
			zip
			county
			country
			phone
			fax
			email
			website
			facebook
			twitter
			instagram
			logo
			createdAt
		}
	}
`;
