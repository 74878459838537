import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Route, Link } from 'react-router-dom';
import _ from 'lodash';

const BreadcrumbBar = props => (
	<div className="breadcrumb-bar">
		<Grid container justify="center" spacing={24}>
			<Grid item xs={11} sm={11} md={11} lg={10}>
				<Link to="/" className="breadcrumb-first">
					Home
				</Link>
				<Route path="/:path" component={BreadcrumbsItem} />
			</Grid>
		</Grid>
	</div>
);

const BreadcrumbsItem = ({ match, ...rest }) => (
	<span>
		<Link to={match.url || ''} className="breadcrumb">
			{_.capitalize(match.params.path)}
		</Link>
		<Route path={`${match.url}/:path`} component={BreadcrumbsItem} />
	</span>
);

export default BreadcrumbBar;
