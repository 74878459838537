import gql from 'graphql-tag';

import { locationFragment } from '../../../api/fragments/location';

export const updateMatchMutation = gql`
	mutation UpdateMatch($input: MatchInput) {
		updateMatch(input: $input) {
			id
			type
			gameType
			format
			location {
				...locationInfo
			}
			division {
				id
				name
			}
			tournament {
				id
				name
			}
			players {
				id
				name
			}
			teams {
				id
				name
			}
			games {
				id
				createdAt
			}
			status
			verified
			startTime
			createdAt
		}
	}
	${locationFragment}
`;
