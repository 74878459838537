import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TeamCreateEditForm from './TeamCreateEditForm';
import TeamCreateForm from './TeamCreateForm';
import { SectionHeader, Card } from '../../components/chrome';
import Typography from '@material-ui/core/Typography';
import { getTeamQuery } from './queries/getTeamQuery';

const styles = theme => ({
	toolbarTitle: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	margin: {
		[theme.breakpoints.up('sm')]: {
			marginTop: 24,
			marginBottom: 24
		}
	}
});

class TeamEdit extends Component {
	handleAfterSubmit = slug => {
		const { history } = this.props;
	};

	_updateCacheAfterUpdate = (store, updateTeam, teamId) => {
		const data = store.readQuery({ query: getTeamQuery });

		// const updatedTeam = data.team.find(team => team.id === teamId);
		// votedLink.votes = createVote.link.votes

		store.writeQuery({ query: getTeamQuery, updateTeam });
	};

	render() {
		const { classes } = this.props;

		return (
			<SectionHeader title="Edit Team" backButton={true} backPath={`/teams/${this.props.match.params.slug}`}>
				<div className={classes.margin}>
					<Grid container justify="center" spacing={24}>
						<Grid item xs={12} sm={11} md={11} lg={10}>
							<Grid container justify="center" spacing={24}>
								<Grid item xs={12}>
									<Card>
										<Typography variant="title" color="inherit" className={classes.toolbarTitle}>
											<span>Team Details</span>
										</Typography>
										<br />
										<TeamCreateForm
											slug={this.props.match.params.slug}
											updateStoreAfterUpdate={this._updateCacheAfterUpdate}
										/>
										{/*<TeamCreateEditForm
											slug={this.props.match.params.slug}
											updateStoreAfterUpdate={this._updateCacheAfterUpdate}
										/>*/}
									</Card>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</SectionHeader>
		);
	}
}

TeamEdit.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TeamEdit);
