import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import PlayersList from './PlayersList';
import client from '../../config/apolloClient';
import qs from 'qs';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import bgImage from '../../assets/images/abstract5.jpg';
import { SectionHeader } from '../../components/chrome';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getPlayersQuery } from './queries/getPlayersQuery';

const GET_TEAMS_META = gql`
	query {
		_allTeamsMeta {
			count
		}
	}
`;

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	},
	headerLight: {
		marginTop: 0,
		marginBottom: 20,
		textAlign: 'left',
		display: 'flex',
		color: 'black',
		fontFamily: 'din_lightregular, Roboto, sans-serif',
		fontWeight: 400,
		fontSize: 30,
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	titleBar: {
		// backgroundColor: '#14171d',
		backgroundColor: '#1c1f23',
		borderRadius: 0,
		backgroundImage: `url(${bgImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100%',
		// backgroundPosition: 'right',
		backgroundAttachment: 'fixed'
	},
	heading: {
		margin: 0,
		textAlign: 'center',
		display: 'flex',
		color: 'white',
		fontFamily: 'din_medium, Roboto, sans-serif',
		alignItems: 'center',
		justifyContent: 'space-between'
		// fontSize: '2em'
		// fontWeight: 300
	},

	headerBar: {
		paddingTop: 30,
		paddingBottom: 30
	}
});

class PlayersContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			count: 0
		};
	}

	componentWillMount() {
		client
			.query({
				query: gql`
					{
						_allTeamsMeta {
							count
						}
					}
				`
			})
			.then(response => {
				const data = response.data;
				this.setState({
					count: data._allTeamsMeta.count
				});
			});
	}
	render() {
		const { classes, title, match, history, location } = this.props;
		const params = qs.parse(location.search.slice(1), { strictNullHandling: true });

		let newOrder;
		if (params.order) {
			newOrder = [];
			const orderSplit = params.order.split(',');
			newOrder.push([orderSplit[0], orderSplit[1]]);
		}

		// const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

		return (
			<SectionHeader
				title="Players"
				rightButton={
					<Button variant="outlined" color="secondary" onClick={() => history.push('/players/new')}>
						Add Player
					</Button>
				}
			>
				<Query
					query={getPlayersQuery}
					variables={{
						where: null,
						offset: params.offset || 0,
						limit: params.limit || 200,
						order: newOrder || [['name', 'ASC']]
					}}
					fetchPolicy="cache-and-network"
				>
					{({ loading, error, data, subscribeToMore }) => {
						if (loading) return <LinearProgress mode="indeterminate" color="primary" />;
						if (error) return <div style={styles.container}>Error: {error.message}</div>;

						return (
							<div style={{ marginTop: 24, marginBottom: 24 }}>
								<Grid container justify="center" spacing={24}>
									<Grid item xs={12} sm={12} md={11} lg={10} style={{ overflow: 'auto' }}>
										<PlayersList
											data={data}
											subscribeToMore={subscribeToMore}
											match={match}
											history={history}
											count={this.state.count}
										/>
									</Grid>
								</Grid>
							</div>
						);
					}}
				</Query>
			</SectionHeader>
		);
	}
}

PlayersContainer.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PlayersContainer);
