import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withAlert } from 'react-alert';
import { Link, withRouter } from 'react-router-dom';
import { SignInLink } from './signin';
import LinearProgress from '@material-ui/core/LinearProgress';
import Card from '@material-ui/core/Card';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import auth from '../../auth/gql';
// import { auth, db } from '../../firebase';
import * as routes from '../../config/routes';

import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PasswordField from 'material-ui-password-field';

const styles = theme => ({
	layout: {
		width: 'auto',
		display: 'block', // Fix IE11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	paper: {
		marginTop: theme.spacing.unit * 8,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
	},
	avatar: {
		margin: theme.spacing.unit,
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE11 issue.
		marginTop: theme.spacing.unit
	},
	submit: {
		marginTop: theme.spacing.unit * 3
	}
});
class SignUpPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showProgress: false
		};

		this.showProgressBar = this.showProgressBar.bind(this);
		this.hideProgressBar = this.hideProgressBar.bind(this);
	}

	showProgressBar() {
		this.setState({
			showProgress: true
		});
	}

	hideProgressBar() {
		this.setState({
			showProgress: false
		});
	}

	render() {
		const { classes, history, theme, ...rest } = this.props;
		return (
			<div className="page-container">
				{this.state.showProgress === true && <LinearProgress mode="indeterminate" color="primary" />}

				<main className={classes.layout}>
					<Paper className={classes.paper}>
						<Avatar className={classes.avatar}>
							<LockIcon />
						</Avatar>
						<Typography variant="h5">Sign Up</Typography>
						<SignUpFormWithStyles
							showProgressBar={this.showProgressBar}
							hideProgressBar={this.hideProgressBar}
							history={history}
							{...rest}
						/>
						<SignInLink />
					</Paper>
				</main>
			</div>
		);
	}
}

SignUpPage.propTypes = {
	classes: PropTypes.object.isRequired
};

SignUpPage.contextTypes = {
	authUser: PropTypes.object,
	getUserProfile: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	changeLoginState: PropTypes.func,
	signIn: PropTypes.func,
	signOut: PropTypes.func
};

const INITIAL_STATE = {
	name: '',
	username: '',
	email: '',
	emailError: false,
	password: '',
	passwordError: false,
	confirmPassword: '',
	confirmPasswordError: false,
	role: 'USER',
	error: null,
	signingUp: false
};

class SignUpForm extends Component {
	constructor(props) {
		super(props);
		this.state = { ...INITIAL_STATE };
		this.onSubmit = this.onSubmit.bind(this);
	}

	onSubmit(event) {
		event.preventDefault();
		this.setState({
			signingUp: true
		});
		this.props.showProgressBar();

		const { name, username, email, password, confirmPassword, role } = this.state;
		const { classes, history, ...rest } = this.props;

		auth.createUserWithEmailAndPassword(name, email, password)
			.then(result => {
				this.setState(() => ({ ...INITIAL_STATE }));
				this.props.hideProgressBar();
				this.props.alert.success('Successfully signed up');
				localStorage.setItem('DPLToken', result.data.signup.token);
				localStorage.setItem('DPLAuthentication', JSON.stringify(result.data.signup.user));
				this.context.changeLoginState(true, result.data.signup, result.data.signup.token);
				auth.setUserAuthToken(result.data.signup.user.id, result.data.signup.token)
					.then(res => {
						console.log(res);
					})
					.catch(error => {
						console.log(error);
					});
				history.push(routes.HOME);
			})
			.catch(error => {
				this.setState({ error: error, signingUp: false });
				this.props.alert.error('Error signing up in');
				this.props.hideProgressBar();
			});
	}

	render() {
		const { classes } = this.props;
		const { name, username, email, password, confirmPassword, error } = this.state;

		const isInvalid = password !== confirmPassword || password === '' || name === '' || email === '';

		return (
			<form className={classes.form} onSubmit={this.onSubmit}>
				<FormControl margin="dense" required fullWidth>
					<InputLabel htmlFor="name">Full Name</InputLabel>
					<Input
						error={Boolean(error)}
						id="name"
						name="name"
						value={name}
						onChange={event => this.setState({ name: event.target.value })}
						type="text"
						style={styles.input}
						autoComplete="name"
						autoFocus
					/>
					{/*<FormHelperText error={Boolean(error)}>Enter your full name</FormHelperText>*/}
				</FormControl>
				{/*<FormControl margin="dense" fullWidth>
					<InputLabel htmlFor="username">Username</InputLabel>
					<Input
						error={Boolean(error)}
						id="username"
						name="username"
						value={username}
						onChange={event => this.setState({ username: event.target.value })}
						type="text"
						style={styles.input}
						autoComplete="username"
					/>
					<FormHelperText error={Boolean(error)}>
						Make it cool! You won't be able to change it!
					</FormHelperText>
		</FormControl>*/}
				<FormControl margin="dense" required fullWidth>
					<InputLabel htmlFor="email">Email Address</InputLabel>
					<Input
						error={Boolean(error)}
						id="email"
						name="email"
						value={email}
						onChange={event => this.setState({ email: event.target.value })}
						type="text"
						style={styles.input}
						autoComplete="email"
					/>
					{/*<FormHelperText error={Boolean(error)}>Enter your email address</FormHelperText>*/}
				</FormControl>
				<FormControl margin="dense" required fullWidth>
					<InputLabel htmlFor="password">Password</InputLabel>
					<PasswordField
						error={Boolean(error)}
						name="password"
						type="password"
						id="password"
						value={password}
						onChange={event => this.setState({ password: event.target.value })}
						autoComplete="current-password"
					/>
					{/*<FormHelperText error={Boolean(error)}>Enter your secret password</FormHelperText>*/}
				</FormControl>
				<FormControl margin="dense" required fullWidth>
					<InputLabel htmlFor="password">Confirm Password</InputLabel>
					<PasswordField
						error={Boolean(error)}
						name="password"
						type="password"
						id="confirm-password"
						value={confirmPassword}
						onChange={event => this.setState({ confirmPassword: event.target.value })}
						autoComplete="confirm-password"
					/>
					{/*<FormHelperText error={Boolean(error)}>Confirm your secret password</FormHelperText>*/}
				</FormControl>
				{/*<FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />*/}
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					disabled={isInvalid || this.state.signingUp === true}
					className={classes.submit}
				>
					{this.state.signingUp === false ? 'Sign Up' : 'Signing up...'}
				</Button>
				<div style={{ marginTop: 5, fontSize: 12, textAlign: 'center' }}>
					By signing up, you agree to our{' '}
					<a href="/terms" style={{ fontWeight: 700 }}>
						Terms of Use
					</a>{' '}
					and{' '}
					<a href="/privacy" style={{ fontWeight: 700 }}>
						Privacy Policy.
					</a>
				</div>
				{error && <p style={{ color: 'red' }}>ERROR: {error.message}</p>}
			</form>
		);
	}
}

const SignUpLink = () => (
	<p>
		Don't have an account?{' '}
		<Link to={routes.SIGN_UP} style={{ fontWeight: 700 }}>
			Sign Up
		</Link>
	</p>
);

SignUpForm.propTypes = {
	classes: PropTypes.object.isRequired
};

SignUpForm.contextTypes = {
	authUser: PropTypes.object,
	getUserProfile: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	changeLoginState: PropTypes.func,
	signIn: PropTypes.func,
	signOut: PropTypes.func
};

const SignUpFormWithStyles = withStyles(styles)(SignUpForm);

const WithStyles = withStyles(styles)(SignUpPage);

const WithAlert = withAlert(WithStyles);

export default withRouter(WithAlert);

// const WithRouter = withRouter(SignUpPage);

// export default graphql(
// 	gql`
// 		mutation signup($name: String!, $username: String!, $email: String!, $password: String!) {
// 			signup(name: $name, username: $username, email: $email, password: $password) {
// 				id
// 				email
// 				token
// 			}
// 		}
// 	`,
// 	{
// 		props: ({ mutate }) => ({
// 			signup: (name, username, email, password) => mutate({ variables: { name, username, email, password } })
// 		})
// 	}
// )(WithRouter);
export { SignUpForm, SignUpLink };
