import client from '../config/apolloClient';
import gql from 'graphql-tag';

const auth = {
	setUserAuthToken: (userId, token) => {
		const setAuthTokenMutation = gql`
			mutation setAuthToken($userId: ID!, $token: String!) {
				setAuthToken(id: $userId, token: $token) {
					token
				}
			}
		`;

		const mutate = client.mutate({
			mutation: setAuthTokenMutation,
			variables: {
				userId: userId,
				token: token
			}
		});

		return mutate;
	},
	signInWithEmailAndPassword: (email, password) => {
		const loginMutation = gql`
			mutation Login($email: String!, $password: String!) {
				login(email: $email, password: $password) {
					token
					user {
						id
						name
						slug
						username
						email
						avatarImg
						firstRun
					}
				}
			}
		`;

		const mutate = client.mutate({
			mutation: loginMutation,
			variables: {
				email: email,
				password: password
			}
		});

		return mutate;
	},

	createUserWithEmailAndPassword: (name, email, password) => {
		const signupMutation = gql`
			mutation SignUp($data: SignupInput!) {
				signup(data: $data) {
					token
					user {
						id
						name
						slug
						username
						email
						avatarImg
						firstRun
					}
				}
			}
		`;

		const mutate = client.mutate({
			mutation: signupMutation,
			variables: {
				data: {
					name: name,
					email: email,
					password: password
				}
			}
		});

		return mutate;
	},

	confirmEmail: (email, token) => {
		const confirmEmailMutation = gql`
			mutation ConfirmEmail($email: String!, $emailConfirmToken: String!) {
				confirmEmail(email: $email, emailConfirmToken: $emailConfirmToken) {
					token
				}
			}
		`;

		const mutate = client.mutate({
			mutation: confirmEmailMutation,
			variables: {
				email: email,
				emailConfirmToken: token
			}
		});

		return mutate;
	}
};

export default auth;
