import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Paper from '@material-ui/core/Paper';
import client from '../../config/apolloClient';
import qs from 'qs';
import Button from '@material-ui/core/Button';
import bgImage from '../../assets/images/abstract5.jpg';
import ConversationList from './ConversationList';
import MessagesContainer from './MessagesContainer';
import LinearProgress from '@material-ui/core/LinearProgress';
import ContainerDimensions from 'react-container-dimensions';
import chatImg from '../../assets/images/chat.png';
import { SectionHeader } from '../../components/chrome';

const GET_CONVERSATIONS = gql`
	query ConversationsQuery {
		conversations {
			id
			name
			participants {
				id
				name
				username
				email
				avatarImg
			}
			messages {
				id
				text
				from {
					id
					name
					username
					email
					avatarImg
				}
				read
				typing
				createdAt
				updatedAt
			}
			createdAt
			updatedAt
		}
	}
`;

const GET_MATCHES_META = gql`
	query {
		_allTeamsMeta {
			count
		}
	}
`;

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	},
	headerLight: {
		marginTop: 0,
		marginBottom: 20,
		textAlign: 'left',
		display: 'flex',
		color: 'black',
		fontFamily: 'din_lightregular, Roboto, sans-serif',
		fontWeight: 400,
		fontSize: 30,
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	titleBar: {
		backgroundColor: '#14171d',
		borderRadius: 0,
		backgroundImage: `url(${bgImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100%',
		backgroundPosition: 'right'
	},
	heading: {
		margin: 0,
		textAlign: 'center',
		display: 'flex',
		color: 'white',
		fontFamily: 'din_medium, Roboto, sans-serif',
		alignItems: 'center',
		justifyContent: 'space-between'
		// fontWeight: 300
	},

	headerBar: {
		paddingTop: 30,
		paddingBottom: 30
	},
	card: {
		// padding: 10,
		// margin: 24,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},
	conversations: {
		// width: 400
	}
});

class MatchesContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			count: 0,
			messages: []
		};
		this.handleConversationClicked = this.handleConversationClicked.bind(this);
	}

	componentWillMount() {
		client
			.query({
				query: gql`
					{
						_allTeamsMeta {
							count
						}
					}
				`
			})
			.then(response => {
				const data = response.data;
				this.setState({
					count: data._allTeamsMeta.count
				});
			});
	}

	handleConversationClicked(messages) {
		this.setState({
			messages: messages
		});
	}

	render() {
		const { classes, title, match, history, location } = this.props;
		const params = qs.parse(location.search.slice(1), { strictNullHandling: true });

		let newOrder;
		if (params.order) {
			newOrder = [];
			const orderSplit = params.order.split(',');
			newOrder.push([orderSplit[0], orderSplit[1]]);
		}

		// const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

		return (
			<SectionHeader
				title="Messages"
				rightButton={
					<Button variant="outlined" color="secondary" onClick={() => history.push('/messages/new')}>
						Create conversation
					</Button>
				}
			>
				<Query
					query={GET_CONVERSATIONS}
					variables={{
						where: null,
						offset: params.offset || 0,
						limit: params.limit || 10,
						order: newOrder || [['createdAt', 'DESC']]
					}}
					fetchPolicy="cache-and-network"
				>
					{({ loading, error, data, subscribeToMore }) => {
						if (loading) return <LinearProgress mode="indeterminate" color="primary" />;
						if (error) return <div style={styles.container}>Error: {error.message}</div>;

						return (
							<React.Fragment>
								<Grid container justify="center" spacing={24}>
									<Grid item xs={12} sm={12} md={11} lg={10}>
										<div style={{ marginTop: 24 }}>
											<Paper elevation={0} className={classes.card}>
												<div
													style={{
														display: 'flex',
														flexDirection: 'row',
														flex: 1,
														maxHeight: 600
													}}
												>
													<ConversationList
														data={data}
														subscribeToMore={subscribeToMore}
														match={match}
														history={history}
														count={this.state.count}
														onConversationClicked={this.handleConversationClicked}
														style={styles.conversations}
													/>
													<MessagesContainer messages={this.state.messages} />
													{/*<img src={chatImg} alt="" style={{ maxWidth: 270, borderLeft: '1px solid #eee' }} />*/}
												</div>
											</Paper>
										</div>
									</Grid>
								</Grid>
							</React.Fragment>
						);
					}}
				</Query>
			</SectionHeader>
		);
	}
}

MatchesContainer.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MatchesContainer);
