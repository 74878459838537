import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TournamentGrid from './TournamentGrid';
import TournamentTable from './TournamentTable';
import TournamentContainerTabs from './TournamentContainerTabs';
import IconButton from '@material-ui/core/IconButton';
import ListIcon from '@material-ui/icons/List';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { SectionHeader, SectionContent } from '../../components/chrome';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';

const GET_TOURNAMENTS = gql`
	query Tournaments($offset: Int, $limit: Int) {
		tournaments(offset: $offset, limit: $limit) {
			id
			name
			description
			slug
			location {
				id
				name
				city
				state
			}
			type
			description
			gameType
			winnersRaceTo
			losersRaceTo
			totalPlayers
			startTime
			director {
				id
				name
			}
			players {
				id
				name
			}
			status
			progress
			# raw
		}
	}
`;

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	},
	fabIcon: {
		position: 'fixed',
		bottom: 20,
		right: 40
	},
	fabIconStyle: {
		fill: '#000000'
	},
	card: {
		// padding: 10,
		// margin: 24,
		border: '1px solid rgba(0,0,0,0.1)',
		borderRadius: 8
	},
	cell: {
		padding: '4px 30px 4px 20px'
	},
	textField: {
		margin: 0,
		width: 200
	},
	formContainer: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	barColorPrimary: {
		backgroundColor: 'transparent'
	},
	activeIconColor: {
		fill: '#FFCC00'
	},
	iconColor: {
		fill: 'rgba(255,255,255, 0.54)'
	}
});

/**
 * A simple table demonstrating the hierarchy of the `Table` component and its sub-components.
 */
// const TournamentsList = ({ match, ...rest }) => (
class TournamentsContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			displayType: 'list',
			offset: 0,
			limit: 10
		};
		this.handleToggleDisplayType = this.handleToggleDisplayType.bind(this);
	}
	componentDidMount() {
		window.scrollTo(0, 0);
	}

	handleToggleDisplayType(displayType) {
		this.setState({
			displayType
		});
	}

	render() {
		const { classes, match, title, history } = this.props;
		const { displayType, limit, offset } = this.state;

		return (
			<SectionHeader
				title="Tournaments"
				rightButton={
					<Hidden smDown>
						<Button
							variant="outlined"
							color="secondary"
							onClick={() => history.push('/tournaments/new')}
							style={{ marginRight: 15 }}
						>
							Create Tournament
						</Button>
						<IconButton onClick={() => this.handleToggleDisplayType('list')}>
							<ListIcon
								className={displayType === 'list' ? classes.activeIconColor : classes.iconColor}
							/>
						</IconButton>
						<IconButton onClick={() => this.handleToggleDisplayType('grid')}>
							<DashboardIcon
								className={displayType === 'grid' ? classes.activeIconColor : classes.iconColor}
							/>
						</IconButton>
					</Hidden>
				}
				tabs={<TournamentContainerTabs />}
			>
				<Query
					query={GET_TOURNAMENTS}
					variables={{
						offset: offset,
						limit: limit
					}}
					fetchPolicy="cache-and-network"
					notifyOnNetworkStatusChange={true}
				>
					{({ loading, error, data, subscribeToMore, fetchMore }) => {
						if (loading)
							return (
								<LinearProgress
									mode="indeterminate"
									color="secondary"
									className={classes.barColorPrimary}
								/>
							);
						if (error) return <div style={styles.container}>Error: {error.message}</div>;

						return (
							<div style={{ marginTop: 24, marginBottom: 24 }}>
								<Grid container justify="center" spacing={24}>
									<Grid item xs={12} sm={12} md={11} lg={10} style={{ overflow: 'auto' }}>
										{/*<form className={classes.formContainer} noValidate autoComplete="off">
												<input
													id="search"
													// value={email}
													// onChange={event => this.setState({ email: event.target.value })}
													type="text"
													placeholder="Search"
													style={{ maxWidth: 400, marginBottom: 10 }}
												/>
									</form>*/}
										{displayType === 'grid' ? (
											<TournamentGrid
												data={data || []}
												subscribeToMore={subscribeToMore}
												match={match}
												limit={limit}
												offset={offset}
												onLoadMore={() =>
													fetchMore({
														variables: {
															offset: data.tournaments.length
															// offset: offset + limit
														},
														updateQuery: (prev, { fetchMoreResult }) => {
															if (!fetchMoreResult) return prev;
															return Object.assign({}, prev, {
																tournaments: [
																	...prev.tournaments,
																	...fetchMoreResult.tournaments
																]
															});
														}
													})}
											/>
										) : (
											<TournamentTable
												data={data || []}
												subscribeToMore={subscribeToMore}
												match={match}
												limit={limit}
												offset={offset}
												onLoadMore={() =>
													fetchMore({
														variables: {
															offset: data.tournaments.length
															// offset: offset + limit
														},
														updateQuery: (prev, { fetchMoreResult }) => {
															if (!fetchMoreResult) return prev;
															return Object.assign({}, prev, {
																tournaments: [
																	...prev.tournaments,
																	...fetchMoreResult.tournaments
																]
															});
														}
													})}
											/>
										)}
									</Grid>
								</Grid>
							</div>
						);
					}}
				</Query>
				{/*<Button
					variant="fab"
					color="primary"
					aria-label="add"
					style={{ position: 'fixed', bottom: 20, right: 20 }}
					className="scale-transition"
				>
					<AddIcon />
				</Button>*/}
			</SectionHeader>
		);
	}
}

TournamentsContainer.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TournamentsContainer);
