import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import Card from '@material-ui/core/Card';
import moment from 'moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import AttachMoney from '@material-ui/icons/AttachMoney';
import { isoCountries, getGameType, displayEnumType } from '../../../utils';
import { ZeroState, Card, TabContainer } from '../../../components/chrome';
import eightBall from '../../../assets/images/ball/eight-ball.png';
import * as routes from '../../../config/routes';
import MatchCardTabs from '../MatchCardTabs';
import CheckIcon from '@material-ui/icons/Check';

const styles = theme => ({
	listItem: {
		// padding: 0
	},
	cardNarrow: {
		margin: '24px 0px',
		padding: '5px 10px'
	}
});

class MatchScheduleCard extends Component {
	render() {
		const { classes, matches, history } = this.props;
		let weekMatches = [];
		let futureMatches = [];

		matches.forEach(match => {
			const currentDate = moment();
			const matchStartTime = moment(match.startTime);

			const difference = Math.abs(currentDate.diff(matchStartTime, 'days'));
			if (difference < 7) {
				weekMatches.push(match);
			} else {
				futureMatches.push(match);
			}
		});

		// console.log({
		// 	weekMatches,
		// 	futureMatches
		// });

		return (
			<Card nopadding>
				<h3 style={{ marginLeft: 24, marginTop: 20 }}>Match schedule</h3>
				<MatchCardTabs titles={['Next 7 days', 'Upcoming']}>
					<TabContainer>
						<List
							defaultValue={0}
							style={{
								marginTop: 10,
								marginBottom: 10,
								padding: 0
							}}
						>
							{weekMatches &&
								weekMatches.length === 0 && (
									<ZeroState
										title="No matches scheduled for this week"
										style={{
											marginTop: 5,
											marginLeft: 15,
											marginRight: 15,
											textAlign: 'center'
										}}
									/>
								)}
							{weekMatches &&
								weekMatches.length > 0 &&
								weekMatches.map((match, index) => {
									const title = match.players
										.map(player => {
											return player.name;
										})
										.join(' vs. ');

									return (
										<React.Fragment key={index}>
											{/*<ListSubheader>8-Ball Open Division</ListSubheader>*/}
											<ListItem
												className={classes.listItem}
												onClick={() => history.push(`/matches/${match.id}`)}
												button
											>
												<Avatar src={getGameType(match.gameType)} className={classes.avatar} />
												<ListItemText
													primary={title}
													secondary={
														<span>
															<span>
																{moment(match.startTime).format('lll')} ({moment(match.startTime).fromNow()})
															</span>
															<br />
															<span>{match.location && match.location.name}</span>
														</span>
													}
												/>
												<ListItemSecondaryAction style={{ marginRight: 24 }}>
													{/*<Button size="small" variant="outlined" color="secondary">
														<CheckIcon className={classes.leftIcon} />
														Going
												</Button>*/}
													{/*<Button size="small" variant="contained" color="primary">
														RSVP
											</Button>*/}
													<Button
														size="small"
														variant="outlined"
														color="primary"
														style={{ marginLeft: 10 }}
													>
														Check-in
													</Button>
												</ListItemSecondaryAction>
											</ListItem>
										</React.Fragment>
									);
								})}
						</List>

						<Link to={`${routes.MATCHES}`}>
							<Button primary="true" style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
								View all matches
							</Button>
						</Link>
					</TabContainer>

					<TabContainer>
						<List
							defaultValue={0}
							style={{
								marginTop: 10,
								marginBottom: 10,
								padding: 0
							}}
						>
							{futureMatches &&
								futureMatches.length === 0 && (
									<ZeroState
										title="No future matches scheduled"
										style={{
											marginTop: 5,
											marginLeft: 15,
											marginRight: 15,
											textAlign: 'center'
										}}
									/>
								)}
							{futureMatches &&
								futureMatches.length > 0 &&
								futureMatches.map((match, index) => {
									const title = match.players
										.map(player => {
											return player.name;
										})
										.join(' vs. ');

									return (
										<React.Fragment key={index}>
											{/*<ListSubheader>8-Ball Open Division</ListSubheader>*/}
											<ListItem
												className={classes.listItem}
												onClick={() => history.push(`/matches/${match.id}`)}
												button
											>
												<Avatar src={getGameType(match.gameType)} className={classes.avatar} />
												<ListItemText
													primary={title}
													secondary={
														<span>
															<span>
																{moment(match.startTime).format('lll')} ({moment(match.startTime).fromNow()})
															</span>
															<br />
															<span>{match.location && match.location.name}</span>
														</span>
													}
												/>
												<ListItemSecondaryAction style={{ marginRight: 24 }}>
													{/*<Button size="small" variant="outlined" color="secondary">
														<CheckIcon className={classes.leftIcon} />
														Going
												</Button>*/}
													{/*<Button size="small" variant="contained" color="primary">
														RSVP
											</Button>*/}
													<Button
														size="small"
														variant="outlined"
														color="primary"
														style={{ marginLeft: 10 }}
													>
														Check-in
													</Button>
												</ListItemSecondaryAction>
											</ListItem>
										</React.Fragment>
									);
								})}
						</List>

						<Link to={`${routes.MATCHES}`}>
							<Button primary="true" style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
								View all matches
							</Button>
						</Link>
					</TabContainer>
				</MatchCardTabs>
			</Card>
		);
	}
}

MatchScheduleCard.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithRouter = withRouter(MatchScheduleCard);
export default withStyles(styles)(WithRouter);
