import React from 'react';
import { Route } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import AppBarHeader from '../components/chrome/AppBarHeader';
// import AppBarHeader2 from '../components/chrome/AppBarHeader2';
import SubHeader from '../components/chrome/SubHeader';
import Hidden from '@material-ui/core/Hidden';
import NavDrawer from '../components/chrome/NavDrawer';

// import NavDrawerMini from "../components/chrome/nav_drawer_mini";

export default class DefaultLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			top: false,
			left: true,
			bottom: false,
			right: false,
			navDrawerVisible: false,
			snackbar: false,
			snackbarMessage: ''
		};
	}

	// handleToggle = () => this.setState({ open: !this.state.open });
	// handleClose = () => this.setState({ open: false });

	handleSnackbarToggle = message => {
		this.setState({
			snackbar: !this.state.stackbar,
			snackbarMessage: message || ''
		});
	};

	handleSnackbarClose = () => {
		this.setState({
			snackbar: false
		});
	};

	render() {
		const { component: Component, title, ...rest } = this.props;

		return (
			<Route
				{...rest}
				render={matchProps => (
					<div className="wrapper">
						<div className="content-container">
							<AppBarHeader title={title} {...matchProps} {...rest}>
								{/*<Header
								title={title}
								handleToggle={() => this.handleToggle()}
								menuIcon={true}
								{...matchProps}
								{...rest}
							/>*/}
								<Hidden smDown>
									<SubHeader title={title} {...matchProps} {...rest} />
								</Hidden>

								<Component title={title} {...matchProps} {...rest} />
							</AppBarHeader>
						</div>

						<Snackbar
							open={this.state.snackbar}
							message={this.state.snackbarMessage}
							autoHideDuration={4000}
							onRequestClose={this.handleSnackbarClose}
						/>
					</div>
				)}
			/>
		);
	}
}
