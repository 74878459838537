import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
// import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import moment from 'moment';
import _ from 'lodash';
// import * as tournamentData from "./tournament_data.json";
import gql from 'graphql-tag';
import eightBall from '../../assets/images/ball/eight-ball.png';
import nineBall from '../../assets/images/ball/nine-ball.png';
import tenBall from '../../assets/images/ball/ten-ball.png';
import TournamentCard from './TournamentCard';

const TOURNAMENT_ADDED = gql`
	subscription {
		tournamentAdded {
			id
			name
			slug
			location {
				id
				name
				city
				state
			}
			type
			description
			gameType
			totalPlayers
			winnersRaceTo
			losersRaceTo
			totalPlayers
			startTime
			director {
				id
				name
			}
			players {
				id
				name
			}
			status
			# raw
		}
	}
`;

const TOURNAMENT_UPDATED = gql`
	subscription {
		tournamentUpdated {
			id
			name
			slug
			location {
				id
				name
				city
				state
			}
			type
			description
			gameType
			winnersRaceTo
			losersRaceTo
			totalPlayers
			startTime
			director {
				id
				name
			}
			players {
				id
				name
			}
			status
			# raw
		}
	}
`;

const TOURNAMENT_REMOVED = gql`
	subscription {
		tournamentDeleted {
			id
		}
	}
`;

const styles = theme => ({
	cell: {
		padding: '4px 30px 4px 20px'
	},
	card: {
		border: '1px solid rgba(0,0,0,0.1)',
		borderRadius: 8,
		paddingLeft: 20,
		paddingTop: 10,
		paddingRight: 20,
		paddingBottom: 10,
		marginBottom: 20
		// flexGrow: 1,
		// display: 'flex',
		// height: '100%',
		// width: '100%',
		// flexDirection: 'column'
	}
});
class TournamentGrid extends Component {
	componentDidMount() {
		this.props.subscribeToMore({
			document: TOURNAMENT_ADDED,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;
				// const newTournament = subscriptionData.data.tournamentAdded;

				return Object.assign({}, prev, {
					tournaments: [...prev.tournaments, subscriptionData.data.tournamentAdded]
				});

				// return {
				// 	tournaments: [...prev.tournaments, subscriptionData.data.tournamentAdded]
				// };
			}
		});

		this.props.subscribeToMore({
			document: TOURNAMENT_UPDATED,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;

				const updatedList = prev.tournaments.map(r => {
					if (r !== null && r.id === subscriptionData.data.tournamentUpdated.id) {
						return subscriptionData.data.tournamentUpdated;
					} else {
						return r;
					}
				});

				return {
					tournaments: [...updatedList]
				};
			}
		});

		this.props.subscribeToMore({
			document: TOURNAMENT_REMOVED,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;
				// let removed = subscriptionData.data.tournamentRemoved;
				// let original = prev.tournaments;
				// let toDelete = original.find(tournament => {
				// 	if (tournament) {
				// 		return tournament.id === removed.id;
				// 	}
				// });
				// let updatedList = original.splice(toDelete, 1);
				// return updatedList;

				return {
					tournaments: [
						...prev.tournaments.filter(r => {
							if (r !== null) {
								return r.id !== subscriptionData.data.tournamentRemoved.id;
							} else {
								return false;
							}
						})
					]
				};
			}
		});
	}

	getGameType(tournament) {
		return tournament.gameType === 'EIGHT_BALL'
			? eightBall
			: tournament.gameType === 'NINE_BALL' ? nineBall : tournament.gameType === 'TEN_BALL' ? tenBall : null;
	}

	displayEnumType(type) {
		const typeSplit = type.split('_');
		const prefix = _.capitalize(typeSplit[0]);
		const suffix = _.capitalize(typeSplit[1]);
		return `${prefix} ${suffix}`;
	}

	render() {
		const { classes, match, history, data } = this.props;

		return (
			<Grid container spacing={24}>
				{data.tournaments.map(tournament => {
					if (tournament) {
						return (
							<Grid item xs={12} sm={6} md={6} lg={6} key={tournament.id}>
								<TournamentCard tournament={tournament} history={history} match={match} />
							</Grid>
						);
					}
				})}
			</Grid>
		);
	}
}

TournamentGrid.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles, { withTheme: true })(TournamentGrid);
export default withRouter(WithStyles);
