import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Post from './Post';

const posts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const styles = theme => ({});

class Feed extends Component {
	render() {
		return (
			<Grid container spacing={24}>
				{posts.map((post, index) => {
					return (
						<Grid item xs={12} key={index}>
							<Post data={post} />
						</Grid>
					);
				})}
			</Grid>
		);
	}
}

Feed.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Feed);
