import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { withAlert } from 'react-alert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import NavigationArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import bgImage from '../../assets/images/abstract5.jpg';
// import eightBallHeader from '../../assets/images/eight-ball-header.png';

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	},
	titleBar: {
		// paddingLeft: 22,
		// paddingRight: 22,
		// backgroundColor: '#1D2029',
		// backgroundColor: '#1D2029',
		// backgroundColor: '#14171d',
		background: 'linear-gradient(#20242d, #14171d)',
		// backgroundColor: '#fafbfb',
		borderRadius: 0
		// [theme.breakpoints.up('sm')]: {
		// 	backgroundImage: `url(${bgImage})`
		// },
		// backgroundRepeat: 'no-repeat',
		// backgroundSize: '100%',
		// backgroundPosition: 'right'
	},
	heading: {
		// margin: 0,
		// textAlign: 'center',
		// display: 'flex',
		// alignItems: 'center',
		// justifyContent: 'center',
		// fontSize: '2em',
		// color: 'white',
		// fontFamily: 'din_lightregular, Roboto, sans-serif',
		// fontWeight: 300
		margin: 0,
		lineHeight: '1em',
		display: 'flex',
		alignItems: 'center',
		color: 'white',
		fontFamily: 'din_medium, Roboto, sans-serif',
		justifyContent: 'space-between',
		color: '#fff'
		// fontWeight: 300
	},
	headerBar: {
		// marginLeft: 10,
		// marginRight: 10,
		// paddingTop: 30,
		// paddingBottom: 30,
		height: 100,
		display: 'flex',
		alignItems: 'center'
		// backgroundColor: '#14171d'
	},
	card: {
		padding: 20,
		fontWeight: 400,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},
	cardNopadding: {
		padding: 0,
		fontWeight: 400,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},
	toolbarTitle: {
		// color: '#000',
		// fontWeight: 400
	},
	input: {
		margin: theme.spacing.unit,
		width: 300

		// fontSize: 14
	},
	tabs: {
		// height: 49,
		borderTop: '1px solid rgba(255, 255, 255, 0.15)'
	}
});

const INITIAL_STATE = {
	name: '',
	error: null
};

class SectionHeader extends PureComponent {
	render() {
		const { classes, history, title, tabs, children, backButton, backPath, rightButton } = this.props;

		return (
			<React.Fragment>
				<Paper className={classes.titleBar} elevation={0}>
					<div className={classes.headerBar}>
						<Grid container justify="center" spacing={24}>
							<Grid item xs={11} sm={11} md={11} lg={10}>
								<h2 className={classes.heading}>
									{backButton === true ? (
										<span style={{ display: 'flex', alignItems: 'center' }}>
											<IconButton
												onClick={() => (backPath ? history.push(backPath) : history.goBack())}
												style={{ marginRight: 14 }}
											>
												<NavigationArrowBack
													style={{
														color: '#fff',
														'&:hover': { color: '#fff' }
													}}
												/>
											</IconButton>
											{title}
										</span>
									) : (
										<span>{title}</span>
									)}

									{rightButton && <span>{rightButton}</span>}
								</h2>
							</Grid>
						</Grid>
					</div>
					{tabs && (
						<div className={classes.tabs}>
							<Grid container justify="center">
								<Grid item xs={12} sm={12} md={11} lg={10}>
									{tabs}
								</Grid>
							</Grid>
						</div>
					)}
				</Paper>

				{children}
			</React.Fragment>
		);
	}
}

SectionHeader.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles)(SectionHeader);

const WithAlert = withAlert(WithStyles);

export default withRouter(WithAlert);
