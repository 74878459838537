import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
// import DivisionToolbar from './DivisionToolbar';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
import LocationOn from '@material-ui/icons/LocationOn';
import Avatar from '@material-ui/core/Avatar';
// import gql from 'graphql-tag';
import ConfirmDialog from '../../components/ConfirmDialog';
import { graphql, compose } from 'react-apollo';
import { getTeamQuery } from './queries/getTeamQuery';
import { getTeamsQuery } from './queries/getTeamsQuery';
import { removeTeamMutation } from './mutations/removeTeamMutation';
import { isoCountries } from '../../utils/isoCountryCodes';
import TeamsList from './TeamsList';
import Flag from 'react-flags';

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function stableSort(array, cmp) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	},
	card: {
		// padding: 10,
		// margin: 24,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 0,
		[theme.breakpoints.up('sm')]: {
			borderRadius: 8
		},
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},
	cell: {
		padding: '4px 30px 4px 20px',
		fontSize: 13
	},
	avatar: {
		width: 30,
		height: 30,
		fontSize: 14,
		backgroundColor: '#44475b',
		color: '#fff'
	}
});

const rows = [
	// {
	// 	id: 'index',
	// 	label: '#',
	// 	selected: true
	// },
	{
		id: 'name',
		label: 'Name',
		selected: true
	},
	{
		id: 'number',
		label: 'Number',
		numeric: true,
		selected: true
	},
	{
		id: 'isActive',
		label: 'Status',
		selected: true
	},
	{
		id: 'captain.name',
		label: 'Captain',
		selected: true
	},
	{
		id: 'location.name',
		label: 'Location',
		selected: true
	}
];
class TeamsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dialogOpen: false,
			selectedTeam: null,
			order: 'asc',
			orderBy: 'name',
			selected: [],
			page: 0,
			rowsPerPage: 10,
			limit: this.props.limit || 10,
			offset: this.props.offset || 0
		};
		this.handleCloseDialog = this.handleCloseDialog.bind(this);
		this.handleConfirmDialog = this.handleConfirmDialog.bind(this);
		this.handleRequestSort = this.handleRequestSort.bind(this);
	}

	createSortHandler = property => event => {
		this.handleRequestSort(event, property);
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.offset !== this.props.offset || nextProps.limit !== this.props.limit) {
			this.setState({
				offset: nextProps.offset,
				limit: nextProps.limit
			});
		}
	}

	handleRequestSort(event, property) {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}

		// const data =
		// 	order === 'desc'
		// 		? this.props.data.teams.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
		// 		: this.props.data.teams.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
		// this.setState({ data, order, orderBy });
		this.setState({ order, orderBy });
	}

	handleChangePage = (event, page) => {
		this.setState({ page });
		this.props.onLoadMore();
	};

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
		this.props.onLoadMore();
	};

	handleCloseDialog() {
		this.setState({ dialogOpen: false, selectedTeam: null });
	}

	handleConfirmDialog(team) {
		this.setState({
			selectedTeam: team,
			dialogOpen: true
		});
	}

	displayEnumType(type) {
		const typeSplit = type.split('_');
		const prefix = _.capitalize(typeSplit[0]);
		const suffix = _.capitalize(typeSplit[1]);
		let combined = '';
		typeSplit.forEach(part => {
			combined += `${_.capitalize(part)} `;
		});
		return combined;
	}

	render() {
		const {
			classes,
			match,
			history,
			data,
			loading,
			error,
			subscribeToMore,
			count,
			onLoadMore,
			offset,
			limit
		} = this.props;
		const { order, orderBy, selected, rowsPerPage, page } = this.state;
		let emptyRows = 0;

		if (!loading && data) {
			emptyRows = rowsPerPage - Math.min(rowsPerPage, data.teams.length - page * rowsPerPage);
		}

		return (
			<Paper elevation={0} className={classes.card}>
				<div className={classes.container}>
					{loading && (
						<div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 15, paddingBottom: 15 }}>
							Loading...
						</div>
					)}
					{error && <div style={styles.container}>Error: {error.message}</div>}

					{!loading &&
						data.teams && (
							<React.Fragment>
								{/*<DivisionToolbar data={data} />*/}
								<Table className={classes.table}>
									<TableHead>
										<TableRow>
											{rows.map((row, index) => {
												return (
													<TableCell
														key={index}
														numeric={row.numeric}
														padding={row.disablePadding ? 'none' : 'default'}
														sortDirection={orderBy === row.id ? order : false}
														className={classes.cell}
													>
														<Tooltip
															title="Sort"
															placement={row.numeric ? 'bottom-end' : 'bottom-start'}
															enterDelay={300}
														>
															<TableSortLabel
																active={orderBy === row.id}
																direction={order}
																onClick={this.createSortHandler(row.id)}
															>
																{row.label}
															</TableSortLabel>
														</Tooltip>
													</TableCell>
												);
											}, this)}

											<TableCell className={classes.cell}>Actions</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TeamsList
											teams={data.teams}
											subscribeToMore={subscribeToMore}
											match={match}
											rowsPerPage={rowsPerPage}
											page={page}
											order={order}
											orderBy={orderBy}
										/>
										{emptyRows > 0 && (
											<TableRow style={{ height: 49 * emptyRows }}>
												<TableCell colSpan={8} />
											</TableRow>
										)}
									</TableBody>
								</Table>
								<TablePagination
									component="div"
									count={count}
									rowsPerPage={rowsPerPage}
									rowsPerPageOptions={[10, 25, 50]}
									page={page}
									backIconButtonProps={{
										'aria-label': 'Previous Page'
									}}
									nextIconButtonProps={{
										'aria-label': 'Next Page'
									}}
									onChangePage={this.handleChangePage}
									onChangeRowsPerPage={this.handleChangeRowsPerPage}
								/>
								<ConfirmDialog
									title="Confirm Team Removal"
									open={this.state.dialogOpen}
									onClose={this.handleCloseDialog}
									onConfirm={() => {
										this.props
											.removeTeam({
												mutation: removeTeamMutation,
												variables: { id: this.state.selectedTeam.id },
												refetchQueries: ['GetTeams']
											})
											.then(({ removedTeam }) => {
												this.setState({ dialogOpen: false }, () => {
													this.setState({
														selectedTeam: null
													});
												});
											})
											.catch(error => {
												console.log(error);
												this.setState({ dialogOpen: false }, () => {
													this.setState({
														selectedTeam: null
													});
												});
											});
									}}
								>
									Are you sure you want to delete this team{' '}
									<b>{this.state.selectedTeam && this.state.selectedTeam.name}</b> ?
								</ConfirmDialog>
								{/*<Button
						variant="fab"
						color="primary"
						aria-label="add"
						style={{ position: 'fixed', bottom: 20, right: 20 }}
					>
						<AddIcon />
          </Button>*/}
							</React.Fragment>
						)}
				</div>
			</Paper>
		);
	}
}

TeamsTable.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithGraphQL = graphql(removeTeamMutation, {
	options: ({ id }) => ({ variables: { id } }),
	name: 'removeTeam'
})(TeamsTable);

export default withStyles(styles)(WithGraphQL);
