import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { withAlert } from 'react-alert';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
	margin: {
		[theme.breakpoints.up('sm')]: {
			marginTop: 24,
			marginBottom: 24
		}
	}
});

class SectionContent extends PureComponent {
	render() {
		const { classes, children, style } = this.props;

		return (
			<div className={classes.margin}>
				<Grid container justify="center" spacing={24} style={style}>
					<Grid item xs={12} sm={11} md={11} lg={10}>
						{children}
					</Grid>
				</Grid>
			</div>
		);
	}
}

SectionContent.propTypes = {
	classes: PropTypes.object.isRequired
};

const WithStyles = withStyles(styles)(SectionContent);

const WithAlert = withAlert(WithStyles);

export default withRouter(WithAlert);
