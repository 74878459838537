import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import NavigationArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import bgImage from '../../assets/images/abstract5.jpg';
import SimpleGoogleMap from '../../components/SimpleGoogleMap';
import { StaticGoogleMap, Marker, Path } from 'react-static-google-map';
import LocationOn from '@material-ui/icons/LocationOn';
import { SectionHeader, SectionContent } from '../../components/chrome';
import { Line, Circle } from 'rc-progress';
import StatContainer from '../home/StatContainer';
import CountUp from 'react-countup';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import borderRadius from 'highcharts-border-radius';
borderRadius(Highcharts);

const styles = theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: 'transparent'
	},
	container: {
		position: 'relative',
		overflow: 'auto'
	},
	titleBar: {
		// paddingLeft: 22,
		// paddingRight: 22,
		// backgroundColor: '#1D2029',
		// backgroundColor: '#1D2029',
		backgroundColor: '#14171d',
		borderRadius: 0,
		backgroundImage: `url(${bgImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100%',
		backgroundPosition: 'right'
	},
	heading: {
		// margin: 0,
		// textAlign: 'center',
		// display: 'flex',
		// alignItems: 'center',
		// justifyContent: 'center',
		// fontSize: '2em',
		// color: 'white',
		// fontFamily: 'din_lightregular, Roboto, sans-serif',
		// fontWeight: 300
		margin: 0,
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		color: 'white',
		fontFamily: 'din_medium, Roboto, sans-serif'
		// fontWeight: 300
	},
	headerBar: {
		// marginLeft: 10,
		// marginRight: 10,
		paddingTop: 30,
		paddingBottom: 30
		// backgroundColor: '#14171d'
	},
	card: {
		border: '1px solid #EAEDF3',
		boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.04)',
		padding: 20,
		fontWeight: 400,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
		flexGrow: 1,
		display: 'flex',
		height: '100%',
		width: '100%',
		flexDirection: 'column'
	},
	cardNopadding: {
		border: '1px solid #EAEDF3',
		boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.04)',
		padding: 0,
		fontWeight: 400,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
		flexGrow: 1,
		display: 'flex',
		height: '100%',
		width: '100%',
		flexDirection: 'column'
	},
	toolbarTitle: {
		// color: '#000',
		// fontWeight: 400
	},
	list: {
		paddingTop: 0,
		paddingBottom: 10
	},
	avatar: {
		backgroundColor: '#44475b',
		color: '#fff'
	},
	tabs: {
		// display: 'flex',
		// flexGrow: 1,
		// width: '100%',
		borderTop: '1px solid rgba(255, 255, 255, 0.15)'
	},
	label: {
		fontSize: 13
		// fontWeight: 400
	},
	textColorPrimary: {
		color: '#fff'
	},
	textColorSecondary: {
		color: '#999',
		'&:hover': {
			color: '#fff',
			transition: 'all 300ms cubic-bezier(0.4, 0.0, 0.2, 1)'
		}
	}
});

// const GET_LOCATION = gql`
// 	query LocationDetailQuery($slug: String!) {
// 		location(slug: $slug) {
// 			id
// 			name
// 			slug
// 			address
// 			city
// 			state
// 			zip
// 			country
// 			lat
// 			lon
// 			teams {
// 				id
// 				name
// 				divisions {
// 					id
// 					name
// 				}
// 			}
// 			tournaments {
// 				id
// 				name
// 			}
// 			createdAt
// 			# raw
// 		}
// 	}
// `;

// Add this before rendering your chart
Highcharts.setOptions({
	global: {
		useUTC: false
	}
});

const defaultWidth = 2;
class StatsContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: 0,
			matchWinPercentage: 0,
			gameWinPercentage: 0
		};
		// this.overviewRef = React.createRef();
		// this.bracketsRef = React.createRef();
		// this.standingsRef = React.createRef();
		// this.scoresRef = React.createRef();
		// this.statsRef = React.createRef();
		// this.playersRef = React.createRef();

		this.handleActive = this.handleActive.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.isActive = this.isActive.bind(this);
	}

	componentDidMount() {
		this.setState({
			matchWinPercentage: 65,
			gameWinPercentage: 72
		});
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextState.matchWinPercentage !== this.state.matchWinPercentage ||
			nextState.gameWinPercentage !== this.state.gameWinPercentage ||
			nextState.value !== this.state.value
		) {
			return true;
		} else {
			return false;
		}
	}

	handleActive(tab) {
		const route = tab.props['data-route'] || '/';
		// const match = tab.props['data-match'];
		let urlPath = window.location.href;
		let parsed = urlPath.split('/');
		let newUrl = `/${parsed[3]}/${parsed[4]}${route}`;
		const location = {
			pathname: newUrl
			// state: { fromDashboard: true }
		};
		this.props.history.replace(location);
	}

	handleChange(event, value) {
		this.setState({ value });
		// const refName = `${value}Ref`;
		// const ref = this[refName].current;
		// this.handleActive(ref);
	}

	isActive(tab) {
		const activeTab = this.state.activeTab;
		return tab === activeTab ? 'tab active' : 'tab';
	}

	render() {
		const { value } = this.state;
		const { classes, title, match, history } = this.props;

		const optionsDivision = {
			colors: ['rgba(26, 135, 255, 1)', 'rgba(255, 204, 0, 1)', '#44475b'],
			chart: {
				height: 250,
				type: 'bar'
			},
			title: {
				text: null
			},
			xAxis: {
				categories: ['Singles Open 8-Ball', 'Singles Open 9-Ball', 'Singles Open 10-ball'],
				crosshair: true,
				lineColor: '#C0D0E0',
				lineWidth: 1,
				gridLineColor: '#D8D8D8',
				gridLineDashedStyle: 'solid',
				gridLineWidth: 0
			},
			yAxis: {
				min: 0,
				title: {
					text: null
				},
				tickInterval: 5,
				lineColor: '#C0D0E0',
				lineWidth: 0,
				gridLineColor: '#efefef',
				gridLineDashedStyle: 'solid',
				gridLineWidth: 1
			},
			credits: { enabled: false },
			tooltip: {
				shared: true
			},
			legend: {
				reversed: true
			},
			plotOptions: {
				series: {
					// stacking: 'normal'
				}
			},
			series: [
				{
					name: 'Wins',
					data: [5, 3, 4],
					borderRadiusTopLeft: 3,
					borderRadiusTopRight: 3
				},
				{
					name: 'Losses',
					data: [2, 2, 3]
				}
			]
		};

		const optionsDonut = {
			colors: ['rgba(26, 135, 255, 1)', 'rgba(255, 204, 0, 1)', '#44475b'],
			title: {
				text: null
			},
			chart: {
				height: 250,
				type: 'pie'
			},
			yAxis: {
				title: {
					text: null
				},
				crosshair: true
			},
			credits: { enabled: false },
			tooltip: {
				shared: true
			},
			plotOptions: {
				pie: {
					shadow: false,
					dataLabels: {
						enabled: true,
						format: '{point.y}',
						style: {
							color: 'black'
						}
					}
				}
			},

			// tooltip: {
			// 	formatter: function() {
			// 		return '<b>' + this.point.name + '</b>: ' + this.y + ' %';
			// 	}
			// },
			series: [
				{
					name: 'Wins',
					// data: [['10-Ball', 120], ['9-Ball', 86], ['8-Ball', 35]],

					data: [
						{
							name: '10-ball',
							y: 120
						},
						{
							name: '9-ball',
							y: 86
						},
						{
							name: '8-ball',
							y: 35
						}
					],
					size: '100%',
					innerSize: '50%',
					showInLegend: true
					// dataLabels: {
					// 	enabled: false
					// }
				}
			]
		};

		const options1 = {
			colors: [
				'rgba(26, 135, 255, 1)',
				'rgba(26, 135, 255, 0.4)',
				'rgba(255, 204, 0, 1)',
				'rgba(255, 204, 0, 0.4)',
				'#44475b'
			],
			title: {
				text: null
			},
			chart: {
				height: 150,
				panning: false,
				zoomType: 'x'
			},
			legend: {
				align: 'left'
				// symbolWidth: 12,
				// symbolRadius: 6
			},
			credits: { enabled: false },
			tooltip: {
				shared: true
			},
			plotOptions: {
				column: {
					// stacking: 'normal',
					pointPadding: 0.1,
					borderWidth: 0
					// dataLabels: {
					// 	enabled: true,
					// 	formatter: function() {
					// 		if (this.y > 0) {
					// 			return this.y;
					// 		}
					// 		return null;
					// 	}
					// }
					// borderRadiusTopLeft: 5,
					// borderRadiusTopRight: 5
					// dataLabels: {
					// 	enabled: true,
					// 	color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
					// }
				}
			},
			yAxis: [
				{
					title: {
						text: null
					},
					lineColor: '#C0D0E0',
					lineWidth: 0,
					gridLineColor: '#efefef',
					gridLineDashedStyle: 'solid',
					gridLineWidth: 1
				}
			],
			xAxis: {
				categories: ['League', 'Tournament'],
				crosshair: true,
				lineColor: '#C0D0E0',
				lineWidth: 1,
				gridLineColor: '#D8D8D8',
				gridLineDashedStyle: 'solid',
				gridLineWidth: 0
			},
			series: [
				{
					name: 'Matches won',
					type: 'column',
					data: [120, 120],
					stack: 'league',
					borderRadiusTopLeft: 3,
					borderRadiusTopRight: 3
				},
				{
					name: 'Matches lost',
					type: 'column',
					data: [82, 82],
					stack: 'league'
					// borderRadiusTopLeft: 3,
					// borderRadiusTopRight: 3
				},
				{
					name: 'Games won',
					type: 'column',
					data: [120, 120],
					stack: 'tournament',
					borderRadiusTopLeft: 3,
					borderRadiusTopRight: 3
				},
				{
					name: 'Games lost',
					type: 'column',
					data: [82, 82],
					stack: 'tournament'
					// borderRadiusTopLeft: 3,
					// borderRadiusTopRight: 3
				}
			]
		};

		const options2 = {
			colors: [
				'rgba(26, 135, 255, 1)',
				'rgba(26, 135, 255, 0.4)',
				'rgba(255, 204, 0, 1)',
				'rgba(255, 204, 0, 0.4)',
				'#44475b'
			],
			title: {
				text: null
			},
			chart: {
				height: 300,
				panning: false,
				zoomType: 'x'
			},
			legend: {
				align: 'left'
			},
			credits: { enabled: false },
			tooltip: {
				shared: true
			},
			plotOptions: {
				column: {
					// stacking: 'normal',
					pointPadding: 0.1,
					borderWidth: 0
					// borderRadiusTopLeft: 5,
					// borderRadiusTopRight: 5
					// dataLabels: {
					// 	enabled: true,
					// 	color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
					// }
				}
			},
			yAxis: [
				{
					title: {
						// text: 'Matches and Games played'
						text: null
					},
					lineColor: '#C0D0E0',
					lineWidth: 0,
					gridLineColor: '#efefef',
					gridLineDashedStyle: 'solid',
					gridLineWidth: 1
				}
				// {
				// 	title: {
				// 		text: 'Game wins'
				// 	},
				// 	opposite: true
				// }
			],
			xAxis: {
				categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
				crosshair: true,
				// type: 'datetime',
				// labels: {
				// 	formatter: function() {
				// 		return moment(this.value).format('MMM');
				// 	}
				// },
				// minTickInterval: moment.duration(1, 'month').asMilliseconds(),
				// crosshair: true,
				lineColor: '#C0D0E0',
				lineWidth: 1,
				gridLineColor: '#D8D8D8',
				gridLineDashedStyle: 'solid',
				gridLineWidth: 0
			},
			series: [
				{
					name: 'Matches won',
					type: 'column',
					data: [10, 5, 8, 2, 10, 3, 6, 8, 4, 3, 7, 4],
					// data: [
					// 	[moment('2014-01-01').valueOf(), 10],
					// 	[moment('2014-02-01').valueOf(), 5],
					// 	[moment('2014-03-01').valueOf(), 8],
					// 	[moment('2014-04-01').valueOf(), 2],
					// 	[moment('2014-05-01').valueOf(), 10],
					// 	[moment('2014-06-01').valueOf(), 3],
					// 	[moment('2014-07-01').valueOf(), 6],
					// 	[moment('2014-08-01').valueOf(), 8],
					// 	[moment('2014-09-01').valueOf(), 4],
					// 	[moment('2014-10-01').valueOf(), 3],
					// 	[moment('2014-11-01').valueOf(), 7],
					// 	[moment('2014-12-01').valueOf(), 4]
					// ],
					stack: 'matches',
					borderRadiusTopLeft: 3,
					borderRadiusTopRight: 3
				},
				{
					name: 'Matches lost',
					type: 'column',
					data: [0, 5, 2, 8, 0, 8, 4, 2, 6, 7, 3, 6],
					// data: [
					// 	[moment('2014-01-01').valueOf(), 0],
					// 	[moment('2014-02-01').valueOf(), 5],
					// 	[moment('2014-03-01').valueOf(), 2],
					// 	[moment('2014-04-01').valueOf(), 8],
					// 	[moment('2014-05-01').valueOf(), 0],
					// 	[moment('2014-06-01').valueOf(), 8],
					// 	[moment('2014-07-01').valueOf(), 4],
					// 	[moment('2014-08-01').valueOf(), 2],
					// 	[moment('2014-09-01').valueOf(), 6],
					// 	[moment('2014-10-01').valueOf(), 7],
					// 	[moment('2014-11-01').valueOf(), 3],
					// 	[moment('2014-12-01').valueOf(), 6]
					// ],
					stack: 'matches'
				},
				{
					name: 'Games won',
					type: 'column',
					data: [25, 18, 10, 7, 30, 18, 21, 13, 17, 8, 14, 23],
					// data: [
					// 	[moment('2014-01-01').valueOf(), 25],
					// 	[moment('2014-02-01').valueOf(), 18],
					// 	[moment('2014-03-01').valueOf(), 10],
					// 	[moment('2014-04-01').valueOf(), 7],
					// 	[moment('2014-05-01').valueOf(), 30],
					// 	[moment('2014-06-01').valueOf(), 18],
					// 	[moment('2014-07-01').valueOf(), 21],
					// 	[moment('2014-08-01').valueOf(), 13],
					// 	[moment('2014-09-01').valueOf(), 17],
					// 	[moment('2014-10-01').valueOf(), 8],
					// 	[moment('2014-11-01').valueOf(), 14],
					// 	[moment('2014-12-01').valueOf(), 23]
					// ],
					stack: 'games',
					borderRadiusTopLeft: 3,
					borderRadiusTopRight: 3
				},
				{
					name: 'Games lost',
					type: 'column',
					data: [7, 10, 7, 12, 9, 12, 8, 15, 10, 19, 11, 9],
					// data: [
					// 	[moment('2014-01-01').valueOf(), 7],
					// 	[moment('2014-02-01').valueOf(), 10],
					// 	[moment('2014-03-01').valueOf(), 7],
					// 	[moment('2014-04-01').valueOf(), 12],
					// 	[moment('2014-05-01').valueOf(), 9],
					// 	[moment('2014-06-01').valueOf(), 12],
					// 	[moment('2014-07-01').valueOf(), 8],
					// 	[moment('2014-08-01').valueOf(), 15],
					// 	[moment('2014-09-01').valueOf(), 10],
					// 	[moment('2014-10-01').valueOf(), 19],
					// 	[moment('2014-11-01').valueOf(), 11],
					// 	[moment('2014-12-01').valueOf(), 9]
					// ],
					stack: 'games'
				}
			]
		};

		return (
			<SectionHeader
				title="Statistics"
				tabs={
					<Tabs
						value={value}
						onChange={this.handleChange}
						indicatorColor="secondary"
						textColor="secondary"
						scrollable
						scrollButtons="off"
						// className={classes.tabContainer}
						fullWidth
					>
						<Tab
							label="My Stats"
							classes={{
								label: classes.label,
								textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
								textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
							}}
							style={{ maxWidth: 200 }}
						/>
						<Tab
							label="Team Stats"
							classes={{
								label: classes.label,
								textColorPrimary: classes.textColorPrimary, // class name, e.g. `classes-nesting-root-x`
								textColorSecondary: classes.textColorSecondary // class name, e.g. `classes-nesting-label-x`
							}}
							style={{ maxWidth: 200 }}
						/>
					</Tabs>
				}
			>
				<div style={{ marginTop: 24, marginBottom: 24 }}>
					<Grid container justify="center" spacing={24}>
						<Grid item xs={12} sm={11} md={11} lg={10}>
							<Grid container justify="center" spacing={24}>
								<Grid item xs={12}>
									<Card className={classes.card} elevation={0}>
										<Typography variant="title" color="inherit" className={classes.toolbarTitle}>
											Wins and losses by month for 2018
										</Typography>
										<br />
										<HighchartsReact highcharts={Highcharts} options={options2} />
									</Card>
								</Grid>

								<Grid item xs={12} sm={4}>
									<Card className={classes.card} elevation={0}>
										<Typography variant="title" color="inherit" className={classes.toolbarTitle}>
											Totals for 2018
										</Typography>
										<br />
										<Grid container spacing={16}>
											<Grid item xs={6} sm={3}>
												<Typography variant="caption" className="caption" gutterBottom>
													Matches won
												</Typography>
												<Typography variant="headline" gutterBottom>
													<CountUp end={120} duration={2.75} />
												</Typography>
											</Grid>
											<Grid item xs={6} sm={3}>
												<Typography variant="caption" className="caption" gutterBottom>
													Matches lost
												</Typography>
												<Typography variant="headline" gutterBottom>
													<CountUp end={82} duration={2.75} />
												</Typography>
											</Grid>
											<Grid item xs={6} sm={3}>
												<Typography variant="caption" className="caption" gutterBottom>
													Games won
												</Typography>
												<Typography variant="headline" gutterBottom>
													<CountUp end={120} duration={2.75} />
												</Typography>
											</Grid>
											<Grid item xs={6} sm={3}>
												<Typography variant="caption" className="caption" gutterBottom>
													Games lost
												</Typography>
												<Typography variant="headline" gutterBottom>
													<CountUp end={82} duration={2.75} />
												</Typography>
											</Grid>
										</Grid>
										<br />
										<HighchartsReact highcharts={Highcharts} options={options1} />
									</Card>
								</Grid>

								<Grid item xs={12} sm={8}>
									<Card className={classes.card} elevation={0}>
										<Typography variant="title" color="inherit" className={classes.toolbarTitle}>
											Win percentages for 2018
										</Typography>
										<br />

										<Grid container spacing={24}>
											<Grid item xs={6} sm={6} md={3}>
												<StatContainer
													title="League Matches"
													winPercentage={this.state.matchWinPercentage}
													trailColor="rgba(26, 135, 255, 0.2)"
													strokeColor="rgba(26, 135, 255, 1)"
													centered
												/>
											</Grid>
											<Grid item xs={6} sm={6} md={3}>
												<StatContainer
													title="League Games"
													winPercentage={this.state.gameWinPercentage}
													trailColor="#fff6cf"
													strokeColor="#ffcd00"
													centered
												/>
											</Grid>
											<Grid item xs={6} sm={6} md={3}>
												<StatContainer
													title="Tournament Matches"
													winPercentage={this.state.matchWinPercentage}
													trailColor="rgba(26, 135, 255, 0.2)"
													strokeColor="rgba(26, 135, 255, 1)"
													centered
												/>
											</Grid>
											<Grid item xs={6} sm={6} md={3}>
												<StatContainer
													title="Tournament Games"
													winPercentage={this.state.gameWinPercentage}
													trailColor="#fff6cf"
													strokeColor="#ffcd00"
													centered
												/>
											</Grid>
										</Grid>
									</Card>
								</Grid>

								<Grid item xs={12} sm={4}>
									<Card className={classes.card} elevation={0}>
										<Typography variant="title" color="inherit" className={classes.toolbarTitle}>
											Totals by division type
										</Typography>
										<br />
										<HighchartsReact highcharts={Highcharts} options={optionsDivision} />
									</Card>
								</Grid>

								<Grid item xs={12} sm={4}>
									<Card className={classes.card} elevation={0}>
										<Typography variant="title" color="inherit" className={classes.toolbarTitle}>
											Total wins by game type
										</Typography>
										<br />
										<HighchartsReact highcharts={Highcharts} options={optionsDonut} />
									</Card>
								</Grid>
								<Grid item xs={12} sm={4}>
									<Card className={classes.card} elevation={0}>
										<Typography variant="title" color="inherit" className={classes.toolbarTitle}>
											Performance stats
										</Typography>
										<br />
										<List defaultValue={0} style={{ padding: 0 }}>
											<ListItem style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}>
												<ListItemText
													primary="Break and runs - 12"
													secondary={
														<span>
															<Line
																percent="10"
																strokeWidth={defaultWidth}
																trailWidth={defaultWidth}
																trailColor="rgba(26, 135, 255, 0.2)"
																strokeColor="rgba(26, 135, 255, 1)"
															/>
														</span>
													}
												/>
											</ListItem>
											<ListItem style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}>
												<ListItemText
													primary="On the snaps - 24"
													secondary={
														<span>
															<Line
																percent="18"
																strokeWidth={defaultWidth}
																trailWidth={defaultWidth}
																trailColor="rgba(26, 135, 255, 0.2)"
																strokeColor="rgba(26, 135, 255, 1)"
															/>
														</span>
													}
												/>
											</ListItem>
											<ListItem style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}>
												<ListItemText
													primary="Clean sweeps - 7"
													secondary={
														<span>
															<Line
																percent="7"
																strokeWidth={defaultWidth}
																trailWidth={defaultWidth}
																trailColor="rgba(26, 135, 255, 0.2)"
																strokeColor="rgba(26, 135, 255, 1)"
															/>
														</span>
													}
												/>
											</ListItem>
											<ListItem style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}>
												<ListItemText
													primary="Highest winning streak - 5"
													secondary={
														<span>
															<Line
																percent="2"
																strokeWidth={defaultWidth}
																trailWidth={defaultWidth}
																trailColor="rgba(26, 135, 255, 0.2)"
																strokeColor="rgba(26, 135, 255, 1)"
															/>
														</span>
													}
												/>
											</ListItem>
										</List>
									</Card>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</SectionHeader>
		);
	}
}

StatsContainer.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(StatsContainer);
