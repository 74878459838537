import gql from 'graphql-tag';

export const getTournamentQuery = gql`
	query GetTournament($slug: String!) {
		tournament(slug: $slug) {
			id
			name
			slug
			location {
				id
				name
				address
				city
				state
				zip
			}
			type
			description
			gameType
			winnersRaceTo
			losersRaceTo
			totalPlayers
			handicap
			startTime
			director {
				id
				name
			}
			players {
				id
				name
				username
				avatarImg
				country
			}
			status
			# raw
		}
	}
`;
