import gql from 'graphql-tag';

export const getTeamsQuery = gql`
	query GetTeams($where: JSON, $offset: Int, $limit: Int, $order: [[String]]) {
		teams(where: $where, offset: $offset, limit: $limit, order: $order) {
			id
			name
			slug
			number
			isActive
			captain {
				id
				name
				slug
				username
				email
			}
			coCaptain {
				id
				name
				slug
				username
				email
			}
			league {
				id
				name
				slug
				type
			}
			location {
				id
				name
				country
			}
			createdAt
		}
	}
`;
