import { combineReducers } from "redux";
import tournamentReducer from "./reducer_tournaments";
import userReducer from "./reducer_users";

const rootReducer = combineReducers({
  tournamentReducer,
  userReducer
});

export default rootReducer;
