'use strict';
import _ from 'lodash';

// Single elimination	Number of matches = N-1
// Double elimination	Number of matches =（N-1）× 2 ＋ 1
// Round robin	Number of matches =【N ×（N-1）】÷ 2
// Mixed system	No fixed formula. The mix of different systems means that the number of matches differs

// To determine the number of games for a single round robin tournament, as seen above, use the following formula, N x(N - 1) / 2. With a tournament of 6 teams, the calculation would be: 6 x(6 - 1) / 2 = 6 x 5 / 2 = 30 / 2 = 15 games.To keep track of winners I use the following record chart in class.
// http://www.denegames.ca/tournaments/index.html

// The standard scoring system awards one point for each correct pick in the Round of 64, two points in the Round of 32, four points in the Round of 16, with rewards continuing to double each round until the championship, where picking the ultimate winner is worth 32 points

export default class DoubleEliminationBracketGenerator {
	constructor(participants) {
		this.participants = participants;
		this.getSeeds(16);
		const config = this.configureBracket(participants.length);
		console.log('Bracket Config:', config);
		const roundsAndMatches = this.getRoundsAndMatchesTree(config);

		const matchFormats = this.getMatchesByFormat(participants.length);
		console.log('Match Formats:', matchFormats);
		// const seeds = this.foo(config.bracketSize, participants);
		// const readableArr = seeds.map(i => i + 1);
		// console.log(readableArr);
		// var rounds = [32, 16, 8, 4, 2, 1]
		// this.knownBrackets = [2, 4, 8, 16, 32, 64, 128, 256, 512]
	}

	getMatchesByFormat(participants) {
		const n = participants;
		const singleElim = n - 1; // e.g. 15 w/16 player bracket
		const doubleElim = (n - 1) * 2 + 1; // e.g. 31 w/16 player bracket
		const roundRobin = [n * (n - 1)] / 2; // e.g 120 w/16 player bracket
		return {
			singleElim,
			doubleElim,
			roundRobin
		};
	}

	fillRange(start, end) {
		return Array(start - end + 1)
			.fill()
			.map((item, index) => {
				return Math.pow(2, start - index);
			});
	}

	getTree(numPlayers) {
		const winnerRounds = Math.log2(numPlayers);
		const loserRounds = Math.ceil(winnerRounds) + Math.ceil(Math.log2(winnerRounds));
		const surplus = loserRounds - winnerRounds;
		let loserRoundArr = [];
		const winnerRoundArr = this.fillRange(winnerRounds, 0);

		for (let i = 0; i < loserRounds; i++) {
			if (i === 0) {
				loserRoundArr.push(numPlayers / surplus);
			} else if (i === 1) {
				loserRoundArr.push(numPlayers / surplus);
			} else if (i > 1 && i % 2 === 0) {
				loserRoundArr.push(numPlayers / surplus);
			} else if (i === loserRounds - 1) {
				loserRoundArr.push(1);
			} else if (i === loserRounds - 2) {
				loserRoundArr.push(2);
			} else {
				loserRoundArr.push(numPlayers / surplus);
			}
		}

		console.log({
			winnerRounds,
			winnerRoundArr,
			loserRounds,
			loserRoundArr,
			surplus
		});
	}

	configureBracket(numPlayers) {
		const participants = this.participants;
		// Thi is the number of rounds
		const rounds = Math.ceil(Math.log(numPlayers) / Math.log(2));

		// matchesPerRoundWinners = [16, 8, 4, 4, 2]
		// matchesPerRoundLosers = [8, 8, 4, 4]

		const totalMatches = (numPlayers - 1) * 2 + 1;

		const tree = this.getTree(numPlayers);
		console.log(tree);

		// Find out what is the number that is a power of 2 and lower than numPlayers.
		const maxPlayers = Math.pow(2, rounds);

		// Find out what is the number that is a power of 2 and higher than numPlayers.
		const minPlayers = maxPlayers / 2;

		// This is the number of nodes that will not show in the 1st level.
		const countNodesHidden = numPlayers - minPlayers;

		const requiredByes = maxPlayers - numPlayers;
		// Figure out how many matches per round. e.g. [16, 8, 4, 2, 1]
		const roundArr = this.fillRange(rounds - 1, 0);

		const seeds = this.generateSeeds(numPlayers, rounds);

		const matchesWithPlayers = seeds.map((match, index) => {
			const player1 = participants[match[0] - 1];
			const player2 = participants[match[1] - 1];
			return {
				// round: round,
				identifier: index + 1,
				player1: player1,
				player2: player2,
				winnerId: null,
				loserId: null
			};
		});

		console.log(matchesWithPlayers);

		// console.log(roundArr);
		// console.log(matchArr);

		let matchCount = 0;
		let winByTwo = true;
		let matchDepth = 2;

		const fillInMatches = (round, roundNum) => {
			return round.map(item => {
				const players = _.range(matchDepth).map(match => {
					const player = seeds[item] ? this.participants[seeds[item][match] - 1] : [];
					return {
						...player,
						seed: seeds[item] ? seeds[item][match] : []
					};
				});

				const match = {
					round: roundNum,
					identifier: matchCount + 1,
					players
				};
				matchCount++;
				return match;
			});
		};

		const matchesByRound = roundArr.map((round, index) => {
			let matches = [];
			const roundNum = index + 1;

			if (roundNum % 2 === 0) {
				let winnerSide = _.range(0, round);
				matches = fillInMatches(winnerSide, roundNum);
			} else {
				let range = _.range(0, round);
				matches = fillInMatches(range, roundNum);
			}

			return matches;
		});

		console.log('Matches by round:', matchesByRound);

		console.log('Number of participants: {0}'.format(numPlayers));
		console.log('Number of rounds: {0}'.format(rounds));
		console.log('Bracket size: {0}'.format(maxPlayers));
		console.log('Required number of byes: {0}'.format(requiredByes));

		// for (var i = 0; i < countNodesLowerBound; i++) {
		// 	if (i < countNodesHidden) {
		// 		// Must be on the first level
		// 		var rng = _.range(i * 4 + 1, 1);
		// 		setBracketItem_(rng, participants);
		// 		// setBracketItem_(rng.offset(2, 0, 1, 1), participants);
		// 		// setBracketItem_(rng.offset(1, 2, 1, 1));
		// 	} else {
		// 		// This player gets a bye
		// 		setBracketItem_(_.range(i * 4 + 2, 3), participants);
		// 	}
		// }

		// function setBracketItem_(rng, players) {
		// 	if (players) {
		// 		var rand = Math.ceil(Math.random() * players.length);
		// 		rng = players.splice(rand - 1, 1)[0][0];
		// 	}
		// 	console.log(rng);
		// 	return rng;
		// }

		// // Now fill in the rest of the bracket
		// upperPower--;
		// for (var i = 0; i < upperPower; i++) {
		// 	var pow1 = Math.pow(2, i + 1);
		// 	var pow2 = Math.pow(2, i + 2);
		// 	var pow3 = Math.pow(2, i + 3);
		// 	for (var j = 0; j < Math.pow(2, upperPower - i - 1); j++) {
		// 		setBracketItem_(_.range(j * pow3 + pow2, i * 2 + 5));
		// 		// setConnector_(_.range((j * pow3) + pow1, i * 2 + 4, pow2 + 1, 1));
		// 	}
		// }

		return {
			numPlayers,
			rounds,
			bracketSize: maxPlayers,
			minPlayers,
			hiddenFromFirstRound: countNodesHidden,
			requiredByes
		};
	}

	changeIntoBye(seed, participantsCount) {
		//return seed <= participantsCount ?  seed : '{0} (= bye)'.format(seed);
		return seed <= participantsCount ? seed : null;
	}

	generateSeeds(participants, rounds) {
		if (participants < 2) {
			return [];
		}

		let matches = [[1, 2]];
		let newRounds = [];

		for (let i = rounds; i >= 1; i--) {
			newRounds.push(i);
		}

		for (var round = 1; round < rounds; round++) {
			var roundMatches = [];
			var sum = Math.pow(2, round + 1) + 1;

			for (var i = 0; i < matches.length; i++) {
				var home = this.changeIntoBye(matches[i][0], participants);
				var away = this.changeIntoBye(sum - matches[i][0], participants);
				roundMatches.push([home, away]);
				home = this.changeIntoBye(sum - matches[i][1], participants);
				away = this.changeIntoBye(matches[i][1], participants);
				roundMatches.push([home, away]);
			}
			matches = roundMatches;
		}

		return matches;
	}

	getRoundsAndMatchesTree(config) {
		const totalMatches = config.maxPlayers;
		const totalRounds = config.rounds;
		const totalParticipants = config.numPlayers;

		for (let i = totalRounds; i >= 1; i--) {
			let matchesInRound = totalRounds * i;
			let matches = _.range(1, matchesInRound + 1);

			// console.log(matches);
		}
	}

	// foo(n) {
	// 	const arr = new Array(n);
	// 	arr[0] = 0;
	// 	for (let i = n >> 1, m = 1; i >= 1; i >>= 1, m = (m << 1) + 1) {
	// 		for (let j = n - i; j > 0; j -= i) {
	// 			arr[j] = m - arr[(j -= i)];
	// 		}
	// 	}
	// 	return arr;
	// }
	// }

	getSeeds(n) {
		let teams = [
			{ id: 1, name: 'Jayson Shaw' },
			{ id: 2, name: 'Eklent Kaci' },
			{ id: 3, name: 'Francisco Sanchez-Ruiz' },
			{ id: 4, name: 'Jung-Lin Chang' },
			{ id: 5, name: 'Ping-Chung Ko' },
			{ id: 6, name: 'Naoyuki Oi' },
			{ id: 7, name: 'Jeffrey De Luna' },
			{ id: 8, name: 'Corey Deuel' },
			{ id: 9, name: 'Thorsten Hohmann' },
			{ id: 10, name: 'Joshua Filler' },
			{ id: 11, name: 'Jin-Hu Dang' },
			{ id: 12, name: 'Billy Thorpe' },
			{ id: 13, name: 'Nick Van Den Berg' },
			{ id: 14, name: 'Shane Van Boening' },
			{ id: 15, name: 'Skyler Woodward' },
			{ id: 16, name: 'Justin Bergman' }
		];

		let matchesAdded = 1;

		function addByes(teams, nbByes) {
			for (var i = 1; i <= nbByes; i++) {
				teams.push({ bye: true });
			}
		}

		function generateGames2(teams) {
			var games = [];
			var nbTeams = teams.length;
			for (var i = 1; i <= nbTeams / 2; i++) {
				games.push(new Game(teams[nbTeams - i], teams[i - 1]));
			}

			return games;
		}

		function shuffle(array) {
			var counter = array.length;

			// While there are elements in the array.
			while (counter > 0) {
				// Pick a random index.
				var index = Math.floor(Math.random() * counter);

				// Decrease counter by 1.
				counter--;

				// And swap the last element with it.
				var temp = array[counter];
				array[counter] = array[index];
				array[index] = temp;
			}

			return array;
		}

		function Game(visitor, home) {
			const game = {
				id: generateUUID(),
				number: matchesAdded,
				visitor: visitor,
				home: home
			};
			matchesAdded++;
			return game;
		}

		function generateUUID() {
			var d = new Date().getTime();

			var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
				var r = ((d + Math.random() * 16) % 16) | 0;
				d = Math.floor(d / 16);
				return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
			});

			return uuid;
		}

		function generateGames(teams, type, shuffleTeams) {
			if (teams.length < 3) {
				// TODO Raise error.
				console.error('You must have at least 3 teams.');
				return;
			}

			if (teams.length > 64) {
				// TODO Raise error.
				console.error('You can only create brackets for 64 or fewer teams.');
				return;
			}

			if (!type) {
				// TODO Raise error.
				console.error('The type of tournament is undefined.');
				return;
			}
			// Check if the teams array needs to be shuffled.
			if (shuffleTeams) {
				teams = shuffle(teams);
			}

			switch (type) {
				case 'single-elimination':
					break;

				case 'double-elimination':
					break;

				case 'round-robin':
					break;
			}

			var matchDepth = 2;

			// Get the number of rounds.
			var nbRounds = Math.ceil(Math.log(teams.length * matchDepth) / Math.log(2));

			// Get the number of maximum team.
			var nbTeams = Math.pow(2, nbRounds);

			// Check if bye needs to be added.
			if (nbTeams != teams.length) {
				addByes(teams, nbTeams - teams.length);
			}

			// Generate first round games.
			var firstRoundGames = generateGames2(teams);

			var rounds = [];
			for (var i = 0; i < nbRounds; i++) {
				var game;

				// Dispath first round games.
				if (i == 0) {
					rounds.push({ leftBracket: [], rightBracket: [] });

					for (var j = 0; j < firstRoundGames.length; j++) {
						rounds[i].rightBracket.push(firstRoundGames[j]);
						// left bracket.
						// if (j % 2 === 0) {
						// 	rounds[i].leftBracket.push(firstRoundGames[j]);
						// } else {
						// 	// right side.
						// 	rounds[i].rightBracket.push(firstRoundGames[j]);
						// }
					}
				} else if (i == nbRounds - 1) {
					rounds.push({ championship: [], bronze: [] });
					const player1 = rounds[i - 1].leftBracket[0] ? rounds[i - 1].leftBracket[0].id : [];
					const player2 = rounds[i - 1].rightBracket[0] ? rounds[i - 1].rightBracket[0].id : [];
					game = new Game(player1, player2);
					rounds[i].championship.push(game);
					rounds[i].bronze.push(game);
				} else {
					rounds.push({ leftBracket: [], rightBracket: [] });

					for (var x = 0; x < rounds[i - 1].rightBracket.length; x += 2) {
						const player1 = rounds[i - 1].rightBracket[x] ? rounds[i - 1].rightBracket[x].id : [];
						const player2 = rounds[i - 1].rightBracket[x + 1] ? rounds[i - 1].rightBracket[x + 1].id : [];
						game = new Game(player1, player2);
						rounds[i].leftBracket.push(game);
					}

					for (var y = 0; y < rounds[i - 1].rightBracket.length / 2; y++) {
						const player1 = rounds[i - 1].rightBracket[y] ? rounds[i - 1].rightBracket[y].id : [];
						const player2 = rounds[i - 1].rightBracket[y + 1] ? rounds[i - 1].rightBracket[y + 1].id : [];
						game = new Game(player1, player2);
						rounds[i].rightBracket.push(game);
					}
				}
			}

			/*
              [
                  {
                      roundOf: 8,
                      header: 'Quarter-finals',
                      games: [
                          {}, {}
                      ]
                  },
                  {
                      roundOf: 4,
                      header: 'Semi-finals',
                      games: [
                          {}
                      ]
                  },
                  {
                      roundOf: 2,
                      header: 'Final',
                      games: [
                          {}, {}
                      ]
                  },
                  {
                      roundOf: 4,
                      header: 'Semi-finals',
                      games: [
                          {}
                      ]
                  },
                  {
                      roundOf: 8,
                      header: 'Quarter-finals',
                      games: [
                          {}, {}
                      ]
                  }
              ]
          */

			// Dispatch first round games.
			/*for (var j = 0; j < firstRound.length; j++) {
              if ((j % 2) === 0) {
                  // left side.
              } else {
                  // right side.
              }
          }*/

			// 8 teams
			var games = [{ leftBracket: [] }, { rightBrackets: [] }];
			/*
              [
                  {
                      leftBrackets: [
                          { round: 'Quart-final' },
                          { round: 'Semi-final' }
                      ]
                  },
                  {
                      middleBrackets: [
                          { round: 'Final' },
                          { round: 'Bronze' }
                      ]
                  },
                  {
                      rightBrackets: [
                          { round: 'Quart-final' },
                          { round: 'Semi-final' }
                      ]
                  }
              ]
              1---\                       |---2
                  ---\               |---
              8---|    ---         ---    \---7
              3---\                       |---4
                  ---|               \---
              6---|                       \---5
          */
			// console.log(teams);
			// console.log(rounds);

			return rounds;
		}

		const games2 = generateGames(teams, 'double-elimination', false);
		console.log(games2);
	}

	// // functional style
	// function foo(n) {
	// 	const arr = new Array(n);
	// 	arr[0] = 0;
	// 	for (let i = n >> 1, m = 1; i >= 1; i >>= 1, m = (m << 1) + 1) {
	// 		for (let j = n - i; j > 0; j -= i) {
	// 			arr[j] = m - arr[(j -= i)];
	// 		}
	// 	}
	// 	return arr;
	// }
	// const seeds = foo(n);
	// const readableArr = seeds.map(i => i + 1);
	// console.log(readableArr);

	// Number of participants: 16
	// Number of rounds: 4
	// Bracket size: 16
	// Required number of byes: 0
	// C:\projects\draw\draw.php:7:
	// array (size=8)
	//   0 =>
	//     array (size=2)
	//       0 => int 1
	//       1 => int 16
	//   1 =>
	//     array (size=2)
	//       0 => int 9
	//       1 => int 8
	//   2 =>
	//     array (size=2)
	//       0 => int 5
	//       1 => int 12
	//   3 =>
	//     array (size=2)
	//       0 => int 13
	//       1 => int 4
	//   4 =>
	//     array (size=2)
	//       0 => int 3
	//       1 => int 14
	//   5 =>
	//     array (size=2)
	//       0 => int 11
	//       1 => int 6
	//   6 =>
	//     array (size=2)
	//       0 => int 7
	//       1 => int 10
	//   7 =>
	//     array (size=2)
	//       0 => int 15
	//       1 => int 2
	// }
}
