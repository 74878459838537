import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Flag from 'react-flags';
import { isoCountries } from '../../utils';
import Podium from './Podium';

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto'
	},
	table: {
		minWidth: 700
	},
	cell: {
		padding: '4px 30px 4px 20px',
		fontSize: 13
	}
});

// let id = 0;
function createData(id, country, name, matches, matchHistory, winnings, points) {
	// id += 1;
	return { id, country, name, matches, matchHistory, winnings, points };
}

const rows = [
	createData(1, 'United Kingdom', 'Chris Melling', '7 (6/1)', ['W', 'W', 'W', 'W', 'W', 'L', 'W'], 5000, 100),
	createData(2, 'Scotland', 'Jayson Shaw', '6 (5/1)', ['W', 'W', 'W', 'W', 'W', 'L', 'W'], 3150, 70),
	createData(3, 'Albania', 'Klenti KaCi', '7 (5/2)', ['W', 'W', 'W', 'W', 'W', 'L', 'L'], 2000, 50),
	createData(3, 'Romania', 'Ioan Ladanyi', '8 (6/2)', ['W', 'W', 'W', 'W', 'W', 'L', 'L'], 2000, 50),
	createData(5, 'Russia', 'Fedor Gorst', '4 (3/1)', ['W', 'W', 'W', 'L'], 1200, 40),
	createData(5, 'Germany', 'Thorsten Hohmann', '5 (3/2)', ['W', 'W', 'W', 'L', 'L'], 1200, 40),
	createData(5, 'Poland', 'Wojciech Szewczyk', '5 (4/1)', ['W', 'W', 'W', 'L'], 1200, 40),
	createData(5, 'Poland', 'Daniel Maciol', '6 (4/2)', ['W', 'W', 'L', 'W', 'L'], 1200, 40),
	createData(9, 'Austria', 'Mario He', '3 (2/1)', ['W', 'L'], 600, 40),
	createData(9, 'Greece', 'Alex Kazakis', '3 (2/1)', ['W', 'L'], 600, 40),
	createData(9, 'Russia', 'Ruslan Chinahov', '3 (2/1)', ['W', 'L'], 600, 40),
	createData(9, 'Austria', 'Albin Ouschan', '6 (4/2)', ['W', 'L', 'W', 'W', 'W', 'L'], 600, 40),
	createData(9, 'United Kingdom', 'Darren Appleton', '3 (2/1)', ['W', 'L'], 600, 40),
	createData(9, 'Netherlands', 'Marc Bijsterbosch', '3 (2/1)', ['W', 'L'], 600, 40),
	createData(9, 'Finland', 'Petri Makkonen', '5 (3/2)', ['W', 'L', 'W', 'W', 'L'], 600, 40),
	createData(9, 'Romania', 'Ciprian Gindac', '5 (3/2)', ['W', 'W', 'W', 'L', 'L'], 600, 40)
];

class StandingsList extends Component {
	render() {
		const { classes } = this.props;

		return (
			<React.Fragment>
				<Podium />
				<Divider light />
				<div style={{ overflow: 'auto' }}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell className={classes.cell}>Place</TableCell>
								<TableCell className={classes.cell}>Name</TableCell>
								<TableCell numeric className={classes.cell}>
									Matches (W/L)
								</TableCell>
								<TableCell className={classes.cell}>Match History</TableCell>
								<TableCell numeric className={classes.cell}>
									Winnings
								</TableCell>
								<TableCell numeric className={classes.cell}>
									Points
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row, index) => {
								const countryName = isoCountries[row.country.toLowerCase()] || '';

								return (
									<TableRow
										key={index}
										hover
										style={{
											cursor: 'pointer',
											// backgroundColor: getRowColor(row.id),
											transition: 'all 150ms ease'
											// '&:hover': {
											// 	backgroundColor: `darken(${getRowColor(row.id)}, 50%)`
											// }
										}}
									>
										<TableCell className={classes.cell}>{row.id}</TableCell>
										<TableCell className={classes.cell}>
											<div
												style={{
													display: 'flex',
													alignItems: 'center'
												}}
											>
												<Flag
													name={countryName || 'Unknown'}
													format="png"
													pngSize={24}
													shiny={false}
													alt="Canada Flag"
													className="flag"
													basePath="/images/flags"
												/>
												<span style={{ marginLeft: 10 }}>{row.name}</span>
											</div>
										</TableCell>

										<TableCell numeric className={classes.cell}>
											{row.matches}
										</TableCell>
										<TableCell className={classes.cell}>
											{row.matchHistory.map((match, index) => {
												return (
													<span
														key={index}
														className={
															match === 'W' ? 'match-badge win' : 'match-badge loss'
														}
													>
														{match}
													</span>
												);
											})}
										</TableCell>
										<TableCell numeric className={classes.cell}>
											{row.winnings}
										</TableCell>
										<TableCell numeric className={classes.cell}>
											{row.points}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
			</React.Fragment>
		);
	}
}

StandingsList.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(StandingsList);
