import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Card } from '../../components/chrome';
import DivisionCreateEditForm from './DivisionCreateEditForm';
import DivisionCreateForm from './DivisionCreateForm';
import { SectionHeader } from '../../components/chrome';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	toolbarTitle: {
		marginBottom: 15
	}
});

class DivisionEdit extends Component {
	handleAfterSubmit = slug => {
		const { history } = this.props;
	};

	render() {
		const { classes } = this.props;

		return (
			<SectionHeader
				title="Edit Division"
				backButton={true}
				backPath={`/divisions/${this.props.match.params.slug}`}
			>
				<div style={{ marginTop: 24, marginBottom: 24 }}>
					<Grid container justify="center" spacing={24}>
						<Grid item xs={12} md={11} lg={10}>
							<Grid container justify="center" spacing={24}>
								<Grid item xs={12}>
									<Card className={classes.card} elevation={0}>
										<Typography variant="title" color="inherit" className={classes.toolbarTitle}>
											<span>Division Details</span>
										</Typography>
										<DivisionCreateForm slug={this.props.match.params.slug} />
									</Card>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
			</SectionHeader>
		);
	}
}

DivisionEdit.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DivisionEdit);
