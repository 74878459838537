import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import { auth } from '../../firebase';

const styles = {
	container: {
		position: 'relative'
	},
	heading: {
		marginTop: 0,
		marginBottom: 0,
		textAlign: 'left',
		display: 'inline-block',
		color: 'black',
		fontFamily: 'Roboto, sans-serif',
		fontWeight: 300,
		fontSize: 30
	},
	headerLight: {
		marginRight: 50,
		marginTop: 30,
		marginBottom: 10,
		textAlign: 'left',
		display: 'inline-block',
		color: 'black',
		fontFamily: 'din_lightregular, Roboto, sans-serif',
		fontWeight: 300,
		fontSize: 30
	},
	card: {
		margin: '24px 0px',
		padding: '24px'
	}
};

const updateByPropertyName = (propertyName, value) => () => ({
	[propertyName]: value
});

const INITIAL_STATE = {
	passwordOne: '',
	passwordTwo: '',
	error: null
};

class PasswordChangeForm extends Component {
	constructor(props) {
		super(props);

		this.state = { ...INITIAL_STATE };
	}

	onSubmit = event => {
		const { passwordOne } = this.state;

		auth
			.updatePassword(passwordOne)
			.then(() => {
				this.setState(() => ({ ...INITIAL_STATE }));
			})
			.catch(error => {
				this.setState(updateByPropertyName('error', error));
			});

		event.preventDefault();
	};

	render() {
		const { passwordOne, passwordTwo, error } = this.state;

		const isInvalid = passwordOne !== passwordTwo || passwordOne === '';

		return (
			<div className="page-container">
				<div style={styles.container} className="main-content container">
					<h2 style={styles.headerLight}>Change password</h2>
					<Card style={styles.card}>
						<form onSubmit={this.onSubmit}>
							<input
								value={passwordOne}
								onChange={event =>
									this.setState(updateByPropertyName('passwordOne', event.target.value))}
								type="password"
								placeholder="New Password"
							/>
							<input
								value={passwordTwo}
								onChange={event =>
									this.setState(updateByPropertyName('passwordTwo', event.target.value))}
								type="password"
								placeholder="Confirm New Password"
							/>
							<Button label="Reset My Password" primary={true} disabled={isInvalid} type="submit" />
							{error && <p style={{ color: 'red' }}>ERROR: {error.message}</p>}
						</form>
					</Card>
				</div>
			</div>
		);
	}
}

export default PasswordChangeForm;
