import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import NavigationArrowBack from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import bgImage from '../../assets/images/abstract5.jpg';
import { getPlayerQuery } from './queries/getPlayerQuery';
import SimpleGoogleMap from '../../components/SimpleGoogleMap';
import { SectionHeader, SectionContent, Card, ZeroState } from '../../components/chrome';
import { StaticGoogleMap, Marker, Path } from 'react-static-google-map';
import LocationOn from '@material-ui/icons/LocationOn';
import * as routes from '../../config/routes';
import eightBall from '../../assets/images/ball/eight-ball.png';
import nineBall from '../../assets/images/ball/nine-ball.png';
import tenBall from '../../assets/images/ball/ten-ball.png';
import moment from 'moment';
import _ from 'lodash';

const styles = theme => ({
	container: {
		position: 'relative',
		overflow: 'auto'
	},
	titleBar: {
		// paddingLeft: 22,
		// paddingRight: 22,
		// backgroundColor: '#1D2029',
		// backgroundColor: '#1D2029',
		// backgroundColor: '#14171d',
		backgroundColor: '#1c1f23',
		borderRadius: 0,
		backgroundImage: `url(${bgImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100%',
		// backgroundPosition: 'right',
		backgroundAttachment: 'fixed'
	},
	heading: {
		// margin: 0,
		// textAlign: 'center',
		// display: 'flex',
		// alignItems: 'center',
		// justifyContent: 'center',
		// fontSize: '2em',
		// color: 'white',
		// fontFamily: 'din_lightregular, Roboto, sans-serif',
		// fontWeight: 300
		margin: 0,
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		color: 'white',
		fontFamily: 'din_medium, Roboto, sans-serif'
		// fontSize: '2em'
		// fontWeight: 300
	},
	headerBar: {
		// marginLeft: 10,
		// marginRight: 10,
		paddingTop: 30,
		paddingBottom: 30
		// backgroundColor: '#14171d'
	},
	card: {
		// border: '1px solid #EAEDF3',
		// boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.04)',
		padding: 20,
		fontWeight: 400,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},
	cardNopadding: {
		// border: '1px solid #EAEDF3',
		// boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.04)',
		paddingTop: 0,
		paddingLeft: 0,
		paddingRight: 0,
		paddingBottom: 10,
		fontWeight: 400,
		// border: '1px solid rgba(0,0,0,0)',
		borderRadius: 8,
		// boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
		// boxShadow: '#bfccdf 0px 1px 6px, #bfccdf 0px 1px 4px',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
	},
	toolbarTitle: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	avatar: {
		backgroundColor: '#44475b',
		color: '#fff'
	},
	avatarLarge: {
		width: 60,
		height: 60,
		backgroundColor: '#44475b',
		color: '#fff'
	}
});

class PlayerDetail extends Component {
	getGameType(type) {
		return type.indexOf('EIGHT_BALL') !== -1
			? eightBall
			: type.indexOf('NINE_BALL') !== -1
			? nineBall
			: type.indexOf('TEN_BALL') !== -1
			? tenBall
			: null;
	}

	displayEnumType(type) {
		const typeSplit = type.split('_');
		const prefix = _.capitalize(typeSplit[0]);
		const suffix = _.capitalize(typeSplit[1]);
		let combined = '';
		typeSplit.forEach(part => {
			combined += `${_.capitalize(part)} `;
		});
		return combined;
	}

	render() {
		const { classes, match, history } = this.props;

		return (
			<Query
				query={getPlayerQuery}
				fetchPolicy="cache-and-network"
				notifyOnNetworkStatusChange={true}
				variables={{ slug: match.params.slug }}
			>
				{({ loading, error, data, subscribeToMore }) => {
					if (loading) return <LinearProgress mode="indeterminate" color="primary" />;
					if (error) return <div style={styles.container}>Error: {error.message}</div>;
					if (data && !data.user) {
						return <div>Player not found.</div>;
					}

					return (
						<SectionHeader
							title={
								<React.Fragment>
									<Avatar
										size="small"
										src={data.user && data.user.avatarImg}
										className={classes.avatar}
										style={{ marginRight: 15 }}
									>
										{data.user.name.slice(0, 1)}
									</Avatar>
									<span>{data.user.name}</span>
								</React.Fragment>
							}
							backButton={true}
							backPath="/players"
						>
							<SectionContent>
								<Grid container justify="center" spacing={24}>
									{/*<Grid item xs={11} sm={12}>
										<Link to={'/divisions'} className={classes.backLink}>
											<Typography variant="subtitle1">
												<div
													style={{
														display: 'flex',
														alignItems: 'center'
													}}
												>
													<NavigationArrowBack style={{ width: 16, height: 16 }} />
													<span style={{ marginLeft: 8 }}>Back to Divisions list</span>
												</div>
											</Typography>
										</Link>
												</Grid>*/}
									<Grid item xs={12} sm={12} md={8}>
										<Grid container justify="center" spacing={24}>
											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20,
															paddingBottom: 20
														}}
													>
														<span>Player Details</span>
														<div style={{ display: 'flex', alignItems: 'center' }}>
															<Button
																variant="text"
																color="primary"
																size="small"
																onClick={e => {
																	e.preventDefault();
																	history.push(`/players/${data.user.slug}/edit`);
																}}
															>
																Edit
															</Button>
															<Button
																variant="text"
																color="primary"
																size="small"
																onClick={e => {
																	e.preventDefault();
																	this.handleConfirmDialog(data.user);
																}}
																style={{
																	marginLeft: 10,
																	// borderColor: 'rgb(225, 0, 80)',
																	color: 'rgb(225, 0, 80)'
																}}
															>
																Remove
															</Button>
														</div>
													</Typography>

													<div
														style={{
															paddingTop: 0,
															paddingLeft: 20,
															paddingRight: 20,
															paddingBottom: 20
														}}
													>
														<Grid container spacing={16}>
															<Grid item xs={12} sm={4}>
																<Typography variant="caption" gutterBottom>
																	Username
																</Typography>
																<Typography variant="subtitle1" gutterBottom>
																	{data.user.username || 'N/A'}
																</Typography>
															</Grid>
															<Grid item xs={12} sm={4}>
																<Typography variant="caption" gutterBottom>
																	Player Number
																</Typography>
																<Typography variant="subtitle1" gutterBottom>
																	{data.user.playerNumber || 'N/A'}
																</Typography>
															</Grid>
															<Grid item xs={12} sm={4}>
																<Typography variant="caption" gutterBottom>
																	Status
																</Typography>
																<Typography variant="subtitle1" gutterBottom>
																	{data.user.isActive === true
																		? 'Active'
																		: 'Not Active'}
																</Typography>
															</Grid>
															<Grid item xs={12} sm={4}>
																<Typography variant="caption" gutterBottom>
																	Location
																</Typography>
																<Typography variant="subtitle1" gutterBottom>
																	{data.user.city ? (
																		<React.Fragment>
																			{data.user.city}, {data.user.state}{' '}
																			{data.user.country}
																		</React.Fragment>
																	) : (
																		'N/A'
																	)}
																</Typography>
															</Grid>
														</Grid>
													</div>
													<Divider />
													<div
														style={{
															padding: 20,
															display: 'flex',
															alignItems: 'center',
															backgroundColor: '#fff'
														}}
													>
														<Button
															variant="contained"
															color="primary"
															size="large"
															style={{ marginRight: 10 }}
															onClick={() => {
																history.push(
																	`/challenges/new/?opponentId=${data.user.id}`
																);
															}}
														>
															Create challenge invite
														</Button>
													</div>
													{/*<pre style={{ fontSize: 12 }}>{JSON.stringify(data, null, 2)}</pre>*/}
												</Card>
											</Grid>

											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 10,
															paddingLeft: 20,
															paddingRight: 10,
															paddingBottom: 10,
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between'
														}}
													>
														<div>Teams</div>
														<div>
															<IconButton>
																<AddCircleOutline />
															</IconButton>
														</div>
													</Typography>
													{data.user.teams.length > 0 ? (
														<List
															defaultValue={0}
															style={{
																// marginTop: 20,
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															{data.user.teams.map((team, index) => {
																return (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																		onClick={() =>
																			history.push(`/teams/${team.slug}`)
																		}
																		button
																	>
																		<Avatar size="small" className={classes.avatar}>
																			{team.name.slice(0, 1)}
																		</Avatar>
																		<ListItemText
																			primary={team.name}
																			secondary={`${
																				team.divisions.length
																			} Divisions`}
																		/>
																	</ListItem>
																);
															})}
														</List>
													) : (
														<ZeroState
															title="This player has not joined any teams yet"
															style={{
																marginLeft: 15,
																marginRight: 15,
																marginBottom: 15
															}}
														>
															<Button
																variant="outlined"
																color="primary"
																size="small"
																onClick={e => {
																	e.preventDefault();
																	history.push(`/teams/new`);
																}}
															>
																Add Team
															</Button>
														</ZeroState>
													)}
												</Card>
											</Grid>
											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 10,
															paddingLeft: 20,
															paddingRight: 10,
															paddingBottom: 10,
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between'
														}}
													>
														<div>Divisions</div>
														<div>
															<IconButton>
																<AddCircleOutline />
															</IconButton>
														</div>
													</Typography>
													{data.user.divisions.length > 0 ? (
														<List
															defaultValue={0}
															style={{
																// marginTop: 20,
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															{data.user.divisions.map((division, index) => {
																return (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																		onClick={() =>
																			history.push(`/divisions/${division.slug}`)
																		}
																		button
																	>
																		<Avatar
																			size="small"
																			src={this.getGameType(division.type)}
																			className={classes.avatar}
																		/>
																		<ListItemText
																			primary={division.name}
																			secondary={this.displayEnumType(
																				division.type
																			)}
																		/>
																	</ListItem>
																);
															})}
														</List>
													) : (
														<ZeroState
															title="This player has not joined any divisions yet"
															style={{
																marginLeft: 15,
																marginRight: 15,
																marginBottom: 15
															}}
														>
															<Button
																variant="outlined"
																color="primary"
																size="small"
																onClick={e => {
																	e.preventDefault();
																	history.push(`/divisions/new`);
																}}
															>
																Add Division
															</Button>
														</ZeroState>
													)}
												</Card>
											</Grid>
											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 10,
															paddingLeft: 20,
															paddingRight: 10,
															paddingBottom: 10,
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between'
														}}
													>
														<div>Tournaments</div>
														<div>
															<IconButton>
																<AddCircleOutline />
															</IconButton>
														</div>
													</Typography>
													{data.user.tournaments.length > 0 ? (
														<List
															defaultValue={0}
															style={{
																// marginTop: 20,
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															{data.user.tournaments.map((tournament, index) => {
																return (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																		onClick={() =>
																			history.push(
																				`/tournaments/${tournament.slug}`
																			)
																		}
																		button
																	>
																		<Avatar size="small">
																			{tournament.name.slice(0, 1)}
																		</Avatar>
																		<ListItemText
																			primary={tournament.name}
																			secondary={moment(
																				tournament.startTime
																			).format('lll')}
																		/>
																	</ListItem>
																);
															})}
														</List>
													) : (
														<ZeroState
															title="This player has not played any tournaments yet"
															style={{
																marginLeft: 15,
																marginRight: 15,
																marginBottom: 15
															}}
														>
															<Button
																variant="outlined"
																color="primary"
																size="small"
																onClick={e => {
																	e.preventDefault();
																	history.push(`/tournaments/new`);
																}}
															>
																Add Tournament
															</Button>
														</ZeroState>
													)}
												</Card>
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={12} sm={4}>
										<Grid container justify="center" spacing={24}>
											<Grid item xs={12}>
												<Card>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
													>
														Player Ratings
													</Typography>
													<br />
													{data.user && data.user.homeLocation ? (
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center'
															}}
														>
															<Grid container spacing={16}>
																{data.user.playerRatings.map((rating, index) => {
																	return (
																		<Grid item xs={12} sm={6} key={index}>
																			<Typography variant="caption" gutterBottom>
																				{rating.type !== 'CUSTOM'
																					? this.displayEnumType(rating.type)
																					: rating.customName}
																			</Typography>
																			<Typography
																				variant="subtitle1"
																				gutterBottom
																			>
																				{rating.rating}
																			</Typography>
																		</Grid>
																	);
																})}
															</Grid>
														</div>
													) : (
														<ZeroState title="No player ratings defined yet" />
													)}
												</Card>
											</Grid>

											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 20,
															paddingLeft: 20,
															paddingRight: 20,
															paddingBottom: 20
														}}
													>
														Home Location
													</Typography>
													{data.user && data.user.homeLocation ? (
														<List
															defaultValue={0}
															style={{
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															<ListItem
																className={classes.listItem}
																onClick={() =>
																	history.push(
																		`/locations/${data.user.homeLocation.slug}`
																	)
																}
																button
															>
																<Avatar size="small" className={classes.avatar}>
																	<LocationOn
																		style={{
																			height: 22
																		}}
																	/>
																</Avatar>
																<ListItemText
																	primary={data.user.homeLocation.name}
																	secondary={`${data.user.homeLocation.address}, ${
																		data.user.homeLocation.city
																	}, ${data.user.homeLocation.state} ${
																		data.user.homeLocation.zip
																	}`}
																/>
															</ListItem>
														</List>
													) : (
														<ZeroState
															title="No home location chosen yet"
															style={{
																marginLeft: 15,
																marginRight: 15,
																marginBottom: 15
															}}
														/>
													)}
												</Card>
											</Grid>

											<Grid item xs={12}>
												<Card nopadding>
													<Typography
														variant="h6"
														color="inherit"
														className={classes.toolbarTitle}
														style={{
															paddingTop: 10,
															paddingLeft: 20,
															paddingRight: 10,
															paddingBottom: 10,
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between'
														}}
													>
														<div>Locations</div>
														<div>
															<IconButton>
																<AddCircleOutline />
															</IconButton>
														</div>
													</Typography>
													{data.user.locations && data.user.locations.length > 0 ? (
														<List
															defaultValue={0}
															style={{
																paddingTop: 0,
																paddingLeft: 0,
																paddingRight: 0,
																paddingBottom: 10
															}}
														>
															{data.user.locations.map((location, index) => {
																return (
																	<ListItem
																		key={index}
																		className={classes.listItem}
																		onClick={() =>
																			history.push(`/locations/${location.slug}`)
																		}
																		button
																	>
																		<Avatar size="small" className={classes.avatar}>
																			<LocationOn
																				style={{
																					height: 22
																				}}
																			/>
																		</Avatar>
																		<ListItemText
																			primary={location.name}
																			secondary={`${location.address}, ${
																				location.city
																			}, ${location.state} ${location.zip}`}
																		/>
																	</ListItem>
																);
															})}
														</List>
													) : (
														<ZeroState
															title="No locations yet"
															style={{
																marginLeft: 15,
																marginRight: 15,
																marginBottom: 15
															}}
														/>
													)}
												</Card>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</SectionContent>
						</SectionHeader>
					);
				}}
			</Query>
		);
	}
}

PlayerDetail.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PlayerDetail);
