import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import TextInput from '../../components/forms/TextInputFormik';
import SelectField from '../../components/forms/SelectFormik';
import SwitchInput from '../../components/forms/SwitchFormik';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import Button from '@material-ui/core/Button';
import { getDivisionQuery } from './queries/getDivisionQuery';
import { updateDivisionMutation } from './mutations/updateDivisionMutation';
import { createDivisionMutation } from './mutations/createDivisionMutation';

const getInitialValues = division => ({
	id: (division && division.id) || '',
	name: (division && division.name) || '',
	description: (division && division.description) || '',
	type: (division && division.type) || '',
	isActive: (division && division.isActive) || ''
});

const DivisionSchema = Yup.object().shape({
	name: Yup.string()
		.min(3, 'Must be longer than 3 characters')
		.required('Required')
	// last_name: Yup.string()
	//   .min(2, 'Must be longer than 2 characters')
	//   .max(20, 'Nice try, nobody has a last name that long')
	//   .required('Required'),
	// birthday: Yup.date()
	//   .required('You should add a correct birthday date'),
	// job: Yup.string()
	//   .required('Job is required'),
	// email: Yup.string()
	//   .email('Invalid email address')
	//   .required('Required Email'),
	// password: Yup.string()
	//   .required('Required Password'),
	// password_confirmation: Yup.string()
	//   .oneOf([Yup.ref('password')], 'Password and confirmation must been the same')
	//   .required('Required Password Confirmation'),
});

class DivisionForm extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(values, { setSubmitting, setErrors }) {
		// console.log('Edit Division Form submitted', values);
		setSubmitting(true);
		if (!this.props.slug) {
			this.props
				.mutate({
					mutation: createDivisionMutation,
					variables: {
						input: {
							name: values.name,
							description: values.description,
							type: values.type,
							isActive: values.isActive,
							operatorId: this.context.authUser.id
							// matchFormat: values.matchFormat
						}
					}
				})
				.then(({ data }) => {
					// console.log(data);
					this.props.history.push(`/divisions/${data.createDivision.slug}`);
				})
				.catch(error => {
					// console.log(error);
					setSubmitting(false);
					setErrors({ name: ' ', description: ' ', form: error });
				});
		} else {
			this.props
				.mutate({
					mutation: updateDivisionMutation,
					variables: {
						input: {
							id: values.id,
							name: values.name,
							description: values.description,
							type: values.type,
							isActive: values.isActive
							// matchFormat: values.matchFormat
						}
					}
				})
				.then(({ data }) => {
					// console.log(data);
					this.props.history.push(`/divisions/${data.updateDivision.slug}`);
				})
				.catch(error => {
					// console.log(error);
					setSubmitting(false);
					setErrors({ name: ' ', description: ' ', form: error });
				});
		}
	}

	render() {
		const { data: { division, loading, error } } = this.props;
		if (loading) return null;
		// console.log('in division form', division);

		return (
			<Formik
				enableReinitialize
				initialValues={getInitialValues(division)}
				validationSchema={DivisionSchema}
				onSubmit={this.handleSubmit}
				render={props => {
					const {
						// values,
						// touched,
						errors,
						dirty,
						isSubmitting,
						// handleChange,
						// handleBlur,
						// handleSubmit,
						handleReset
					} = props;
					return (
						<Form>
							<Field type="text" name="name" label="Name" component={TextInput} />
							<div className="spacer" />
							<Field type="text" name="description" label="Description" component={TextInput} />
							<div className="spacer" />
							{/*<Field
								type="text"
								name="matchFormat"
								label="Match Format"
								component={SelectField}
								options={[
									{
										label: 'Singles',
										value: 'SINGLES'
									},
									{ label: 'Doubles', value: 'DOUBLES' },
									{ label: 'Scotch Doubles', value: 'SCOTCH_DOUBLES' },
									{ label: '3-person Teams', value: 'THREE_PERSON_TEAMS' },
									{ label: '5-person Teams', value: 'FIVE_PERSON_TEAMS' }
								]}
								style={{ marginBottom: 20 }}
							/>*/}
							<Field
								type="text"
								name="type"
								label="Type"
								component={SelectField}
								options={[
									{
										label: '9-Ball Singles',
										value: 'NINE_BALL_SINGLES'
									},
									{ label: '9-Ball Doubles', value: 'NINE_BALL_DOUBLES' },
									{ label: '9-Ball Scotch Doubles', value: 'NINE_BALL_SCOTCH_DOUBLES' },
									{ label: '9-Ball Teams', value: 'NINE_BALL_TEAMS' },
									{
										label: '8-Ball Singles',
										value: 'EIGHT_BALL_SINGLES'
									},
									{ label: '8-Ball Doubles', value: 'EIGHT_BALL_DOUBLES' },
									{ label: '8-Ball Scotch Doubles', value: 'EIGHT_BALL_SCOTCH_DOUBLES' },
									{ label: '8-Ball Teams', value: 'EIGHT_BALL_TEAMS' }
								]}
							/>
							<div className="spacer" />
							<Field
								// type="checkbox"
								// checked={division.isActive}
								id="isActive"
								name="isActive"
								label="Active"
								component={SwitchInput}
							/>
							<div className="spacer" />
							{this.props.slug ? (
								<Button
									variant="contained"
									color="primary"
									disabled={isSubmitting || !isEmpty(errors) || !dirty}
									type="submit"
								>
									{isSubmitting ? 'Saving...' : 'Update'}
								</Button>
							) : (
								<Button
									variant="contained"
									color="primary"
									disabled={isSubmitting || !isEmpty(errors) || !dirty}
									type="submit"
								>
									{isSubmitting ? 'Saving...' : 'Create'}
								</Button>
							)}

							<Button
								variant="outlined"
								onClick={handleReset}
								disabled={!dirty || isSubmitting}
								style={{ marginLeft: 10 }}
							>
								Reset
							</Button>
						</Form>
					);
				}}
			/>
		);
	}
}

DivisionForm.contextTypes = {
	authUser: PropTypes.object,
	getUserProfile: PropTypes.func,
	isAuthenticated: PropTypes.bool,
	changeLoginState: PropTypes.func,
	signIn: PropTypes.func,
	signOut: PropTypes.func
};

const WithGraphQL = compose(
	graphql(getDivisionQuery, {
		options: ({ slug }) => ({ variables: { slug } })
	}),
	graphql(createDivisionMutation, {
		options: ({ input }) => ({ variables: { input } })
	}),
	graphql(updateDivisionMutation, {
		options: ({ input }) => ({ variables: { input } })
	})
)(DivisionForm);

export default withRouter(WithGraphQL);
