import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import avatarImg from '../../assets/images/contemplative-reptile.jpg';
import firstPlace from '../../assets/images/podium-1st.png';
import secondPlace from '../../assets/images/podium-2nd.png';
import thirdPlace from '../../assets/images/podium-3rd.png';
import '../../assets/css/podium.css';

const styles = theme => ({
	avatar: {
		margin: 0
	},
	bigAvatar: {
		width: 40,
		height: 40
	}
});

class Podium extends Component {
	render() {
		const { classes } = this.props;

		return (
			<div className="podium">
				<div className="place position2">
					<div className="participant">
						<div className="info">
							<Avatar src={secondPlace} />
							<span style={{ marginTop: 5 }}>Jayson Shaw</span>
						</div>
					</div>
					<div className="base" />
					<div className="number">
						<Typography variant="subheading">
							<b>2nd (3,150)</b>
						</Typography>
					</div>
				</div>
				<div className="place position1">
					<div className="participant">
						<div className="info">
							<Avatar src={firstPlace} />
							<span style={{ marginTop: 5 }}>Chris Melling</span>
						</div>
					</div>
					<div className="base" />
					<div className="number">
						<Typography variant="subheading">
							<b>1st (5,000)</b>
						</Typography>
					</div>
				</div>
				<div className="place position3">
					<div className="participant">
						<div className="info">
							<Avatar src={thirdPlace} />
							<span style={{ marginTop: 5 }}>Klenti Kaci</span>
						</div>
					</div>
					<div className="base" />
					<div className="number">
						<Typography variant="subheading">3rd (2,000)</Typography>
					</div>
				</div>
				<div className="place position3">
					<div className="participant">
						<div className="info">
							<Avatar src={thirdPlace} />
							<span style={{ marginTop: 5 }}>Ioan Ladanyi</span>
						</div>
					</div>
					<div className="base" />
					<div className="number">
						<Typography variant="subheading">3rd (2,000)</Typography>
					</div>
				</div>
			</div>
		);
	}
}

Podium.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Podium);
